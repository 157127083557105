import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { NewPolicyFormState } from "../../redux/reducers/NewPolicyFormReducer";
import { MetaDataLoaderState } from "../../redux/reducers/MetaDataLoaderReducer";
import "./CategoryOptions.css";
import React from "react";
import { MAX_UNITS_TYPE, MOD_INTRACTION_TYPE } from "../../redux/actions/NewPolicyFormActionTypes";
import { getMaxUnitsLkpData, getModIntractionLkpData } from "../../redux/actions/LookupsActions";
import StarIcon from '@mui/icons-material/Star';

const CategoryThirtyFive = ({ edit, viewMode }) => {
    const formState: NewPolicyFormState = useSelector(
        (state: any) => state.newPolicyForm
    );

    const updatedState1: MetaDataLoaderState = useSelector(
      (state: any) => state.metaDataLoader
    );


const dispatch = useDispatch();

useEffect(() => {
  if (updatedState1.maxUnitsLkpData.length == 0) {
    getMaxUnitsLkpData(dispatch);
  }
  if (updatedState1.ModInteractionLkpData.length == 0) {
    getModIntractionLkpData(dispatch);
  }
}, []);

const MaxUnitsLinkLkp = updatedState1.maxUnitsLkpData?.map((k) => {
  return { label: k.description, value: k.maxUnitsLkpKey }
})

const ModIntractionLkp = updatedState1.ModInteractionLkpData?.map((k) => {
  return { label: k.mitDesc, value: k.mitKey }
})

  return (
        <div>
           <GridContainer>
                  <GridItem sm={2} md={2} xs={2}>
                    <small
                      style={{
                        fontSize: 13,
                        color: "black",
                        fontWeight: 400,
                        position: "relative",
                        top: 15,
                      }}
                    >
                      Max Units Type {<StarIcon style={{position:"relative", bottom:"4px",fontSize:"7px"}}/>}
                    </small>
                  </GridItem>
                  <GridItem sm={4} md={4} xs={4}>
                    <div className="catkey">
                      <CustomSelect
                        error={formState.errors?.maxUnitsType}
                        options={MaxUnitsLinkLkp}
                        value={MaxUnitsLinkLkp.filter(function (option) {
                          return option.value == formState.maxUnitsType;
                        })}
                        isDisabled={!edit ? undefined : viewMode}
                        catTitle={MaxUnitsLinkLkp.filter((option, index) => {
                          if (formState.maxUnitsType == option.value) {
                            return option
                          }
                        })}
                        onSelect={(event) => {
                          dispatch({
                            type: MAX_UNITS_TYPE,
                            payload: event.value,
                          });
                        }}
                      />
                    </div>
                  </GridItem>
                  <GridItem sm={2} md={2} xs={2}>
                    <small
                      style={{
                        fontSize: 13,
                        color: "black",
                        fontWeight: 400,
                        position: "relative",
                        top: 15,
                      }}
                    >
                      Modifier Interaction Type {<StarIcon style={{position:"relative", bottom:"4px",fontSize:"7px"}}/>}
                    </small>
                  </GridItem>
                  <GridItem sm={4} md={4} xs={4}>
                    <div className="catkey">
                      <CustomSelect
                        error={formState.errors?.modIntractionType}
                        options={ModIntractionLkp}
                        value={ModIntractionLkp.filter(function (option) {
                          return option.value == formState.modIntractionType;
                        })}
                        isDisabled={!edit ? undefined : viewMode}
                        catTitle={ModIntractionLkp.filter((option, index) => {
                          if (formState.modIntractionType == option.value) {
                            return option
                          }
                        })}
                        onSelect={(event) => {
                          dispatch({
                            type: MOD_INTRACTION_TYPE,
                            payload: event.value,
                          });
                        }}
                      />
                    </div>
                  </GridItem>
                </GridContainer>
        </div>
    );

};
export default CategoryThirtyFive;
import { apiUrl, policyConfigUrl } from "../../configs/apiUrls";
import { store } from "../store";
import { SET_IS_LOADING } from "./actionTypes";
import { GET_DETAILS_TOTAL_DATA, GET_FILTERD_POLICIES_DATA, GET_FILTERD_REASON_DATA, GET_FILTERD_SUB_POLICIES_DATA, GET_MEDICAL_TOTAL_DATA, GET_POLICY_DETAILS_FROM_REASON, GET_PRODUCT_DATA, GET_REASON_TREE_DATA, GET_SUB_TOTAL_DATA, GET_USED_CAT } from "./policyViewType";
import swal from 'sweetalert2';
import { navyColor } from "../../assets/jss/material-kit-react";

import { saveAs } from 'file-saver';


  export async function getUsedCategories(dispatch: typeof store.dispatch) {
    await fetch(policyConfigUrl + apiUrl.usedCat)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        dispatch({ type: GET_USED_CAT, payload: data });
      });
  }
  export async function getPolicyTotalData(
    dispatch: typeof store.dispatch,
  ) {
      await fetch(policyConfigUrl + apiUrl.policyTotalData)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        dispatch({ type: GET_FILTERD_POLICIES_DATA, payload: data });
      });
  }

  export async function searchViewPolicy(dispatch: typeof store.dispatch, data) {
    // dispatch({ type: SET_IS_LOADING, payload: true });
    await fetch(policyConfigUrl + apiUrl.policyViewData, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          // throw new Error(response.statusText);
        }
        // dispatch({ type: SET_IS_LOADING, payload: false });
        return response.json();
      })
      .then((data) => {
        dispatch({ type: GET_MEDICAL_TOTAL_DATA, payload: data });
        // dispatch({ type: SET_IS_LOADING, payload: false });
      });
  }


  export async function subPolicyData1(
    dispatch: typeof store.dispatch,
    data,
  ) {
      // dispatch({ type: SET_IS_LOADING, payload: true });
    await fetch(policyConfigUrl + apiUrl.filterSubPol, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          // throw new Error(response.statusText);
        }
        // dispatch({ type: SET_IS_LOADING, payload: false });
        return response.json();
      })
      .then((data) => {
        dispatch({ type: GET_FILTERD_SUB_POLICIES_DATA, payload: data });
        // dispatch({ type: SET_IS_LOADING, payload: false });
      });
  }



  
  export async function filterdReasonData(
    dispatch: typeof store.dispatch,
    data,
  ) {
      // dispatch({ type: SET_IS_LOADING, payload: true });
    await fetch(policyConfigUrl + apiUrl.filterReason, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          // throw new Error(response.statusText);
        }
        // dispatch({ type: SET_IS_LOADING, payload: false });
        return response.json();
      })
      .then((data) => {
        dispatch({ type: GET_FILTERD_REASON_DATA, payload: data });
      });
  }

  export async function filterdPolicies(
    dispatch: typeof store.dispatch,
    data,
  ) {
      // dispatch({ type: SET_IS_LOADING, payload: true });
    await fetch(policyConfigUrl + apiUrl.filterdPolicies, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          // throw new Error(response.statusText);
        }
        // dispatch({ type: SET_IS_LOADING, payload: false });
        return response.json();
      })
      .then((data) => {
        dispatch({ type: GET_FILTERD_POLICIES_DATA, payload: data });
      });
  }
  export async function ExportPolicies(dispatch: typeof store.dispatch, data, buttonType) {
    let policyNumber = data.map((k) => {
      return {
        policyNumber: k.policyNumber,
        medicalPolicyKeyFk:k.medicalPolicyKey
      };
    });
  
    try {
      dispatch({ type: SET_IS_LOADING, payload: true });
  
      const response = await fetch(policyConfigUrl + apiUrl.ExportPolicyView + '/' + buttonType, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(policyNumber),
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.statusText}`);
      }
  
      const blob = await response.blob();
  
      // Trigger file download using FileSaver.js
      let FileName = "Policy_Details" + "-" + buttonType +".docx"
      saveAs(blob, FileName);
  
      dispatch({ type: SET_IS_LOADING, payload: false });
  
      swal.fire({
        icon: 'success',
        text: 'File Downloaded Successfully!.',
        confirmButtonColor: navyColor,
        confirmButtonText: 'OK',
      });
  
    } catch (error) {  
      dispatch({ type: SET_IS_LOADING, payload: false });
      swal.fire({
        icon: 'error',
        text: 'Please Reach out It Team',
        confirmButtonColor: navyColor,
        confirmButtonText: 'OK',
      });
    }
  }

  export async function uploadClaimProcssingIntroduction(
    dispatch: typeof store.dispatch,
    file
  ) {
    dispatch({ type: SET_IS_LOADING, payload: true });
      fetch(policyConfigUrl + apiUrl.uploadPolicyView, {
        method: 'POST',
        body: file,
      })
      setTimeout(() => {
        dispatch({ type: SET_IS_LOADING, payload: false });
        swal.fire({
          icon: 'success',
          text: "File Uploaded Successfully",
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK'
        });
      }, 2000)
    }
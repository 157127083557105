import { IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { navyColor } from "../../assets/jss/material-kit-react";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import "../../components/FontFamily/fontFamily.css";
import "../LookUps/LookUp.css";
import * as FileSaver from "file-saver";
import { LookUpState } from "../../redux/reducers/LookUpReducer";
import { Add } from "@material-ui/icons";
import XLSX from "xlsx";
import CustomButton from "../../components/CustomButtons/CustomButton";
import CustomHeader from "../../components/CustomHeaders/CustomHeader";
import Paragraph from "../../components/Paragraph/Paragraph";
import AgGrids from "../../components/TableGrid/AgGrids";
import Template from "../../components/Template/Template";
import { MODIFIER_PAY_PERCENTAGE_DATA } from "../../redux/actions/NewPolicyFormActionTypes";
import { UserState } from "../../redux/reducers/UserReducer";
import "../LookUps/LookUp.css";
import BillTypeLookup from "./BillTypeLookup";
import BoTypeLookup from "./BoTypeLookup";
import BwTypeLookup from "./BwTypeLookup";
import CciLookup from "./CciLookup";
import CciRationaleLookup from "./CciRationaleLookup";
import ConditionCodeLookup from "./ConditionCodeLookup";
import {
  BILL_TYPE_LKP,
  BO_TYPE_LKP,
  BW_TYPE_LKP,
  CCI_LKP,
  CCI_RATIONALE_LKP,
  CONDITION_CODE_LKP,
  MAX_UNITS_LKP,
  MAX_UNITS_TYPES,
  MIN_MAX_AGE_LKP,
  MODIFIER_INTERACTION_LKP,
  MODIFIER_PAY_PERCENTAGE_LKP,
  MODIFIER_PAY_PERCENTAGE_LKP_DATA,
  MODIFIER_PRIORITY_LKP,
  MOD_LKP,
  MUE_RATIONALE_LKP,
  POLICY_CATEGORY_LKP,
  POS_LKP,
  REASON_CODE_LKP,
  REVENUE_CODE_LKP,
  SAME_OR_SIMILAR_CODE_LKP,
  SPECS_LKP,
  SUB_SPEC_LKP,
} from "./LookUpConsts";
import MaxUnitsLookup from "./MaxUnitsLookup";
import MaxUnitsType from "./MaxUnitsType";
import MinMaxAgeLookup from "./MinMaxAgeLookup";
import ModLookup from "./ModLookup";
import ModifierInteractionLookup from "./ModifierInteractionLookup";
import ModifierPayPercentage from "./ModifierPayPercentage";
import ModifierPriorityLookUp from "./ModifierPriorityLookup";
import MueRationaleLkp from "./MueRationaleLkp";
import PolicyCategoryLookup from "./PolicyCategoryLookup";
import PosLookup from "./PosLookup";
import ReasonCodeLookup from "./ReasonCodeLookup";
import RevenueCodeLookup from "./RevenueCodeLookup";
import SameOrSimilarCodeLookup from "./SameOrSimilarCodeLookup";
import SpecLookup from "./SpecLookup";
import SubSpecLookup from "./SubSpecLookup";
import { AccessForExport } from "../../redux/actions/AccessForExport";
import { exportedExcelFileData } from "../../components/ExportExcel/ExportExcelFile";
import { fetchLookupData } from "../../redux/actions/LookupsActions";
import { ModifierPayPercentageData } from "../../redux/actions/NewPolicyAction";
const _ = require("lodash");
function alphaOrder(a, b) {
  const name1 = a.label.toUpperCase();
  const name2 = b.label.toUpperCase();

  let comparison = 0;

  if (name1 > name2) {
    comparison = 1;
  } else if (name1 < name2) {
    comparison = -1;
  }
  return comparison;
}

const selectedLKPOptions = [
  { value: SPECS_LKP, label: SPECS_LKP },
  { value: SUB_SPEC_LKP, label: SUB_SPEC_LKP },
  { value: MIN_MAX_AGE_LKP, label: MIN_MAX_AGE_LKP },
  { value: REVENUE_CODE_LKP, label: REVENUE_CODE_LKP },
  { value: BILL_TYPE_LKP, label: BILL_TYPE_LKP },
  { value: CONDITION_CODE_LKP, label: CONDITION_CODE_LKP },
  { value: MOD_LKP, label: MOD_LKP },
  { value: POS_LKP, label: POS_LKP },
  { value: POLICY_CATEGORY_LKP, label: POLICY_CATEGORY_LKP },
  { value: REASON_CODE_LKP, label: REASON_CODE_LKP },
  { value: CCI_RATIONALE_LKP, label: CCI_RATIONALE_LKP },
  { value: CCI_LKP, label: CCI_LKP },
  { value: MUE_RATIONALE_LKP, label: MUE_RATIONALE_LKP },
  { value: BO_TYPE_LKP, label: BO_TYPE_LKP },
  { value: BW_TYPE_LKP, label: BW_TYPE_LKP },
  { value: MAX_UNITS_TYPES, label: MAX_UNITS_TYPES },
  { value: MAX_UNITS_LKP, label: MAX_UNITS_LKP },
  { value: MODIFIER_PRIORITY_LKP, label: MODIFIER_PRIORITY_LKP },
  { value: MODIFIER_INTERACTION_LKP, label: MODIFIER_INTERACTION_LKP },
  { value: SAME_OR_SIMILAR_CODE_LKP, label: SAME_OR_SIMILAR_CODE_LKP },
  { value: MODIFIER_PAY_PERCENTAGE_LKP, label: MODIFIER_PAY_PERCENTAGE_LKP },
];

const ViewConfig = (props) => {
  const [selectedLookup, setSelectedLookUp] = useState("");
  const [selectedModifierPayLkp, setSelectedModifierPayLkp] = useState("");
  const updatedState: LookUpState = useSelector(
    (state: any) => state.lookupReducer
  );
  const dispatch = useDispatch();

  const [openLkp, setOpenLkp] = React.useState(false);
  const roleState: UserState = useSelector((state: any) => state.userReducer);

  let roles = roleState.roleName;
  let Role = JSON.stringify(roles);
  let adminIdx = Role.toLocaleLowerCase().search("admin");



  function openLookUp() {
    setOpenLkp(true);
  }

  const showAddIcon = () => {
    let flag = false;
    if (adminIdx > 0 && selectedLookup) {
      if (
        selectedLookup == "Modifier Pay Percentage Lkp" ||
        selectedLookup == "Modifier Interaction" ||
        selectedLookup == "Modifier Priority" ||
        selectedLookup == "Same Or Similar Code"
      ) {
        flag = false;
      } else {
        flag = true;
      }
    } else {
      flag = false;
    }
    return flag;
  };

  const fromLkpchilds = (msg) => {
    setOpenLkp(msg);
  };
  const [LookUpColums, setLookUpColums] = useState([]);
  const [LookUpRowData, setLookUpRowData] = useState([]);
  const [numberOfRows, setNumberOfRows] = useState(0);

  const onFilterChanged = (params) => {
    setNumberOfRows(params.api.getDisplayedRowCount());
  };
  const allLookUpColumns = (col) => {
    setLookUpColums(col);
  };
  const allLookUpRowData = (row) => {
    setLookUpRowData(row);
  };
  function showTable() {
    let flag = false;
    if (selectedLookup && LookUpRowData.length > 0) {
      flag = true;
    }
    return flag
  }
  const modifierPayPercentageLkp =
    updatedState.getModifierPayPercentageLkp?.map((mp) => {
      return { label: mp.mppKey + "-" + mp.description, value: mp.mppKey };
    });
  useEffect(() => {
    if (updatedState.getModifierPayPercentageLkp.length == 0) {
      let lkpName = MODIFIER_PAY_PERCENTAGE_LKP_DATA;
      fetchLookupData(dispatch,lkpName);
    }
  }, []);
  const gridIconStyle = {
    position: "absolute",
    float: "right",
    top: "121px",
    display: "inline",
    right: "100px",
  };

  function showLookUps(selectedLkpValue, selectedModifierPayLkp) {
    switch (selectedLkpValue) {
      case SPECS_LKP: {
        return (
          <SpecLookup
            lkpInput={{
              selectedLookup,
              fromLkpchilds,
              openLkp,
              allLookUpColumns,
              allLookUpRowData,
              Role,
            }}
          />
        );
      }
      case SUB_SPEC_LKP: {
        return (
          <SubSpecLookup
            lkpInput={{
              selectedLookup,
              fromLkpchilds,
              openLkp,
              allLookUpColumns,
              allLookUpRowData,
              Role,
            }}
          />
        );
      }
      case MIN_MAX_AGE_LKP: {
        return (
          <MinMaxAgeLookup
            lkpInput={{
              selectedLookup,
              fromLkpchilds,
              openLkp,
              allLookUpColumns,
              allLookUpRowData,
              Role,
            }}
          />
        );
      }
      case BILL_TYPE_LKP: {
        return (
          <BillTypeLookup
            lkpInput={{
              selectedLookup,
              fromLkpchilds,
              openLkp,
              allLookUpColumns,
              allLookUpRowData,
              Role,
            }}
          />
        );
      }
      case REVENUE_CODE_LKP: {
        return (
          <RevenueCodeLookup
            lkpInput={{
              selectedLookup,
              fromLkpchilds,
              openLkp,
              allLookUpColumns,
              allLookUpRowData,
              Role,
            }}
          />
        );
      }
      case CONDITION_CODE_LKP: {
        return (
          <ConditionCodeLookup
            lkpInput={{
              selectedLookup,
              fromLkpchilds,
              openLkp,
              allLookUpColumns,
              allLookUpRowData,
              Role,
            }}
          />
        );
      }
      case MOD_LKP: {
        return (
          <ModLookup
            lkpInput={{
              selectedLookup,
              fromLkpchilds,
              openLkp,
              allLookUpColumns,
              allLookUpRowData,
              Role,
            }}
          />
        );
      }
      case POS_LKP: {
        return (
          <PosLookup
            lkpInput={{
              selectedLookup,
              fromLkpchilds,
              openLkp,
              allLookUpColumns,
              allLookUpRowData,
              Role,
            }}
          />
        );
      }
      case POLICY_CATEGORY_LKP: {
        return (
          <PolicyCategoryLookup
            lkpInput={{
              selectedLookup,
              fromLkpchilds,
              openLkp,
              allLookUpColumns,
              allLookUpRowData,
              Role,
            }}
          />
        );
      }
      case REASON_CODE_LKP: {
        return (
          <ReasonCodeLookup
            lkpInput={{
              selectedLookup,
              fromLkpchilds,
              openLkp,
              allLookUpColumns,
              allLookUpRowData,
              Role,
            }}
          />
        );
      }
      case CCI_RATIONALE_LKP: {
        return (
          <CciRationaleLookup
            lkpInput={{
              selectedLookup,
              fromLkpchilds,
              openLkp,
              allLookUpColumns,
              allLookUpRowData,
              Role,
            }}
          />
        );
      }
      case MUE_RATIONALE_LKP: {
        return (
          <MueRationaleLkp
            lkpInput={{
              selectedLookup,
              fromLkpchilds,
              openLkp,
              allLookUpColumns,
              allLookUpRowData,
              Role,
            }}
          />
        );
      }
      case BO_TYPE_LKP: {
        return (
          <BoTypeLookup
            lkpInput={{
              selectedLookup,
              fromLkpchilds,
              openLkp,
              allLookUpColumns,
              allLookUpRowData,
              Role,
            }}
          />
        );
      }
      case BW_TYPE_LKP: {
        return (
          <BwTypeLookup
            lkpInput={{
              selectedLookup,
              fromLkpchilds,
              openLkp,
              allLookUpColumns,
              allLookUpRowData,
              Role,
            }}
          />
        );
      }
      case MAX_UNITS_TYPES: {
        return (
          <MaxUnitsType
            lkpInput={{
              selectedLookup,
              fromLkpchilds,
              openLkp,
              allLookUpColumns,
              allLookUpRowData,
              Role,
            }}
          />
        );
      }
      case MODIFIER_PAY_PERCENTAGE_LKP: {
        return (
          <ModifierPayPercentage
            lkpInput={{
              selectedLookup,
              fromLkpchilds,
              openLkp,
              allLookUpColumns,
              allLookUpRowData,
              Role,
              selectedModifierPayLkp,
            }}
          />
        );
      }
      case CCI_LKP: {
        return (
          <CciLookup
            lkpInput={{
              selectedLookup,
              fromLkpchilds,
              openLkp,
              allLookUpColumns,
              allLookUpRowData,
              Role,
            }}
          />
        );
      }
      case MAX_UNITS_LKP: {
        return (
          <MaxUnitsLookup
            lkpInput={{
              selectedLookup,
              fromLkpchilds,
              openLkp,
              allLookUpColumns,
              allLookUpRowData,
              Role,
            }}
          />
        );
      }
      case MODIFIER_PRIORITY_LKP: {
        return (
          <ModifierPriorityLookUp
            lkpInput={{
              selectedLookup,
              fromLkpchilds,
              openLkp,
              allLookUpColumns,
              allLookUpRowData,
              Role,
            }}
          />
        );
      }
      case MODIFIER_INTERACTION_LKP: {
        return (
          <ModifierInteractionLookup
            lkpInput={{
              selectedLookup,
              fromLkpchilds,
              openLkp,
              allLookUpColumns,
              allLookUpRowData,
              Role,
            }}
          />
        );
      }
      case SAME_OR_SIMILAR_CODE_LKP: {
        return (
          <SameOrSimilarCodeLookup
            lkpInput={{
              selectedLookup,
              fromLkpchilds,
              openLkp,
              allLookUpColumns,
              allLookUpRowData,
              Role,
            }}
          />
        );
      }
    }
  }
  function showOption() {
    let flag = false;
    if (selectedLookup == MODIFIER_PAY_PERCENTAGE_LKP) {
      flag = true;
    }
    return flag;
  }

  return (
    <Template>
      <div className="row">
        <div className="col-sm-4" style={{ position: "relative", top: "7px" }}>
          <CustomHeader labelText={"View Configuration"} />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-3">
          <CustomSelect
            options={selectedLKPOptions.sort(alphaOrder)}
            onSelect={(event) => {
              if (event != null) {
                setSelectedLookUp(event.value);
                setNumberOfRows(0);

              } else {
                setSelectedLookUp(null);
                if (selectedModifierPayLkp) {
                  setSelectedModifierPayLkp(null);
                }
                setLookUpRowData([]);
              }
            }}
            value={selectedLKPOptions.filter(function (option) {
              return option?.value == selectedLookup;
            })}
          />
        </div>
        {showOption() ? (
          <>
            <div
              className="col-sm-1"
              style={{ width: "6.7%", marginTop: "10px" }}
            >
              <Paragraph labelText={"MPP Key"} />
            </div>
            <div className="col-sm-3">
              <CustomSelect
                options={modifierPayPercentageLkp}
                onSelect={async (event) => {
                  if (event != null) {
                    await ModifierPayPercentageData(dispatch, event.value);
                    setSelectedModifierPayLkp(event.value);
                  } else {
                    setSelectedModifierPayLkp(null);
                    dispatch({
                      type: MODIFIER_PAY_PERCENTAGE_DATA,
                      payload: [],
                    });
                  }
                }}
                value={modifierPayPercentageLkp.filter(function (option) {
                  return option?.value == selectedModifierPayLkp;
                })}
              />
            </div>
          </>
        ) : (
          <div className="col-sm-3" />
        )}
        <div className="col-sm-6">
          {showAddIcon() ? (
            <IconButton
              onClick={openLookUp}
              style={{
                backgroundColor: navyColor,
                float: "right",
                color: "white",
              }}
            >
              <Add />
            </IconButton>
          ) : undefined}
        </div>
      </div>
      {showTable() ? (
        <div className="row">
          <div style={{ height: window.innerHeight / 1.5 }}>
            <AgGrids
              columnDefs={LookUpColums}
              gridIconStyle={gridIconStyle}
              onFilterChanged={onFilterChanged}
              rowData={LookUpRowData}
            />
          </div>
          <div className="row">
            <small>
              Number of rows :{" "}
              {numberOfRows != 0 ? numberOfRows : LookUpRowData.length}
            </small>
          </div>
        </div>
      ) : undefined}
      {showTable() ? (
        <div className="row">
          <div className="col-sm-12">
            {AccessForExport(LookUpRowData, adminIdx) ? (
              <CustomButton
                style={{
                  backgroundColor: navyColor,
                  color: "white",
                  textTransform: "capitalize",
                  float: "right",
                  fontSize: 11,
                  padding: 4,
                  marginTop: -20,
                }}
                onClick={(e) =>
                  exportedExcelFileData(LookUpRowData, selectedLookup,selectedLookup)
                }
              >
                export
              </CustomButton>
            ) : undefined}
          </div>
        </div>
      ) : undefined}

      <div className="row">
        <>{showLookUps(selectedLookup, selectedModifierPayLkp)}</>
      </div>
    </Template>
  );
};
export default ViewConfig;

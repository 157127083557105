import { ButtonGroup, InputAdornment, Tab, Tabs } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import { MoreHoriz } from "@mui/icons-material";
import StarIcon from "@mui/icons-material/Star";
import { Box } from "@mui/system";
import Moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert2";
import {
  black,
  dangerColor,
  navyColor,
} from "../../assets/jss/material-kit-react";
import CustomButton from "../../components/CustomButtons/CustomButton";
import CustomCheckBox from "../../components/CustomCheckBox/CustomCheckBox";
import CustomHeader from "../../components/CustomHeaders/CustomHeader";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomPaper from "../../components/CustomPaper/CustomPaper";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import Dialogbox from "../../components/Dialog/DialogBox";
import "../../components/FontFamily/fontFamily.css";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import AgGrids from "../../components/TableGrid/AgGrids";
import Template from "../../components/Template/Template";
import TextArea from "../../components/TextArea/TextArea";

import {
  getAllClaimType,
  getCAT,
  getChangesById,
  getCurationVersion,
  getMedicalPolicy,
  getPolicyById,
  getReasonCodes,
  getSubPolicy,
} from "../../redux/actions";
import {
  // getPolicyNumber,
  onSaveNewPolicy,
  onUpdateNewPolicy,
  validateNewPolicyForm,
} from "../../redux/actions/NewPolicyAction";
import {
  BILLED_WITH,
  BW_TYPE_KEY,
  CAT,
  CAT_CHECK,
  CAT_DESC,
  CCI_KEY,
  CLAIM_TYPE,
  CUSTOM,
  DEACTIVATED,
  DISABLED,
  ENFO_CAT_CHECK,
  ENFO_CAT_DESC,
  ERROR_RESET_STATE,
  LINE_OR_HEADER_OR_PRINC,
  MAX_AGE_ID_CHECK,
  MAX_UNITS_TYPE,
  MAX__AGE_DESC,
  MEDICAL_CHECK,
  MEDICAL_DESC,
  MEDICAL_POLICY,
  MIN_AGE_DESC,
  MIN_AGE_ID_CHECK,
  MOD_INTRACTION_TYPE,
  NEW_POLICY_TABS,
  PATHS,
  POLICY_BILL_TYPE_DATA,
  POLICY_CONDITION_TYPE_DATA,
  PROD,
  REASON,
  REASON_CHECK,
  REASON_DESC,
  REFERENCE,
  RESET_STATE,
  ROLE_DESCRIPTION,
  SUBPOLICY_CHECK,
  SUBPOLICY_DESC,
  SUB_POLICY,
  TEMP_CAT_CHECK,
  TEMP_CAT_CODE,
  TEMP_CAT_DESC,
  TEMP_ENFORCE_BEFORE_CAT_CODE,
  TEMP_ENFO_CAT_CHECK,
  TEMP_ENFO_CAT_DESC,
  TEMP_MAX_AGE_DESC,
  TEMP_MAX__AGE_ID_CHECK,
  TEMP_MEDICAL_CHECK,
  TEMP_MEDICAL_DESC,
  TEMP_MEDICAL_POLICY_CODE,
  TEMP_MIN_AGE_DESC,
  TEMP_MIN_AGE_ID_CHECK,
  TEMP_PROCEDURE_MAX_AGE_CODE,
  TEMP_PROCEDURE_MIN_AGE_CODE,
  TEMP_REASON_CHECK,
  TEMP_REASON_CODE,
  TEMP_REASON_DESC,
  TEMP_SUBPOLICY_CHECK,
  TEMP_SUBPOLICY_CODE,
  TEMP_SUBPOLICY_DESC,
  UNITS,
} from "../../redux/actions/NewPolicyFormActionTypes";
import { GET_PROCS } from "../../redux/actions/actionTypes";
import {
  GET_ACTIVE_CLIENT_DATA,
  GET_CLIENT_ASSIGNMENT_DATA,
} from "../../redux/actions/clientAssignmentTypes";
import { NewPolicyFormState } from "../../redux/reducers/NewPolicyFormReducer";
import { PolicyState } from "../../redux/reducers/PolicyReducer";
import { UserState } from "../../redux/reducers/UserReducer";
import "../NewPolicy/NewPolicy.css";
import BillType from "./BillType";
import CatOpt from "./CatOpt";
import Changes from "./Changes";
import ClientAssignment from "./ClientAssignment";
import {
  MedicalPolicyLKPColumns,
  PolicyCatLKPColumns,
  ReasonLKPColumns,
  SubPolicyLKPColumns,
} from "./Columns";
import ConditionCode from "./ConditionCode";
import Desc from "./Desc";
import Diagnosis from "./Diagnosis";
import "./NewPolicy.css";
import NewPolicyPopUp from "./NewPolicyPopUp";
import { PolicyConstants } from "./PolicyConst";
import Procs from "./Procs";
import Props from "./Props";
import { NEW_POLICY_CREATE_ARRAY } from "../../redux/actions/PolicyActionType";
const _ = require("lodash");

const NewPolicy = ({
  edit,
  fromViewPolicy,
  showImportButton,
  customCreatedB,
}) => {
  const { state } = useLocation();
  const formState: NewPolicyFormState = useSelector(
    (state: any) => state.newPolicyForm
  );
  const policyCreateState: PolicyState = useSelector(
    (state: any) => state.policyCreation
  );
  const claimTypeValues = formState.cliamType?.map((p) => {
    return { label: p.label.charAt(0), value: p.value };
  });
  const _ = require("lodash");

  //@ts-ignore
  const newPolicy = state?.new;
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [reasonCodes, setReasonCodes] = useState([]);
  const [cats, setCats] = useState([]);
  const [medicalPolicy, setMedicalPolicy] = useState([]);
  const [openLkp, setOpenLkp] = React.useState(false);
  const [subPolicy, setSubPolicy] = useState([]);
  const [selectedTab, setSelectedTab] = useState("Description");
  const [selectedLkpColumns, setselectedLkpColumns] = useState([]);
  const [selectedLkp, setSelectedLkp] = useState("");
  const [rows, setRows] = useState([]);
  const [tabErr, setTabErr] = useState(false);
  const [propErr, setpropErr] = useState(false);
  const [ChangeErr, setChangeErr] = useState(false);
  const [catTabErr, setCatTabErr] = useState(false);
  const [catError, setCatError] = useState(false);
  const [selectedCheckboxValue, setSelectedCheckboxValue] = useState("");
  const lkpGridIconStyle = useMemo(
    () => ({
      position: "absolute",
      top: "15px",
      float: "right",
      right: "75px",
      display: "inline",
    }),
    []
  );
  const goToViewPolicy = () => {
    getPolicyById(dispatch, formState.policyId);
    getChangesById(dispatch, formState.policyId);
    dispatch({ type: ERROR_RESET_STATE });
    navigate("/viewPolicy");
  };
  const viewPolicyPage = () => {
    dispatch({ type: CAT_DESC, payload: formState.temporaryCatDesc });
    dispatch({ type: CAT, payload: formState.cat });
    dispatch({ type: CAT_CHECK, payload: formState.tmpCatCheck });
    dispatch({ type: REASON_DESC, payload: formState.temporaryReasonDesc });
    dispatch({ type: REASON_CHECK, payload: formState.tmpReasonCheck });
    dispatch({ type: MEDICAL_DESC, payload: formState.temporaryMedicalDesc });
    dispatch({ type: MEDICAL_CHECK, payload: formState.temporaryMedicalCheck });
    dispatch({
      type: SUBPOLICY_CHECK,
      payload: formState.temporarysubpolicyCheck,
    });
    dispatch({
      type: SUBPOLICY_DESC,
      payload: formState.temporarysubpolicyDesc,
    });
    dispatch({
      type: ENFO_CAT_CHECK,
      payload: formState.temporaryEnfoCatCheck,
    });
    dispatch({ type: ENFO_CAT_DESC, payload: formState.temporaryEnfocatDesc });

    dispatch({
      type: MIN_AGE_ID_CHECK,
      payload: formState.temporaryMinAgeIdCheck,
    });
    dispatch({ type: MIN_AGE_DESC, payload: formState.temporaryMinAgeDesc });
    dispatch({
      type: MAX_AGE_ID_CHECK,
      payload: formState.temporaryMaxAgeIdCheck,
    });
    dispatch({ type: MAX__AGE_DESC, payload: formState.tempoararyMaxAgeDesc });
  };
  const handleToClose = () => {
    setOpen(false);
    setOpenLkp(false);
  };
  const location = useLocation();
  const paths = location.pathname.replaceAll("/", "");
  useEffect(() => {
    if (paths == PolicyConstants.VIEW_POLICY) {
      getPolicyById(dispatch, formState.policyId);
      viewPolicyPage();
    }
  }, []);

  useEffect(() => {
    dispatch({ type: PATHS, payload: paths });
  }, [formState.paths]);
  useEffect(() => {
    if (formState.reasonDesc == "") {
      updatedState.RSN?.map((rs, i) => {
        if (rs.reasonCode == formState.reason) {
          dispatch({ type: REASON_DESC, payload: rs.reasonDesc });
          dispatch({ type: TEMP_REASON_DESC, payload: rs.reasonDesc });
          dispatch({ type: TEMP_REASON_CODE, payload: rs.reasonCode });
          dispatch({ type: REASON_CHECK, payload: i });
          dispatch({ type: TEMP_REASON_CHECK, payload: i });
        }
      });
    }
    if (formState.catDesc == "") {
      updatedState.CAT?.map((c, i) => {
        if (c.policyCategoryLkpId === formState.cat) {
          dispatch({ type: CAT_DESC, payload: c.policyCategoryDesc });
          dispatch({ type: TEMP_CAT_DESC, payload: c.policyCategoryDesc });
          dispatch({ type: TEMP_CAT_CODE, payload: c.policyCategoryLkpId });
          dispatch({ type: CAT_CHECK, payload: i });
          dispatch({ type: TEMP_CAT_CHECK, payload: i });
        }
      });
    }
    if (formState.medicalDesc == "") {
      updatedState.MedicalPolicy?.map((c, i) => {
        if (c.medicalPolicyKey === formState.medicalPolicy) {
          dispatch({ type: MEDICAL_DESC, payload: c.medicalPolicyDesc });
          dispatch({
            type: TEMP_MEDICAL_POLICY_CODE,
            payload: c.medicalPolicyKey,
          });
          dispatch({ type: MEDICAL_CHECK, payload: i });
          dispatch({ type: TEMP_MEDICAL_CHECK, payload: i });
          dispatch({ type: TEMP_MEDICAL_DESC, payload: c.medicalPolicyDesc });
        }
      });
    }
    if (formState.subpolicyDesc == "") {
      updatedState.SubPolicy?.map((c, i) => {
        if (c.subPolicyKey === formState.subPolicy) {
          dispatch({ type: SUBPOLICY_DESC, payload: c.subPolicyDesc });
          dispatch({ type: SUBPOLICY_CHECK, payload: i });
          dispatch({ type: TEMP_SUBPOLICY_CHECK, payload: i });
          dispatch({ type: TEMP_SUBPOLICY_DESC, payload: c.subPolicyDesc });
          dispatch({ type: TEMP_SUBPOLICY_CODE, payload: c.subPolicyKey });
        }
      });
    }
  }, [formState]);
  const saveDialogValues = () => {
    const dispatchActions = [
      { type: TEMP_REASON_DESC, payload: formState.reasonDesc },
      { type: TEMP_REASON_CHECK, payload: formState.reasonCheck },
      { type: TEMP_REASON_CODE, payload: formState.reason },
      { type: TEMP_CAT_DESC, payload: formState.catDesc },
      { type: TEMP_CAT_CHECK, payload: formState.catCheck },
      { type: TEMP_CAT_CODE, payload: formState.cat },
      { type: TEMP_MEDICAL_CHECK, payload: formState.medicalCheck },
      { type: TEMP_MEDICAL_DESC, payload: formState.medicalDesc },
      { type: TEMP_MEDICAL_POLICY_CODE, payload: formState.medicalPolicy },
      { type: TEMP_SUBPOLICY_DESC, payload: formState.subpolicyDesc },
      { type: TEMP_SUBPOLICY_CHECK, payload: formState.subpolicyCheck },
      { type: TEMP_SUBPOLICY_CODE, payload: formState.subPolicy },
      { type: TEMP_ENFO_CAT_CHECK, payload: formState.enfoCatCheck },
      { type: TEMP_ENFO_CAT_DESC, payload: formState.enfoCatDesc },
      {
        type: TEMP_ENFORCE_BEFORE_CAT_CODE,
        payload: formState.enforceBefCategory,
      },
      { type: TEMP_MIN_AGE_ID_CHECK, payload: formState.minAgeIdCheck },
      { type: TEMP_MIN_AGE_DESC, payload: formState.minAgeDesc },
      { type: TEMP_PROCEDURE_MIN_AGE_CODE, payload: formState.procedureMinAge },
      { type: TEMP_MAX__AGE_ID_CHECK, payload: formState.maxAgeIdCheck },
      { type: TEMP_MAX_AGE_DESC, payload: formState.maxAgeDesc },
      { type: TEMP_PROCEDURE_MAX_AGE_CODE, payload: formState.procedureMaxAge },
    ];
    batch(() => {
      dispatchActions.forEach((action) => dispatch(action));
    });
  };

  function checkCustomCheckBox(check) {
    if (formState.version === 0 && check === true) {
      swal.fire({
        icon: "error",
        text: "Base policy should not been created has a custom policy",
      });
    }
  }

  const clearDialogValues = () => {
    if (fromViewPolicy) {
      if (selectedLkp == PolicyConstants.REASON_CODE_LKP) {
        dispatch({ type: REASON_DESC, payload: formState.temporaryReasonDesc });
        dispatch({ type: REASON_CHECK, payload: formState.tmpReasonCheck });
        dispatch({ type: REASON, payload: formState.tmpReasonCode });
      }
      if (selectedLkp == PolicyConstants.POLICY_CAT_LKP) {
        dispatch({ type: CAT_DESC, payload: formState.temporaryCatDesc });
        dispatch({ type: CAT_CHECK, payload: formState.tmpCatCheck });
        dispatch({ type: CAT, payload: formState.tmpCatCode });
      }
      if (selectedLkp == PolicyConstants.MEDICAL_POLICY_LKP) {
        dispatch({
          type: MEDICAL_DESC,
          payload: formState.temporaryMedicalDesc,
        });
        dispatch({
          type: MEDICAL_CHECK,
          payload: formState.temporaryMedicalCheck,
        });
        dispatch({
          type: MEDICAL_POLICY,
          payload: formState.tmpMedicalPolicyCode,
        });
      }
      if (selectedLkp == PolicyConstants.SUB_POLICY_LKP) {
        dispatch({
          type: SUBPOLICY_DESC,
          payload: formState.temporarysubpolicyDesc,
        });
        dispatch({
          type: SUBPOLICY_CHECK,
          payload: formState.temporarysubpolicyCheck,
        });
        dispatch({ type: SUB_POLICY, payload: formState.tmpSubPolicyCode });
      }
    }
  };

  const fullWidth = true;
  const maxWidth = "sm";

  const checkTextSize = (event, fieldName, expectedLength) => {
    if (event.length > expectedLength) {
      swal.fire({
        icon: "error",
        text:
          "Please check the length of  " +
          fieldName +
          " (more than " +
          expectedLength +
          " characters)",
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
  };

  const handleClickToOpen = () => {
    let error = validateNewPolicyForm(formState, dispatch);
    if (fromViewPolicy) {
      if (formState.jiraId === "" || formState.jiraDescription === "") {
        showError("Please create Jira Ticket.");
      } else {
        handleFormErrors();
      }
    } else {
      handleFormErrors();
    }

    function handleFormErrors() {
      if (error) {
        showInfo("Please fill required fields");
      } else if (error && !formState.roldDescription) {
        showError(
          "Please check the length of Description (more than 4000 characters)"
        );
      } else if (error && !formState.notes) {
        showError(
          "Please check the length of Notes (more than 4000 characters)"
        );
      } else if (error && !formState.policySummary) {
        showError(
          "Please check the length of Policy Summary (more than 4000 characters)"
        );
      } else if (error && !formState.policyExplantion) {
        showError(
          "Please check the length of Policy Explanation (more than 4000 characters)"
        );
      } else if (catError) {
        showError("Please check CAT Tab");
      } else {
        setOpen(true);
      }
    }

    function showError(message) {
      swal.fire({
        icon: "error",
        text: message,
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }

    function showInfo(message) {
      swal.fire({
        icon: "info",
        text: message,
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
  };

  const updatedState = useSelector((state: any) => state.newPolicy);
  const claimTypeOptions = updatedState.getClaimTypes?.map((p) => {
    return { label: p.claimType + "-" + p.description, value: p.claimType };
  });
  useEffect(() => {
    if (updatedState.RSN.length == 0) {
      getReasonCodes(dispatch);
    }
    if (updatedState.CAT.length == 0) {
      getCAT(dispatch);
    }
    if (updatedState.MedicalPolicy.length == 0) {
      getMedicalPolicy(dispatch);
    }
    if (updatedState.SubPolicy.length == 0) {
      getSubPolicy(dispatch);
    }
    if (updatedState.getClaimTypes.length == 0) {
      getAllClaimType(dispatch);
    }
    getCurationVersion(dispatch);
  }, []);
  useEffect(() => {
    if (formState.cat == PolicyConstants.TWENTY_THREE) {
      if (formState.ccikey == undefined || formState.ccikey == null) {
        setCatError(true);
      } else {
        setCatError(false);
      }
    }
    if (formState.cat == PolicyConstants.THIRTY_EIGHT) {
      if (formState.billedWith == undefined || formState.billedWith == null) {
        setCatError(true);
      } else {
        setCatError(false);
      }
    }
    if (formState.cat == PolicyConstants.TWELVE) {
      if (
        formState.lineOrHeaderOrPrincipal == "" ||
        formState.lineOrHeaderOrPrincipal == null
      ) {
        setCatError(true);
      } else {
        setCatError(false);
      }
    }
    if (formState.cat == PolicyConstants.TWENTY_FIVE) {
      if (formState.bwTypeKey == "" || formState.bwTypeKey == null) {
        setCatError(true);
      } else {
        setCatError(false);
      }
    }
    if (formState.cat == PolicyConstants.TWENTY) {
      if (
        formState.changeModifierKey == "" ||
        formState.changeModifierKey == null
      ) {
        setCatError(true);
      } else {
        setCatError(false);
      }
    }
    if (formState.cat == PolicyConstants.FOURTY_FIVE) {
      if (
        formState.modifierPayPercentage == "" ||
        formState.modifierPayPercentage == null
      ) {
        setCatError(true);
      } else {
        setCatError(false);
      }
    }
    if (formState.cat == PolicyConstants.FOURTY_SIX) {
      if (
        formState.modifierPayPercentage == "" ||
        formState.modifierPayPercentage == null
      ) {
        setCatError(true);
      } else {
        setCatError(false);
      }
    }
    if (formState.cat == PolicyConstants.FOURTY_NINE) {
      if (
        formState.modifierPriority == "" ||
        formState.modifierPriority == null
      ) {
        setCatError(true);
      } else {
        setCatError(false);
      }
    }

    if (formState.cat == PolicyConstants.THIRTY_FIVE) {
      if (formState.maxUnitsType == "" || formState.maxUnitsType == undefined) {
        setCatError(true);
      }
      if (
        formState.modIntractionType == "" ||
        formState.modIntractionType == undefined
      ) {
        setCatError(true);
      } else {
        setCatError(false);
      }
    }

    if (formState.cat == PolicyConstants.THIRTY_TWO) {
      if (formState.units == "" || formState.units == null) {
        setCatError(true);
      } else {
        setCatError(false);
      }
      if (formState.duration == "" || formState.duration == null) {
        setCatError(true);
      } else {
        setCatError(false);
      }
      if (
        formState.frequency === "RD" &&
        (formState.durationDropdown == undefined ||
          formState.durationDropdown == null)
      ) {
        setCatError(true);
      } else {
        setCatError(false);
      }
    }
  }, [formState]);
  useEffect(() => {
    if (newPolicy || !edit) {
      if (
        formState.errors?.notes ||
        formState.errors?.lob ||
        formState.errors?.productType ||
        formState.errors?.sourceIndicator ||
        formState.errors?.policyExplantion ||
        formState.errors?.policySummary ||
        formState.errors?.referenceSrcTitleDesc ||
        formState.errors?.referenceSrcDesc
      ) {
        setTabErr(true);
      } else {
        setTabErr(false);
      }
      if (
        formState.errors?.createdDate ||
        formState.errors?.gender ||
        formState.errors?.enforceBefCategory ||
        formState.errors?.procedureMinAge ||
        formState.errors?.procedureMaxAge ||
        formState.errors?.npi ||
        formState.errors?.taxIdLogic ||
        formState.errors?.taxonomyLogicFk ||
        formState.errors?.posLink ||
        formState.errors?.revenueCodeClaimLink ||
        formState.errors?.claimTypeLink ||
        formState.errors?.cptLink
      ) {
        setpropErr(true);
      } else {
        setpropErr(false);
      }
      if (formState.errors?.jiraId || formState.errors?.jiraDescription) {
        setChangeErr(true);
      } else {
        setChangeErr(false);
      }
      if (formState.cat == PolicyConstants.TWENTY_THREE) {
        if (formState.errors?.cciKey) {
          setCatTabErr(true);
        } else {
          setCatTabErr(false);
        }
      }
      if (formState.cat == PolicyConstants.THIRTY_EIGHT) {
        if (formState.errors?.billedWith) {
          setCatTabErr(true);
        } else {
          setCatTabErr(false);
        }
      }
      if (formState.cat == PolicyConstants.TWELVE) {
        if (formState.errors?.lineOrHeaderOrPrincipal) {
          setCatTabErr(true);
        } else {
          setCatTabErr(false);
        }
      }
      if (formState.cat == PolicyConstants.TWENTY_FIVE) {
        if (formState.errors?.bwTypeKey) {
          setCatTabErr(true);
        } else {
          setCatTabErr(false);
        }
      }
      if (formState.cat === PolicyConstants.TWENTY) {
        if (formState.errors?.changeModifierKey) {
          setCatTabErr(true);
        } else {
          setCatTabErr(false);
        }
      }
      if (formState.cat === PolicyConstants.FOURTY_FIVE) {
        if (formState.errors?.modifierPayPercentage) {
          setCatTabErr(true);
        } else {
          setCatTabErr(false);
        }
      }
      if (formState.cat === PolicyConstants.FOURTY_SIX) {
        if (formState.errors?.modifierPayPercentage) {
          setCatTabErr(true);
        } else {
          setCatTabErr(false);
        }
      }
      if (formState.cat === PolicyConstants.FOURTY_NINE) {
        if (formState.errors?.modifierPriority) {
          setCatTabErr(true);
        } else {
          setCatTabErr(false);
        }
      }
      if (formState.cat == PolicyConstants.THIRTY_FIVE) {
        if (formState.errors?.maxUnitsType) {
          setCatTabErr(true);
        }
        if (formState.errors?.modIntractionType) {
          setCatTabErr(true);
        } else {
          setCatTabErr(false);
        }
      }
      if (formState.cat == PolicyConstants.THIRTY_TWO) {
        if (formState.errors?.units) {
          setCatTabErr(true);
        }
        if (formState?.errors?.duration) {
          setCatTabErr(true);
        }
        if (formState?.errors?.durationDropdown) {
          setCatTabErr(true);
        } else {
          setCatTabErr(false);
        }
      }
    }
  }, [formState]);

  function checkCatTab() {
    let checkCatTab = false;
    if (
      formState.cat != PolicyConstants.TWENTY_THREE &&
      formState.cat != PolicyConstants.THIRTY_EIGHT &&
      formState.cat != PolicyConstants.TWELVE &&
      formState.cat != PolicyConstants.TWENTY_FIVE &&
      formState.cat != PolicyConstants.THIRTY_FIVE &&
      formState.cat != PolicyConstants.TWENTY &&
      formState.cat != PolicyConstants.FOURTY_FIVE &&
      formState.cat != PolicyConstants.FOURTY_SIX &&
      formState.cat != PolicyConstants.FOURTY_NINE &&
      formState.cat != PolicyConstants.THIRTY_TWO
    ) {
      checkCatTab = true;
    } else {
      checkCatTab = false;
    }
    return checkCatTab;
  }
  useEffect(() => {
    if (checkCatTab() == true) {
      if (formState.newPolicyTabs == PolicyConstants.CAT_OPT_TAB) {
        setSelectedTab(PolicyConstants.DESC_TAB);
        dispatch({ type: NEW_POLICY_TABS, payload: PolicyConstants.DESC_TAB });
      } else {
        setSelectedTab(formState.newPolicyTabs);
      }
    }

    if (checkCatTab() == true) {
      dispatch({ type: CCI_KEY, payload: undefined });
      dispatch({ type: BILLED_WITH, payload: undefined });
      dispatch({ type: LINE_OR_HEADER_OR_PRINC, payload: "" });
      dispatch({ type: BW_TYPE_KEY, payload: undefined });
      dispatch({ type: MAX_UNITS_TYPE, payload: undefined });
      dispatch({ type: MOD_INTRACTION_TYPE, payload: undefined });
      dispatch({ type: UNITS, payload: undefined });
    }
  }, [formState.cat]);

  useEffect(() => {
    if (formState.newPolicyTabs == "") {
      setSelectedTab(PolicyConstants.DESC_TAB);
      dispatch({ type: NEW_POLICY_TABS, payload: PolicyConstants.DESC_TAB });
    } else {
      setSelectedTab(formState.newPolicyTabs);
    }
    if (paths == PolicyConstants.EDIT_POLICY) {
      if (formState.newPolicyTabs == PolicyConstants.CAT_OPT_TAB) {
        if (checkCatTab() == true) {
          setSelectedTab(PolicyConstants.DESC_TAB);
        }
      }
    }
  }, [formState.newPolicyTabs]);
  useEffect(() => {
    if (selectedLkp == PolicyConstants.REASON_CODE_LKP) {
      let col = Object.assign({}, selectedLkpColumns);
      col = ReasonLKPColumns;
      setselectedLkpColumns(col);
      const reasonCodeLkp = reasonCodes.map((sp, i) => {
        return {
          id: i,
          reasonCode: sp.reasonCode,
          reasonDesc: sp.reasonDesc,
          challengeCode: sp.challengeCode,
          challengeDesc: sp.challengeDesc,
          pcoCode: sp.pcoCode,
          hipaaCode: sp.hipaaCode,
          hippaDesc: sp.hippaDesc,
          deactivatedB: sp.deactivatedB,
          customB: sp.customB,
        };
      });
      setRows(reasonCodeLkp);
    }
    if (selectedLkp == PolicyConstants.POLICY_CAT_LKP) {
      let col = Object.assign({}, selectedLkpColumns);
      col = PolicyCatLKPColumns;
      setselectedLkpColumns(col);
      const cate = cats.map((sp, i) => {
        return {
          id: i,
          policyCategoryLkpId: sp.policyCategoryLkpId,
          priority: sp.priority,
          policyCategoryDesc: sp.policyCategoryDesc,
          lastUpdatedAt: Moment(sp.lastUpdatedAt).format("MM-DD-YYYY hh:mm:ss"),
        };
      });
      setRows(cate);
    }
    if (selectedLkp == PolicyConstants.MEDICAL_POLICY_LKP) {
      let col = Object.assign({}, selectedLkpColumns);
      col = MedicalPolicyLKPColumns;
      setselectedLkpColumns(col);
      const medical = medicalPolicy.map((sp, i) => {
        return {
          id: i,
          medicalPolicykey: sp.medicalPolicyKey,
          medicalPolicyDesc: sp.medicalPolicyDesc,
          lastUpdatedTs: Moment(sp.lastUpdatedTs).format("MM-DD-YYYY hh:mm:ss"),
        };
      });
      setRows(medical);
    }
    if (selectedLkp == PolicyConstants.SUB_POLICY_LKP) {
      let col = Object.assign({}, selectedLkpColumns);
      col = SubPolicyLKPColumns;
      setselectedLkpColumns(col);
      const subpolicy = subPolicy.map((sp, i) => {
        return {
          id: i,
          subPolicyKey: sp.subPolicyKey,
          subPolicyDesc: sp.subPolicyDesc,
          lastUpdatedTs: Moment(sp.lastUpdatedTs).format("MM-DD-YYYY hh:mm:ss"),
        };
      });
      setRows(subpolicy);
    }
  }, [selectedLkp]);

  function DisplayCatTab() {
    let showCatTab = false;
    if (
      formState.cat == PolicyConstants.TWENTY_THREE ||
      formState.cat == PolicyConstants.THIRTY_EIGHT ||
      formState.cat == PolicyConstants.TWELVE ||
      formState.cat == PolicyConstants.TWENTY_FIVE ||
      formState.cat == PolicyConstants.THIRTY_FIVE ||
      formState.cat == PolicyConstants.TWENTY ||
      formState.cat == PolicyConstants.FOURTY_FIVE ||
      formState.cat == PolicyConstants.FOURTY_SIX ||
      formState.cat == PolicyConstants.FOURTY_NINE ||
      formState.cat == PolicyConstants.THIRTY_TWO
    ) {
      showCatTab = true;
    } else {
      showCatTab = false;
    }
    return showCatTab;
  }

  const resetInputField = () => {
    setShowAlert(true);
  };

  const setCheckboxValues = () => {
    if (selectedLkp == PolicyConstants.POLICY_CAT_LKP) {
      let a = _.cloneDeep(selectedCheckboxValue);
      let id = "";
      let policyCategoryDesc = "";
      let policyCategoryLkpId = "";
      if (a.length > 0) {
        policyCategoryDesc = a[0].policyCategoryDesc;
        policyCategoryLkpId = a[0].policyCategoryLkpId;
        id = a[0].id;
      }
      dispatch({ type: CAT_CHECK, payload: id });
      if (!edit) {
        dispatch({ type: CAT_DESC, payload: policyCategoryDesc });
        dispatch({ type: CAT, payload: policyCategoryLkpId });
      }
    }
    if (selectedLkp == PolicyConstants.REASON_CODE_LKP) {
      let a = _.cloneDeep(selectedCheckboxValue);
      let id = "";
      let reasonDesc = "";
      let reasonCode = "";
      if (a.length > 0) {
        reasonDesc = a[0].reasonDesc;
        reasonCode = a[0].reasonCode;
        id = a[0].id;
      }
      dispatch({ type: REASON_CHECK, payload: id });
      if (!edit) {
        dispatch({ type: REASON_DESC, payload: reasonDesc });
        dispatch({ type: REASON, payload: reasonCode });
      }
    }
    if (selectedLkp == PolicyConstants.MEDICAL_POLICY_LKP) {
      let a = _.cloneDeep(selectedCheckboxValue);
      let id = "";
      let medicalPolicyDesc = "";
      let medicalPolicykey = "";
      if (a.length > 0) {
        medicalPolicyDesc = a[0].medicalPolicyDesc;
        medicalPolicykey = a[0].medicalPolicykey;
        id = a[0].id;
      }
      dispatch({ type: MEDICAL_CHECK, payload: id });
      if (!edit) {
        dispatch({
          type: MEDICAL_DESC,
          payload: medicalPolicyDesc,
        });
        dispatch({
          type: MEDICAL_POLICY,
          payload: medicalPolicykey,
        });
      }
    }
    if (selectedLkp == PolicyConstants.SUB_POLICY_LKP) {
      let a = _.cloneDeep(selectedCheckboxValue);
      let id = "";
      let subPolicyDesc = "";
      let subPolicyKey = "";
      if (a.length > 0) {
        subPolicyDesc = a[0].subPolicyDesc;
        subPolicyKey = a[0].subPolicyKey;
        id = a[0].id;
      }
      dispatch({ type: SUBPOLICY_CHECK, payload: id });
      if (!edit) {
        dispatch({ type: SUBPOLICY_DESC, payload: subPolicyDesc });
        dispatch({ type: SUB_POLICY, payload: subPolicyKey });
      }
    }
  };
  const onSelectionChanged = async (event) => {
    if (!edit) {
      if (selectedLkp == PolicyConstants.POLICY_CAT_LKP) {
        let a = event.api.getSelectedRows();
        setSelectedCheckboxValue(a);
      }
    }

    if (!edit) {
      if (selectedLkp == PolicyConstants.REASON_CODE_LKP) {
        let a = event.api.getSelectedRows();
        setSelectedCheckboxValue(a);
      }
    }

    if (!edit) {
      if (selectedLkp == PolicyConstants.MEDICAL_POLICY_LKP) {
        let a = event.api.getSelectedRows();
        setSelectedCheckboxValue(a);
      }
    }
    if (!edit) {
      if (selectedLkp == PolicyConstants.SUB_POLICY_LKP) {
        let a = event.api.getSelectedRows();
        setSelectedCheckboxValue(a);
      }
    }
  };
  const onGridReady = (data) => {
    data.api.forEachLeafNode((s) => {
      if (selectedLkp == PolicyConstants.POLICY_CAT_LKP) {
        if (s.data?.id === formState.catCheck) {
          s.setSelected(true);
        }
      }

      if (selectedLkp == PolicyConstants.REASON_CODE_LKP) {
        if (s.data?.id === formState.reasonCheck) {
          s.setSelected(true);
        }
      }
      if (selectedLkp == PolicyConstants.MEDICAL_POLICY_LKP) {
        if (s.data?.id === formState.medicalCheck) {
          s.setSelected(true);
        }
      }
      if (selectedLkp == PolicyConstants.SUB_POLICY_LKP) {
        if (s.data?.id === formState.subpolicyCheck) {
          s.setSelected(true);
        }
      }
    });
  };
  useEffect(() => {
    setReasonCodes(updatedState.RSN);
    setCats(updatedState.CAT);
    setMedicalPolicy(updatedState.MedicalPolicy);
    setSubPolicy(updatedState.SubPolicy);
  }, [updatedState]);
  const roleState: UserState = useSelector((state: any) => state.userReducer);
  const navigate = useNavigate();
  function stringMethod(e) {
    const re = /^[0-9\b]+$/;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }
  function getHeader() {
    if (fromViewPolicy) {
      if (!edit) {
        return (
          <div className="newPolicyHeader1">
            <CustomHeader labelText={"Edit Policy"} />
          </div>
        );
      } else {
        return (
          <div className="viewPolicyHeader">
            <CustomHeader labelText={"View Policy"} />
          </div>
        );
      }
    } else {
      return (
        <div className="newPolicyHeader">
          <CustomHeader labelText={"New Policy"} />
        </div>
      );
    }
  }

  async function onSave() {
    setOpen(false);
    if (fromViewPolicy) {
      await onUpdateNewPolicy(
        dispatch,
        fromViewPolicy,
        formState,
        navigate,
        roleState
      );
    } else {
      await onSaveNewPolicy(
        dispatch,
        fromViewPolicy,
        formState,
        navigate,
        policyCreateState.newpolicyCreateArray,
        forClientTabPolicyId
      );
    }
  }
  const [openPolicyCreationPopUp, setOpenPolicyCreationPopUp] = useState(false);
  const [clickOnBrowserBackButton, setClickOnBrowserBackButton] =
    useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [forClientTabPolicyId, setForClientTabPolicyId] = useState("");
  const leavePage = () => {
    if (showAlert) {
      const actions: any = [
        { type: RESET_STATE },
        { type: GET_PROCS, payload: [] },
        { type: GET_CLIENT_ASSIGNMENT_DATA, payload: [] },
        { type: GET_ACTIVE_CLIENT_DATA, payload: [] },
        { type: POLICY_BILL_TYPE_DATA, payload: [] },
        { type: POLICY_CONDITION_TYPE_DATA, payload: [] },
        { type: NEW_POLICY_CREATE_ARRAY, payload: [] },
      ];
      batch(() => {
        actions.forEach((k) => dispatch(k));
      });
      setSelectedTab(PolicyConstants.DESC_TAB);
      setShowAlert(false);
    } else {
      navigate("/");
    }
  };
  const hiddenInputRef = useRef(null);

  const enterValuesInHiddenInput = () => {
    if (hiddenInputRef.current) {
      hiddenInputRef.current.click();
    }
  };

  const urlChangeHandler = (url, replace = false) => {
    const historyMethod = replace ? "replaceState" : "pushState";
    window.history[historyMethod]({}, "", url);
  };
  useEffect(() => {
    urlChangeHandler(window.location.href);
    enterValuesInHiddenInput();
    return () => {
      if (!fromViewPolicy && openPolicyCreationPopUp)
        setClickOnBrowserBackButton(true);
    };
  }, []);
  useEffect(() => {
    enterValuesInHiddenInput();

    const handlePopState = (event) => {
      event.preventDefault();
      if (!fromViewPolicy && openPolicyCreationPopUp) {
        setClickOnBrowserBackButton(true);
      }
    };
    window.addEventListener("popstate", handlePopState);
    window.history.pushState({ clickOnBrowserBackButton: false }, "");

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [hiddenInputRef, urlChangeHandler]);
  function showPopUpTitle() {
    let title = "";
    if (clickOnBrowserBackButton || showAlert) {
      title = "Alert";
    }
    if (openLkp) {
      title = selectedLkp;
    }
    return title;
  }
  function showContentInPoPup() {
    if (clickOnBrowserBackButton || showAlert) {
      return "You might loose your data if not saved";
    }

    if (openLkp) {
      return (
        <>
          <div style={{ height: window.innerHeight / 1.8, marginTop: "-10px" }}>
            <AgGrids
              rowData={rows}
              columnDefs={selectedLkpColumns}
              rowSelection={"single"}
              onSelectionChanged={onSelectionChanged}
              onGridReady={onGridReady}
              gridIconStyle={lkpGridIconStyle}
            />
          </div>
        </>
      );
    }
  }
  function showButtonsInPopUp() {
    return (
      <>
        <ButtonGroup style={{ marginTop: "-50px" }}>
          <CustomButton
            variant={"contained"}
            onClick={(data) => {
              if (openLkp) {
                handleToClose();
                setCheckboxValues();
              } else {
                leavePage();
              }
            }}
            style={{
              backgroundColor: navyColor,
              color: "white",
              margin: 10,
              padding: 4,
              fontSize: 12,
              textTransform: "capitalize",
            }}
          >
            Yes
          </CustomButton>
          <CustomButton
            onClick={() => {
              if (openLkp) {
                handleToClose();
                clearDialogValues();
              } else {
                setClickOnBrowserBackButton(false);
                setShowAlert(false);
              }
            }}
            variant={"contained"}
            style={{
              backgroundColor: dangerColor,
              color: "white",
              margin: 10,
              padding: 4,
              fontSize: 12,
              textTransform: "capitalize",
            }}
          >
            {clickOnBrowserBackButton || showAlert ? "Cancel" : "No"}
          </CustomButton>
        </ButtonGroup>
      </>
    );
  }
  function openPop() {
    if (openLkp) {
      return openLkp;
    }
    if (clickOnBrowserBackButton) {
      return clickOnBrowserBackButton;
    }
    if (showAlert) {
      return showAlert;
    }
  }
  return (
    <Template>
      <>
        {!openPolicyCreationPopUp && !fromViewPolicy ? (
          <NewPolicyPopUp
            value={{ setOpenPolicyCreationPopUp, setForClientTabPolicyId }}
          />
        ) : (
          <div>
            <div
              style={{
                pointerEvents: edit ? "none" : "visible",
                opacity: edit ? 0.7 : 1,
              }}
            >
              <GridContainer>
                <GridItem sm={10} md={10} xs={10}>
                  {getHeader()}
                </GridItem>
                {!edit ? (
                  <GridItem sm={2} md={2} xs={2}>
                    {!edit && !fromViewPolicy ? (
                      <CustomButton
                        variant={"contained"}
                        onClick={() => {
                          resetInputField();
                        }}
                        style={{
                          backgroundColor: dangerColor,
                          float: "right",
                          color: "white",
                          padding: 4,
                          fontSize: 12,
                          marginLeft: 10,
                          textTransform: "capitalize",
                        }}
                      >
                        Reset
                      </CustomButton>
                    ) : undefined}

                    {fromViewPolicy ? (
                      <CustomButton
                        variant={"contained"}
                        onClick={() => {
                          goToViewPolicy();
                        }}
                        style={{
                          backgroundColor: dangerColor,
                          float: "right",
                          color: "white",
                          marginLeft: 10,
                          textTransform: "capitalize",
                          fontSize: 12,
                          padding: 4,
                        }}
                      >
                        Cancel
                      </CustomButton>
                    ) : undefined}
                    <CustomButton
                      variant={"contained"}
                      onClick={handleClickToOpen}
                      style={{
                        backgroundColor: navyColor,
                        float: "right",
                        color: "white",
                        fontSize: 12,
                        padding: 4,
                        textTransform: "capitalize",
                      }}
                    >
                      Save
                    </CustomButton>
                  </GridItem>
                ) : undefined}
              </GridContainer>
            </div>
            <GridContainer className="newpolicy">
              <div>
                <CustomPaper
                  style={{
                    paddingLeft: 15,
                    paddingRight: 25,
                    boxShadow: "none",
                    border: "1px solid #D6D8DA",
                    marginRight: "10px",
                  }}
                >
                  <GridContainer>
                    <GridItem sm={1} md={1} xs={1}>
                      <div className="pNum">
                        <CustomInput
                          // error={formState.errors?.policy}
                          fullWidth={true}
                          maxLength={10}
                          type={"text"}
                          value={fromViewPolicy ? formState.policy : ""}
                          labelText={"Policy#"}
                          onKeyPress={(e) => stringMethod(e)}
                          disabled
                          variant={"outlined"}
                        />
                      </div>
                    </GridItem>
                    <GridItem sm={1} md={1} xs={1}>
                      <div className="vers">
                        <CustomInput
                          fullWidth={true}
                          labelText={"Version"}
                          disabled
                          // error={formState.errors?.version}
                          value={fromViewPolicy ? formState.version : ""}
                          type={"text"}
                          variant={"outlined"}
                        />
                      </div>
                    </GridItem>
                    <GridItem sm={1} md={1} xs={1}>
                      <CustomCheckBox
                        size="small"
                        // checked={customCreatedB == true?true:false}
                        checked={formState.custom == true}
                        value={formState.custom}
                        labelPlacement={"top"}
                        className="checkboxes"
                        // disabled={!edit ? undefined : fromViewPolicy}
                        disabled
                        label={
                          <span
                            style={{
                              fontSize: "13px",
                              position: "relative",
                              top: "10px",
                            }}
                          >
                            Custom
                          </span>
                        }
                        onChange={(event) => {
                          dispatch({
                            type: CUSTOM,
                            payload: event.target.checked,
                          });
                          checkCustomCheckBox(event.target.checked);
                        }}
                      />
                    </GridItem>
                    <GridItem sm={1} md={1} xs={1}>
                      <div className="pId">
                        <CustomInput
                          fullWidth={true}
                          disabled
                          labelText={"Policy ID"}
                          variant={"outlined"}
                          value={fromViewPolicy ? formState.policyId : ""}
                        />
                      </div>
                    </GridItem>
                    <GridItem sm={2} md={2} xs={2}>
                      <CustomInput
                        error={formState.errors?.catDesc}
                        fullWidth={true}
                        labelText={"Category"}
                        showStarIcon={true}
                        variant={"outlined"}
                        value={
                          (formState.cat && formState.catDesc) ||
                          (formState.cat == 0 && formState.catDesc)
                            ? formState.cat + " - " + formState.catDesc
                            : ""
                        }
                        endAdornment={
                          <InputAdornment
                            position="end"
                            onClick={() => {
                              setOpenLkp(true);
                              setSelectedLkp(PolicyConstants.POLICY_CAT_LKP);
                            }}
                          >
                            <MoreHoriz
                              style={{
                                cursor: "pointer",
                                fontSize: 15,
                                color: black,
                              }}
                            />
                          </InputAdornment>
                        }
                      />
                    </GridItem>
                    <GridItem sm={3} md={3} xs={3}>
                      <CustomInput
                        fullWidth={true}
                        type={"text"}
                        labelText={"Reason Code"}
                        showStarIcon={true}
                        variant={"outlined"}
                        error={formState.errors?.reason}
                        value={
                          formState.reason && formState.reasonDesc
                            ? formState.reason + "-" + formState.reasonDesc
                            : ""
                        }
                        endAdornment={
                          <InputAdornment
                            position="end"
                            onClick={() => {
                              setSelectedLkp(PolicyConstants.REASON_CODE_LKP);
                              setOpenLkp(true);
                            }}
                          >
                            <MoreHoriz
                              style={{
                                cursor: "pointer",
                                fontSize: 15,
                                color: black,
                              }}
                            />
                          </InputAdornment>
                        }
                      />
                    </GridItem>
                    <GridItem sm={1} md={1} xs={1}>
                      <div className="checkbox">
                        <CustomCheckBox
                          checked={formState.prod == 1}
                          value={formState.prod}
                          size="small"
                          className="checkboxes"
                          disabled={!edit ? undefined : fromViewPolicy}
                          label={
                            <span
                              style={{
                                fontSize: "12px",
                                position: "relative",
                                bottom: "2px",
                              }}
                            >
                              Prod
                            </span>
                          }
                          onChange={(event) => {
                            dispatch({
                              type: PROD,
                              payload: event.target.checked,
                            });
                          }}
                        />
                      </div>
                    </GridItem>
                    <GridItem sm={1} md={1} xs={1}>
                      <div className="checkbox">
                        <CustomCheckBox
                          checked={formState.deactivated == 1}
                          value={formState.deactivated}
                          className="deactivated"
                          disabled={!edit ? undefined : fromViewPolicy}
                          label={
                            <span
                              style={{
                                fontSize: "12px",
                                position: "relative",
                                bottom: "2px",
                              }}
                            >
                              Deactivated
                            </span>
                          }
                          onChange={(event) => {
                            // formState.deactivated = event.target.checked ? 1 : 0;
                            dispatch({
                              type: DEACTIVATED,
                              payload: event.target.checked,
                            });
                          }}
                        />
                      </div>
                    </GridItem>
                    <GridItem sm={1} md={1} xs={1}>
                      <div className="checkbox">
                        <CustomCheckBox
                          checked={formState.disabled == 1}
                          value={formState.disabled}
                          disabled={!edit ? undefined : fromViewPolicy}
                          size="small"
                          className="checkboxes"
                          label={
                            <span
                              style={{
                                fontSize: "12px",
                                position: "relative",
                                bottom: "2px",
                              }}
                            >
                              Disabled
                            </span>
                          }
                          onChange={(event) => {
                            // formState.disabled ? 1 : 0;
                            dispatch({
                              type: DISABLED,
                              payload: event.target.checked,
                            });
                          }}
                        />
                      </div>
                    </GridItem>
                    <GridContainer
                      style={{ width: "100%", marginLeft: 0, marginTop: -10 }}
                    >
                      <GridItem sm={3} md={3} xs={3}>
                        <CustomInput
                          error={formState.errors?.medicalPolicy}
                          fullWidth={true}
                          labelText={"Medical Policy"}
                          showStarIcon={true}
                          variant={"outlined"}
                          value={
                            formState.medicalPolicy && formState.medicalDesc
                              ? formState.medicalPolicy +
                                "-" +
                                formState.medicalDesc
                              : ""
                          }
                          endAdornment={
                            <InputAdornment
                              position="end"
                              onClick={() => {
                                setOpenLkp(true);
                                setSelectedLkp(
                                  PolicyConstants.MEDICAL_POLICY_LKP
                                );
                              }}
                            >
                              <MoreHoriz
                                style={{
                                  cursor: "pointer",
                                  fontSize: 15,
                                  color: black,
                                }}
                              />
                            </InputAdornment>
                          }
                        />
                      </GridItem>
                      <GridItem sm={3} md={3} xs={3}>
                        <CustomInput
                          error={formState.errors?.subPolicy}
                          fullWidth={true}
                          labelText={"Sub Policy"}
                          showStarIcon={true}
                          variant={"outlined"}
                          value={
                            formState.subPolicy && formState.subpolicyDesc
                              ? formState.subPolicy +
                                "-" +
                                formState.subpolicyDesc
                              : ""
                          }
                          endAdornment={
                            <InputAdornment
                              position="end"
                              onClick={() => {
                                setOpenLkp(true);
                                setSelectedLkp(PolicyConstants.SUB_POLICY_LKP);
                              }}
                            >
                              <MoreHoriz
                                style={{
                                  cursor: "pointer",
                                  fontSize: 15,
                                  color: black,
                                }}
                              />
                            </InputAdornment>
                          }
                        />
                      </GridItem>
                      <GridItem sm={4} md={3} xs={4}>
                        <CustomInput
                          disabled={!edit ? undefined : fromViewPolicy}
                          error={formState.errors?.reference}
                          value={formState.reference}
                          onChange={(event) => {
                            dispatch({
                              type: REFERENCE,
                              payload: event.target.value,
                            });
                          }}
                          fullWidth={true}
                          labelText={"Reference"}
                          showStarIcon={true}
                          variant={"outlined"}
                        />
                      </GridItem>
                      <GridItem sm={2} md={3} xs={2}>
                        <CustomSelect
                          isMulti
                          checkBoxes={true}
                          isDisabled={!edit ? undefined : fromViewPolicy}
                          error={formState.errors?.cliamType}
                          onSelect={(event) =>
                            dispatch({ type: CLAIM_TYPE, payload: event })
                          }
                          value={claimTypeValues}
                          options={claimTypeOptions}
                          labelText={"Claim Type"}
                          showStarIcon={true}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextArea
                        error={formState.errors?.roldDescription}
                        multiline
                        disabled={!edit ? undefined : fromViewPolicy}
                        labelText={"Description"}
                        showStarIcon={true}
                        fullWidth
                        rows={2}
                        variant={"outlined"}
                        value={formState.roldDescription}
                        onChange={(event) => {
                          checkTextSize(
                            event.target.value,
                            "Description",
                            4000
                          );
                          if (event.target.value.length > 4000) {
                            dispatch({
                              type: ROLE_DESCRIPTION,
                              payload: undefined,
                            });
                          } else {
                            dispatch({
                              type: ROLE_DESCRIPTION,
                              payload: event.target.value,
                            });
                          }
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CustomPaper>
              </div>
              <Box sx={{ width: "100%" }}>
                <TabContext value={selectedTab}>
                  <Tabs
                    onChange={(value, newValue) => {
                      setSelectedTab(newValue);
                      dispatch({ type: NEW_POLICY_TABS, payload: newValue });
                    }}
                    style={{
                      backgroundColor: navyColor,
                      minHeight: "5px",
                      height: "26px",
                      marginTop: "3px",
                    }}
                    value={selectedTab}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      className="desc"
                      style={{
                        minHeight: "3px",
                        minWidth: "3px",
                        color:
                          selectedTab == PolicyConstants.DESC_TAB
                            ? navyColor
                            : "white",
                        backgroundColor:
                          selectedTab == PolicyConstants.DESC_TAB
                            ? "white"
                            : navyColor,
                        textTransform: "capitalize",
                        fontSize: 12,
                        marginTop: -4,
                      }}
                      label={
                        tabErr ? (
                          <span>
                            <StarIcon
                              className="Starcon"
                              style={{ fontSize: 9 }}
                            />
                            Description
                          </span>
                        ) : (
                          "Description"
                        )
                      }
                      value={"Description"}
                    />
                    <Tab
                      className="desc"
                      style={{
                        minHeight: "3px",
                        minWidth: "3px",
                        color:
                          selectedTab == PolicyConstants.DETAIL_TAB
                            ? navyColor
                            : "white",
                        backgroundColor:
                          selectedTab == PolicyConstants.DETAIL_TAB
                            ? "white"
                            : navyColor,
                        textTransform: "capitalize",
                        fontSize: 12,
                        marginTop: -4,
                      }}
                      label={
                        propErr ? (
                          <span>
                            <StarIcon
                              className="Starcon"
                              style={{ fontSize: 9 }}
                            />
                            Details
                          </span>
                        ) : (
                          "Details"
                        )
                      }
                      value={"Details"}
                    />
                    <Tab
                      className="desc"
                      style={{
                        minHeight: "3px",
                        minWidth: "3px",
                        color:
                          selectedTab == PolicyConstants.CPT_HCPCS_TAB
                            ? navyColor
                            : "white",
                        backgroundColor:
                          selectedTab == PolicyConstants.CPT_HCPCS_TAB
                            ? "white"
                            : navyColor,
                        textTransform: "capitalize",
                        fontSize: 12,
                        marginTop: -4,
                      }}
                      label={"CPT/HCPCS"}
                      value={"CPT/HCPCS"}
                    />
                    {DisplayCatTab() ? (
                      <Tab
                        className="desc"
                        style={{
                          minHeight: "3px",
                          minWidth: "3px",
                          color:
                            selectedTab == PolicyConstants.CAT_OPT_TAB
                              ? navyColor
                              : "white",
                          backgroundColor:
                            selectedTab == PolicyConstants.CAT_OPT_TAB
                              ? "white"
                              : navyColor,
                          textTransform: "capitalize",
                          fontSize: 12,
                          marginTop: -4,
                        }}
                        label={
                          catTabErr ? (
                            <span>
                              <StarIcon
                                className="Starcon"
                                style={{ fontSize: 9 }}
                              />
                              CAT OPT
                            </span>
                          ) : (
                            "CAT OPT"
                          )
                        }
                        value={"catOpt"}
                      />
                    ) : undefined}

                    <Tab
                      className="desc"
                      style={{
                        minHeight: "3px",
                        minWidth: "3px",
                        color:
                          selectedTab == PolicyConstants.CHANGES_TAB
                            ? navyColor
                            : "white",
                        backgroundColor:
                          selectedTab == PolicyConstants.CHANGES_TAB
                            ? "white"
                            : navyColor,
                        textTransform: "capitalize",
                        fontSize: 12,
                        marginTop: -4,
                      }}
                      label={
                        ChangeErr ? (
                          <span>
                            <StarIcon
                              className="Starcon"
                              style={{ fontSize: 9 }}
                            />
                            Changes
                          </span>
                        ) : (
                          "Changes"
                        )
                      }
                      value={"Changes"}
                    />

                    <Tab
                      className="desc"
                      style={{
                        minHeight: "3px",
                        minWidth: "3px",
                        color:
                          selectedTab == PolicyConstants.DIAGNOSIS_TAB
                            ? navyColor
                            : "white",
                        backgroundColor:
                          selectedTab == PolicyConstants.DIAGNOSIS_TAB
                            ? "white"
                            : navyColor,
                        textTransform: "capitalize",
                        fontSize: 12,
                        marginTop: -4,
                      }}
                      label={"Diagnosis"}
                      value={"Diagnosis"}
                    />

                    <Tab
                      className="desc"
                      style={{
                        minHeight: "3px",
                        minWidth: "3px",
                        color:
                          selectedTab == PolicyConstants.BILL_TYPE
                            ? navyColor
                            : "white",
                        backgroundColor:
                          selectedTab == PolicyConstants.BILL_TYPE
                            ? "white"
                            : navyColor,
                        textTransform: "capitalize",
                        fontSize: 12,
                        marginTop: -4,
                      }}
                      label={"Bill Type"}
                      value={"BillType"}
                    />
                    <Tab
                      className="desc"
                      style={{
                        minHeight: "3px",
                        minWidth: "3px",
                        color:
                          selectedTab == PolicyConstants.CONDITION_TYPE
                            ? navyColor
                            : "white",
                        backgroundColor:
                          selectedTab == PolicyConstants.CONDITION_TYPE
                            ? "white"
                            : navyColor,
                        textTransform: "capitalize",
                        fontSize: 12,
                        marginTop: -4,
                      }}
                      label={"Condition Code"}
                      value={"ConditionCode"}
                    />
                    <Tab
                      className="desc"
                      style={{
                        minHeight: "3px",
                        minWidth: "3px",
                        color:
                          selectedTab == PolicyConstants.CLIENT_ASSIGNMENT
                            ? navyColor
                            : "white",
                        backgroundColor:
                          selectedTab == PolicyConstants.CLIENT_ASSIGNMENT
                            ? "white"
                            : navyColor,
                        textTransform: "capitalize",
                        fontSize: 12,
                        marginTop: -4,
                      }}
                      label={"Client Assignment"}
                      value={"ClientAssignment"}
                    />
                  </Tabs>
                  <div
                    style={
                      {
                        // pointerEvents: edit ? "none" : "visible",
                        // opacity: edit ? 0.7 : 1,
                      }
                    }
                  >
                    <TabPanel value="Description">
                      <Desc fromViewPolicy={fromViewPolicy} edit={edit} />
                    </TabPanel>
                  </div>
                  <div>
                    <TabPanel value={"Details"}>
                      <Props fromViewPolicy={fromViewPolicy} edit={edit} />
                    </TabPanel>
                  </div>
                  <TabPanel value={"CPT/HCPCS"}>
                    {/* {fromViewPolicy ? ( */}
                    <Procs
                      edit={true}
                      policyId={formState.policyId}
                      showImportButton={showImportButton}
                    />
                    {/* ) : undefined} */}
                  </TabPanel>
                  <div>
                    <TabPanel value={"catOpt"}>
                      <CatOpt
                        edit={fromViewPolicy}
                        viewMode={edit}
                        // catData={formState.cat}
                      />
                    </TabPanel>
                    <TabPanel value={"Changes"}>
                      <Changes edit={fromViewPolicy} viewMode={edit} />
                    </TabPanel>

                    <TabPanel value={"Diagnosis"}>
                      {/* {fromViewPolicy ? ( */}
                      <Diagnosis
                        edit={true}
                        policyId={formState.policyId}
                        showImportButton={showImportButton}
                        fromViewPolicy={edit}
                      />
                      {/* ) : undefined} */}
                    </TabPanel>
                  </div>
                  <TabPanel value={"BillType"}>
                    {/* {fromViewPolicy ? ( */}
                    <BillType
                      edit={true}
                      showImportButton={showImportButton}
                      fromViewPolicy={edit}
                      policyId={formState.policyId}
                    />
                    {/* ) : undefined} */}
                  </TabPanel>

                  <TabPanel value={"ConditionCode"}>
                    {/* {fromViewPolicy ? ( */}
                    <ConditionCode
                      edit={true}
                      fromViewPolicy={edit}
                      policyId={formState.policyId}
                    />
                    {/* ) : undefined} */}
                  </TabPanel>

                  <TabPanel value={"ClientAssignment"}>
                    {/* {fromViewPolicy ? ( */}
                    <ClientAssignment
                      fromViewPolicy={edit}
                      policyId={formState.policyId}
                      edit={fromViewPolicy}
                      forClientTabPolicyId={forClientTabPolicyId}
                    />
                    {/* ) : undefined} */}
                  </TabPanel>
                </TabContext>
              </Box>
            </GridContainer>

            <Dialogbox
              onClose={handleToClose}
              disableBackdropClick={true}
              open={open}
              title={"Confirm"}
              message={"Do you want to save ?"}
              actions={
                <ButtonGroup>
                  <CustomButton
                    onClick={() => {
                      onSave();
                      saveDialogValues();
                    }}
                    style={{
                      backgroundColor: navyColor,
                      color: "white",
                      marginRight: 10,
                      textTransform: "capitalize",
                      fontSize: 12,
                      padding: 4,
                    }}
                  >
                    Ok
                  </CustomButton>
                  <CustomButton
                    onClick={handleToClose}
                    style={{
                      backgroundColor: dangerColor,
                      color: "white",
                      textTransform: "capitalize",
                      fontSize: 12,
                      padding: 4,
                    }}
                  >
                    Cancel
                  </CustomButton>
                </ButtonGroup>
              }
            />
          </div>
        )}
      </>

      <Dialogbox
        fullWidth={fullWidth}
        maxWidth={openLkp ? "md" : "xs"}
        disableBackdropClick={true}
        open={openPop()}
        onClose={handleToClose}
        title={showPopUpTitle()}
        message={<>{showContentInPoPup()}</>}
        actions={<>{showButtonsInPopUp()}</>}
      />
    </Template>
  );
};
export default NewPolicy;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import GridContainer from "../../components/Grid/GridContainer";
import { NewPolicyFormState } from "../../redux/reducers/NewPolicyFormReducer";
import { NewPolicyState } from "../../redux/reducers/NewPolicyReducer";
import "./CategoryOptions.css";
import { PolicyConstants } from "../NewPolicy/PolicyConst";
import GridItem from "../../components/Grid/GridItem";
import { BY_PASS_MOD, MUTUALLY_EXCLUSIVE, DENY, CCI_KEY } from "../../redux/actions/NewPolicyFormActionTypes";
import RadioButton from "../../components/RadioButton/RadioButton";
import { getCci } from "../../redux/actions";
import StarIcon from '@mui/icons-material/Star';

const CategoryTwentyThree = ({ edit, viewMode }) => {
  const formState: NewPolicyFormState = useSelector(
    (state: any) => state.newPolicyForm
  );
  const updatedState: NewPolicyState = useSelector(
    (state: any) => state.newPolicy
  );

  useEffect(() => {
    if (updatedState.ptpCci.length == 0) {
      
      getCci(dispatch);
    }
  }, []);
  const ptpcci = updatedState.ptpCci?.map((p) => {
    return { label: p.cciDesc, value: p.cciKey }
  })
  const dispatch = useDispatch();

  const [selectedType, setSelectedType] = useState("");
  const [exclusiveType, setExclusiveType] = useState("");
  const [denyType, setDenyType] = useState("");
  const [ccikey, setCciKey] = useState({});
  const [byPassMod, setByPassMod] = useState("");

  useEffect(() => {
    setDenyType("columnii");
    setExclusiveType("yes");
    setSelectedType("headerlevel");
    setByPassMod("both")
  }, [updatedState]);
  useEffect(() => {
    if (formState.cat == PolicyConstants.TWENTY_THREE) {
      if (formState.byPassMod == "" || formState.byPassMod == null) {
        dispatch({
          type: BY_PASS_MOD,
          payload: 3,
        });
      }
      if (
        formState.mutuallyExclusive == "" ||
        formState.mutuallyExclusive == null
      ) {
        dispatch({
          type: MUTUALLY_EXCLUSIVE,
          payload: 1,
        });
      }
      if (formState.denyType == "" || formState.denyType == null) {
        dispatch({
          type: DENY,
          payload: 2,
        });
      }
    }
  }, [formState]);
  return (
    <div>
      <GridContainer>
        <GridItem sm={2} md={2} xs={2}>
          <small
            style={{
              fontSize: 13,
              color: "black",
              fontWeight: 400,
              position: "relative",
              top: 15,
            }}
          >
            CCI Key {<StarIcon style={{position:"relative", bottom:"4px",fontSize:"7px"}}/>}
          </small>
        </GridItem>
        <GridItem sm={4} md={4} xs={4}>
          <div className="catkey">
            <CustomSelect
              error={formState.errors?.cciKey}
              options={ptpcci}
              value={ptpcci.filter(function (option) {
                return option.value == formState.ccikey;
              })}
              isDisabled={!edit ? undefined : viewMode}
              catTitle={ptpcci.filter((option, index) => {
                if (formState.ccikey == option.value) {
                  return option
                }
              })}
              onSelect={(event) => {
                setCciKey(event);
                dispatch({
                  type: CCI_KEY,
                  payload: event.value,
                });
              }}
            />
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem sm={2} md={2} xs={2}>
          <div style={{ marginTop: 15 }}>
            <span>Bypass Modifier</span>
          </div>
        </GridItem>
        <GridItem sm={4} md={4} xs={4}>
          <div style={{ marginTop: 15 }}>
            <RadioButton
              label={"Yes"}
              checked={byPassMod == "yes"}
              disabled={true}
              value="yes"
              onChange={(e) => {
                if (e.target.checked) {
                  setByPassMod("yes");
                  dispatch({
                    type: BY_PASS_MOD,
                    payload: 1,
                  });
                }
              }}
            />
            <RadioButton
              label={"No"}
              disabled={true}
              checked={byPassMod == "no"}
              value="no"
              onChange={(e) => {
                if (e.target.checked) {
                  setByPassMod("no");
                  dispatch({
                    type: BY_PASS_MOD,
                    payload: 2,
                  });
                }
              }}
            />
            <RadioButton
              label={"Both"}
              checked={byPassMod == "both"}
              value="both"
              onChange={(e) => {
                if (e.target.checked) {
                  setByPassMod("both");
                  dispatch({
                    type: BY_PASS_MOD,
                    payload: 3,
                  });
                }
              }}
            />
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem sm={2} md={2} xs={2}>
          <div style={{ marginTop: 15 }}>
            <span>Mutually Exclusive</span>
          </div>
        </GridItem>
        <GridItem sm={4} md={4} xs={4}>
          <div style={{ marginTop: 15 }}>
            <RadioButton
              label={"Yes"}
              disabled={viewMode}
              checked={
                formState.mutuallyExclusive == null ||
                  formState.mutuallyExclusive == ""? 
                exclusiveType == "yes"
                  : formState.mutuallyExclusive == 1
              }
              value={"yes"}
              onChange={(e) => {
                if (e.target.checked) {
                  setExclusiveType("yes");
                  dispatch({
                    type: MUTUALLY_EXCLUSIVE,
                    payload: 1,
                  });
                }
              }}
            />
            <RadioButton
              label={"No"}
              disabled={viewMode}
              value={"no"}
              checked={ formState.mutuallyExclusive == null ||
                formState.mutuallyExclusive == ""? 
              exclusiveType == "no"
                : formState.mutuallyExclusive == 2}
              onChange={(e) => {
                if (e.target.checked) {
                  setExclusiveType("no");
                  dispatch({
                    type: MUTUALLY_EXCLUSIVE,
                    payload: 2,
                  });
                }
              }}
            />
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem sm={2} md={2} xs={2}>
          <div style={{ marginTop: 15 }}>
            <span>Deny</span>
          </div>
        </GridItem>
        <GridItem sm={4} md={4} xs={4}>
          <div style={{ marginTop: 15 }}>
            <RadioButton
              label={"COLUMN I"}
              disabled={true}
              checked={denyType == "columni"}
              value={"columni"}
              onChange={(e) => {
                if (e.target.checked) {
                  setDenyType("columni");
                  dispatch({
                    type: DENY,
                    payload: 1,
                  });
                }
              }}
            />
            <RadioButton
              label={"COLUMN II"}
              checked={denyType == "columnii"}
              value={"columnii"}
              onChange={(e) => {
                if (e.target.checked) {
                  setDenyType("columnii");
                  dispatch({
                    type: DENY,
                    payload: 2,
                  });
                }
              }}
            />
            <RadioButton
              label={"High $"}
              checked={denyType == "high"}
              value={"high"}
              disabled={true}
              onChange={(e) => {
                if (e.target.checked) {
                  setDenyType("high");
                  dispatch({
                    type: DENY,
                    payload: 3,
                  });
                }
              }}
            />
            <RadioButton
              label={"Low $"}
              checked={denyType == "low"}
              value={"low"}
              disabled={true}
              onChange={(e) => {
                if (e.target.checked) {
                  setDenyType("low");
                  dispatch({
                    type: DENY,
                    payload: 4,
                  });
                }
              }}
            />
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );

};
export default CategoryTwentyThree;
import { navyColor } from '../../assets/jss/material-kit-react';
import { apiUrl, policyConfigUrl } from '../../configs/apiUrls';
import { CustomPolicyState } from '../reducers/CustomPolicyReducer';
import { store } from '../store';
import { CUSTOM_POLICY_VALIDATION } from './CustomPolicyActionType';
import {
  createChangesObject,
  createPolicyObject,
  handleChanges,
  mapAndSort,
  navigateAndFetch,
} from './NewPolicyAction';
import { SET_IS_LOADING } from './actionTypes';
import swal from 'sweetalert2';

export function validateCustomPolicyForm(
  customPolicyForm: CustomPolicyState,
  dispatch
) {
  let error = false;
  let customPolicyErrors = {
    customJiraId: false,
    customJiraDesc: false,
    customNewPolicyDate: false,
  };
  if (
    customPolicyForm.customJiraId === undefined ||
    customPolicyForm.customJiraId === ''
  ) {
    customPolicyErrors.customJiraId = true;
    error = true;
  }
  if (customPolicyForm.customJiraDesc === undefined ||customPolicyForm.customJiraDesc ==='' ) {
    customPolicyErrors.customJiraDesc = true;
    error = true;
  }
  if (customPolicyForm.cloneAssignmentCheck) {
    if (
      customPolicyForm.customNewPolicyDate === undefined ||
      customPolicyForm.customNewPolicyDate === ''
    ) {
      customPolicyErrors.customNewPolicyDate = true;
      error = true;
    }
  }

  if (error) {
    dispatch({ type: CUSTOM_POLICY_VALIDATION, payload: customPolicyErrors });
  }
  return error;
}

export async function onSaveCustomPolicy(dispatch, formState,createdDate,cloned, navigate,edit) {
  try {
    const clm = mapAndSort(formState.cliamType);
    const cgtype = mapAndSort(formState.productType);

    dispatch({ type: SET_IS_LOADING, payload: true });
    const response = await fetch(
      `${policyConfigUrl + apiUrl.saveCustomPolicyData}/${createdDate}/${cloned}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(createPolicyObject(formState, clm, cgtype,edit,null,null),createdDate),
      }
    );

    if (!response.ok) {
      throw new Error('Failed to create new policy');
    }

    const data = await response.json();
    await handleCustomChanges(dispatch, formState, data, navigate);
  } catch (e) {
    swal.fire({
      icon: 'error',
      text: 'Please Reach Out IT Team',
      confirmButtonColor: navyColor,
      confirmButtonText: 'OK',
    });
  } finally {
    dispatch({ type: SET_IS_LOADING, payload: false });
  }
}
 async function handleCustomChanges(dispatch, formState, data, navigate) {
  try {
    let changesObj = createChangesObject(formState, data);
    const response = await fetch(policyConfigUrl + apiUrl.customChanges, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(changesObj),
    });

    if (!response.ok) {
      throw new Error('Failed to create changes');
    }

    const policyId = await response.json();
    await navigateAndFetch(dispatch, navigate, policyId);
  } catch (e) {
    console.error(e);
  }
}
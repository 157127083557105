export const GET_CONFIG_REPORT = 'getConfigReport';
export const GET_SINGLE_CONFIG_REPORT = 'getSingleConfigReport';
export const SEND_CLAIMDATA = 'sendclaims';
export const GET_TESTING_REPORT_DATA = 'getPolicyNumber';
export const POLICY = 'policy';
export const POLICY1 = 'getpolicy';
// export const BILL_TYPE = 'getBillTypeLookUp';
export const VALIDATIONREPORTSPINNER = 'validationreportspinner';
export const PROD = 'PROD';
export const DEACTIVATED = 'DEACTIVATED';
export const DISABLED = 'DISABLED';
export const POS_LKP ='pos';
export const INCLUDE_DB ='include_db';

export const TEMP_DATA ='tempData';
export const HISTORY_TEMP_DATA ='historyTempData';

export const CLAIM_HEADER_DATA ='claimHeaderData'
export const CLAIM_HISTORY_DATA ='claimHistoryData'

export const LINE_LEVEL_DATA = 'lineLevelData'
export const TOTAL_CLAIMS_DATA = 'totalClaimsDATA'






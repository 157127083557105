import { navyColor } from "../../assets/jss/material-kit-react";
import { policyConfigUrl, apiUrl } from "../../configs/apiUrls";
import { store } from "../store";
import { SET_IS_LOADING } from "./actionTypes";
import { GET_CONDITION_TYPE_DATA, POLICY_CONDITION_TYPE_DATA, POST_CONDITION_TYPE_DATA } from "./NewPolicyFormActionTypes";
import swal from 'sweetalert2';


export async function getConditionTypeData(dispatch: typeof store.dispatch) {
    dispatch({ type: SET_IS_LOADING, payload: true });
    await fetch(policyConfigUrl + apiUrl.getConditionTypeData)
      .then((response) => {
        if (!response.ok) {
          // throw new Error(response.statusText);
        }
        dispatch({ type: SET_IS_LOADING, payload: false });
        return response.json();
      })
      .then((data) => {
        // if(data.)
        dispatch({ type: GET_CONDITION_TYPE_DATA, payload: data });
        dispatch({ type: SET_IS_LOADING, payload: false });
      });
  }
  
  export async function getPolicyConditionTypeData(
    dispatch: typeof store.dispatch,
    policyId
  ) {
    dispatch({ type: SET_IS_LOADING, payload: true });
    await fetch(`${policyConfigUrl + apiUrl.getPolicyConditionType}/${policyId}`)
      .then((response) => {
        if (!response.ok) {
        }
        dispatch({ type: SET_IS_LOADING, payload: false });
        return response.json();
      })
      .then((data) => {
        dispatch({ type: POLICY_CONDITION_TYPE_DATA, payload: data });
        dispatch({ type: SET_IS_LOADING, payload: false });
      });
  }
  export async function postConditionTypeData(
    dispatch: typeof store.dispatch,
    data,
    policyId
  ) {
    dispatch({ type: SET_IS_LOADING, payload: true });
    let ok = data.map((k, i) => {
      return {
        policyId: parseInt(policyId),
        conditionCode: k.condCode,
        conditionCodeDesc: k.condDesc,
      };
    });
    await fetch(policyConfigUrl + apiUrl.postConditionTypeData, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(ok),
    })
      .then((response) => {
        if (!response.ok) {
        }
        dispatch({ type: SET_IS_LOADING, payload: false });
        return response.json;
      })
      .then((data) => {
        getPolicyConditionTypeData(dispatch, policyId);
        dispatch({ type: POST_CONDITION_TYPE_DATA, payload: data });
        dispatch({ type: SET_IS_LOADING, payload: false });
        swal.fire({
          icon: 'success',
          text: 'ConditionType data added successfully',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
      });   
  }
  export async function DeleteConditionTypeData(
    dispatch: typeof store.dispatch,
    data,
    policyId
  ) {
  
    dispatch({ type: SET_IS_LOADING, payload: true });
    await fetch(policyConfigUrl + apiUrl.deleteConditionTypeData + '/' + data ,{
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: data,
    })
      .then((response) => {
        if (!response.ok) {
        }
        dispatch({ type: SET_IS_LOADING, payload: false });
        return response.text();
      })
      .then((data) => {
        getPolicyConditionTypeData(dispatch, policyId);
        dispatch({ type: SET_IS_LOADING, payload: false });
        swal.fire({
          icon: 'success',
          text: 'Condition Type data deleted successfully',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
      });
  }
  
export const SEARCH_POLICY_NUMBER = "searchPolicyNumber"
export const SEARCH_POLICY_ID = "searchPolicyId"
export const SEARCH_CATEGORY = "searchCatgory"
export const SEARCH_REASON_CODE = "searchReasonCode"
export const SEARCH_DEACTIVATED = "searchDeactivated"
export const SEARCH_DISABLED = "searchDisabled"
export const SEARCH_LOB = "searchLob"
export const SEARCH_PRODUCT_TYPE = "searchProductType"
export const SEARCH_EBC = "searchEbc"
export const SEARCH_CLAIM_TYPE = "searchClaimType"
export const SEARCH_MEDICAL_POLICY = "searchMedicalPolicy"
export const SEARCH_SUB_POLICY = "searchSubPolicy"
export const SEARCH_REFERENCE = "searchReference"
export const SEARCH_DESCRIPTION = "searchDescription"
export const SEARCH_PRIORITY = "searchPriority"
export const SEARCH_CREATED_DATE = "searchCreatedDate"

export const CATEGORY_VALUE = "categoryValue"
export const REASON_CODE_VALUE = "reasonCodeValue"
export const EBC_VALUE = "ebcValue"
export const MEDICAL_POLICY_VALUE = "medicalPolicyValue"
export const SUB_POLICY_VALUE = "subPolicyValue"
export const LOB_VALUE = "lobValue"
export const PRODUCT_TYPE_VALUE = "productTypeValue"
export const CLAIM_TYPE_VALUE = "claimTypeValue"
export const CPT_CODE = "cptCode"
export const POS = "searchPos"
export const BILL_TYPE = "billType"
export const RESET = "resetAllInputFields"


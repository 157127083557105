export const modifierPayPercentageColumns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 90,
      headerTooltip: "ID",
    },
    {
      field: "modifier",
      headerName: "Modifier",
      minWidth: 90,
      headerTooltip: "Modifier",
    },
    {
      field: "allowedPercentage",
      headerName: "Allowed Percentage",
      minWidth: 109,
      headerTooltip: "Allowed Percentage",
    },
    {
      field: "preOp",
      headerName: "Pre Op",
      minWidth: 60,
      headerTooltip: "Pre Op",
    },
  
    {
      field: "intraOp",
      headerName: "Intra Op",
      minWidth: 60,
      headerTooltip: "Intra Op",
    },
  
    {
      field: "postOp",
      headerName: "Post Op",
      minWidth: 60,
      headerTooltip: "Post Op",
    },];
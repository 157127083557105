// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";

import { InputAdornment, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import styles from "../../assets/jss/material-kit-react/components/customInputStyle.js";
import '../CustomInput/CustomInput.css';
import StarIcon from '@mui/icons-material/Star';

const useStyles = makeStyles(styles);
export default function CustomInput(props) {
  const classes = useStyles();
  const {
    formControlProps,
    endAdornment,
    labelText,
    isSkyblue,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    variant,
    disabled,
    fullWidth,
    multiline,
    type,
    onChange,
    onBlur,
    tooltip,
    placeholder,
    onKeyPress,
    // dateformat,
    inputFormat,
    rows,
    value,
    title,
    className,
    InputProps,
    maxLength,
    helperText,
    autoComplete,
    onClick,
    onKeyUp,
    style,
    showStarIcon

  } = props;

  var formControlClasses;

  const [er, setEr] = useState(false)

  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  const customStyles = {
    control: styles => ({ ...styles, border: er ? '1px solid red' : "" })
  }
  useEffect(() => {
    setEr(error)
  }, [error])

  return (

    <FormControl fullWidth {...formControlProps} className={formControlClasses}>
      {/* <FormLabel style={{fontSize:'12px',position:'absolute',color:'black',top:'4px',left:'7px',fontFamily:'Arial, Helvetica, sans-serif',padding:'1px', backgroundColor:'white',zIndex:1}}>{labelText}</FormLabel> */}
      {/* <Tooltip value="labelText" arrow> */}
      <small title={placeholder} style={{color:isSkyblue?'#00c7d8':'black', fontSize:13,fontWeight:400}}>
        {labelText}{showStarIcon ===true ?<StarIcon style={{position:"relative", bottom:"4px",fontSize:"7px"}}/>:''}</small>
      {/* </Tooltip> */}
      <div title={value}>
        <TextField
          // styles={customStyles}
          placeholder={placeholder}
          fullWidth={fullWidth}
          onChange={onChange}
          helperText={helperText}
          onKeyUp={onKeyUp}
          className="CustomInput"
          onKeyPress={onKeyPress}
          onClick={onClick}
          type={type}
          disabled={disabled}
          onBlur={onBlur}
          tooltip={tooltip}
          size={"small"}
          id="outlined-basic"
          // label={labelText}
          variant={variant}
          InputProps={{ endAdornment: <InputAdornment position="start">{endAdornment}</InputAdornment>, }}
          inputProps={{ style: { fontSize: 11, fontFamily: 'Arial, Helvetica, sans-serif', borderColor: "skyblue" }, maxLength: maxLength }}
          rows={rows}
          value={value}
          error={error}
          title={title}
          // dateformat={dateformat}
          inputFormat={inputFormat}
          style={style}
          multiline={multiline}
          autoComplete={autoComplete}
          // autoComplete={"off"}
          {...props}
        />
        </div>
    </FormControl>
  );
}

CustomInput.propTypes = {
  labelText: PropTypes.node,
  showStarIcon:PropTypes.bool,
  placeholder: PropTypes.string,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
  isSkyblue: PropTypes.bool,
  rows: PropTypes.number,
  type: PropTypes.string | PropTypes.func,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyUp: PropTypes.func,
  title: PropTypes.node,
  value: PropTypes.string | PropTypes.number,
  onBlur: PropTypes.func,
  onClick:PropTypes.func,
  tooltip: PropTypes.string,
  InputProps: PropTypes.object,
  className: PropTypes.string,
  maxLength: PropTypes.number,
  helperText: PropTypes.any,
  endAdornment: PropTypes.any,
  autoComplete:PropTypes.string,
  style: PropTypes.any,
};

import swal from 'sweetalert2';
import { navyColor } from '../../assets/jss/material-kit-react';
import { apiUrl, policyConfigUrl, policyengine } from '../../configs/apiUrls';
import { store } from '../store';
import { SET_IS_LOADING } from './actionTypes';
import {
  // BILL_TYPE,
  GET_CONFIG_REPORT,
  // GET_POLICYNUMBER,
  GET_TESTING_REPORT_DATA,
  SEND_CLAIMDATA,
  TOTAL_CLAIMS_DATA,
} from './TestingReportType';
export async function getPolicyNumber(
  dispatch: typeof store.dispatch,
  policyNumber: number,
  version: number
) {
  let policiesData;
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(
    `${policyConfigUrl + apiUrl.testReport}/${policyNumber}/${version}`
  )
    .then((response) => {
      if (!response.ok) {
      }
      return response.json();
    })
    .then( (data) => {
      if (data.length == 1) {
        policiesData = data[0];
        dispatch({ type: GET_TESTING_REPORT_DATA, payload: data[0] });
      } else if (data.length == 0) {
         swal.fire({
          icon: 'info',
          text: 'Policy number does not exist',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
        dispatch({ type: GET_TESTING_REPORT_DATA, payload: data });
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
    });
  return policiesData;
}
let count = 0;
export async function sendClaimData(
  dispatch: typeof store.dispatch,
  data1,
  claimSlIdLen,
  runType
  // scenarioLength
) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(policyengine + apiUrl.sendclaimdata, {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify(data1),
  })
    .then((response) => {
      if (!response.ok) {
      }
      if (response.status == 200) {
        swal.fire({
          icon: 'success',
          text: 'Claim Processed successfully',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
        dispatch({ type: SET_IS_LOADING, payload: false });
      } else {
        swal.fire({
          icon: 'error',
          text: 'Claim Processing Failed',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
        dispatch({ type: SET_IS_LOADING, payload: false });
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: TOTAL_CLAIMS_DATA, payload: data });
      dispatch({ type: SET_IS_LOADING, payload: false });
    });
}

export async function getConfigReport(dispatch: typeof store.dispatch) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(policyConfigUrl + apiUrl.generateconfigreport)
    .then((response) => {
      if (!response.ok) {
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_CONFIG_REPORT, payload: data });
      dispatch({ type: SET_IS_LOADING, payload: false });
    });
}

export async function getConfigValidation(
  dispatch: typeof store.dispatch,
  data
) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(`${policyConfigUrl + apiUrl.generateconfigreport}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        // throw new Error(response.statusText);
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return JSON.stringify(response);
    })
    .then((data) => {
      dispatch({ type: SET_IS_LOADING, payload: false });
      swal.fire({
        icon: 'success',
        text: 'Report Generated Successfully. ',
        confirmButtonColor: navyColor,
        confirmButtonText: 'OK',
      });
    });
}

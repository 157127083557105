import React, { useEffect, useRef, useState } from "react";
import RadioButton from "../../components/RadioButton/RadioButton";
import Dialogbox from "../../components/Dialog/DialogBox";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomCheckBox from "../../components/CustomCheckBox/CustomCheckBox";
import CustomButton from "../../components/CustomButtons/CustomButton";
import {
  dangerColor,
  grayColor,
  navyColor,
  successColor,
} from "../../assets/jss/material-kit-react";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  IS_JIRA_OPEN,
  JIRA_DESCRIPTION,
  JIRA_ID,
  JIRA_ISOPEN,
  JIRA_LINK,
  RESET_STATE,
  USER_ID,
} from "../../redux/actions/NewPolicyFormActionTypes";
import { NewPolicyFormState } from "../../redux/reducers/NewPolicyFormReducer";
import { PolicyConstants, intialPolicyCreationFileds } from "./PolicyConst";
import { validateNewPolicyForm } from "../../redux/actions/NewPolicyAction";
import { PolicyState } from "../../redux/reducers/PolicyReducer";
import {
  getPolicyNumberAndVersionChecking,
  validatePolicyCreationFields,
} from "../../redux/actions/PolicyAction";
import {
  FOR_CLONED_POLICYID,
  FOR_EXSISTING_POLICYID,
  NEW_POLICY_CREATE_ARRAY,
} from "../../redux/actions/PolicyActionType";
import { Alert } from "@mui/material";
import { getPolicyById } from "../../redux/actions";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { TAB_PATHS } from "../../redux/actions/UserActionTypes";
import Swal from "sweetalert2";
import { DIALOG, GET_PROCS } from "../../redux/actions/actionTypes";

const _ = require("lodash");

const fullWidth = true;
const maxWidth = "sm";
const NewPolicyPopUp = (props) => {
  const dispatch = useDispatch();
  const [showPopInNewPolicy, setShowPopInNewPolicy] = useState(true);
  const [openSecondPopUp, setOpenSecondPopUp] = useState(false);
  const [openThirdPopUp, setOpenThirdPopUp] = useState(false);

  const [checkFiledsErro, setCheckFiledsError] = useState(false);
  const [exsistingPolicyId, setExsistingPolicyId] = useState("");

  const [localPolicyState, setLocalPolicyState] = useState(
    intialPolicyCreationFileds
  );
  const navigate = useNavigate();

  const formState: NewPolicyFormState = useSelector(
    (state: any) => state.newPolicyForm
  );

  function closePolicyPopUp() {
    setShowPopInNewPolicy(false);
  }
  useEffect(()=>{
    dispatch({type:RESET_STATE})
    dispatch({ type: GET_PROCS, payload: [] });
  },[dispatch])
  function showPolicyNumberFiled(label) {
    return (
      <>
        <div className="row">
          <div className="col-sm-6">
            <CustomInput
              fullWidth={true}
              error={
                localPolicyState.cloneClientAssignmentTab
                  ? !localPolicyState.forClonedPolicyAndVersion
                  : false
              }
              maxLength={10}
              type={"text"}
              value={
                label === PolicyConstants.Create_From_Policy_Version
                  ? localPolicyState.policyAndVersion
                  : localPolicyState.forClonedPolicyAndVersion
              }
              labelText={label}
              onChange={(event) => {
                let obj = _.cloneDeep(localPolicyState);
                if (label === PolicyConstants.Create_From_Policy_Version) {
                  obj.policyAndVersion = event.target.value;
                } else {
                  obj.forClonedPolicyAndVersion = event.target.value;
                }
                if (!event.target.value) {
                  setExsistingPolicyId("");
                }

                setLocalPolicyState(obj);
              }}
              variant={"outlined"}
            />
          </div>
        </div>
      </>
    );
  }
  function ShowExsistingOrNewPolicyPopUpFileds() {
    return (
      <>
        <div>
          <>
            {!openSecondPopUp ? (
              <>
                <div className="row">
                  <div className="col-sm-6">
                    <CustomInput
                      fullWidth={true}
                      labelText={"Jira ID"}
                      showStarIcon={true}
                      error={checkFiledsErro ? !localPolicyState.jiraId : false}
                      value={localPolicyState.jiraId}
                      onChange={(event) => {
                        let obj = _.cloneDeep(localPolicyState);
                        obj.jiraId = event.target.value;
                        setLocalPolicyState(obj);
                      }}
                      variant={"outlined"}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <CustomInput
                      fullWidth={true}
                      error={
                        checkFiledsErro
                          ? !localPolicyState?.jiraDescription
                          : false
                      }
                      value={localPolicyState?.jiraDescription}
                      labelText={"Jira Description"}
                      showStarIcon={true}
                      onChange={(event) => {
                        let obj = _.cloneDeep(localPolicyState);
                        obj.jiraDescription = event.target.value;
                        setLocalPolicyState(obj);
                      }}
                      variant={"outlined"}
                    />
                  </div>
                </div>
                {showPolicyNumberFiled("Create From Policy.Version")}
              </>
            ) : undefined}
          </>

          <>
            {openSecondPopUp ? (
              <>
                <div className="row">
                  <CustomCheckBox
                    size="small"
                    disabled={localPolicyState.cloneClientAssignmentTab}
                    onChange={(event) => {
                      let obj = _.cloneDeep(localPolicyState);
                      obj.addAllActiveClients = event.target.checked;
                      if (!event.target.checked) {
                        obj.newPolicyStartDate = "";
                      }
                      setLocalPolicyState(obj);
                    }}
                    checked={localPolicyState.addAllActiveClients}
                    label={
                      <span
                        style={{
                          fontSize: "13px",
                          position: "relative",
                          fontWeight: "400",
                          bottom: "2px",
                        }}
                      >
                        Add All Active Client Groups (Standard TPAs Only)
                      </span>
                    }
                  />
                </div>
                <div className="row">
                  <CustomCheckBox
                    size="small"
                    disabled={localPolicyState.addAllActiveClients}
                    checked={localPolicyState.cloneClientAssignmentTab}
                    onChange={(event) => {
                      let obj = _.cloneDeep(localPolicyState);
                      obj.cloneClientAssignmentTab = event.target.checked;
                      if (!event.target.checked) {
                        obj.newPolicyStartDate = "";
                      }
                      setLocalPolicyState(obj);
                    }}
                    label={
                      <span
                        style={{
                          fontSize: "13px",
                          position: "relative",
                          fontWeight: "400",
                          bottom: "2px",
                        }}
                      >
                        Clone Client Assignment Tab
                      </span>
                    }
                  />
                </div>
                <div className="row" style={{ marginLeft: "6%" }}>
                  {localPolicyState.cloneClientAssignmentTab
                    ? showPolicyNumberFiled("Enter Policy.Version")
                    : undefined}
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <CustomInput
                      labelText={"Process Start Date"}
                      type="date"
                      disabled={
                        localPolicyState.addAllActiveClients ||
                        localPolicyState.cloneClientAssignmentTab
                          ? false
                          : true
                      }
                      variant={"outlined"}
                      error={
                        checkFiledsErro
                          ? !localPolicyState.newPolicyStartDate
                          : false
                      }
                      value={localPolicyState.newPolicyStartDate}
                      onChange={(event) => {
                        let obj = _.cloneDeep(localPolicyState);
                        obj.newPolicyStartDate = event.target.value;
                        setLocalPolicyState(obj);
                      }}
                    />
                  </div>
                </div>
              </>
            ) : undefined}
          </>
        </div>
      </>
    );
  }
  function showContentInThirdPopUp() {
    if (
      localPolicyState.policyAndVersion &&
      localPolicyState.cloneClientAssignmentTab
    ) {
      return (
        "Create A New Policy from exsisting Policy.Version: " +
        localPolicyState.policyAndVersion +
        " and by cloning client Assignment Tab from the Policy.Version: " +
        localPolicyState.forClonedPolicyAndVersion
      );
    } else if (
      localPolicyState.policyAndVersion &&
      localPolicyState.addAllActiveClients
    ) {
      return (
        "Create A New Policy from exsisting Policy.Version: " +
        localPolicyState.policyAndVersion +
        " and adding all Active Client Groups"
      );
    } else if (localPolicyState.policyAndVersion) {
      return (
        "Create A New Policy from exsisting Policy.Version :" +
        localPolicyState.policyAndVersion
      );
    } else if (localPolicyState.addAllActiveClients) {
      return "Create A New Policy by adding all Active Client Groups(Standard TPAs Only)";
    } else if (localPolicyState.cloneClientAssignmentTab) {
      return (
        "Create A New Policy by cloning Assignment Tab from the Policy.Version:" +
        localPolicyState.forClonedPolicyAndVersion
      );
    } else {
      return "Create a New Policy";
    }
  }
  function showPolicyNumberIsValid() {
    dispatch({
      type: DIALOG,
      payload: true,
      title: "Error",
      message: "Please enter the Valid Policy.version",
    });
  }
  function showContentInPopUp() {
    if (openThirdPopUp) {
      return showContentInThirdPopUp();
    } else {
      return ShowExsistingOrNewPolicyPopUpFileds();
    }
  }
  function showTitleInPopUp() {
    let title = "";
    if (showPopInNewPolicy) {
      title = "Create New Policy";
    }
    if (openSecondPopUp && showPopInNewPolicy) {
      title = "Add Client Groups To New Policy";
    }
    if (openSecondPopUp && showPopInNewPolicy && openThirdPopUp) {
      title = "Confirm";
    }
    return title;
  }
  function saveNewPolicyValues() {
    const action = [
      { type: JIRA_ID, payload: localPolicyState.jiraId },
      { type: JIRA_DESCRIPTION, payload: localPolicyState.jiraDescription },
      {
        type: JIRA_LINK,
        payload:
          "https://advancedpricing.atlassian.net/browse/" +
          localPolicyState.jiraId,
      },
      { type: JIRA_ISOPEN, payload: 1 },
      { type: USER_ID, payload: formState.userDetails.emailId },
      { type: NEW_POLICY_CREATE_ARRAY, payload: localPolicyState },
    ];
    batch(() => {
      action.forEach((action) => dispatch(action));
    });
  }
  function activateSecondPopUpFiledsError(error) {
    if (!error) {
      setOpenThirdPopUp(true);
      let create = localPolicyState.newPolicyStartDate;
      let obj = _.cloneDeep(localPolicyState);
      obj.newPolicyStartDate = create;
      setLocalPolicyState(obj);
    }
  }
  function showFirstPopFieldsError(error) {
    setCheckFiledsError(true);
    if (!error) {
      setOpenSecondPopUp(true);
      setCheckFiledsError(false);
    }
  }

  async function validateFileds() {
    let error = validatePolicyCreationFields(
      localPolicyState,
      formState,
      dispatch
    );
    let valid;
    if (localPolicyState.policyAndVersion) {
      const [policyNumber, version] = localPolicyState?.policyAndVersion
        .toString()
        .split(".");
      valid = await getPolicyNumberAndVersionChecking(
        dispatch,
        policyNumber,
        version
      );
    }
    if (valid) {
      if (Object.keys(valid).length === 0) {
        showPolicyNumberIsValid();
      } else {
        setExsistingPolicyId(valid?.policyId);
        showFirstPopFieldsError(error);
      }
    } else {
      showFirstPopFieldsError(error);
    }

    if (openSecondPopUp) {
      if (localPolicyState.forClonedPolicyAndVersion) {
        const [policyNumber, version] =
          localPolicyState?.forClonedPolicyAndVersion.toString().split(".");
        valid = await getPolicyNumberAndVersionChecking(
          dispatch,
          policyNumber,
          version
        );
      }
      if (valid) {
        if (Object.keys(valid).length === 0) {
          showPolicyNumberIsValid();
        } else {
          props.value.setForClientTabPolicyId(valid?.policyId);
          activateSecondPopUpFiledsError(error);
        }
      } else {
        activateSecondPopUpFiledsError(error);
      }
    }
    if (openThirdPopUp) {
      saveNewPolicyValues();
      if (exsistingPolicyId) {
        getPolicyById(dispatch, exsistingPolicyId);
      }
      props.value.setOpenPolicyCreationPopUp(true);
    }
  }

  return (
    <>
      <Dialogbox
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        disableBackdropClick={true}
        open={showPopInNewPolicy}
        onClose={closePolicyPopUp}
        title={showTitleInPopUp()}
        message={<>{showContentInPopUp()}</>}
        actions={
          <>
            <CustomButton
              variant={"contained"}
              onClick={() => {
                validateFileds();
              }}
              style={{
                backgroundColor: successColor,
                color: "white",
                margin: 10,
                padding: 4,
                fontSize: 12,
                textTransform: "capitalize",
              }}
            >
              {openThirdPopUp ? "Save" : "Continue"}
            </CustomButton>
            <CustomButton
              variant={"contained"}
              onClick={() => {
                if (showPopInNewPolicy && !openSecondPopUp && !openThirdPopUp) {
                  batch(() => {
                    dispatch({ type: TAB_PATHS, payload: "search" });
                    dispatch({ type: NEW_POLICY_CREATE_ARRAY, payload: [] });
                  });
                  navigate("/");
                }

                if (openThirdPopUp) {
                  setOpenThirdPopUp(false);
                }
                if (!openThirdPopUp && openSecondPopUp) {
                  let obj = _.cloneDeep(localPolicyState);
                  obj.addAllActiveClients = undefined;
                  obj.cloneClientAssignmentTab = undefined;
                  obj.newPolicyStartDate = undefined;
                  obj.forClonedPolicyAndVersion = undefined;
                  setLocalPolicyState(obj);
                  setOpenSecondPopUp(false);
                  // setValidPolicyNumber(false);
                }
              }}
              style={{
                backgroundColor: dangerColor,
                color: "white",
                margin: 10,
                padding: 4,
                fontSize: 12,
                textTransform: "capitalize",
              }}
            >
              Cancel
            </CustomButton>
          </>
        }
      />
    </>
  );
};

export default NewPolicyPopUp;

export const claimId = "Clm ID";
export const ItemizedBillLineID = "Itemized Bill Line ID"
export const slId = "SL ID"
export const refSlId = "Ref SL ID";
export const refClmId = "Ref Clm ID";
export const medPolicy = "Med Policy";
export const policy = "Policy.Version";
export const submittedCpt = "Submitted CPT";
export const submittedMod1 = "Submitted Mod 1";
export const submittedMod2 = "Submitted Mod 2";
export const submittedMod3 = "Submitted Mod 3";
export const submittedMod4 = "Submitted Mod 4";
export const principalDx = "Principal Dx";
export const DxClmLevel = "Dx Clm Level";
export const admitDx = "Admit Dx";
export const extDx = "Ext Dx";
export const dxCode1 = "Dx Code 1";
export const dxCode2 = "Dx Code 2";
export const dxCode3 = "Dx Code 3";
export const dxCode4 = "Dx Code 4";
export const reasonCode = "Reason Code";
export const IpuChgCode = "IPU CHG Code";
export const POSBillType = "POS/Bill Type";
export const LineLevelPos = "Line Level POS";
export const ConditionCode = "Condition Code";
export const clmFormType = "Clm Form Type";
export const ipuClmType = "IPU Clm Type";
export const dosFrom = "DOS From";
export const dosTo = "DOS To";
export const rvuPrice = "RVU Price";
export const ipuChallengeAmount = "IPU Challenge Amt";
export const socProviderId = "SOC Prov Id";
export const socProviderPostalCode = "SOC Prov Postal Code";
export const submittedChargeAmt = "Submitted Charge Amt";
export const billingProviderId = "Bill Prov ID";
export const billingProviderPostalCode = "Bill Prov Postal Code";
export const renderingProviderNpi = "Rend NPI";
export const lineLevelNpi = "Line Level NPI";
export const renderingTaxnomy = "Rend Taxonomy";
export const lineLevelTaxnomy = "Line Level Taxonomy";
export const taxIdentifier = "Tax Identifier";
export const RevCode = "Rev Code";
export const clientCode = "Client Code";
export const clientGroup = "Client Group";
export const processedDate = "Processed Date";
export const subQuantity = "Sub Quantity";
export const allowedQuantity = "Allowed Quantity";
export const allowedProcedureCode = "Allowed Procedure Code";
export const allowedMod1 = "Allowed Mod 1";
export const allowedMod2 = "Allowed Mod 2";
export const allowedMod3 = "Allowed Mod 3";
export const allowedMod4 = "Allowed Mod 4";
export const lineAllowedAmount = "Allowed Line Amt ";
export const allowedUnits = "Allowed Units";
























import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import {  LINE_OR_HEADER_OR_PRINC } from "../../redux/actions/NewPolicyFormActionTypes";
import { NewPolicyFormState } from "../../redux/reducers/NewPolicyFormReducer";
import { NewPolicyState } from "../../redux/reducers/NewPolicyReducer";
import "./CategoryOptions.css";
import { PolicyConstants } from "../NewPolicy/PolicyConst";
import RadioButton from "../../components/RadioButton/RadioButton";
import { useLocation } from "react-router-dom";

const CategoryTwelve= ({ edit, viewMode }) => {
    const formState: NewPolicyFormState = useSelector(
        (state: any) => state.newPolicyForm
    );
const updatedState: NewPolicyState = useSelector(
        (state: any) => state.newPolicy
    );

    const dispatch = useDispatch();
    const [selectedType, setSelectedType] = useState("");

      const location = useLocation();
      const paths = location.pathname.replaceAll("/", "");
      
      useEffect(() => {
        if (formState.cat === PolicyConstants.TWELVE) {
            if (
              formState.lineOrHeaderOrPrincipal == "" ||
              formState.lineOrHeaderOrPrincipal == null
            ) {
              dispatch({
                type: LINE_OR_HEADER_OR_PRINC,
                payload: 1,
              });
            }
          }
        }, [formState]);

    return (
        <div>
          <GridContainer>
                  <GridItem sm={6} md={6} xs={6}>
                    <div style={{ marginTop: 7 }}>

                      <RadioButton
                        label={"Header Level Diagnosis"}
                        checked={formState.lineOrHeaderOrPrincipal == 1}
                        disabled={viewMode}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedType("headerlevel")
                            dispatch({
                              type: LINE_OR_HEADER_OR_PRINC,
                              payload: 1,
                            });
                          }
                        }
                        }
                      />
                      <RadioButton
                        label={"Line Level Diagnosis"}
                        checked={formState.lineOrHeaderOrPrincipal == 2}
                        disabled={viewMode}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedType("linelevel")
                            dispatch({
                              type: LINE_OR_HEADER_OR_PRINC,
                              payload: 2,
                            });
                          }
                        }} />
                      <RadioButton
                        label={"Principal Diagnosis"}
                        checked={formState.lineOrHeaderOrPrincipal == 3}
                        disabled={viewMode}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedType("both")
                            dispatch({
                              type: LINE_OR_HEADER_OR_PRINC,
                              payload: 3,
                            });
                          }
                        }}
                      />
                    </div>
                  </GridItem>
                </GridContainer>
        </div>
    );
};
export default CategoryTwelve;
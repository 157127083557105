import {
  AllCommunityModules,
  ModuleRegistry,
} from "@ag-grid-community/all-modules";
import { CircularProgress, Link } from "@material-ui/core";
import { ButtonGroup } from "@mui/material";
import * as FileSaver from "file-saver";
import Moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert2";
import XLSX from "xlsx";
import {
  dangerColor,
  disabledColor,
  navyColor,
} from "../../assets/jss/material-kit-react";
import CustomButton from "../../components/CustomButtons/CustomButton";
import CustomPaper from "../../components/CustomPaper/CustomPaper";
import Dialogbox from "../../components/Dialog/DialogBox";
import AgGrids from "../../components/TableGrid/AgGrids";
import {
  getChangesId,
  getClmLinkLkp,
  getPolicyCptActionLkp,
  getProcs,
  uploadProceduresToStage,
  UploadProceduresToTarget,
} from "../../redux/actions";
import { GET_PROCS, PROCS_TARGET } from "../../redux/actions/actionTypes";
import { NewPolicyFormState } from "../../redux/reducers/NewPolicyFormReducer";
import { NewPolicyState } from "../../redux/reducers/NewPolicyReducer";
import "./NewPolicy.css";
import { exportedExcelFileData } from "../../components/ExportExcel/ExportExcelFile";

const colStyle = {
  backgroundColor: navyColor,
  color: "white",
  fontWeight: "400px",
  fontFamily: "Arial, Helvetica, sans-serif",
};

const Procs = ({ edit, policyId, showImportButton }) => {
  const [data, setData] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [changesData, setChangesData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [deltaLink, setDeltaLink] = useState(undefined);
  const [numberOfRows, setNumberOfRows] = useState(0);
  const [dataclm, setDataclm] = useState([]);
  const [actionData, setActionData] = useState([]);
  const updatedState: NewPolicyState = useSelector(
    (state: any) => state.newPolicy
  );
  const formState: NewPolicyFormState = useSelector(
    (state: any) => state.newPolicyForm
  );

  ModuleRegistry.registerModules(AllCommunityModules);

  const columnDefs = [
    {
      field: "cptFrom",
      headerName: "CPT FROM",
      minWidth: 90,
      headerTooltip: "CPT FROM",
      cellRenderer: (props) => {
        if (props.value !== undefined) {
          return props.value;
        } else {
          return (
            <>
              <img src="https://www.ag-grid.com/example-assets/loading.gif" />
            </>
          );
        }
      },
    },
    {
      field: "cptTo",
      headerName: "CPT To",
      minWidth: 90,
      headerTooltip: "CPT To",
    },
    {
      field: "mod1",
      headerName: "Mod1",
      minWidth: 83,
      headerTooltip: "Mod1",
    },
    {
      field: "mod2",
      headerName: "Mod2",
      minWidth: 83,
      headerTooltip: "Mod2",
    },
    {
      field: "mod3",
      headerName: "Mod3",
      minWidth: 83,
      headerTooltip: "Mod3",
    },
    {
      field: "daysLo",
      headerName: "Look Back Start",
      minWidth: 80,
      headerTooltip: "Look Back Start",
    },
    {
      field: "daysHi",
      headerName: "Look Back End",
      minWidth: 80,
      headerTooltip: "Look Back End",
    },
    {
      field: "revFrom",
      headerName: "Rev From",
      minWidth: 100,
      headerTooltip: "Rev From",
    },
    {
      field: "revTo",
      headerName: "Rev To",
      minWidth: 90,
      headerTooltip: "Rev To",
    },
    {
      field: "pos",
      headerName: "POS",
      minWidth: 80,
      headerTooltip: "POS",
    },
    {
      field: "dosFrom",
      headerName: "DOS From",
      minWidth: 100,
      headerTooltip: "DOS From",
      cellRenderer: (data) => {
        return data.value ? data.value : "";
      },
    },
    {
      field: "dosTo",
      headerName: "DOS To",
      minWidth: 100,
      headerTooltip: "DOS To",
      cellRenderer: (data) => {
        return data.value ? data.value : "";
      },
    },
    {
      field: "action",
      headerName: "ACTION",
      minWidth: 90,
      headerTooltip: actionHeader(),
    },

    {
      field: "exclusion",
      headerTooltip: "Exclusion",
      headerName: "Exclusion",
      minWidth: 90,

      cellStyle: (isFlag) =>
        isFlag.value == "1"
          ? { "pointer-events": "none" }
          : { "pointer-events": "none" },

      cellRenderer: (data) => {
        return (
          <input
            type="checkbox"
            disabled
            checked={data.value == 1 ? true : false}
          />
        );
      },
    },
    {
      field: "dxLink",
      headerTooltip: "Dx",
      headerName: "Dx",
      minWidth: 70,
      cellStyle: (isFlag) =>
        isFlag.value == "1"
          ? { "pointer-events": "none" }
          : { "pointer-events": "none" },

      cellRenderer: (data) => {
        return (
          <input
            type="checkbox"
            disabled
            checked={data.value == 1 ? true : false}
          />
        );
      },
    },
    {
      field: "claimLink",
      headerName: "Clm Link",
      minWidth: 100,
      resizable: false,
      headerTooltip: customheader(),
      cellRenderer: (cellValues) => {
        return (
          <span
            title={`${
              cellValues.data?.claimLinkKey + " - " + cellValues.data?.claimDesc
            }`}
          >{`${cellValues.data?.claimLink}`}</span>
        );
      },
    },
  ];

  function customheader() {
    let claimKey;
    let claimDesc;
    let dataClm = dataclm.map((k) => {
      claimKey = k.claimLinkKey;
      claimDesc = k.claimLinkDesc;
      return " " + claimKey + "-" + claimDesc;
    });
    return dataClm;
  }

  function actionHeader() {
    let policyCptActionKey;
    let policyCptActionCode;
    let actnData = actionData.map((k) => {
      policyCptActionKey = k.policyCptActionKey;
      policyCptActionCode = k.policyCptActionCode;
      return " " + policyCptActionKey + "-" + policyCptActionCode;
    });
    return actnData;
  }

  const onFilterChanged = (params) => {
    setNumberOfRows(params.api.getDisplayedRowCount());
  };

  const procsState: NewPolicyState = useSelector(
    (state: any) => state.newPolicy
  );

  const [fileError, setFileError] = useState(false);
  const navigate = useNavigate();
  const target = () => {
    UploadProceduresToTarget(dispatch, formState.policyId, navigate);
    dispatch({ type: GET_PROCS, payload: [] });
    if (updatedState.procs.length == 0) {
      getProcs(dispatch, formState.policyId);
    }
  };

  useEffect(() => {
    if (updatedState.procs.length == 0) {
      if (policyId != null) {
        getProcs(dispatch, policyId);
      }
    }
    if (policyId != null) {
      getChangesId(dispatch, policyId);
    }
    
    getClmLinkLkp(dispatch);
    getPolicyCptActionLkp(dispatch);
  }, [policyId]);
  useEffect(() => {
    updatedState.procs.map((d, i) => {
      d.exclusion = d.excludeb;
      d.dxLink = d.dxLink;
      d.action = d.policyCptActionCode;
      d.claimLink = d.claimLinkCode;
      d.id = i;
      d.dosFrom = Moment(d.dosFrom).format("MM-DD-YYYY");
      d.dosTo = Moment(d.dosTo).format("MM-DD-YYYY");
      d.claimDesc = d.claimLinkDesc;
      d.claimLinkKey = d.claimLinkKey;
    });
    let exportedProcsData = updatedState.procs.map((d) => {
      return {
        POLICYID: policyId,
        CPTFROM: d.cptFrom,
        CPTTO: d.cptTo,
        MOD1: d.mod1,
        MOD2: d.mod2,
        MOD3: d.mod3,
        DAYSLO: d.daysLo,
        DAYSHI: d.daysHi,
        REVFROM: d.revFrom,
        REVTO: d.revTo,
        POS: d.pos,
        DOSFROM: d.dosFrom,
        DOSTO: d.dosTo,
        POLICYCPTACTIONKEY: d.policyCptActionKey,
        EXCLUDEB: d.excludeb,
        DXLINK: d.dxLink,
        CLAIMLINKKEY: d.claimLinkKey,
      };
    });
    setData(updatedState.procs);
    setUpdatedData(exportedProcsData);
    setNumberOfRows(updatedState.procs ? updatedState.procs.length : 0);
    if (!updatedState.stageprocs) {
      setOpen(false);
    }
  }, [updatedState.procs]);
  const gridRef = useRef();
  const checkIntegerData = (days) => {
    let j = updatedState.procs.filter((f, i) => {
      if (days["daysLo"].length > 0) {
        if (f.daysLo == days["daysLo"]) {
          return { f };
        }
      }
      if (days["daysHi"] != "") {
        if (f.daysHi == days["daysHi"]) {
          return { f };
        }
      }
      if (days["exclusion"].length > 0) {
        if (f.exclusion == days["exclusion"]) {
          return { f };
        }
      }
      if (days["dxLink"].length > 0) {
        if (f.dxLink == days["dxLink"]) {
          return { f };
        }
      }
    });
    return j;
  };

  const checkFilterdDOSData = (dos) => {
    let j = updatedState.procs.filter((f, i) => {
      if (dos["dosFrom"] != "") {
        if (
          Moment(f.dosFrom).format("MM-DD-YYYY") ==
          Moment(dos["dosFrom"]).format("MM-DD-YYYY")
        ) {
          return { f };
        }
      }
      if (dos["dosTo"] != "") {
        if (
          Moment(f.dosTo).format("MM-DD-YYYY") ==
          Moment(dos["dosTo"]).format("MM-DD-YYYY")
        ) {
          return { f };
        }
      }
    });
    return j;
  };
  const checkFilterdProcsDATA = (procs) => {

    let cptFromValues = procs["cptFrom"].split(',');
    let cptToValues = procs["cptTo"].split(',');
    

    let j = updatedState.procs.filter((f, i) => {
      if (
        cptFromValues.some(value =>f.cptFrom.toLowerCase().includes(value.toLowerCase())) &&
        cptToValues.some(value =>f.cptTo.toLowerCase().includes(value.toLowerCase())) &&
        f.mod1.toLowerCase().includes(procs["mod1"].toLowerCase()) &&
        f.mod2.toLowerCase().includes(procs["mod2"].toLowerCase()) &&
        f.mod3.toLowerCase().includes(procs["mod3"].toLowerCase()) &&
        f.revFrom.toLowerCase().includes(procs["revFrom"].toLowerCase()) &&
        f.revTo.toLowerCase().includes(procs["revTo"].toLowerCase()) &&
        f.pos.toLowerCase().includes(procs["pos"].toLowerCase()) &&
        f.action.toLowerCase().includes(procs["action"].toLowerCase()) &&
        f.claimLink.toLowerCase().includes(procs["claimLink"].toLowerCase())
      ) {
        return {
          f,
        };
      }
    });
    return j;
  };
  function stringToDateFormat(date) {
    let formattedDate = "";
    const newDate = new Date(date);
    if (
      newDate.getFullYear != undefined &&
      newDate.getDate != undefined &&
      newDate.getMonth != undefined
    ) {
      formattedDate = Moment(newDate).format("YYYY-MM-DD");
    }
    return formattedDate;
  }
  const onGridReady = async (params) => {

    gridRef.current = params.api;
    const dataSource = {
      rowCount: null,
      getRows: async (params) => {
        let sortType = "";
        let sortableColumn = "";
        let procs1 = {};
        if (!(params.filterModel == null || undefined)) {
          procs1 = {
            cptFrom: params.filterModel.cptFrom
              ? params.filterModel.cptFrom.filter
              : "",
            cptTo: params.filterModel.cptTo
              ? params.filterModel.cptTo.filter
              : "",
            mod1: params.filterModel.mod1 ? params.filterModel.mod1.filter : "",
            mod2: params.filterModel.mod2 ? params.filterModel.mod2.filter : "",
            mod3: params.filterModel.mod3 ? params.filterModel.mod3.filter : "",
            daysLo: params.filterModel.daysLo
              ? params.filterModel.daysLo.filter
              : "",
            daysHi: params.filterModel.daysHi
              ? params.filterModel.daysHi.filter
              : "",
            revFrom: params.filterModel.revFrom
              ? params.filterModel.revFrom.filter
              : "",
            revTo: params.filterModel.revTo
              ? params.filterModel.revTo.filter
              : "",
            pos: params.filterModel.pos ? params.filterModel.pos.filter : "",
            dosFrom: params.filterModel.dosFrom
              ? stringToDateFormat(params.filterModel.dosFrom.filter)
              : "",
            dosTo: params.filterModel.dosTo
              ? stringToDateFormat(params.filterModel.dosTo.filter)
              : "",
            action: params.filterModel.action
              ? params.filterModel.action.filter
              : "",
            exclusion: params.filterModel.exclusion
              ? params.filterModel.exclusion.filter
              : "",
            dxLink: params.filterModel.dxLink
              ? params.filterModel.dxLink.filter
              : "",
            claimLink: params.filterModel.claimLink
              ? params.filterModel.claimLink.filter
              : "",
            isSort: sortType != "" ? sortType : "",
            sortColumn: sortableColumn != "" ? sortableColumn : "",
            policyId: policyId,
          };
        }

        let StringData = [];
        let dosData = [];
        let IntegerData = [];
        let filterdData = [];
        if (filterdData.length == 0) {
          filterdData = data;
        }
        if (procs1["dosFrom"].length > 0 || procs1["dosTo"].length > 0) {
          dosData = checkFilterdDOSData(procs1);
          filterdData = dosData;
        }
        if (
          procs1["daysLo"].length > 0 ||
          procs1["daysHi"].length > 0 ||
          procs1["exclusion"].length > 0 ||
          procs1["dxLink"].length > 0
        ) {
          IntegerData = checkIntegerData(procs1);
          filterdData = IntegerData;
        }
        if (
          procs1["cptFrom"].length > 0 ||
          procs1["cptTo"].length > 0 ||
          procs1["pos"].length > 0 ||
          procs1["action"].length > 0 ||
          procs1["revFrom"].length > 0 ||
          procs1["revTo"].length > 0 ||
          procs1["claimLink"].length > 0 ||
          procs1["mod1"].length > 0 ||
          procs1["mod2"].length > 0 ||
          procs1["mod3"].length > 0
        ) {
        
          StringData = checkFilterdProcsDATA(procs1);
          filterdData = StringData;
        }
        if (filterdData.length == 0) {
          swal.fire({
            icon: "info",
            text: "No data found.",
            confirmButtonColor: navyColor,
            confirmButtonText: "OK",
          });
        }
        let filterdExportedData = filterdData.map((d) => {
          return {
            POLICYID: policyId,
            CPTFROM: d.cptFrom,
            CPTTO: d.cptTo,
            MOD1: d.mod1,
            MOD2: d.mod2,
            MOD3: d.mod3,
            DAYSLO: d.daysLo,
            DAYSHI: d.daysHi,
            REVFROM: d.revFrom,
            REVTO: d.revTo,
            POS: d.pos,
            DOSFROM: d.dosFrom,
            DOSTO: d.dosTo,
            POLICYCPTACTIONKEY: d.policyCptActionKey,
            EXCLUDEB: d.excludeb,
            DXLINK: d.dxLink,
            CLAIMLINKKEY: d.claimLinkKey,
          };
        });
        setUpdatedData(filterdExportedData);
        setNumberOfRows(filterdData.length);
        setTimeout(() => {
          const rowsThisPage = filterdData.slice(
            params.startRow,
            params.endRow
          );
          let lastRow = -1;
          if (filterdData.length <= params.endRow) {
            lastRow = filterdData.length;
          }
          params.successCallback(rowsThisPage, lastRow);
        }, 500);
      },
    };
    params.api.setDatasource(dataSource);
  };
  useEffect(() => {
    updatedState.clmLinkLkp.forEach((d, i) => {
      d.claimLinkKey = d.claimLinkKey;
      d.claimLinkCode = d.claimLinkCode;
    });
    setDataclm(updatedState.clmLinkLkp);
  }, [updatedState.clmLinkLkp]);

  useEffect(() => {
    setActionData(updatedState.policyCptActionLkp);
  }, [updatedState.policyCptActionLkp]);

  useEffect(() => {
    setChangesData(updatedState.changesisOpenB);
    setDeltaLink(updatedState.deltaLink);
  }, [updatedState]);

  const handleClickToOpen = () => {
    setOpen(true);
  };
  const handleToClose = () => {
    setOpen(false);
    setSelectedFile("");
  };
  const handleToCloseTargetProcs = () => {
    dispatch({ type: PROCS_TARGET, payload: false });
  };

  function checkIsPresent(uniqueCodes, value) {
    const index = uniqueCodes.indexOf(value);
    return index >= 0;
  }
  function ProcsData() {
    if (changesData.length > 0) {
      handleClickToOpen();
    } else {
      swal.fire({
        icon: "error",
        text: "Please create Jira Ticket.",
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
    setSelectedFile(undefined);
  }

  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(undefined);

  const handleUploadFile = (file) => {
    var allowedFiles = [".xlsx", ".csv"];
    var regex = new RegExp(
      "([a-zA-Z0-9s_\\.-:()])+(" + allowedFiles.join("|") + ")$"
    );
    if (file != undefined) {
      if (!regex.test(file.name.toLowerCase())) {
        setOpen(false);
        swal.fire({
          icon: "error",
          text: "Please upload valid file",
          confirmButtonColor: navyColor,
          confirmButtonText: "OK",
        });
        return false;
      } else {
        return true;
      }
    }
  };
  function checkDates(dos, dosTo) {
    let err = true;
    let dosFromErr = true,
      dosToErr = true;
    let dosFrom1 = dos?.toString().includes("/");
    let dosFrom2 = dos?.toString().includes("-");
    let dosTo1 = dosTo?.toString().includes("/");
    let dosTo2 = dosTo?.toString().includes("-");

    if (dosFrom1 == true || dosFrom2 == true) {
      dosFromErr = false;
    } else {
      dosFromErr = true;
    }
    if (dosTo1 == true || dosTo2 == true) {
      dosToErr = false;
    } else {
      dosToErr = true;
    }
    if (dosFromErr == true || dosToErr == true) {
      err = true;
    } else {
      err = false;
    }
    return err;
  }
  function checkNull(d, i) {
    let error2 = false;
    if (!d.CPTFROM) {
      error2 = true;
    } else if (!d.CPTTO) {
      error2 = true;
    } else if (!d.MOD1) {
      error2 = true;
    } else if (!d.MOD2) {
      error2 = true;
    } else if (!d.MOD3) {
      error2 = true;
    } else if (!(d.REVFROM?.toString().length > 0)) {
      error2 = true;
    } else if (!(d.REVTO?.toString().length > 0)) {
      error2 = true;
    } else if (!(d.POS?.toString().length > 0)) {
      error2 = true;
    } else if (!d.DOSFROM) {
      error2 = true;
    } else if (!d.DOSTO) {
      error2 = true;
    } else if (!d.DAYSLO && !(d.DAYSLO?.toString().length > 0)) {
      error2 = true;
    } else if (!d.DAYSHI && !(d.DAYSHI?.toString().length > 0)) {
      error2 = true;
    } else if (!(d.POLICYCPTACTIONKEY?.toString().length > 0)) {
      error2 = true;
    } else if (!(d.EXCLUDEB?.toString().length > 0)) {
      error2 = true;
    } else if (!(d.DXLINK?.toString().length > 0)) {
      error2 = true;
    } else if (!(d.CLAIMLINKKEY?.toString().length > 0)) {
      error2 = true;
    } else {
      error2 = false;
    }
    return error2;
  }

  const onFileUpload = async () => {
    var formData = new FormData();

    formData.append("uploadfile", selectedFile);
    let validation = handleUploadFile(selectedFile);
    if (validation) {
      let user = formState.userDetails;
      formData.append("email", user.emailId);
      let fileReader = new FileReader();
      fileReader.readAsArrayBuffer(selectedFile);
      fileReader.onload = async (e) => {
        let arrayBuffer: any = [];
        arrayBuffer = fileReader.result;
        let data = new Uint8Array(arrayBuffer);
        let arr = new Array();
        for (var i = 0; i != data.length; ++i)
          arr[i] = String.fromCharCode(data[i]);
        let bstr = arr.join("");
        let workbook = XLSX.read(bstr, { type: "binary" });
        let first_sheet_name = workbook.SheetNames[0];
        let worksheet = workbook.Sheets[first_sheet_name];
        var arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        let array: any = [];
        let exportarray: any = [];
        array = exportarray.concat(arraylist);
        let uniqueCodes = [];
        let error = false;

        array.forEach((o, i) => {
          if (
            !checkIsPresent(
              uniqueCodes,
              o.CPTFROM +
                "-" +
                o.CPTTO +
                "-" +
                o.MOD1 +
                "-" +
                o.MOD2 +
                "-" +
                o.MOD3 +
                "-" +
                o.DAYSLO +
                "-" +
                o.REVFROM +
                "-" +
                o.REVTO +
                "-" +
                o.POS +
                "-" +
                o.DOSFROM +
                "-" +
                o.EXCLUDEB +
                "-" +
                o.POLICYCPTACTIONKEY
            )
          ) {
            uniqueCodes.push(
              o.CPTFROM +
                "-" +
                o.CPTTO +
                "-" +
                o.MOD1 +
                "-" +
                o.MOD2 +
                "-" +
                o.MOD3 +
                "-" +
                o.DAYSLO +
                "-" +
                o.REVFROM +
                "-" +
                o.REVTO +
                "-" +
                o.POS +
                "-" +
                o.DOSFROM +
                "-" +
                o.EXCLUDEB +
                "-" +
                o.POLICYCPTACTIONKEY
            );
          } else {
            setOpen(false);
            error = true;
            swal.fire({
              icon: "error",
              title: "Error",
              text:
                "Duplicate Row at " +
                (i + 2) +
                " - " +
                "CPTFROM is : " +
                o.CPTFROM +
                ",  CPTTO is : " +
                o.CPTTO +
                ", MOD1 is : " +
                o.MOD1 +
                ", MOD2 is : " +
                o.MOD2 +
                ", MOD3 is : " +
                o.MOD3 +
                ", DASYLO is : " +
                o.DAYSLO +
                ", REVFROM is : " +
                o.REVFROM +
                ", REVTO is : " +
                o.REVTO +
                ", POS is : " +
                o.POS +
                ", DOSFROM is : " +
                o.DOSFROM +
                ", EXCLUDEB is : " +
                o.EXCLUDEB +
                ", ACTIONFK is : " +
                o.POLICYCPTACTIONKEY,
              confirmButtonColor: navyColor,
              confirmButtonText: "OK",
            });
          }
        });
        let importPolicyId = undefined;
        array.filter((d, i) => {
          if (formState.policyId != d.POLICYID) {
            importPolicyId = d.POLICYID;
            if (d.POLICYID == undefined) {
              importPolicyId = -1;
            }
          } else if (importPolicyId == undefined) {
            importPolicyId = policyId;
          }
          if (checkNull(d, i)) {
            setOpen(false);
            error = true;
            swal.fire({
              icon: "error",
              title: "Error",
              text: "Excel sheet contains empty cell Row at " + (i + 2),
              confirmButtonColor: navyColor,
              confirmButtonText: "OK",
            });
          }
          if (!checkNull(d, i)) {
            if (checkDates(d.DOSFROM, d.DOSTO)) {
              setOpen(false);
              error = true;
              swal.fire({
                icon: "error",
                title: "Error",
                text: "Incorrect Date Format Row at " + (i + 2),
                confirmButtonColor: navyColor,
                confirmButtonText: "OK",
              });
            }
          }
        });

        if (importPolicyId == policyId) {
          if (!error) {
            setOpen(false);
            await uploadProceduresToStage(dispatch, formData);
          }
        } else {
          setOpen(false);

          if (importPolicyId == -1) {
            swal.fire({
              icon: "error",
              title: "Error",
              text: "Policy Id is blank.",
              confirmButtonColor: navyColor,
              confirmButtonText: "OK",
            });
          } else {
            swal.fire({
              icon: "error",
              title: "Error",
              text: "Policy Id not matched.",
              confirmButtonColor: navyColor,
              confirmButtonText: "OK",
            });
          }
        }
      };
    }
  };

  return edit ? (
    <div style={{ marginTop: "-20px" }}>
      <CustomPaper
        style={{
          paddingLeft: 10,
          position: "relative",
          right: 20,
          paddingRight: 8,
          paddingBottom: 15,
          boxShadow: "none",
          border: data.length > 0 ? "1px solid #D6D8DA" : "",
        }}
      >
        {data.length > 0 ? (
          <div style={{ height: window.innerHeight / 2.2 }}>
            <AgGrids
              rowData={data}
              ref={gridRef}
              columnDefs={columnDefs}
              onFilterChanged={onFilterChanged}
              onGridReady={onGridReady}
              cacheOverflowSize={2}
              maxConcurrentDatasourceRequests={1}
              infiniteInitialRowCount={1}
              cacheBlockSize={1000}
              maxBlocksInCache={1}
              modules={AllCommunityModules}
              debug={true}
              rowBuffer={0}
              animateRows={true}
              rowModelType={"infinite"}
            />
          </div>
        ) : undefined}
      </CustomPaper>
      {data.length > 0 ? (
        <small style={{ position: "relative", fontSize: "12px" }}>
          Number of rows : {numberOfRows}
        </small>
      ) : undefined}
      <div style={{ float: "right", top: 5, position: "relative", right: 18 }}>
        <CustomButton
          onClick={() => {
            ProcsData();
          }}
          variant={"contained"}
          disabled={!showImportButton}
          style={{
            backgroundColor: !showImportButton ? disabledColor : navyColor,
            color: "white",
            padding: 3,
            fontSize: 12,
            textTransform: "capitalize",
            marginLeft: 10,
          }}
        >
          Import
        </CustomButton>
        {data.length > 0 ? (
          <CustomButton
            onClick={() => {
              exportedExcelFileData(
                updatedData,
                formState.policy + "/" + formState.version,
                "Procedures"
              );
            }}
            variant={"contained"}
            style={{
              backgroundColor: navyColor,
              color: "white",
              padding: 3,
              fontSize: 12,
              textTransform: "capitalize",
              marginLeft: 10,
            }}
          >
            Export
          </CustomButton>
        ) : undefined}
      </div>

      <Dialogbox
        open={updatedState.stageprocs || updatedState.targetprocsSpinner}
        onClose={handleToClose}
      />
      <div>
        <Dialogbox
          open={updatedState.targetprocs}
          onClose={handleToCloseTargetProcs}
          disableBackdropClick={true}
          title="Confirm"
          message={
            <p>
              Delta Path:{" "}
              <Link target="_blank" href={`${deltaLink}`}>
                Delta Link
              </Link>{" "}
              <br></br>
              Would you like to Send Stage data to Target?
            </p>
          }
          actions={
            <>
              <ButtonGroup>
                <CustomButton
                  onClick={() => {
                    target();
                  }}
                  style={{
                    backgroundColor: navyColor,
                    color: "white",
                    marginRight: 10,
                    padding: 4,
                    fontSize: 12,
                    textTransform: "capitalize",
                  }}
                >
                  Yes
                </CustomButton>
                <CustomButton
                  onClick={handleToCloseTargetProcs}
                  style={{
                    backgroundColor: dangerColor,
                    color: "white",
                    padding: 4,
                    fontSize: 12,
                    textTransform: "capitalize",
                  }}
                >
                  No
                </CustomButton>
              </ButtonGroup>
              <div>
                {updatedState.stageprocs ? <CircularProgress /> : undefined}
              </div>
            </>
          }
        />
      </div>

      <Dialogbox
        open={open}
        onClose={handleToClose}
        title={"Upload Source File"}
        message={
          <input
            type="file"
            accept=".xlsx"
            onChange={(event) => {
              let file = event.target.files[0];
              setSelectedFile(file);
              let flag = handleUploadFile(event.target.files[0]);
              setFileError(flag);
            }}
          />
        }
        actions={
          <ButtonGroup>
            {selectedFile ? (
              <CustomButton
                onClick={() => {
                  onFileUpload();
                }}
                style={{
                  backgroundColor: navyColor,
                  color: "white",
                  marginRight: 10,
                  padding: 4,
                  fontSize: 12,
                  textTransform: "capitalize",
                }}
              >
                upload
              </CustomButton>
            ) : undefined}
            <CustomButton
              onClick={handleToClose}
              style={{
                backgroundColor: dangerColor,
                color: "white",
                textTransform: "capitalize",
                fontSize: 12,
                padding: 4,
              }}
            >
              cancel
            </CustomButton>
          </ButtonGroup>
        }
      />

      <div>
        <div></div>
      </div>
    </div>
  ) : (
    <></>
  );
};
export default Procs;

import { navyColor } from '../../assets/jss/material-kit-react';
import { policyConfigUrl, apiUrl } from '../../configs/apiUrls';
import { store } from '../store';
import { GET_TESTING_REPORT_DATA } from './TestingReportType';
import { SET_IS_LOADING } from './actionTypes';

export function validatePolicyCreationFields(
  policyCreationState,
  formstate,
  dispatch
) {
  let error = false;
  if (!policyCreationState.jiraId) {
    error = true;
  }
  if (!policyCreationState.jiraDescription) {
    error = true;
  }
  if (policyCreationState.cloneClientAssignmentTab) {
    if (!policyCreationState.forClonedPolicyAndVersion) {
      error = true;
    }
  }

  if (
    policyCreationState.cloneClientAssignmentTab || policyCreationState.addAllActiveClients
  ) {
    if (policyCreationState.newPolicyStartDate === undefined || policyCreationState.newPolicyStartDate ==="") {
      error = true;
    }
  }

  return error;
}

export async function getPolicyNumberAndVersionChecking(
  dispatch: typeof store.dispatch,
  policyNumber: number,
  version: number
) {
  let policiesData: any = {};
  await fetch(
    `${policyConfigUrl + apiUrl.testReport}/${policyNumber}/${version}`
  )
    .then((response) => {
      if (!response.ok) {
      }
      return response.json();
    })
    .then((data) => {
      if (data.length === 1) {
        policiesData = data[0];
      }
    });
  return policiesData;
}


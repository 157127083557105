import { navyColor } from "../../assets/jss/material-kit-react";
import { policyConfigUrl, apiUrl } from "../../configs/apiUrls";
import { store } from "../store";
import { GET_CLIENT_EXCLUSION } from "./ClientPolicyExclusionActionTypes";
import { SET_IS_LOADING } from "./actionTypes";
import { GET_ACTIVE_CLIENT_DATA, GET_CLIENT_ASSIGNMENT_DATA, POST_CLIENT_ASSIGNMENT } from "./clientAssignmentTypes";
import swal from 'sweetalert2';
import { NEW_POLICY_CREATE_ARRAY } from "./PolicyActionType";


export async function getClientAssignmentData(dispatch: typeof store.dispatch,policyId) {
    await fetch(`${policyConfigUrl + apiUrl.clientAssignmet}/${policyId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        dispatch({ type: GET_CLIENT_ASSIGNMENT_DATA, payload: data });
      });
  }

  
export async function postClientAssignment(dispatch: typeof store.dispatch, data, policyId) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(policyConfigUrl + apiUrl.postClientAssignmentData, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json;
    })
    .then(
      (data) => {
        getClientAssignmentData(dispatch,policyId);
        dispatch({ type: POST_CLIENT_ASSIGNMENT, payload: data });
        dispatch({ type: SET_IS_LOADING, payload: false });
        swal.fire({
          icon: 'success',
          text: 'Data Added successfully',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
      }
      // });
    );
}

export async function editClientAssignment(dispatch: typeof store.dispatch, data, policyId) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  let obj ={
    policyClntAssmtKey: data.policyClntAssmtKey,
    policyId: data.policyId,
    clientCode: data.clientCode,
    clientName: data.clientName,
    clientGroupCode: data.clientGroupCode[0].value,
    clientGroupName: data.clientGroupName,
    clientStartDate: data.clientStartDate,
    clientEndDate: data.clientEndDate,
    excludeClientSpecificCodes: data.excludeClientSpecificCodes,
    hp: data.hp
  }
  await fetch(policyConfigUrl + apiUrl.editClientAssignmentData, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(obj),
  })
    .then((response) => {
      if (!response.ok) {
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json;
    })
    .then(
      (data) => {
        getClientAssignmentData(dispatch,policyId);
        dispatch({ type: POST_CLIENT_ASSIGNMENT, payload: data });
        dispatch({ type: SET_IS_LOADING, payload: false });
        swal.fire({
          icon: 'success',
          text: 'Data Updated successfully',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
      }
      // });
    );
}

export async function deleteClientAssignmentData(
  dispatch: typeof store.dispatch,
  data,
  policyId
) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(policyConfigUrl + apiUrl.deleteClientAssignmentData + '/' + data, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: data,
  })
    .then((response) => {
      if (!response.ok) {
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.text();
    })
    .then((data) => {
      getClientAssignmentData(dispatch,policyId);
      dispatch({ type: SET_IS_LOADING, payload: false });
      swal.fire({
        icon: 'success',
        text: 'Data Deleted successfully',
        confirmButtonColor: navyColor,
        confirmButtonText: 'OK',
      });
    });
}

// export async function getActiveClientGroups(dispatch: typeof store.dispatch) {
//   await fetch(`${policyConfigUrl + apiUrl.activeClients}`)
//     .then((response) => {
//       if (!response.ok) {
//         throw new Error(response.statusText);
//       }
//       return response.json();
//     })
//     .then((data) => {
//       dispatch({ type: GET_ACTIVE_CLIENT_DATA, payload: data });
//     });
// }

export async function getActiveClientGroups(dispatch: typeof store.dispatch) {
  await fetch(`${policyConfigUrl + apiUrl.activeClients}`)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_ACTIVE_CLIENT_DATA, payload: data });
    });
}


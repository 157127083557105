import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import GridContainer from "../../components/Grid/GridContainer";
import { NewPolicyFormState } from "../../redux/reducers/NewPolicyFormReducer";
import { NewPolicyState } from "../../redux/reducers/NewPolicyReducer";
import "./CategoryOptions.css";
import { PolicyConstants } from "../NewPolicy/PolicyConst";
import { BW_TYPE_KEY } from "../../redux/actions/NewPolicyFormActionTypes";
import GridItem from "../../components/Grid/GridItem";
import { getBwTypeData } from "../../redux/actions";
import StarIcon from '@mui/icons-material/Star';


const CategoryTwentyFive = ({ edit, viewMode }) => {
 const formState: NewPolicyFormState = useSelector(
    (state: any) => state.newPolicyForm
  );
const updatedState: NewPolicyState = useSelector(
        (state: any) => state.newPolicy
    );
const dispatch = useDispatch();
const [bwTypekey, setBwTypeKey] = useState({});

const bwType = updatedState.getBwTypeData?.map((k) => {
    return { label: k.description, value: k.bwTypeKey }
  })

  useEffect(() => {
    if (updatedState.getBwTypeData.length == 0) {
      getBwTypeData(dispatch);
    }
   }, []);

useEffect(() => {
    if (formState.cat === PolicyConstants.TWENTY_FIVE) {
      if (
        formState.bwTypeKey == "" ||
        formState.bwTypeKey == null
      ) {
        dispatch({
          type: BW_TYPE_KEY,
          payload: 1,
        });
      }
    }
  }, [formState]);

    return(
        <div>
         <GridContainer>
                  <GridItem sm={2} md={2} xs={2}>
                    <small
                      style={{
                        fontSize: 13,
                        color: "black",
                        fontWeight: 400,
                        position: "relative",
                        top: 15,
                      }}
                    >
                      BW Type Key {<StarIcon style={{position:"relative", bottom:"4px",fontSize:"7px"}}/>}
                    </small>
                  </GridItem>
                  <GridItem sm={4} md={4} xs={4}>
                    <div className="catkey">
                      <CustomSelect
                        error={formState.errors?.bwTypeKey}
                        options={bwType}
                        value={bwType.filter(function (option) {
                          return option.value == formState.bwTypeKey;
                        })}
                        isDisabled={!edit ? undefined : viewMode}
                        catTitle={bwType.filter((option, index) => {
                          if (formState.bwTypeKey == option.value) {
                            return option
                          }
                        })}
                        onSelect={(event) => {
                          setBwTypeKey(event);
                          dispatch({
                            type: BW_TYPE_KEY,
                            payload: event.value,
                          });
                        }}
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                </div>
            );
};

export default CategoryTwentyFive;
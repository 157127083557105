import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { NewPolicyFormState } from "../../redux/reducers/NewPolicyFormReducer";
import "./CategoryOptions.css";
import React from "react";
import { DURATION, DURATION_DROPDOWN, FREQUENCY, FREQUENCY_RESET, PAYMENT, RANKING, RESET_STATE, UNITS } from "../../redux/actions/NewPolicyFormActionTypes";
import RadioButton from "../../components/RadioButton/RadioButton";
import CustomInput from "../../components/CustomInput/CustomInput";
import { PolicyConstants } from "../NewPolicy/PolicyConst";
import StarIcon from '@mui/icons-material/Star';


const CategoryThirtyTwo = ({ edit, viewMode }) => {

    const [frequency, setFrequency] = React.useState("");

    const formState: NewPolicyFormState = useSelector(
        (state: any) => state.newPolicyForm
    );

    let durationDropdown = [
        { value: 'D', label: 'Days' },
        { value: 'M', label: 'Months' },
        { value: 'Y', label: 'Years' }
    ];

    const durationData = durationDropdown?.map((f) => {
        return { label: f.label, value: f.value };
    });
    const dispatch = useDispatch();

    let durationValue = formState?.duration?.replace(/[a-zA-Z]/g, "");

    function stringMethod(e) {
        const re = /^[0-9\b]+$/;
        if (!re.test(e.key)) {
          e.preventDefault();
        }
      }
    function displayDuration() {
        let data = [];
        durationData?.map((d) => {
            if (formState?.durationDropdown?.value === (d?.value)) {
                data.push({
                    label: d.label,
                    value: d.value
                });
            }
        })
        return data;
    }

    useEffect(() => {
        if (formState.duration !== "" ) {
            formState.frequency = "RD";
            setFrequency("RD");
        }
    }, []);

      useEffect(() => {
        if (formState.cat == PolicyConstants.THIRTY_TWO) {
          if (formState.ranking == "" || formState.ranking == null) {
            dispatch({
              type:  RANKING,
              payload: "UP",
            });
          }
          if (
            formState.payment == "" ||
            formState.payment == null
          ) {
            dispatch({
              type: PAYMENT,
              payload: "PH",
            });
          }
          if (formState.frequency == "" || formState.frequency == null) {
            dispatch({
              type: FREQUENCY,
              payload: "RD",
            });
          }
        }
      }, [formState, frequency]);

    return (
        <div>
            <GridContainer>
                <GridItem sm={2} md={2} xs={2}>
                    <div style={{ marginTop: 30 }}>
                        <span>Units {<StarIcon style={{position:"relative", bottom:"4px",fontSize:"7px"}}/>}</span>
                    </div>
                </GridItem>
                <div style={{ marginTop: 15, marginLeft: 25 }}>
                    <CustomInput
                        fullWidth={true}
                        error={formState.errors?.units}
                        onKeyPress={(e) => stringMethod(e)}
                        value={formState.units}
                        disabled={!edit ? undefined : viewMode}
                        onChange={(event) => {
                            dispatch({
                                type: UNITS,
                                payload: event.target.value,
                            });
                        }}
                        type={"text"}
                        variant={"outlined"}
                    />
                </div>
            </GridContainer>
            <GridContainer>
                <GridItem sm={2} md={2} xs={2}>
                    <div style={{ marginTop: 15 }}>
                        <span>Frequency</span>
                    </div>
                </GridItem>
                <GridItem sm={4} md={4} xs={4}>
                    <div style={{ marginTop: 15 }}>
                        <RadioButton
                            label={"Rolling Duration"}
                            checked={formState.frequency == "RD"}
                            disabled={!edit ? undefined : viewMode}
                            value={frequency}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setFrequency("RD");
                                    dispatch({
                                        type: FREQUENCY,
                                        payload: "RD",
                                    });
                                }
                            }}
                        />
                        <RadioButton
                            label={"Calendar Month"}
                            checked={formState.frequency == "CM"}
                            disabled={!edit ? undefined : viewMode}
                            value={frequency}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    dispatch({ type: FREQUENCY_RESET });
                                    setFrequency("CM");
                                    dispatch({
                                        type: FREQUENCY,
                                        payload: "CM",
                                    });
                                }
                            }}
                        />
                        <RadioButton
                            label={"Calendar Year"}
                            checked={formState.frequency == "CY"}
                            disabled={!edit ? undefined : viewMode}
                            value={frequency}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    dispatch({ type: FREQUENCY_RESET });
                                    setFrequency("CY");
                                    dispatch({
                                        type: FREQUENCY,
                                        payload: "CY",
                                    });
                                }
                            }}
                        />
                    </div>
                </GridItem>
            </GridContainer>

            <GridContainer>
                <GridItem sm={2} md={2} xs={2}>
                    <div style={{ marginTop: 15 }}>
                        <span>Duration {<StarIcon style={{position:"relative", bottom:"4px",fontSize:"7px"}}/>}</span>
                    </div>
                </GridItem>
                <div style={{ marginTop: 1, marginLeft: 25 }}>
                    <CustomInput
                        fullWidth={true}
                        error={formState.errors?.duration}
                        // disabled={!edit ? true: frequency==="RD"?viewMode:edit }
                        disabled={viewMode == true?true:(edit == true && formState.frequency == "RD")?false:((edit == false && viewMode == false)&& formState.frequency == "RD"?false:true)  }

                        value={formState.frequency != "RD" ? null : durationValue}
                        onChange={(event) => {
                                dispatch({
                                    type: DURATION,
                                    payload: event.target.value,
                                });
                        }}
                        type={"text"}
                        variant={"outlined"}
                    />
                </div>
                <div style={{ marginTop: 1, marginLeft: 25 }}>
                    <CustomSelect
                        options={durationData}
                        error={formState.errors?.durationDropdown}
                        // isDisabled={!edit ? true: frequency==="RD"?viewMode:edit}
                        isDisabled={viewMode == true?true:(edit == true && formState.frequency == "RD")?false:((edit == false && viewMode == false)&& formState.frequency == "RD"?false:true)  }

                        value={displayDuration()}
                        onSelect={(event) => {
                                dispatch({
                                    type: DURATION_DROPDOWN,
                                    payload: event,

                                });
                        }}
                    />
                </div>

            </GridContainer>
            <GridContainer>
                <GridItem sm={2} md={2} xs={2}>
                    <div style={{ marginTop: 15 }}>
                        <span>Ranking</span>
                    </div>
                </GridItem>
                <GridItem sm={4} md={4} xs={4}>
                    <div style={{ marginTop: 15 }}>
                        <RadioButton
                            label={"Unit Price"}
                            disabled={!edit ? undefined : viewMode}
                            checked={formState.ranking == "UP"}
                            value="UP"
                            onChange={(e) => {
                                if (e.target.checked) {
                                    dispatch({
                                        type: RANKING,
                                        payload: "UP",
                                    });
                                }
                            }}
                        />
                        <RadioButton
                            label={"Total Charge"}
                            disabled={!edit ? undefined : viewMode}
                            checked={formState.ranking == "TC"}
                            value="TC"
                            onChange={(e) => {
                                if (e.target.checked) {
                                    dispatch({
                                        type: RANKING,
                                        payload: "TC",
                                    });
                                }
                            }}
                        />
                    </div>
                </GridItem>
            </GridContainer>

            <GridContainer>
                <GridItem sm={2} md={2} xs={2}>
                    <div style={{ marginTop: 15 }}>
                        <span>Payment</span>
                    </div>
                </GridItem>
                <GridItem sm={4} md={4} xs={4}>
                    <div style={{ marginTop: 15 }}>
                        <RadioButton
                            label={"Pay Highest"}
                            disabled={!edit ? undefined : viewMode}
                            checked={formState.payment == "PH"}
                            value="PH"
                            onChange={(e) => {
                                if (e.target.checked) {
                                    dispatch({
                                        type: PAYMENT,
                                        payload: "PH",
                                    });
                                }
                            }}
                        />
                        <RadioButton
                            label={"Pay Lowest"}
                            checked={formState.payment == "PL"}
                            disabled={!edit ? undefined : viewMode}
                            value="PL"
                            onChange={(e) => {
                                if (e.target.checked) {
                                    dispatch({
                                        type: PAYMENT,
                                        payload: "PL",
                                    });
                                }
                            }}
                        />
                    </div>
                </GridItem>
            </GridContainer>
        </div>
    );

};
export default CategoryThirtyTwo;
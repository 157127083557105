export const enum MetaLoaderConstants {
  MFS = 'MFS',
  SAME_OR_SIMILAR = 'CPT_SAME_OR_SIMILAR_CODES',
  MFS_DATE_BINDED = 'MFS DATE BINDED',
  ADHOC_CPT_HCPCS = 'ADHOC_CPT_HCPCS',
  HCPCS = 'HCPCS',
  ICD_10_CM = 'ICD_10_CM',
  RBRVS = 'RBRVS',
  ICD_10_CM_DRGN = 'ICD_10_CM_DRGN',
  BW_Pairs = 'BW Pairs',
  Modifier_Interaction = 'Modifier Interaction',
  GPCI = 'GPCI',
  GPCI_DATE_BINDED = 'GPCI DATE BINDED',
  ZIP_5 = 'ZIP 5',
  ZIP_9 = 'ZIP 9',
  ZIP_5_DATE_BINDED = 'ZIP 5 DATE BINDED',
  ZIP_9_DATE_BINDED = 'ZIP 9 DATE BINDED',
  CCI_MEDICAID_HOSPITAL = 'CCI Medicaid Outpatient Hospital',
  CCI_MEDICAID_PRACT = 'CCI Medicaid Practitioner',
  CCI_MEDICARE_HOSPITAL = 'CCI Medicare Outpatient Hospital',
  CCI_MEDICARE_PRACT = 'CCI Medicare Practitioner',
  DMUV_PROFESSIONAL = 'Medicare - CMS Daily Medically Unlikely Edits -Professional',
  DMUV_OUTPATIENT = 'Medicare - CMS Daily Medically Unlikely Edits -Outpatient',
  ANNUAL_MAX_UNITS = 'Annual Max Units',
  DMUV_PROFESSIONAL_AUTO = 'DMUV CMS Professional',
  DMUV_OUTPATIENT_AUTO = 'DMUV CMS Outpatient',
  ICD_10_PCS = 'ICD_10_PCS',
  ICD_10_PCS_DRGN_AUTO = 'ICD_10_PCS_DRGN',
  ADD_ON_CODES = 'ADD_ON_CODES',
  ADDON_CODE_TYPE_2 = 'Addon Code Type 2',
  ADDON_CODE_TYPE_3 = 'Addon Code Type 3',

  APC_DATE_BINDED = 'APC DATE BINDED',
  CAPC_DATE_BINDED = 'CAPC DATE BINDED',
  HCPCS_DATE_BINDED = 'HCPCS DATE BINDED',
  APC = 'APC',
  OCE_HCPCS = 'OCE_HCPCS',
  CAPC = 'CAPC',
  ADHOC = 'adhoc',
  CPT = 'CPT',


  CCI = 'CCI',
  MAX_UNITS = 'MAX UNITS',
  ADHOCCPTHCPCS = 'adhoccpthcpcs',

 
 
}

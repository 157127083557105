import { ButtonGroup, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert2";
import {
  dangerColor,
  disabledColor,
  navyColor,
} from "../../assets/jss/material-kit-react";
import CustomButton from "../../components/CustomButtons/CustomButton";
import CustomHeader from "../../components/CustomHeaders/CustomHeader";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import Dialogbox from "../../components/Dialog/DialogBox";
import "../../components/FontFamily/fontFamily.css";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Paragraph from "../../components/Paragraph/Paragraph";
import Template from "../../components/Template/Template";
import { DIALOG } from "../../redux/actions/actionTypes";
import {
  getMfsQuaterLoader,
  uploadMetaLoader,
} from "../../redux/actions/MetaDataLoaderAction";
import { META_DATA_LOAD_SPINNER } from "../../redux/actions/MetaDataLoaderActionTypes";
import {
  getLastQuater,
} from "../../redux/actions/TaskActions";
import { SELECTED_METADATA_SOURCE } from "../../redux/actions/TaskActionTypes";
import { MetaDataLoaderState } from "../../redux/reducers/MetaDataLoaderReducer";
import { NewPolicyFormState } from "../../redux/reducers/NewPolicyFormReducer";
import "../MetaDataLoader/MetaData.css";
import { MetaLoaderConstants } from "./MetaLoaderConst";
import { getBwTypeData } from "../../redux/actions";
import { NewPolicyState } from "../../redux/reducers/NewPolicyReducer";
import { LookUpState } from "../../redux/reducers/LookUpReducer";
import { fetchLookupData, getMaxUnitsLkpData, getModIntractionLkpData } from "../../redux/actions/LookupsActions";
import { CCI_LKP } from "../LookUps/LookUpConsts";
import * as Metadata from "../MetaDataLoader/Metadata";

const MetaDataLoader = (props) => {
  const onFileChange = (event) => {};
  const [metaDataLoader, setMetaDataLoader] = useState([]);
  const [latestQuater, setLatestQuater] = useState(undefined);
  const [selectedFile, setSelectedFile] = useState(null);
  const [mfsQuarter, setMfsQuarter] = useState(null);
  const [selectedQuarter, setSelectedQuarter] = useState("");
  const [selectedBwKey, setSelectedBwKey] = useState(null);
  const [selectedModInteractionType, setModInteractionType] = useState(null);
  const [selectedmodInteractionDesc, setSelectedmodInteractionDesc] =
    useState("");
  const [selectedBwDesc, setSelectedBwDesc] = useState("");

  const [showQuarter, setShowQuarter] = useState(false);
  const [adhocQuarter, setAdhocQuarter] = useState("");
  const [selectedMetaDataLoader, setSelectedMetaDataLoader] = useState(null);
  const [selectedMaxLkpValue, setSelectedMaxLkpValue] = useState(null);

  const [pastselectedFileValue, setpastselectedFileValue] = useState("");
  const [fileError, setFileError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formState: NewPolicyFormState = useSelector(
    (state: any) => state.newPolicyForm
  );

  useEffect(() => {
    getMaxUnitsLkpData(dispatch);
    let lkpName = CCI_LKP;
    fetchLookupData(dispatch,lkpName);
  }, []);
  let email = formState.userDetails;
  const inputRef = useRef<HTMLInputElement>(null);
 
  const updatedState: MetaDataLoaderState = useSelector(
    (state: any) => state.metaDataLoader
  );

  let JSZip = require("jszip");

  const handleAdhocFiles = (file) => {
    let zip = new JSZip();

    for (let adhocFile of file) {
      zip.file(adhocFile.name, adhocFile);
    }
    zip.generateAsync({ type: "blob" }).then((blobdata) => {
      var file = new File([blobdata], "adhocZipFile.zip");
      setSelectedFile(file);
    });
  };
  const updatedState1: NewPolicyState = useSelector(
    (state: any) => state.newPolicy
  );

  const lookupState: LookUpState = useSelector(
    (state: any) => state.lookupReducer
  );

  const updatedState2: MetaDataLoaderState = useSelector(
    (state: any) => state.metaDataLoader
  );

  const bwType = updatedState1.getBwTypeData?.map((k) => {
    return { label: k.description, value: k.bwTypeKey };
  });

  const ModInteractionType = updatedState2.ModInteractionLkpData?.map((k) => {
    return { label: k.mitDesc, value: k.mitKey };
  });

  const MaxUnitsLkpData = updatedState.maxUnitsLkpData.map((d) => {
    return {
      value: d.maxUnitsLkpKey,
      label: d.description,
    };
  });

  const CciLkpData = lookupState.cci.map((d) => {
    return {
      value: d.cciKey,
      label: d.cciDesc,
    };
  });

  useEffect(() => {
    getBwTypeData(dispatch);
    getModIntractionLkpData(dispatch);
  }, []);
  const onFileUpload = () => {
    const formData = new FormData();
    formData.append("uploadfile", selectedFile);
    formData.append("source", selectedMetaDataLoader);
  
    switch (selectedMetaDataLoader) {
      case MetaLoaderConstants.ADHOC_CPT_HCPCS:
        formData.append("quarter", adhocQuarter);
        formData.append("desc", null);
        formData.append("selectedMaxLkpValue", null);
        break;
  
      case MetaLoaderConstants.BW_Pairs:
        formData.append("quarter", selectedBwKey);
        formData.append("desc", selectedBwDesc);
        formData.append("selectedMaxLkpValue", null);
        break;
  
      case MetaLoaderConstants.Modifier_Interaction:
        formData.append("quarter", selectedModInteractionType);
        formData.append("desc", selectedmodInteractionDesc);
        formData.append("selectedMaxLkpValue", null);
        break;
  
      case MetaLoaderConstants.SAME_OR_SIMILAR:
        formData.append("quarter", null);
        formData.append("desc", null);
        formData.append("selectedMaxLkpValue", null);
        break;
  
      default:
        const validation = Metadata.handleUploadFile(selectedFile);
        if (validation) {
          const GAP_LOAD = "GAP-LOAD";
          formData.append("quarter", selectedMetaDataLoader === MetaLoaderConstants.RBRVS ? GAP_LOAD : selectedQuarter);
          formData.append("desc", null);
          formData.append("selectedMaxLkpValue", selectedMaxLkpValue?.label);
        }
        break;
    }
    formData.append("email", email.emailId);
    dispatch({ type: META_DATA_LOAD_SPINNER, payload: true });
    uploadMetaLoader(dispatch, formData, selectedMetaDataLoader, navigate);
    resetInputField();
  };

  const [open, setOpen] = React.useState(false);
  const handleClickToOpen = () => {
    setOpen(true);
  };
  const handleToClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setMetaDataLoader(updatedState.metadataLoader.sort(Metadata.alphaOrder));
    // setMfsQuarter(updatedState.mfsQuarter);
  }, [updatedState]);
  
  const resetInputField = () => {
    setSelectedQuarter("");
    setSelectedMetaDataLoader(null);
    setSelectedFile(undefined);
    setSelectedBwKey("");
    setModInteractionType("");
    setSelectedMaxLkpValue(null);
  };

  useEffect(() => {
    if (updatedState.mfsQuarter.length == 0) {
      getMfsQuaterLoader(dispatch);
    }
  }, []);

  useEffect(() => {
    if (selectedMetaDataLoader) {
      if (
        selectedMetaDataLoader == MetaLoaderConstants.MFS ||
        selectedMetaDataLoader == MetaLoaderConstants.MFS_DATE_BINDED ||
        selectedMetaDataLoader == MetaLoaderConstants.OCE_HCPCS ||
        selectedMetaDataLoader == MetaLoaderConstants.APC ||
        selectedMetaDataLoader == MetaLoaderConstants.CAPC ||
        selectedMetaDataLoader == MetaLoaderConstants.HCPCS ||
        selectedMetaDataLoader == MetaLoaderConstants.ADD_ON_CODES ||
        selectedMetaDataLoader == MetaLoaderConstants.ADDON_CODE_TYPE_2 ||
        selectedMetaDataLoader == MetaLoaderConstants.ADDON_CODE_TYPE_3 ||
        selectedMetaDataLoader == MetaLoaderConstants.CPT ||
        selectedMetaDataLoader == MetaLoaderConstants.ICD_10_CM ||
        selectedMetaDataLoader == MetaLoaderConstants.ICD_10_CM_DRGN ||
        selectedMetaDataLoader == MetaLoaderConstants.ICD_10_PCS ||
        selectedMetaDataLoader == MetaLoaderConstants.DMUV_PROFESSIONAL ||
        selectedMetaDataLoader == MetaLoaderConstants.DMUV_OUTPATIENT ||
        selectedMetaDataLoader == MetaLoaderConstants.CCI ||
        selectedMetaDataLoader == MetaLoaderConstants.BW_Pairs
      ) {
        if (updatedState.latestQuarter != null) {
          if (updatedState.latestQuarter.assignee != "" || updatedState.latestQuarter.assignee != null) {
            setLatestQuater("Last Load For" + " " + updatedState.latestQuarter.quarterName + " " +
              "On" + " " + moment(updatedState.latestQuarter.updatedDate).format("MM-DD-YYYY hh:mm:ss") +
              " " + "By" + " " + updatedState.latestQuarter.assignee);
          }
          if (updatedState.latestQuarter.assignee == "" ||updatedState.latestQuarter.assignee == null) {
            setLatestQuater("Last Load For" +" " +updatedState.latestQuarter.quarterName +" " +
              "On" +" " +moment(updatedState.latestQuarter.updatedDate).format("MM-DD-YYYY hh:mm:ss"));
          }
        } else {
          setLatestQuater(" ");
        }
      }
    } else {
      setLatestQuater(" ");
    }
  }, [selectedMetaDataLoader, updatedState]);

  const metaDataLoaderCM = metaDataLoader?.map((ml) => {
    return { label: ml.Name, value: ml.value };
  });
  const mfsQuarterCM = Metadata.newQuarter?.map((mfs) => {
    return { label: mfs.Name, value: mfs.value };
  });
  const yearsIcd = Metadata.Years?.map((icd) => {
    return { label: icd.Name, value: icd.value };
  });



  const checkQuarterValue = () => {
    let quarterValue;
  
    switch (selectedMetaDataLoader) {
      case MetaLoaderConstants.BW_Pairs:
        quarterValue = bwType.find(km => km?.value === selectedBwKey)?.label || '';
        break;
  
      case MetaLoaderConstants.Modifier_Interaction:
        quarterValue = ModInteractionType.find(p => p?.value === selectedModInteractionType)?.label || '';
        break;
  
      case MetaLoaderConstants.ICD_10_CM_DRGN:
      case MetaLoaderConstants.ICD_10_PCS:
      case MetaLoaderConstants.ZIP_5:
      case MetaLoaderConstants.ZIP_9:
      case MetaLoaderConstants.ZIP_5_DATE_BINDED:
      case MetaLoaderConstants.ZIP_9_DATE_BINDED:
        quarterValue = yearsIcd.find(km => km.value === selectedQuarter)?.value || '';
        break;
  
      default:
        quarterValue = mfsQuarterCM.find(km => km.value === selectedQuarter)?.value || '';
        break;
    }
  

    console.log("selected quarter is", quarterValue);


    return { label: quarterValue, value: quarterValue };
  };

  console.log("selected quarter is", selectedQuarter);


  return (
    <Template>
      <div className="row">
        <div className="col-sm-4">
          <CustomHeader labelText={"Metadata Loader"} />
        </div>
      </div>
      <div className="row" style={{ marginTop: "6%" }}>
        <div className="col-sm-3" />
        <div className="col-sm-2" style={{ position: "relative", top: "10px" }}>
          <Typography>
            <Paragraph labelText={"Metadata Loader"} />
          </Typography>
        </div>
        <div className="col-sm-2">
          <CustomSelect
            options={metaDataLoaderCM}
            placeholder={"Select Option"}
            onSelect={(e) => {
              if (e == null) {
                setSelectedMetaDataLoader(undefined);
              } else {
                setSelectedMetaDataLoader(e.value);
                setpastselectedFileValue(e.value);
                dispatch({ type: SELECTED_METADATA_SOURCE, payload: e });
                setSelectedQuarter("");
                setSelectedMaxLkpValue("");
                setSelectedBwKey("");
                setModInteractionType("");
                getLastQuater(dispatch, e.value);
              }
            }}
            value={metaDataLoaderCM.filter(function (option) {
              return option.value == selectedMetaDataLoader;
            })}
          />
          {selectedMetaDataLoader ? (
            <p
              style={{
                color: "blue",
                marginBottom: "-4px",
                fontSize: "12px",
              }}
            >
              {latestQuater}
            </p>
          ) : undefined}
        </div>
      </div>
      {selectedMetaDataLoader == MetaLoaderConstants.MAX_UNITS || selectedMetaDataLoader == MetaLoaderConstants.CCI ? (
        <div className="row">
          <div className="col-sm-3" />
          <div
            className="col-sm-2"
            style={{ position: "relative", top: "10px" }}
          >
            <Typography>
              <Paragraph labelText={selectedMetaDataLoader == MetaLoaderConstants.CCI ? "CCI Lookup" : "MUV Lookup"} />
            </Typography>
          </div>
          <div className="col-sm-2">
            <CustomSelect
              options={selectedMetaDataLoader == MetaLoaderConstants.CCI ? CciLkpData : MaxUnitsLkpData}
              placeholder={"Select Option"}
              onSelect={(e) => {
                if (e == null) {
                  setSelectedMaxLkpValue(undefined);
                  setSelectedQuarter("");
                } else {
                  setSelectedMaxLkpValue(e);
                  setSelectedQuarter("");
                  setSelectedBwKey("");
                  setModInteractionType("");
                }
              }}
              value={(selectedMetaDataLoader == MetaLoaderConstants.CCI ? CciLkpData : MaxUnitsLkpData).filter(function (option) {
                return option?.value == selectedMaxLkpValue?.value;
              })}
            />
          </div>
        </div>
      ) : undefined}
      <div className="row">
        <div className="col-sm-3" />
        <div className="col-sm-2" style={{ position: "relative", top: "10px" }}>
          <Typography>
            <Paragraph
              labelText={selectedMetaDataLoader ? Metadata.checkQauterOrYear(selectedMetaDataLoader,selectedMaxLkpValue) : ""}
            />
          </Typography>
        </div>
        {selectedMetaDataLoader && Metadata.checkRbrsvsOrSameOrSimOrAdhocCpt(selectedMetaDataLoader) ? (
          <div className="col-sm-2">
            <CustomSelect
              options={
                selectedMetaDataLoader == MetaLoaderConstants.BW_Pairs
                  ? bwType
                  : selectedMetaDataLoader ==
                    MetaLoaderConstants.Modifier_Interaction
                    ? ModInteractionType
                    : selectedMetaDataLoader ==
                      MetaLoaderConstants.ICD_10_CM_DRGN ||
                      selectedMetaDataLoader == MetaLoaderConstants.ICD_10_PCS ||
                      selectedMetaDataLoader == MetaLoaderConstants.ZIP_5 ||
                      selectedMetaDataLoader == MetaLoaderConstants.ZIP_9 ||
                      selectedMetaDataLoader == MetaLoaderConstants.ZIP_5_DATE_BINDED ||
                      selectedMetaDataLoader == MetaLoaderConstants.ZIP_9_DATE_BINDED
                      ? yearsIcd
                      : mfsQuarterCM
              }
              placeholder={"Select Quarter"}
              onSelect={(e) => {
                if (e == null) {
                  setSelectedQuarter(undefined);
                  setSelectedBwKey(undefined);
                  setModInteractionType(undefined);
                } else {
                  if (selectedMetaDataLoader == MetaLoaderConstants.BW_Pairs) {
                    let value = e.value;
                    setSelectedBwKey(value);
                    setSelectedBwDesc(e.label);
                  } else {
                    setSelectedQuarter(e.value);
                    setSelectedBwDesc("");
                    setSelectedBwKey("");
                  }

                  if (
                    selectedMetaDataLoader ==
                    MetaLoaderConstants.Modifier_Interaction
                  ) {
                    let value = e.value;
                    setModInteractionType(value);
                    setSelectedmodInteractionDesc(e.label);
                  } else {
                    setSelectedQuarter(e.value);
                    setModInteractionType("");
                    setSelectedmodInteractionDesc("");
                  }
                }
              }}
              // value={checkQuarterValue()}
              value={selectedQuarter || selectedBwKey || selectedModInteractionType ? checkQuarterValue() : null}


            />
          </div>
        ) : undefined}
      </div>
      {selectedMetaDataLoader ? (
        <div className="row">
          <div className="col-sm-3" />
          <div
            className="col-sm-2"
            style={{ position: "relative", top: "10px" }}
          >
            <Typography>
              <Paragraph labelText={"Upload Source File"} />
            </Typography>
          </div>
          <div className="col-sm-2">
            <div className="choosefile">
              <input
                ref={inputRef}
                type="file"
                multiple={
                  selectedMetaDataLoader == MetaLoaderConstants.ADHOC_CPT_HCPCS
                }
                onChange={(event) => {
                  setAdhocQuarter("");
                  if (
                    selectedMetaDataLoader ==
                    MetaLoaderConstants.ADHOC_CPT_HCPCS
                  ) {
                    for (let i = 0; i < event.target.files.length; i++) {
                      if (event.target.files[i].type.includes("xml")) {
                        setAdhocQuarter(
                          event.target.files[i].name
                            .split("HCPCS")[1]
                            .substring(1, 11)
                            .trim()
                        );
                      }
                    }
                  }
                  if (
                    selectedMetaDataLoader ==
                    MetaLoaderConstants.ADHOC_CPT_HCPCS
                  ) {
                    setFileError(true);
                    handleAdhocFiles(event.target.files);
                  } else {
                    setSelectedFile(event.target.files[0]);
                    let flag = Metadata.handleUploadFile(event.target.files[0]);
                    setFileError(flag);
                  }
                }}
              />
            </div>
          </div>
        </div>
      ) : undefined}
      {selectedMetaDataLoader ? (
        <div className="row">
          <div className="col-sm-3" />
          <div className="col-sm-2" />
          <div className="col-sm-2">
            <CustomButton
              variant="contained"
              onClick={handleClickToOpen}
              disabled={Metadata.checkFileDisable(selectedBwKey, selectedMetaDataLoader, selectedQuarter, selectedFile)}
              style={{
                color: "white",
                fontSize: 12,
                textTransform: "capitalize",
                cursor: selectedFile == undefined ? "not-allowed" : "default",
                backgroundColor: Metadata.checkDisable(selectedMetaDataLoader, selectedFile, selectedQuarter, selectedBwKey),
              }}
            >
              Load
            </CustomButton>
            <CustomButton
              variant="contained"
              style={{
                backgroundColor: dangerColor,
                color: "white",
                marginLeft: 10,
                fontSize: 12,
                textTransform: "capitalize",
              }}
              onClick={() => {
                inputRef.current.value = "";
                resetInputField();
              }}
            >
              Reset
            </CustomButton>
          </div>
        </div>
      ) : undefined}

      <Dialogbox
        onClose={handleToClose}
        disableBackdropClick={true}
        open={open}
        title={"Confirm"}
        message={"Would you like to initiate the stage load data?"}
        actions={
          <ButtonGroup>
            <CustomButton
              style={{
                color: "white",
                backgroundColor: navyColor,
                marginRight: 10,
                fontsize: 12,
                padding: 1,
                textTransform: "capitalize",
              }}
              onClick={() => {
                onFileUpload();
                handleToClose();
                dispatch({ type: DIALOG, payload: false });
              }}
            >
              Yes
            </CustomButton>
            <CustomButton
              style={{
                color: "white",
                backgroundColor: dangerColor,
                fontsize: 12,
                padding: 1,
                textTransform: "capitalize",
              }}
              onClick={() => {
                handleToClose();
                dispatch({ type: DIALOG, payload: false });
              }}
            >
              No
            </CustomButton>
          </ButtonGroup>
        }
      />
    </Template>
  );
};
export default MetaDataLoader;

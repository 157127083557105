import { useEffect, useRef, useState } from "react";
import AgGrids from "../../components/TableGrid/AgGrids";
import {
  deleteClientAssignmentData,
  editClientAssignment,
  getActiveClientGroups,
  getClientAssignmentData,
  postClientAssignment,
} from "../../redux/actions/clientAssignmentAction";
import { useDispatch, useSelector } from "react-redux";
import { NewPolicyState } from "../../redux/reducers/NewPolicyReducer";
import moment from "moment";
import { ButtonGroup, DialogContent, IconButton } from "@material-ui/core";
import {
  dangerColor,
  disabledColor,
  navyColor,
} from "../../assets/jss/material-kit-react";
import { Add } from "@material-ui/icons";
import CustomPaper from "../../components/CustomPaper/CustomPaper";
import Dialogbox from "../../components/Dialog/DialogBox";
import GridItem from "../../components/Grid/GridItem";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomButton from "../../components/CustomButtons/CustomButton";
import RadioButton from "../../components/RadioButton/RadioButton";
import React from "react";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import _ from "lodash";
import Moment from "moment";
import { getClientgroupData } from "../../redux/actions/ClientPolicyExclusion";
import { PolicyState } from "../../redux/reducers/PolicyReducer";
import { DIALOG } from "../../redux/actions/actionTypes";
import GridContainer from "../../components/Grid/GridContainer";
import { CSSTransition } from "react-transition-group";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import "../NewPolicy/NewPolicy.css";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { NEW_POLICY_CREATE_ARRAY } from "../../redux/actions/PolicyActionType";




const ClientAssignment = ({
  fromViewPolicy,
  policyId,
  edit,
  forClientTabPolicyId,
}) => {
  const [numberOfRows, setNumberOfRows] = useState(0);

  const newpolicyState: NewPolicyState = useSelector(
    (state: any) => state.newPolicy
  );

  const [rowData, setRowData] = useState([]);

  const dispatch = useDispatch();

  const ClientAssignmentColumns = {
    policyClntAssmtKey: undefined,
    policyId: undefined,
    clientCode: undefined,
    clientName: undefined,
    clientGroupCode: undefined,
    clientGroupName: undefined,
    clientStartDate: undefined,
    clientEndDate: undefined,
    excludeClientSpecificCodes: undefined,
    hp: undefined,
  };
  const fullWidth = true;
  const maxWidth = "sm";

  const [deletedById, setDeletedById] = useState('');

  const [isEdit, setIsEdit] = useState(false);
  const [openedit, setOpenedit] = React.useState(false);
  const [opendelete, setOpendelete] = React.useState(false);
  const [clientAssignment, setClientAssignment] = useState(
    ClientAssignmentColumns
  );

  const [clientGroupCode, setClientGroupCode] = React.useState([]);

  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [totalData, setTotalData] = React.useState([]);
  const [excludeData, setExcludeData] = React.useState(1);
  const [startDate, setStartDate] = React.useState(undefined);
  let checkFileds = false;
  const [fieldError, setFiledError] = useState(false);

  const [accordionState, setAccordionState] = useState({});


  const updatedState = useSelector((state: any) => state.clientPolicy);

  const policyCreateState: PolicyState = useSelector(
    (state: any) => state.policyCreation
  );
  const activeClients =
    policyCreateState.newpolicyCreateArray.addAllActiveClients;
  const newPolicyCloneClientPopUpValue =
    policyCreateState.newpolicyCreateArray.cloneClientAssignmentTab;
  let newPolicyClientStartDate =
    policyCreateState.newpolicyCreateArray.newPolicyStartDate;

  useEffect(() => {
    setNumberOfRows(rowData.length);
  }, [rowData]);

  useEffect(() => {
    if (policyId != null) {
      getClientAssignmentData(dispatch, policyId);
    }
  }, [dispatch, policyId]);

  const handleToClose = () => {
    setInputValue("");
    setOpenedit(false);
    setOpendelete(false);
  };

  function editData() {
    handleClickToOpenEdit();
  }
  const handleClickToOpenEdit = () => {
    setOpenedit(true);
    setTotalData([]);
    setInputValue("");
  };
  const handleClickToCancel = () => {
    setOpenedit(false);
  };

  const DeletedMethod = () => {
    deleteClientAssignmentData(dispatch, deletedById, policyId);
    setOpendelete(false);
  };

  const handleClickToCloseEdit = () => {
    setOpenedit(false);
    setOpendelete(false);
    // setUpdatedDiagsData(true);
  };

  const handleClickToOpendelete = () => {
    setOpendelete(true);
  };

  function deleteData() {
    handleClickToOpendelete();
  }

useEffect(()=>{
  if(edit){
    dispatch({type:NEW_POLICY_CREATE_ARRAY,payload:[]})
  }
},[edit])

  useEffect(() => {
    if (updatedState.getClientExclusion.length == 0) {
      getClientgroupData(dispatch);
    }
  }, []);
  useEffect(() => {
    if (newPolicyCloneClientPopUpValue && !fromViewPolicy) {
      getClientAssignmentData(dispatch, forClientTabPolicyId);
    }
    if (newpolicyState.getActiveClientData.length === 0) {
      getActiveClientGroups(dispatch);
    }
    if (fromViewPolicy) {
      getClientAssignmentData(dispatch, policyId);
    }
  }, [
    newPolicyCloneClientPopUpValue,
    activeClients,
    policyId,
    dispatch,
    forClientTabPolicyId,
    fromViewPolicy,
    edit,
    newpolicyState.getActiveClientData.length,
  ]);

  function resetLookup() {
    setClientAssignment(ClientAssignmentColumns);
    setTotalData([]);
    setClientGroupCode([]);
    clientAssignment.clientGroupCode = [];
    setFiledError(false);
  }

  let clientGroupExclusion = newpolicyState.getActiveClientData
    .filter(
      (k) =>
        k.clientCode === inputValue ||
        k.clientCode === clientAssignment.clientCode
    )
    .map((k) => {
      return { label: k.clientGroupCode, value: k.clientGroupCode };
    });

  function check() {
    if (
      clientAssignment.clientCode == undefined ||
      clientAssignment.clientCode == ""
    ) {
      checkFileds = true;
      setFiledError(true);
    } else if (
      clientAssignment.clientGroupCode == undefined ||
      clientAssignment.clientGroupCode == ""
    ) {
      checkFileds = true;
      setFiledError(true);
    } else if (
      clientAssignment.clientStartDate == undefined ||
      clientAssignment.clientStartDate == ""
    ) {
      checkFileds = true;
      setFiledError(true);
    } else if (
      (isEdit && clientAssignment.clientEndDate == undefined) ||
      clientAssignment.clientEndDate == ""
    ) {
      checkFileds = true;
      setFiledError(true);
    }
    return checkFileds;
  }

  function removeRowByPolicyClntAssmtKey(rowData, key) {
    return rowData.filter((row) => row.policyClntAssmtKey !== key);
  }

  function dateCheck(d1, d2) {
    let flag = false;
    if (d1 < d2) {
      flag = true;
    }
    return flag;
  }

  function checkDates(d) {
    let flag = false;
     let clientStartDate = moment(clientAssignment.clientStartDate).format("MM-DD-YYYY");
    if (isEdit) {
      if (!dateCheck(clientStartDate, clientAssignment.clientEndDate)) {
        flag = true;
      } else if (startDate !== clientStartDate && clientStartDate < startDate) {
        flag = true;
      }
    } else if (clientStartDate < d.clientEndDate) {
      flag = true;
    }
    return flag;
  }

  function duplicateCheck() {
    let checkDuplicate = false;
    let clientEndDate = "";
    let existedClientGroupCodes = [];
    if (isEdit) {
      setRowData(
        removeRowByPolicyClntAssmtKey(
          rowData,
          clientAssignment.policyClntAssmtKey
        )
      );
    } else {
      clientAssignment.clientGroupCode = clientGroupCode.length > 0 ? clientGroupCode : clientGroupExclusion;
    }
    rowData.forEach((d) => {
      if (d.clientCode === clientAssignment.clientCode && checkDates(d)) {
        clientAssignment?.clientGroupCode?.forEach((k) => {
          if (k.value === d.clientGroupCode) {
            checkDuplicate = true;
            existedClientGroupCodes.push(d.clientGroupCode);
            clientEndDate = d.clientEndDate;
          }
        });
      }
    });

    return [checkDuplicate, clientEndDate, existedClientGroupCodes];
  }

  async function saveDiag() {
    let error = check();
    let duplicate = duplicateCheck();
    if (error) {
      dispatch({
        type: DIALOG,
        payload: true,
        title: "Error",
        message: "Please fill in required fields",
      });
    } else if (duplicate[0]) {
      dispatch({
        type: DIALOG,
        payload: true,
        title: "Error",
        message:
          duplicate[1] == "9999-12-31"
            ? `A record for client group codes ` + duplicate[2] + ` already exists. Please check the end date`
            : `Record already exists. Please enter the valid start date.`,
      });
    } else {
      if (!isEdit) {
        postClientAssignment(dispatch, totalData, policyId);
      } else {
        editClientAssignment(dispatch, clientAssignment, policyId);
      }
      setOpenedit(false);
    }
    getClientAssignmentData(dispatch, policyId);
  }
  const todaysDate = new Date();
  const fullDate =
    todaysDate.getFullYear() +
    "-" +
    todaysDate.getMonth() +
    "-" +
    todaysDate.getDate();

  function showTableData() {
    if (!edit) {
      if (activeClients) {
        return newpolicyState.getActiveClientData;
      } else {
        if (newPolicyCloneClientPopUpValue && forClientTabPolicyId) {
          return newpolicyState.getClientAssignmentData;
        } else {
          return [];
        }
      }
    } else {
      return newpolicyState.getClientAssignmentData;
    }
  }

  useEffect(() => {
    let clientAssignmentData = [];
    showTableData().forEach((k) => {
      newpolicyState.getActiveClientData.forEach((d) => {
        if (d.clientGroupId === k.clientGroupId) {
          clientAssignmentData.push({
            policyId: k.policyId,
            policyClntAssmtKey: k.policyClntAssmtKey,
            clientCode: d.clientCode,
            clientName: d.clientName,
            clientGroupCode: d.clientGroupCode,
            clientGroupName: d.clientGroupName,
            clientStartDate: newPolicyClientStartDate
              ? moment(newPolicyClientStartDate).format('MM-DD-YYYY')
              : moment(k.clientStartDate).format('MM-DD-YYYY'),
            clientEndDate: k.clientEndDate
              ? moment(k.clientEndDate).format("MM-DD-YYYY")
              : "12-31-9999",
            excludeClientSpecificCodes:
              k.excludeClientSpecificCodes == false ? "No" : "Yes",
            hp: k.hp == true ? 1 : 0,
            createDate: k.createDate
              ? moment(k.createDate).format('MM-DD-YYYY')
              : moment(todaysDate).format("MM-DD-YYYY"),
            updateDate: k.updateDate
              ? moment(k.updateDate).format("MM-DD-YYYY")
              : moment(todaysDate).format("MM-DD-YYYY")
          });
        }
      });
    });
    clientAssignmentData.sort((a, b) => {
      if (a.clientCode < b.clientCode) return -1;
      if (a.clientCode > b.clientCode) return 1;
      return 0;
    });
    setRowData(clientAssignmentData);
  }, [newpolicyState]);

  const toggleAccordion = (index) => {
    setAccordionState((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };


  const [clientData, setClientData] = useState({ groupData: [], firstRows: [], tableData: [] });

  useEffect(() => {
    const gData = rowData.reduce((acc, item) => {
      if (!acc[item.clientCode]) {
        acc[item.clientCode] = [];
      }
      acc[item.clientCode].push(item);
      return acc;
    }, {})
    setClientData(prev => ({ ...prev, groupData: gData, firstRows: Object.values(gData).map(items => items[0]) }))
  }, [rowData])

  useEffect(() => {
    const gData = rowData.reduce((acc, item) => {
      if (!acc[item.clientCode]) {
        acc[item.clientCode] = [];
      }
      acc[item.clientCode].push(item);
      return acc;
    }, {});

    const firstRows = Object.values(gData).map((items: any[]) => { // Specify items as an array of any type
      return {
        ...items[0],
        clientGroupCode: undefined,
        clientGroupName: undefined,
        excludeClientSpecificCodes: undefined
      };
    });

    setClientData(prev => ({ ...prev, groupData: gData, firstRows: firstRows }));
  }, [rowData]);


  const LIMIT = 50;

  const boxRef = useRef();
  const sentinelRef = useRef();
  const [final, setFinal] = useState(LIMIT);
  const [clientCodeFilter, setClientCodeFilter] = useState(''); // State for the filter input


  useEffect(() => {
    const handleObserver = (entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        handleClick();
      }
    };

    const options = {
      root: boxRef.current,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(handleObserver, options);
    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, [clientData.firstRows]);


  const handleClick = () => {
    if (clientData.firstRows) {
      setFinal(prevCount => Math.min(prevCount + LIMIT, clientData.firstRows.length));
    }
  };

  useEffect(() => {
    let desc = [];
    newpolicyState.getActiveClientData?.forEach((k) => {
      if (clientGroupCode.length > 0) {
        clientGroupCode.forEach((f) => {
          if (f.value === k.clientGroupCode) {
            desc.push(k);
          }
        });
      } else if (inputValue === k.clientCode) {
        desc.push(k);
        clientAssignment.clientGroupCode = [{ label: k.clientGroupCode, value: k.clientGroupCode }];
      }
    });

    let data;
    data = desc.map((d, i) => {
      return {
        policyId: policyId,
        clientGroupId: d.clientGroupId,
        hp: d.hp === true ? 1 : 0,
        clientStartDate: clientAssignment.clientStartDate,
        clientEndDate: "9999-12-31",
        excludeClientSpecificCodes: excludeData,
      };
    });
    setTotalData(data);
  }, [clientGroupCode, clientAssignment, inputValue]);

  const suggestionsList = document.getElementById(
    "suggestionList"
  ) as HTMLElement;
  if (suggestionsList) {
    const options = suggestionsList.children.length;

    if (options < 2) {
      suggestionsList.style.overflowY = "hidden";
    } else {
      suggestionsList.style.overflowY = "scroll";
    }
  }

  useEffect(() => {
    if (!isEdit) {
      setExcludeData(clientAssignment.excludeClientSpecificCodes ?? 1);
    } else {
      setExcludeData(
        clientAssignment.excludeClientSpecificCodes === "Yes" ||
        clientAssignment.excludeClientSpecificCodes === "True" ||
          clientAssignment.excludeClientSpecificCodes === 1
          ? 1
          : 0
      );
    }
  }, [clientAssignment]);

  const handleInputChange = (event) => {
    let value = event.target.value;
    setInputValue(value);
    if (value.length > 0) {
      const seen = new Set();
      const filteredSuggestions = newpolicyState.getActiveClientData
        .filter((k) => {
          const clientCodeLower = k.clientCode?.toLowerCase();
          if (
            clientCodeLower.includes(value?.toLowerCase()) &&
            !seen.has(clientCodeLower)
          ) {
            seen.add(clientCodeLower);
            return true;
          }
          return false;
        })
        .map((k) => ({ label: k.clientCode, value: k.clientCode }));
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
      setClientGroupCode([]);
      clientAssignment.clientGroupCode = [];
      clientAssignment.clientCode = "";
    }
  };

  const handleSuggestionClick = (value) => {
    setInputValue(value);
    clientAssignment.clientCode = value;
    setSuggestions([]);
  };

  const onFilterChanged = (params) => {
    setNumberOfRows(params.api.getDisplayedRowCount());
  };

  const filteredData = clientData.firstRows.filter((data) =>
    data.clientCode.toLowerCase().includes(clientCodeFilter.toLowerCase())
  );


  const [clientGroupCodeFilter, setClientGroupCodeFilter] = useState('');

  const getFilteredGroupData = (data) => {
    if (!clientGroupCodeFilter) {
      return clientData.groupData[data];
    }

    const filteredData = Object.values(clientData.groupData).flatMap((group) =>
      group.filter((detail) => detail.clientGroupCode.toLowerCase().includes(clientGroupCodeFilter.toLowerCase()))
    );
    return filteredData;
  };

  useEffect(() => {
    if (!clientGroupCodeFilter) {
      setNumberOfRows(rowData.length);
    }
    else {
      filteredData.slice(0, final).map((data, index) => {
        const filteredData = getFilteredGroupData(data.clientCode); // replace 'yourDataKey' with the appropriate key
        setNumberOfRows(filteredData.length);
      })
    }
  }, [clientGroupCodeFilter, clientData]);

  return (
    <>
      <CustomPaper
        style={{
          paddingLeft: 10,
          position: "relative",
          right: 20,
          paddingRight: 8,
          paddingTop: 0,
          paddingBottom: 15,
          boxShadow: "none",
          border: rowData.length > 0 ? "1px solid #D6D8DA" : "",
        }}
      >
        <div className="row">
          <div className="col-sm-4" >
            <div style={{ display: "inline-block" }}>
              <CustomInput
                style={{ left: 10 }}
                type="text"
                variant={"outlined"}
                placeholder={"Filter by Client Code"}
                value={clientCodeFilter}
                onChange={(e) => setClientCodeFilter(e.target.value)}
              />
            </div>
            <div style={{ display: "inline-block", marginLeft: '30px' }}>
              <CustomInput
                type="text"
                variant={"outlined"}
                placeholder={"Filter by Client Group Code"}
                value={clientGroupCodeFilter}
                onChange={(e) => setClientGroupCodeFilter(e.target.value)}
              />
            </div>
          </div>
          <div className="col-sm-7" />
          <div className="col-sm-1">
            <IconButton
              onClick={() => {
                // handleClick()
                editData();
                setIsEdit(false);
                resetLookup();
              }}
              style={{
                backgroundColor: navyColor,
                float: "right",
                color: "white",
                padding: 5,
                marginTop: 2,
              }}
            >
              <Add />
            </IconButton>
          </div>
        </div>
        <div className="row" ref={boxRef} style={{ maxWidth: "100%", maxHeight: "300px", overflow: "auto", marginTop: "10px", marginLeft: "2px" }}>
          <table className="tableStyles">
            <thead>
              <tr>
                <th ><span className="excludeColumn">Client Code</span></th>
                <th ><span className="excludeColumn">Client Name</span></th>
                <th ><span className="excludeColumn">Client Group Code</span></th>
                <th ><span className="excludeColumn">Client Group Name</span></th>
                <th ><span className="excludeColumn">Process Start Date</span></th>
                <th ><span className="excludeColumn">Process End Date</span></th>
                {/* <th className="excludeColumn"><span>Exclude Client Specific Codes</span></th> */}
                <th ><span className="excludeColumn">Exclude Client Specific Codes</span></th>

                <th ><span className="excludeColumn">HP</span></th>
                <th ><span className="excludeColumn">Create Date</span></th>
                <th ><span className="excludeColumn">Update Date</span></th>
                <th style={{ zIndex: "10" }}><span className="clientBtn">Action</span></th>
              </tr>
            </thead>
            <tbody>
              {filteredData.slice(0, final).map((data, index) => {
                const isOpen = accordionState[index];
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td onClick={() => toggleAccordion(index)}><div style={{ float: 'left' }}>{isOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}</div>{data.clientCode}</td>
                      <td>{data.clientName}</td>
                      <td>{data.clientGroupCode}</td>
                      <td>{data.clientGroupName}</td>
                      <td>{data.clientStartDate}</td>
                      <td>{data.clientEndDate}</td>
                      <td>{data.excludeClientSpecificCodes}</td>
                      <td>
                        <input type="checkbox" disabled checked={data.hp} />
                      </td>
                      <td>{data.createDate}</td>
                      <td>{data.updateDate}</td>
                      <td>

                      </td>
                    </tr>
                    <CSSTransition
                      in={isOpen}
                      timeout={300}
                      classNames="accordion"
                      unmountOnExit
                    >
                      <tr>
                        <td colSpan={11}>
                          <div className="accordion-content">
                            <table className="tableStyles innerTableStyles">
                              <tbody>
                                {getFilteredGroupData(data.clientCode).map((detail, idx) => (
                                  <tr key={idx}>
                                    <td ><span className="excludeColumn">{detail.clientCode}</span></td>
                                    <td ><span className="excludeColumn">{detail.clientName}</span></td>
                                    <td ><span className="excludeColumn">{detail.clientGroupCode}</span></td>
                                    <td ><span className="excludeColumn">{detail.clientGroupName}</span></td>
                                    <td ><span className="excludeColumn">{detail.clientStartDate}</span></td>
                                    <td ><span className="excludeColumn">{detail.clientEndDate}</span></td>
                                    <td ><span className="excludeColumn">{detail.excludeClientSpecificCodes}</span></td>
                                    <td ><span className="excludeColumn">
                                      <input type="checkbox" disabled checked={detail.hp} />
                                    </span>
                                    </td>
                                    <td ><span className="excludeColumn">{detail.createDate}</span></td>
                                    <td ><span className="excludeColumn">{detail.updateDate}</span></td>
                                    <td><span className="clientBtn">
                                      <ButtonGroup>
                                        <CustomButton
                                          variant="contained"
                                          style={{
                                            height: 18,
                                            marginTop: 1,
                                            fontSize: '11px',
                                            textTransform: 'capitalize',
                                            backgroundColor: navyColor,
                                            color: 'white',
                                            left: '3px',
                                            opacity: fromViewPolicy ? 0.7 : 1,
                                          }}
                                          onClick={() => {
                                            let obj = _.cloneDeep(clientAssignment);
                                            obj.policyClntAssmtKey = detail.policyClntAssmtKey;
                                            obj.clientCode = detail.clientCode;
                                            obj.clientName = detail.clientName;
                                            obj.clientGroupCode = detail.clientGroupCode
                                              ? [{ label: detail.clientGroupCode, value: detail.clientGroupCode }]
                                              : detail.clientGroupCode;
                                            obj.clientGroupName = detail.clientGroupName;
                                            obj.clientStartDate = detail.clientStartDate
                                              ? Moment(detail.clientStartDate).format('YYYY-MM-DD')
                                              : '';
                                            obj.clientEndDate = detail.clientEndDate
                                              ? Moment(detail.clientEndDate).format('YYYY-MM-DD')
                                              : '';
                                            obj.excludeClientSpecificCodes = detail.excludeClientSpecificCodes === "Yes" ? 1 :0;
                                            obj.policyId = detail.policyId;
                                            obj.hp = detail.hp;
                                            setClientAssignment(obj);
                                            setStartDate(Moment(obj.clientStartDate).format('MM-DD-YYYY'));
                                            setIsEdit(true);
                                            editData();
                                          }}
                                        >
                                          Edit
                                        </CustomButton>

                                        <CustomButton
                                          variant="contained"
                                          style={{
                                            height: 18,
                                            marginTop: 1,
                                            marginLeft: 3,
                                            fontSize: '11px',
                                            textTransform: 'capitalize',
                                            backgroundColor: dangerColor,
                                            color: 'white',
                                            left: '3px',
                                            opacity: fromViewPolicy ? 0.7 : 1,
                                          }}
                                          onClick={() => {
                                            let obj = _.cloneDeep(clientAssignment);
                                            obj.policyClntAssmtKey = detail.policyClntAssmtKey;
                                            setDeletedById(obj.policyClntAssmtKey);
                                            deleteData();
                                          }}
                                        >
                                          Delete
                                        </CustomButton>
                                      </ButtonGroup>
                                    </span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </CSSTransition>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
          <div ref={sentinelRef} />
        </div>
      </CustomPaper>
      <small style={{ position: "relative", fontSize: "12px" }}>
        Number of Client Groups : {numberOfRows}
      </small>
      <Dialogbox
        open={fromViewPolicy ? false : openedit}
        onClose={handleToClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        style={{ overflowY: "scroll" }}
        disableBackdropClick={true}
        title={
          isEdit ? "Edit Client Details" : "Add Client Details"
        }
        message={
          <DialogContent>
            <GridItem sm={12} md={12} xs={12}>
              <div>
                <CustomInput
                  type="text"
                  error={clientAssignment.clientCode == undefined || clientAssignment.clientCode == "" ? fieldError : false}
                  labelText={"Client Code"}
                  showStarIcon={true}
                  variant={"outlined"}
                  disabled={isEdit}
                  value={isEdit ? clientAssignment.clientCode : inputValue}
                  onChange={handleInputChange}
                  aria-autocomplete="list"
                  aria-controls="autocomplete-list"
                />
                {suggestions.length > 0 && (
                  <ul className="suggestions-list" id="suggestionList">
                    {suggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        onClick={() => handleSuggestionClick(suggestion.label)}
                        role="option"
                      >
                        {suggestion.label ? suggestion.label : suggestion}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="row">
                <div className="col-sm-10">
                  <CustomSelect
                    isMulti
                    checkBoxes={true}
                    // error={clientAssignment?.clientGroupCode?.length === 0 || clientAssignment?.clientGroupCode === undefined ? fieldError : false}
                    options={inputValue ? clientGroupExclusion : undefined}
                    labelText={"Client Group Code"}
                    isDisabled={isEdit}
                    onSelect={(e) => {
                      clientAssignment.clientGroupCode = e;
                      setClientGroupCode(e);
                    }}
                    value={isEdit ? (clientGroupExclusion.filter(function (option) {
                      return option.value == clientAssignment.clientGroupCode[0].value;
                    })) : clientGroupCode}
                  />
                </div>
              </div>

              <CustomInput
                id="date"
                type="date"
                error={clientAssignment.clientStartDate == undefined || clientAssignment.clientStartDate == "" ? fieldError : false}
                labelText={"Process Start Date"}
                showStarIcon={true}
                variant={"outlined"}
                onChange={(e) => {
                  let obj = _.cloneDeep(clientAssignment);
                  obj.clientStartDate = e.target.value;
                  setClientAssignment(obj);
                }}
                value={
                  isEdit
                    ? Moment(clientAssignment.clientStartDate).format(
                      "YYYY-MM-DD"
                    )
                    : undefined
                }
              />
              <CustomInput
                id="date"
                type="date"
                error={clientAssignment.clientEndDate == undefined || clientAssignment.clientEndDate == "" ? fieldError : false}
                labelText={"Process End Date"}
                variant={"outlined"}
                disabled={!isEdit}
                value={
                  isEdit
                    ? Moment(clientAssignment.clientEndDate).format(
                      "YYYY-MM-DD"
                    )
                    : undefined
                }
                onChange={(e) => {
                  let obj = _.cloneDeep(clientAssignment);
                  obj.clientEndDate = e.target.value;
                  setClientAssignment(obj);
                }}
              />

              <div className="height"></div>
              <br></br>
              <span>Exclude Client Specific Codes</span>

              <RadioButton
                label={"Yes"}
                checked={excludeData == 1}
                onChange={(e) => {
                  let obj = _.cloneDeep(clientAssignment);
                  if (e.target.checked) {
                    setExcludeData(1);
                    obj.excludeClientSpecificCodes = 1;
                  }
                  clientAssignment.excludeClientSpecificCodes = obj;
                  setClientAssignment(obj);
                }}
              />
              <RadioButton
                label={"No"}
                checked={excludeData == 0}
                onChange={(e) => {
                  let obj = _.cloneDeep(clientAssignment);
                  if (e.target.checked) {
                    setExcludeData(0);
                    obj.excludeClientSpecificCodes = 0;
                  }
                  clientAssignment.excludeClientSpecificCodes = obj;
                  setClientAssignment(obj);
                }}
              />
            </GridItem>

            <ButtonGroup>
              <CustomButton
                onClick={saveDiag}
                // disabled={diagFromExist == true || diagToExist == true}
                style={{
                  backgroundColor: navyColor,
                  color: "white",
                  margin: 10,
                  padding: 4,
                  fontSize: 12,
                  textTransform: "capitalize",
                }}
              >
                save
              </CustomButton>
              <CustomButton
                onClick={() => {
                  handleClickToCancel();
                }}
                style={{
                  backgroundColor: dangerColor,
                  color: "white",
                  margin: 10,
                  padding: 4,
                  fontSize: 12,
                  textTransform: "capitalize",
                }}
              >
                cancel
              </CustomButton>
            </ButtonGroup>
          </DialogContent>
        }
      />
      <Dialogbox
        open={fromViewPolicy ? false : opendelete}
        onClose={handleToClose}
        disableBackdropClick={true}
        title={"Confirm"}
        message={"Would you like to Delete This Record ?"}
        actions={
          <ButtonGroup>
            <CustomButton
              onClick={DeletedMethod}
              style={{
                backgroundColor: navyColor,
                color: "white",
                marginRight: 10,
                padding: 4,
                fontSize: 12,
                textTransform: "capitalize",
              }}
            >
              Yes
            </CustomButton>
            <CustomButton
              onClick={handleClickToCloseEdit}
              style={{
                backgroundColor: dangerColor,
                color: "white",
                padding: 4,
                fontSize: 12,
                textTransform: "capitalize",
              }}
            >
              No
            </CustomButton>
          </ButtonGroup>
        }
      />
    </>

  )
};
export default ClientAssignment;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { NewPolicyFormState } from "../../redux/reducers/NewPolicyFormReducer";
import { NewPolicyState } from "../../redux/reducers/NewPolicyReducer";
import "./CategoryOptions.css";
import { PolicyConstants } from "../NewPolicy/PolicyConst";
import { BILLED_WITH } from "../../redux/actions/NewPolicyFormActionTypes";
import { getAddOnCodes } from "../../redux/actions";
import CustomButton from "../../components/CustomButtons/CustomButton";
import { disabledColor } from "../../assets/jss/material-kit-react";
import StarIcon from '@mui/icons-material/Star';


const CategoryThirtyEight = ({ edit, viewMode }) => {
 const formState: NewPolicyFormState = useSelector(
        (state: any) => state.newPolicyForm
    );
const updatedState: NewPolicyState = useSelector(
    (state: any) => state.newPolicy
    );
const dispatch = useDispatch();
const [billedWith, setBilledWith] = useState({});

useEffect(() => {
    if (updatedState.addOnCodes.length == 0) {
         getAddOnCodes(dispatch);
        }
    }, []);

const billed = updatedState.addOnCodes?.map((a) => {
    return { label: a.boDesc, value: a.boKey }
 })

useEffect(() => {
    if (formState.cat == PolicyConstants.THIRTY_EIGHT) {
         if (formState.billedWith == undefined || formState.billedWith == null) {
             dispatch({
                 type: BILLED_WITH,
                   payload: billed[0],
                });
            }
        }
    }, [formState]);

    return (
        <div>
        <GridContainer>
        <GridItem sm={2} md={2} xs={2}>
          <small
            style={{
              fontSize: 13,
              color: "black",
              fontWeight: 400,
              position: "relative",
              top: 15,
            }}
          >
            BO Policy Key {<StarIcon style={{position:"relative", bottom:"4px",fontSize:"7px"}}/>}
          </small>
        </GridItem>
        <GridItem sm={2} md={2} xs={2}>
          <div style={{ marginLeft: -30 }}>
            <CustomSelect
              error={formState.errors?.billedWith}
              options={billed}
              value={billed.filter(function (option) {
                return option.value == formState.billedWith;
              })}
              isDisabled={!edit ? undefined : viewMode}
              catTitle={billed.filter((option, index) => {
                if (formState.billedWith == option.value) {
                  return option
                }
              })}
              onSelect={(event) => {
                setBilledWith(event);
                dispatch({
                  type: BILLED_WITH,
                  payload: event.value,
                });
              }}
            />
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem sm={2} md={2} xs={2}>
          <small
            style={{
              fontSize: 13,
              color: "black",
              fontWeight: 400,
              position: "relative",
              top: 28,
            }}
          >
            Policy Category Table
          </small>
        </GridItem>
        <GridItem sm={2} md={2} xs={2}>
          <CustomButton
            disabled={true}
            variant="contained"
            style={{
              backgroundColor: disabledColor,
              color: "white",
              marginTop: 20,
              fontSize: 12,
              padding: 4,
              textTransform: "capitalize",
            }}
          >
            Link to Table
          </CustomButton>
        </GridItem>
      </GridContainer>
      </div>

    );
};
    export default CategoryThirtyEight;

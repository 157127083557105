import React, { useEffect, useRef, useState } from "react";
import { TestingReportState } from "../../redux/reducers/TestingReportReducer";
import { useDispatch, useSelector } from "react-redux";
import "../TestingReport/TestingReport.css";
import CustomInput from "../../components/CustomInput/CustomInput";
import Moment from "moment";
import CustomButton from "../../components/CustomButtons/CustomButton";
import { navyColor, successColor } from "../../assets/jss/material-kit-react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { sendClaimData } from "../../redux/actions/TestingReport";
import { LINE_LEVEL_DATA, TOTAL_CLAIMS_DATA } from "../../redux/actions/TestingReportType";
import { CircularProgress } from "@material-ui/core";
import swal from "sweetalert2";

const _ = require("lodash");

const TestingReportScenario = (selectedType) => {
  const dispatch = useDispatch();

  const updatedState: TestingReportState = useSelector(
    (state: any) => state.testingReportReducer
  );

  const [headerLevelData, setHeaderLeveldata] = useState([]);

  let header = [];
  let lineLevel = [];
  useEffect(() => {
    let arr = [];
    let i = updatedState.tempData.length;
    for (let p = 0; p < i; p++) {
      updatedState.tempData[p].filter((k) => {
        header.push({
          scenarioId: k.scenarioId,
          claimSlId: k.claimSlId,
          postiveData: k.postiveData,
          gender: k.gender,
          memberId: k.memberId,
          dosFrom: k.dosFrom,
          dosTo: k.dosTo,
          claimFormType: k.claimFormType,
          tin: k.tin,
          npi: k.npi,
          taxonomy: k.taxonomy,
          diagsCodes: k.diagsCodes,
          pos: k.pos,
          conditionCode: k.conditionCode,
          dob: k.dob,
          billType: k.billType,
          scenarioDesc: k.scenarioDesc,
          zipCode: k.zipCode,
        });
        lineLevel.push({
          scenarioId: k.scenarioId,
          claimSlId: k.claimSlId,
          cptFrom: k.cptFrom,
          payerAllowedProcedureCode: k.payerAllowedProcedureCode,
          quantity: k.qty,
          payerAllowedUnits: k.payerAllowedUnits,
          mod1: k.mod1,
          payerAllowedModifier1: k.payerAllowedModifier1,
          mod2: k.mod2,
          payerAllowedModifier2: k.payerAllowedModifier2,
          mod3: k.mod3,
          payerAllowedModifier3: k.payerAllowedModifier3,
          mod4: k.mod4,
          payerAllowedModifier4: k.payerAllowedModifier4,
          dx1: k.dx1,
          dx2: k.dx2,
          dx3: k.dx3,
          dx4: k.dx4,
          dosFrom: k.dosFrom,
          dosTo: k.dosTo,
          challengeCode: k.challengeCode ? k.challengeCode : "",
          reasonCode: k.reasonCode ? k.reasonCode : "",
          policyNumber: k.policyNumber ? k.policyNumber : "",
          policyVersion: k.policyVersion ? k.policyVersion : "",
          refDrgnSlId: k.refDrgnSlId ? k.refDrgnSlId : "",
          // revCode:k.revCode,
          refDrgnClaimId: k.refDrgnClaimId ? k.refDrgnClaimId : "",
          revenueCode: k.revenueCode,
          totalChargeAmount: k.totalChargeAmount,
          hcpAllowedAmount: k.hcpAllowedAmount,
          lineLevelNpi: k.lineLevelNpi,
          lineLevelPos: k.lineLevelPos,
          lineLevelTaxonomy: k.lineLevelTaxonomy,
          claimStatus: k.claimStatus,
        });
      });
    }
    dispatch({ type: LINE_LEVEL_DATA, payload: lineLevel });

    const groupedById = _.groupBy(lineLevel, "scenarioId");
    const unquie = header.filter((obj, index) => {
      return index === header.findIndex((o) => obj.scenarioId === o.scenarioId);
    });
    setHeaderLeveldata(unquie);
  }, [updatedState.tempData]);

  function commaSeparatedRefDrgClaimSlId(refList) {
    let claimSlId = [];
    refList.map((ref) => {
      if (ref.drgnClaimSlId != undefined) {
        claimSlId.push(ref.drgnClaimSlId);
      }
    })
    return claimSlId.toString();
  }

  function commaSeparatedRefDrgClaimId(refList) {
    let claimId = [];
    refList.map((ref) => {
      if (ref.drgnClaimid != undefined) {
        claimId.push(ref.drgnClaimid);
      }
    })
    return claimId.toString();
  }

  function viewRecommendedMod(list) {
    let modList = [];
    if (list.modifier1 != undefined) {
      modList.push(list.modifier1);
    } else if (list.modifier2 != undefined) {
      modList.push(list.modifier2);
    } else if (list.modifier3 != undefined) {
      modList.push(list.modifier3);
    } else if (list.modifier4 != undefined) {
      modList.push(list.modifier4);
    }
    return modList.toString();
  }

  const claimLinesDetails = () => {
    let recommendationMap = [];
    let testClaimLines = [];
    let editedClaimLines = [];
    let finalClaimLines = [];
    let status = "";
    updatedState.totalClaimsData?.map((t) => {
      status = t.claimStatus;
      t.testClaimLines?.map((ts) => {
        testClaimLines.push(ts);
        ts.ipuRecommendationsList?.map((rec) => {
          recommendationMap.push([{ label: ts.claimSlId, value: rec }])
        })
      })
    })
    testClaimLines?.map((k, l) => {
      recommendationMap?.map((rec) => {
        rec?.map((t, l) => {
          if (t.label == k.claimSlId) {
            editedClaimLines.push({
              policyId: t.value.policyId,
              // recommendedMod: viewRecommendedMod(t.value),
              recommendedMod1: t.value.modifier1,
              recommendedMod2: t.value.modifier2,
              recommendedMod3: t.value.modifier3,
              recommendedMod4: t.value.modifier4,
              recommendedPercent: t.value.percent,
              policyNumber: t.value.policyNumber,
              policyVersion: t.value.policyVersion,
              challengeCode: t.value.challengeCodeId,
              refDrgnSlId: commaSeparatedRefDrgClaimSlId(t.value.ipuRecommendationReferencesList),
              reasonCode: t.value.reasonCode,
              refDrgnClaimId: commaSeparatedRefDrgClaimId(t.value.ipuRecommendationReferencesList),
              allowedQuantity: t.value.units,
              cptFrom: k.cptFrom,
              claimSlId: k.claimSlId,
              quantity: k.quantity,
              mod1: k.mod1,
              mod2: k.mod2,
              mod3: k.mod3,
              mod4: k.mod4,
              dx1: k.dx1,
              dx2: k.dx2,
              dx3: k.dx3,
              dx4: k.dx4,
              dosFrom: k.dosFrom,
              dosTo: k.dosTo,
              revenueCode: k.revenueCode,
              totalChargeAmount: k.totalChargeAmount,
              payerAllowedProcedureCode: k.payerAllowedProcedureCode,
              payerAllowedUnits: k.payerAllowedUnits,
              payerAllowedModifier1: k.payerAllowedModifier1,
              payerAllowedModifier2: k.payerAllowedModifier2,
              payerAllowedModifier3: k.payerAllowedModifier3,
              payerAllowedModifier4: k.payerAllowedModifier4,
              hcpAllowedAmount: k.hcpAllowedAmount,
              pos: k.pos,
              rvuPrice: k.rvuPrice,
              renderingProviderNpi: k.renderingProviderNpi,
              renderingTaxonomy: k.renderingTaxonomy,
              claimStatus: status
            })
          }
        })
      })

    })

    editedClaimLines.map((k, l) => {
      updatedState.lineLevelData.map((r, m) => {
        if (r.claimSlId === k.claimSlId) {
          finalClaimLines.push({
            scenarioId: r.scenarioId,
            claimSlId: k.claimSlId,
            cptFrom: k.cptFrom,
            payerAllowedProcedureCode: k.payerAllowedProcedureCode,
            quantity: k.quantity,
            allowedQuantity: k.allowedQuantity,
            payerAllowedUnits: k.payerAllowedUnits,
            mod1: k.mod1,
            payerAllowedModifier1: k.payerAllowedModifier1,
            mod2: k.mod2,
            payerAllowedModifier2: k.payerAllowedModifier2,
            mod3: k.mod3,
            payerAllowedModifier3: k.payerAllowedModifier3,
            mod4: k.mod4,
            payerAllowedModifier4: k.payerAllowedModifier4,
            dx1: k.dx1,
            dx2: k.dx2,
            dx3: k.dx3,
            dx4: k.dx4,
            dosFrom: k.dosFrom,
            dosTo: k.dosTo,
            challengeCode: k.challengeCode ? k.challengeCode : "",
            reasonCode: k.reasonCode ? k.reasonCode : "",
            policyNumber: k.policyNumber ? k.policyNumber : "",
            policyVersion: k.policyVersion,
            refDrgnSlId: k.refDrgnSlId ? k.refDrgnSlId : "",
            refDrgnClaimId: k.refDrgnClaimId ? k.refDrgnClaimId : "",
            recommendedMod1: k.recommendedMod1,
            recommendedMod2: k.recommendedMod2,
            recommendedMod3: k.recommendedMod3,
            recommendedMod4: k.recommendedMod4,
            recommendedPercent: k.recommendedPercent,
            revenueCode: k.revenueCode,
            totalChargeAmount: k.totalChargeAmount,
            hcpAllowedAmount: k.hcpAllowedAmount,
            lineLevelNpi: k.renderingProviderNpi,
            lineLevelPos: k.pos,
            lineLevelTaxonomy: k.renderingTaxonomy,
            claimStatus: k.claimStatus,
            rvuPrice: k.rvuPrice
          })
        }
      })
    })
    updatedState.lineLevelData.map((ts) => {
      if (!finalClaimLines.some(item => item.claimSlId == ts.claimSlId)) {
        finalClaimLines.push(ts);
      }
    })
    return finalClaimLines.sort((a, b) => a.claimSlId - b.claimSlId);
  }




  async function sendClaim(id) {
    let claimDataDTO = {};
    let lineLevelDetails = [];
    let claimSlId;
    headerLevelData.map((k, l) => {
      if (k.scenarioId == id) {
        claimDataDTO["patientDateOfBirth"] = Moment(k.dob).format("YYYY-MM-DD");
        claimDataDTO["patientGender"] = k.gender;
        claimDataDTO["clmFormType"] = k.claimFormType;
        claimDataDTO["billType"] = k.billType;
        claimDataDTO["posLkp"] = k.pos;
        claimDataDTO["condCode"] = k.conditionCode;
        claimDataDTO["diags"] = k.diagsCodes;
        claimDataDTO["renderingTaxonomy"] = k.taxonomy;
        claimDataDTO["renderingProviderNpi"] = k.npi;
        claimDataDTO["taxIdentifier"] = k.tin;
        claimDataDTO["ipuPatientId"] = k.memberId;
        claimDataDTO["zipCode"] = k.zipCode;
        claimDataDTO["dateOfService"] = Moment(checkHeaderDosFrom(id)).format(
          "YYYY-MM-DD"
        );
      }
    });

    updatedState.lineLevelData.map((k) => {
      if (k.scenarioId == id) {
        claimSlId = k.claimSlId;
        lineLevelDetails.push({
          claimSlId: k.claimSlId,
          scenarioDesc: k.scenarioDesc,
          cptFrom: k.cptFrom,
          quantity: k.quantity,
          mod1: k.mod1,
          mod2: k.mod2,
          mod3: k.mod3,
          mod4: k.mod4,
          dx1: k.dx1,
          dx2: k.dx2,
          dx3: k.dx3,
          dx4: k.dx4,
          dosFrom: k.dosFrom,
          dosTo: k.dosTo,
          revenueCode: k.revenueCode,
          totalChargeAmount: k.totalChargeAmount,
          allowedQuantity: k.allowedQuantity,
          payerAllowedProcedureCode: k.payerAllowedProcedureCode,
          payerAllowedUnits: k.payerAllowedUnits,
          payerAllowedModifier1: k.payerAllowedModifier1,
          payerAllowedModifier2: k.payerAllowedModifier2,
          payerAllowedModifier3: k.payerAllowedModifier3,
          payerAllowedModifier4: k.payerAllowedModifier4,
          renderingProviderNpi: k.lineLevelNpi,
          pos: k.lineLevelPos,
          renderingTaxonomy: k.lineLevelTaxonomy,
          hcpAllowedAmount: k.hcpAllowedAmount,
          rvuPrice: k.rvuPrice,
        });
      }
    });

    if (selectedType.selectedType == "single") {
      claimDataDTO["policyId"] = updatedState?.getTestingReportData.policyId;
    }
    claimDataDTO["testClaimLines"] = lineLevelDetails;
    // claimDataDTO["claimHeaderData"] = headerLevel;
    claimDataDTO["clientGroupType"] = selectedType.clientGroupType.label;
    claimDataDTO["clientGroupCode"] = selectedType.clientGroupCode;
    claimDataDTO["clientGroupId"] = selectedType.clientGroup;
    claimDataDTO["fileHistoricalClaimLines"] = updatedState.historicTempData;
    // claimDataDTO["clientGroupId"] = selectedType.clientGroup;
    claimDataDTO["includeDBHistory"] =
      selectedType.includeDbHistory == null ? 0 : selectedType.includeDbHistory;
    if (selectedType.selectedType == "all") {
      claimDataDTO["isProdb"] =
        selectedType.includeTest == null ? 0 : selectedType.includeTest;
    }
    let runType = "single";
    let claimDTOList = [];
    claimDTOList.push(claimDataDTO)
    if (selectedType.clientGroupType.label == "" || selectedType.clientGroupType.label == undefined) {
      swal.fire({
        icon: "info",
        text: "Please Select Client Group Type",
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    } else {
      await sendClaimData(dispatch, claimDTOList, claimSlId, runType);
    }
  }
  const checkHeaderDosFrom = (id) => {
    let headerDosFrom = "";
    let dosHeaderDate = [];
    updatedState.lineLevelData.filter((k, l) => {
      if (k.scenarioId == id) {
        dosHeaderDate.push({
          dosFrom: k.dosFrom,
        });
      }
    });

    for (let i = 0; i < updatedState.lineLevelData.length; i++) {
      dosHeaderDate.filter((k, l) => {
        if (updatedState.lineLevelData[i].scenarioId == id) {
          const date1 = new Date(updatedState.lineLevelData[i].dosFrom);
          const date12 = new Date(k.dosFrom);
          if (date12 < date1) {
            headerDosFrom = k.dosFrom;
          } else if (headerDosFrom == "") {
            headerDosFrom = k.dosFrom;
          }
        }
      });
    }
    return Moment(headerDosFrom).format("MM-DD-YYYY");
  };

  const checkHeaderDosTo = (id) => {
    let dosToDate = "";
    let ok = [];
    updatedState.lineLevelData.filter((k, l) => {
      if (k.scenarioId == id) {
        ok.push({
          dosTo: k.dosTo,
        });
      }
    });
    for (let i = 0; i < updatedState.lineLevelData.length; i++) {
      ok.filter((k, l) => {
        if (updatedState.lineLevelData[i].scenarioId == id) {
          const date1 = new Date(updatedState.lineLevelData[i].dosTo);
          const date12 = new Date(k.dosTo);
          if (date12 > date1) {
            dosToDate = k.dosTo;
          } else if (dosToDate == "") {
            dosToDate = k.dosTo;
          }
        }
      });
    }
    return Moment(dosToDate).format("MM-DD-YYYY");
  };
  const checkTableScroll = (scenarioId) => {
    let scroll;
    let temp = [];
    updatedState.lineLevelData.map((k, l) => {
      if (scenarioId == k.scenarioId) {
        temp.push(k);
        if (temp.length >= 2) {
          scroll = "scroll";
        } else {
          scroll = "hidden";
        }
      }
    });
    return scroll;
  };
  function checkDisable() {
    let checkCode = true;
    if (
      selectedType.selectedType == "single" &&
      selectedType.policyNumber &&
      selectedType.policyVersion
    ) {
      checkCode = false;
    } else if (selectedType.selectedType == "all") {
      checkCode = false;
    }
    return checkCode;
  }
  function checkDisableColor() {
    let checkCodeColor;
    if (
      selectedType.selectedType == "single" &&
      selectedType.policyNumber &&
      selectedType.policyVersion &&
      selectedType.runAllClicked == true
    ) {
      checkCodeColor = "#EE9157";
    } else if (selectedType.selectedType == "all") {
      if (selectedType.runAllClicked == true) {
        checkCodeColor = "#EE9157";
      } else {
        checkCodeColor = successColor;
      }
    } else if (
      selectedType.selectedType == "single" &&
      selectedType.policyNumber &&
      selectedType.policyVersion &&
      selectedType.runAllClicked == false
    ) {
      checkCodeColor = successColor;
    } else {
      checkCodeColor = "#9CAEA4";
    }
    return checkCodeColor;
  }

  function buildRows() {
    const arr = [];
    for (let i = 0; i < headerLevelData.length; i++) {
      arr.push(
        <div key={i}>
          <label className="scenarioHead1">Scenario ID : </label>
          <span className="scenarioVal">{headerLevelData[i].scenarioId}</span>
          <label className="scenarioHead"> Scenario Desc : </label>
          <span
            className="scenarioVal"
            title={headerLevelData[i].scenarioDesc}
            style={{
              overflow: "hidden",
              display: "inline-block",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              textAlign: "center",
              top: "5px",
              position: "relative",
            }}
          >
            {headerLevelData[i].scenarioDesc}
          </span>
          <label className="scenarioHead">Positive/Negative : </label>
          <span className="scenarioVal">{headerLevelData[i].postiveData}</span>

          <div className="sce">
            <div className="testGender">
              <CustomInput
                fullWidth={true}
                labelText={"Gender"}
                //   type={"text"}
                disabled={true}
                variant={"outlined"}
                value={headerLevelData[i].gender}
              />
            </div>
            <div className="testClaim">
              <CustomInput
                fullWidth={true}
                labelText={"Claim Form Type"}
                //   type={"text"}
                disabled={true}
                variant={"outlined"}
                value={
                  headerLevelData[i].claimFormType
                    ? headerLevelData[i].claimFormType
                    : ""
                }
              />
            </div>
            <CustomInput
              fullWidth={true}
              disabled={true}
              labelText={"Member ID"}
              //   type={"text"}
              variant={"outlined"}
              value={
                headerLevelData[i].memberId ? headerLevelData[i].memberId : ""
              }
            />
            <div className="testTin">
              <CustomInput
                fullWidth={true}
                labelText={"TIN"}
                disabled={true}
                //   type={"text"}
                variant={"outlined"}
                value={headerLevelData[i].tin ? headerLevelData[i].tin : ""}
              />
            </div>
            <CustomInput
              fullWidth={true}
              labelText={"NPI"}
              disabled={true}
              //   type={"text"}
              variant={"outlined"}
              value={headerLevelData[i].npi ? headerLevelData[i].npi : ""}
            />
            <CustomInput
              fullWidth={true}
              disabled={true}
              labelText={"Taxonomy"}
              //   type={"text"}
              variant={"outlined"}
              value={
                headerLevelData[i].taxonomy ? headerLevelData[i].taxonomy : ""
              }
            />
            <CustomInput
              fullWidth={true}
              labelText={"POS"}
              disabled={true}
              type={"text"}
              variant={"outlined"}
              value={headerLevelData[i].pos ? headerLevelData[i].pos : ""}
            />
            <CustomInput
              fullWidth={true}
              labelText={"Zip Code"}
              disabled={true}
              type={"text"}
              variant={"outlined"}
              value={headerLevelData[i].zipCode ? headerLevelData[i].zipCode : ""}
            />
            <CustomInput
              fullWidth={true}
              labelText={"DOB"}
              disabled={true}
              type={"text"}
              variant={"outlined"}
              value={Moment(headerLevelData[i].dob).format("MM-DD-YYYY")}
            />
            <CustomInput
              fullWidth={true}
              labelText={"DOS From"}
              type={"text"}
              disabled={true}
              variant={"outlined"}
              value={Moment(
                checkHeaderDosFrom(headerLevelData[i].scenarioId)
              ).format("MM-DD-YYYY")}
            // value={checkHeaderDosFrom(headerLevelData[i].claimSlId)}
            />
            <CustomInput
              fullWidth={true}
              labelText={"DOS To"}
              type={"text"}
              disabled={true}
              variant={"outlined"}
              value={checkHeaderDosTo(headerLevelData[i].scenarioId)}
            />
            <CustomInput
              fullWidth={true}
              labelText={"Cond Code"}
              type={"text"}
              variant={"outlined"}
              disabled={true}
              value={
                headerLevelData[i].conditionCode
                  ? headerLevelData[i].conditionCode
                  : ""
              }
            />
            <CustomInput
              fullWidth={true}
              labelText={"Diags Code"}
              type={"text"}
              disabled={true}
              variant={"outlined"}
              value={
                headerLevelData[i].diagsCodes
                  ? headerLevelData[i].diagsCodes
                  : ""
              }
            />
            <div className="testBillType">
              <CustomInput
                fullWidth={true}
                labelText={"Bill Type"}
                type={"text"}
                disabled={true}
                variant={"outlined"}
                value={
                  headerLevelData[i].billType ? headerLevelData[i].billType : ""
                }
              />
            </div>
          </div>
          <>
            <div
              style={{
                overflowY: checkTableScroll(headerLevelData[i].scenarioId),
                height: "150px"
              }}
            >
              <table>
                <tr>
                  <th>Claim SL ID</th>
                  <th>PROCS Code</th>
                  <th>Allowed PROCS Code</th>
                  <th>Qty</th>
                  <th>Allowed Units</th>
                  <th>Mod 1</th>
                  <th>Allowed Mod 1</th>
                  <th>Mod 2</th>
                  <th>Allowed Mod 2</th>
                  <th>Mod 3</th>
                  <th>Allowed Mod 3</th>
                  <th>Mod 4</th>
                  <th>Allowed Mod 4</th>
                  <th>DOS From</th>
                  <th>DOS To</th>
                  <th>Dx 1</th>
                  <th>Dx 2</th>
                  <th>Dx 3</th>
                  <th>Dx 4</th>
                  <th>Revenue Code</th>
                  <th>Total Charge Amount</th>
                  <th>RVU Price</th>
                  <th>Total Allowed Amount</th>
                  <th>Line Level POS</th>
                  <th>Line Level NPI</th>
                  <th>Line Level Taxonomy</th>
                  <th>Allowed Qty</th>
                  <th>Rec Mod1</th>
                  <th>Rec Mod2</th>
                  <th>Rec Mod3</th>
                  <th>Rec Mod4</th>
                  <th>Rec Per</th>
                  <th>Challenge Code</th>
                  <th>Reason Code</th>
                  <th>Policy#</th>
                  <th>Version</th>
                  <th>Ref Claim ID</th>
                  <th>Ref Sl ID</th>
                </tr>
                {updatedState.totalClaimsData.map((k, l) => {
                  k.testClaimLines.map((f, l) => {
                    updatedState.lineLevelData.map((r, p) => {
                      if (r.claimSlId === f.claimSlId) {
                        r.policyNumber = f.policyNumber;
                        r.policyVersion = f.policyVersion;
                        r.challengeCode = f.challengeCode;
                        r.refDrgnSlId = f.refDrgnSlId;
                        r.reasonCode = f.reasonCode;
                        r.refDrgnClaimId = f.refDrgnClaimId;
                        r.allowedQuantity = f.allowedQuantity;
                        r.rvuPrice = f.rvuPrice;
                      }
                    })
                  })
                })}
                {/* {updatedState.lineLevelData.map((k, index) => { */}
                {claimLinesDetails()?.map((k, index) => {
                  const rowspan = claimLinesDetails()?.filter(
                    (obj) =>
                      obj.claimSlId === k.claimSlId &&
                      obj.cptFrom === k.cptFrom &&
                      obj.payerAllowedProcedureCode === k.payerAllowedProcedureCode &&
                      obj.quantity === k.quantity &&
                      obj.payerAllowedUnits === k.payerAllowedUnits &&
                      obj.mod1 === k.mod1 &&
                      obj.payerAllowedModifier1 === k.payerAllowedModifier1 &&
                      obj.mod2 === k.mod2 &&
                      obj.payerAllowedModifier2 === k.payerAllowedModifier2 &&
                      obj.mod3 === k.mod3 &&
                      obj.payerAllowedModifier3 === k.payerAllowedModifier3 &&
                      obj.mod4 === k.mod4 &&
                      obj.payerAllowedModifier4 === k.payerAllowedModifier4 &&
                      obj.dosFrom === k.dosFrom &&
                      obj.dosTo === k.dosTo &&
                      obj.dx1 === k.dx1 &&
                      obj.dx2 === k.dx2 &&
                      obj.dx3 === k.dx3 &&
                      obj.dx4 === k.dx4 &&
                      obj.revenueCode === k.revenueCode &&
                      obj.totalChargeAmount === k.totalChargeAmount &&
                      obj.rvuPrice === k.rvuPrice &&
                      obj.hcpAllowedAmount === k.hcpAllowedAmount &&
                      obj.lineLevelPos === k.lineLevelPos &&
                      obj.lineLevelNpi === k.lineLevelNpi &&
                      obj.lineLevelTaxonomy === k.lineLevelTaxonomy
                  ).length;

                  if (headerLevelData[i].scenarioId == k.scenarioId) {

                    if (
                      index === 0 ||
                      k.claimSlId !== claimLinesDetails()[index - 1].claimSlId ||
                      k.cptFrom !== claimLinesDetails()[index - 1].cptFrom ||
                      k.payerAllowedProcedureCode !== claimLinesDetails()[index - 1].payerAllowedProcedureCode ||
                      k.quantity !== claimLinesDetails()[index - 1].quantity ||
                      k.payerAllowedUnits !== claimLinesDetails()[index - 1].payerAllowedUnits ||
                      k.mod1 !== claimLinesDetails()[index - 1].mod1 ||
                      k.payerAllowedModifier1 !== claimLinesDetails()[index - 1].payerAllowedModifier1 ||
                      k.mod2 !== claimLinesDetails()[index - 1].mod2 ||
                      k.payerAllowedModifier2 !== claimLinesDetails()[index - 1].payerAllowedModifier2 ||
                      k.mod3 !== claimLinesDetails()[index - 1].mod3 ||
                      k.payerAllowedModifier3 !== claimLinesDetails()[index - 1].payerAllowedModifier3 ||
                      k.mod4 !== claimLinesDetails()[index - 1].mod4 ||
                      k.payerAllowedModifier4 !== claimLinesDetails()[index - 1].payerAllowedModifier4 ||
                      k.dosFrom !== claimLinesDetails()[index - 1].dosFrom ||
                      k.dosTo !== claimLinesDetails()[index - 1].dosTo ||
                      k.dx1 !== claimLinesDetails()[index - 1].dx1 ||
                      k.dx2 !== claimLinesDetails()[index - 1].dx2 ||
                      k.dx3 !== claimLinesDetails()[index - 1].dx3 ||
                      k.dx4 !== claimLinesDetails()[index - 1].dx4 ||
                      k.revenueCode !== claimLinesDetails()[index - 1].revenueCode ||
                      k.totalChargeAmount !== claimLinesDetails()[index - 1].totalChargeAmount ||
                      k.rvuPrice !== claimLinesDetails()[index - 1].rvuPrice ||
                      k.hcpAllowedAmount !== claimLinesDetails()[index - 1].hcpAllowedAmount ||
                      k.lineLevelPos !== claimLinesDetails()[index - 1].lineLevelPos ||
                      k.lineLevelNpi !== claimLinesDetails()[index - 1].lineLevelNpi ||
                      k.lineLevelTaxonomy !== claimLinesDetails()[index - 1].lineLevelTaxonomy
                    ) {
                      return (
                        <tr>
                          <td rowSpan={rowspan}>{k.claimSlId}</td>
                          <td rowSpan={rowspan}>{k.cptFrom}</td>
                          <td rowSpan={rowspan}>{k.payerAllowedProcedureCode}</td>
                          <td rowSpan={rowspan}>{k.quantity}</td>
                          <td rowSpan={rowspan}>{k.payerAllowedUnits}</td>
                          <td rowSpan={rowspan}>{k.mod1}</td>
                          <td rowSpan={rowspan}>{k.payerAllowedModifier1}</td>
                          <td rowSpan={rowspan}>{k.mod2}</td>
                          <td rowSpan={rowspan}>{k.payerAllowedModifier2}</td>
                          <td rowSpan={rowspan}>{k.mod3}</td>
                          <td rowSpan={rowspan}>{k.payerAllowedModifier3}</td>
                          <td rowSpan={rowspan}>{k.mod4}</td>
                          <td rowSpan={rowspan}>{k.payerAllowedModifier4}</td>
                          <td rowSpan={rowspan}>{k.dosFrom}</td>
                          <td rowSpan={rowspan}>{k.dosTo}</td>
                          <td rowSpan={rowspan}>{k.dx1}</td>
                          <td rowSpan={rowspan}>{k.dx2}</td>
                          <td rowSpan={rowspan}>{k.dx3}</td>
                          <td rowSpan={rowspan}>{k.dx4}</td>
                          <td rowSpan={rowspan}>{k.revenueCode}</td>
                          <td rowSpan={rowspan}>{k.totalChargeAmount}</td>
                          <td rowSpan={rowspan}>{k.rvuPrice}</td>
                          <td rowSpan={rowspan}>{k.hcpAllowedAmount}</td>
                          <td rowSpan={rowspan}>{k.lineLevelPos}</td>
                          <td rowSpan={rowspan}>{k.lineLevelNpi}</td>
                          <td rowSpan={rowspan}>{k.lineLevelTaxonomy}</td>
                          <td>{k.allowedQuantity}</td>
                          <td>{k.recommendedMod1}</td>
                          <td>{k.recommendedMod2}</td>
                          <td>{k.recommendedMod3}</td>
                          <td>{k.recommendedMod4}</td>
                          <td>{k.recommendedPercent}</td>
                          <td>{k.challengeCode}</td>
                          <td>{k.reasonCode}</td>
                          <td>{k.policyNumber}</td>
                          <td>{k.policyVersion}</td>
                          <td className="refClmId" title={k.refDrgnClaimId}>{k.refDrgnClaimId}</td>
                          <td className="refClmId" title={k.refDrgnSlId}>{k.refDrgnSlId}</td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr>
                          <td>{k.allowedQuantity}</td>
                          <td>{k.recommendedMod1}</td>
                          <td>{k.recommendedMod2}</td>
                          <td>{k.recommendedMod3}</td>
                          <td>{k.recommendedMod4}</td>
                          <td>{k.recommendedPercent}</td>
                          <td>{k.challengeCode}</td>
                          <td>{k.reasonCode}</td>
                          <td>{k.policyNumber}</td>
                          <td>{k.policyVersion}</td>
                          <td className="refClmId" title={k.refDrgnClaimId}>{k.refDrgnClaimId}</td>
                          <td className="refClmId" title={k.refDrgnSlId}>{k.refDrgnSlId}</td>
                        </tr>
                      );
                    }
                  }
                })}
              </table>
            </div>
          </>

          <div className="scenarioRun">
            <CustomButton
              variant={"contained"}
              // #9CAEA4\
              disabled={checkDisable()}
              style={{
                backgroundColor: checkDisableColor(),
                color: "white",
                margin: 0,
                padding: 4,
                fontSize: 12,
                marginLeft: 6,
                textTransform: "capitalize",
              }}
              onClick={() => {
                sendClaim(headerLevelData[i].scenarioId);
              }}
              startIcon={
                <PlayArrowIcon
                  style={{
                    fontSize: "14px",
                    position: "relative",
                    left: "1px",
                    marginRight: "0px",
                  }}
                />
              }
            >
              Run
            </CustomButton>
          </div>
        </div>
      );
    }
    return arr;
  }
  return <>{buildRows()}</>;
};

export default TestingReportScenario;

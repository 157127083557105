import { NEW_POLICY_CREATE_ARRAY } from "../actions/PolicyActionType";

  export interface PolicyState {
    newpolicyCreateArray: any;
  }
  const intialState: PolicyState = {
    newpolicyCreateArray: [],
  };
  export default function PolicyReducer(
    state = intialState,
    action: { type: string; payload: any }
  ): PolicyState {
    switch (action.type) {
      case NEW_POLICY_CREATE_ARRAY:
        return {
          ...state,
          newpolicyCreateArray: action.payload,
        };
      default:
        return state;
    }
  }
  

import { navyColor } from '../../assets/jss/material-kit-react';
import { dataCurationETL, apiUrl } from '../../configs/apiUrls';
import { ViewMetaConstants } from '../../pages/ViewMeta/viewMetaConstants';
import { store } from '../store';
import { GET_TOTAL_NUMBER_OF_ROWS } from './ReferentialDataTypes';
import {SET_IS_LOADING } from './actionTypes';
import swal from 'sweetalert2';

export async function getReferentialData(
  dispatch: typeof store.dispatch,
  data,
  sourceName
) {
  let d = [];
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(
    `${dataCurationETL + apiUrl.getReferentialDataDetails + '/' + sourceName}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }
  )
    .then((response) => {
      if (!response.ok) {
        // throw new Error(response.statusText);
      }
      if (response.status == 500) {
        swal.fire({
          icon: 'info',
          text: 'No data found',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
      }
      return response.json();
    })
    .then((data) => {
      switch (sourceName) {
        case ViewMetaConstants.MFS:
          d = data.mfsSearchResultDTO;
          break;
        case ViewMetaConstants.MFS_DATE_BINDED:
          d = data.mfsSearchResultDTO;
          break;
        case ViewMetaConstants.GPCI:
          d = data.gpciResult;
          break;
        case ViewMetaConstants.GPCI_DATE_BINDED:
          d = data.gpciResult;
          break;
        case ViewMetaConstants.ADD_ON_CODES:
          d = data.addOnCodeResult;
          break;
        case ViewMetaConstants.APC_DATE_BINDED:
          d = data.apcResult;
          break;
        case ViewMetaConstants.CAPC_DATE_BINDED:
          d = data.capcResult;
          break;
        case ViewMetaConstants.CCI:
          d = data.cciSearchResults;
          break;
        case ViewMetaConstants.OCE_HCPCS:
          d = data.oceHcpcsResult;
          break;
        case ViewMetaConstants.HCPCS_DATE_BINDED:
          d = data.oceHcpcsResult;
          break;
        case 'CPT HCPCS':
          d = data.cptSearchResult;
          break;
        case ViewMetaConstants.ICD:
          d = data.icdResultSet;
          break;
        case ViewMetaConstants.MAX_UNITS:
          d = data.maxUnitsResultSet;
          break;
        case ViewMetaConstants.BW_Pairs:
          d = data.bwPairsSearchResultDTO;
          break;
        case ViewMetaConstants.ZIP_5:
          d = data.zip5Result;
          break;
        case ViewMetaConstants.ZIP_5_DATE_BINDED:
          d = data.zip5Result;
          break;
        case ViewMetaConstants.ZIP_9:
          d = data.zip9Result;
          break;
        case ViewMetaConstants.ZIP_9_DATE_BINDED:
          d = data.zip9Result;
          break;
        case ViewMetaConstants.MAX_UNITS:
          d = data.maxUnitsResultSet;
          break;

        default:
          break;
      }
      //   dispatch({ type: GET_ADD_ON_CODES_DATA, payload: d });
      dispatch({ type: GET_TOTAL_NUMBER_OF_ROWS, payload: data.numberOfRows });
    });
  dispatch({ type: SET_IS_LOADING, payload: false });
  return d;
}

export async function getClientSpecficCodesReferentialData(
  dispatch: typeof store.dispatch,
  data
) {
  let d = [];
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(`${dataCurationETL + apiUrl.viewClientSpecficCodesData}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        // throw new Error(response.statusText);
      }
      if (response.status == 500) {
        swal.fire({
          icon: 'info',
          text: 'No data found',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
      }
      return response.json();
    })
    .then((data) => {
      d = data;
      // dispatch({ type: GET_CLIENT_SPECIFIC_CODES_DATA, payload: d });
      dispatch({ type: GET_TOTAL_NUMBER_OF_ROWS, payload: data.length });
    });
  dispatch({ type: SET_IS_LOADING, payload: false });
  return d;
}

export async function getReferentialDataTotalOfRows(
  dispatch: typeof store.dispatch,
  data
) {
  let d = 0;
  await fetch(
    `${dataCurationETL + apiUrl.getTotalNumberOfRowsFromSource}/${data}`
  )
    .then((response) => {
      if (!response.ok) {
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_TOTAL_NUMBER_OF_ROWS, payload: data });
      d = data;
    });
  return d;
}

import swal from 'sweetalert2';
import { navyColor } from '../../assets/jss/material-kit-react';
import { apiUrl, dataCurationETL, ipuUser } from '../../configs/apiUrls';
import { store } from '../store';
import { SET_IS_LOADING } from './actionTypes';
import { USER_DETAILS } from './NewPolicyFormActionTypes';
import {
  CREATE_USER,
  CREATE_USER_VALIDATION,
  EDIT_PASSWORD,
  EDIT_USER,
  GET_ROLES,
  GET_ROLES_BY_ID,
  GET_USER_LIST,
  LOGIN_USER,
  ROLE_NAME,
} from './UserActionTypes';
export async function getRoles(dispatch: typeof store.dispatch) {
  await fetch(ipuUser + apiUrl.getRoles)
    .then((response) => {
      if (!response.ok) {
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_ROLES, payload: data });
    });
}

export async function getRolesById(dispatch: typeof store.dispatch, data) {
  let params = { userId: data.userId };
  await fetch(ipuUser + apiUrl.getRoleById, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
    .then((response) => {
      if (!response.ok) {
      }
      return response.json();
    })
    .then((data) => {
      if (data.status) {
      } else {
        dispatch({ type: GET_ROLES_BY_ID, payload: data });
      }
    });
}

export async function getUserList(dispatch: typeof store.dispatch) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(ipuUser + apiUrl.getAllUser)
    .then((response) => {
      if (!response.ok) {
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_USER_LIST, payload: data });
      dispatch({ type: SET_IS_LOADING, payload: false });
    });
}

export async function saveUser(dispatch: typeof store.dispatch, data) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(ipuUser + apiUrl.saveUser, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json();
    })
    .then((data) => {
      if (data.status == 500) {
        swal.fire({
          icon: 'error',
          text: 'User details failed to save ',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
      } else {
        getUserList(dispatch);
        dispatch({ type: CREATE_USER, payload: data });
        dispatch({ type: SET_IS_LOADING, payload: false });
        swal.fire({
          icon: 'success',
          text: 'User details saved sucessfully',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
      }
    });
}
export async function editUser(dispatch: typeof store.dispatch, data) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(ipuUser + apiUrl.updateUser, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json();
    })
    .then((data) => {
      if (data.status == 500) {
        swal.fire({
          icon: 'error',
          text: 'User Details update failed',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
      } else {
        getUserList(dispatch);
        dispatch({ type: EDIT_USER, payload: data });
        dispatch({ type: SET_IS_LOADING, payload: false });
        swal.fire({
          icon: 'success',
          text: 'User Details updated successfully',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
      }
    });
}

export async function editPassword(dispatch: typeof store.dispatch, data) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(ipuUser + apiUrl.updatePassword, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response;
    })
    .then((data) => {
      if (data.status == 500) {
        swal.fire({
          icon: 'error',
          text: 'Password update failed',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
      } else {
        dispatch({ type: EDIT_PASSWORD, payload: data });
        dispatch({ type: SET_IS_LOADING, payload: false });
        swal.fire({
          icon: 'success',
          text: 'Password updated successfully',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
      }
    });
}


export async function loginUser(dispatch: typeof store.dispatch, data) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  try {
    const response = await fetch(ipuUser + apiUrl.checkUser, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      swal.fire({
        icon: 'error',
        title: 'Login Failed',
        text: 'Your Username or Password is Incorrect. Please try again.',
        confirmButtonColor: navyColor,
        confirmButtonText: 'OK',
      });
    }else{
      const userData = await response.json();
      dispatch({ type: SET_IS_LOADING, payload: false });
      dispatch({ type: USER_DETAILS, payload: userData });
      return userData;
    }
  } catch (error) {
    dispatch({ type: SET_IS_LOADING, payload: false });
    throw error;
  }
}
export async function getUserRoleById(
  dispatch: typeof store.dispatch,
  id,
) {
    dispatch({ type: SET_IS_LOADING, payload: true });
    let result: any = await fetch(`${ipuUser + apiUrl.getRoleByUser}/${id}`);
    result = await result.json();
    let roleName = [];
    if (result) {
      result.forEach((r) => {
        roleName.push(r.roleId.roleName);
      });
      dispatch({ type: ROLE_NAME, payload: roleName });
    }
    dispatch({ type: SET_IS_LOADING, payload: false });
    return roleName;
  }
export async function createUserValidation(dispatch, data) {
  let error = false;
  let errors = {
    userName: false,
    emailId: false,
    password: false,
    confirmPassword: false,
  };

  if (data.userName == undefined || data.userName == '') {
    errors.userName = true;
    error = true;
  }
  if (data.emailId == undefined || data.emailId == '') {
    errors.emailId = true;
    error = true;
  }
  if (data.password == undefined || data.password == '') {
    errors.password = true;
    error = true;
  }
  if (data.confirmPassword == undefined || data.confirmPassword == '') {
    errors.confirmPassword = true;
    error = true;
  }

  if (error) {
    dispatch({ type: CREATE_USER_VALIDATION, payload: errors });
  }
  return error;
}

export async function removeUsers(dispatch: typeof store.dispatch, data) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(ipuUser + apiUrl.removeNonExistingUser + '/' + data, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: data,
  })
    .then((response) => {
      if (!response.ok) {
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.text();
    })
    .then((data) => {
      getUserList(dispatch);
      dispatch({ type: SET_IS_LOADING, payload: false });
      swal.fire({
        icon: 'success',
        text: 'Data Deleted successfully',
        confirmButtonColor: navyColor,
        confirmButtonText: 'OK',
      });
    });
}

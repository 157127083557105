import {
  ButtonGroup,
  InputAdornment,
  Paper,
  Tab,
  Tabs,
} from "@material-ui/core";

import { MoreHoriz } from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import { GridSelectionModel } from "@mui/x-data-grid";
import * as FileSaver from "file-saver";
import { default as moment, default as Moment } from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import swal from "sweetalert2";
import { Box } from "@mui/system";
import { TabContext, TabPanel } from "@material-ui/lab";
import { PolicyConstants } from "../NewPolicy/PolicyConst";
import XLSX from "xlsx";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  black,
  dangerColor,
  navyColor,
  successColor,
} from "../../assets/jss/material-kit-react";
import CustomButton from "../../components/CustomButtons/CustomButton";
import CustomCheckBox from "../../components/CustomCheckBox/CustomCheckBox";
import CustomInput from "../../components/CustomInput/CustomInput";
import Dialogbox from "../../components/Dialog/DialogBox";
import "../../components/FontFamily/fontFamily.css";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import RadioButton from "../../components/RadioButton/RadioButton";
import AgGrids from "../../components/TableGrid/AgGrids";
import Template from "../../components/Template/Template";
import { PROD } from "../../redux/actions/NewPolicyFormActionTypes";
import {
  getPolicyNumber,
  sendClaimData,
} from "../../redux/actions/TestingReport";
import {
  CLAIM_HEADER_DATA,
  GET_TESTING_REPORT_DATA,
  HISTORY_TEMP_DATA,
  INCLUDE_DB,
  LINE_LEVEL_DATA,
  SEND_CLAIMDATA,
  TEMP_DATA,
  TOTAL_CLAIMS_DATA,
} from "../../redux/actions/TestingReportType";
import {
  intialPolicyData,
  TestingReportState,
} from "../../redux/reducers/TestingReportReducer";
import "../TestingReport/TestingReport.css";
import CustomPaper from "../../components/CustomPaper/CustomPaper";
import { getClientgroupData } from "../../redux/actions/ClientPolicyExclusion";
import TestingReportScenario from "./TestingReportScenario";
import { clientgroupColumns, HistoricColumns } from "./TestingReportColumns";
import { getProductType } from "../../redux/actions";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { NewPolicyState } from "../../redux/reducers/NewPolicyReducer";
import { format, parse } from "date-fns";
const _ = require("lodash");

const TestingReport = () => {
  const [selectedTab, setSelectedTab] = useState("currentLines");

  const [policy, setPolicy] = useState("");
  const [version, setVersion] = useState("");
  const [clientGroup, setClientGroup] = React.useState(null);
  const [clientGroupCode, setClientGroupCode] = React.useState(null);
  const [clientGroupType, setClientGroupType] = useState(undefined);
  const [selectedLkpColumns, setselectedLkpColumns] = useState([]);
  const [selectedLkp, setSelectedLkp] = useState("");
  const [historicdata, sethistoricdata] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [open, setOpen] = React.useState(false);
  const [openLkp, setOpenLkp] = React.useState(false);
  const [numberOfRows, setNumberOfRows] = useState(0);
  const [numberOfHistoricRows, setnumberOfHistoricRows] = useState(0);
  const [importClicked, setImportClicked] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [totaltestingreportdata, settotalreportdata] =
    useState(intialPolicyData);
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);
  const [runAllClicked, setRunAllClicked] = useState(false);
  const [testInclude, setTestInclude] = useState(0);
  const [includeDbHistory, setIncludeDbHistory] = useState(0);
  const [clientRowData, setClientRowData] = useState([]);
  const fullWidth = true;
  const maxWidth = "md";
  const { state } = useLocation();
  const updatedState: TestingReportState = useSelector(
    (state: any) => state.testingReportReducer
  );

  const newPolicyState: NewPolicyState = useSelector(
    (state: any) => state.newPolicy
  );

  const clientState = useSelector((state: any) => state.clientPolicy);

  //@ts-ignore
  const testingReport = state?.testingReport;
  const dispatch = useDispatch();

  const gridIconStyle = useMemo(
    () => ({
      position: "absolute",
      top: "-30px",
      // float: "right",
      // right: "40px",
      // display: "inline",
    }),
    []
  );
  const lkpGridIconStyle = useMemo(
    () => ({
      position: "absolute",
      top: "15px",
      float: "right",
      right: "75px",
      display: "inline",
    }),
    []
  );
  // let memIdError = false;
  // useEffect(() => {
  //   if (selectedType == "all") {
  //     if (totaltestingreportdata.isProdb == undefined) {
  //       updatedState.getTestingReportData.isProdb = 0;
  //     }
  //   }
  // });

  const handleClickToOpen = () => {
    if (selectedType == "single") {
      if (totaltestingreportdata.minAgeFk != null) {
        setOpen(true);
      } else {
        swal.fire({
          icon: "info",
          text: "Policy number does not exist enter correct policy number",
          confirmButtonColor: navyColor,
          confirmButtonText: "OK",
        });
      }
    } else {
      setOpen(true);
    }
  };
  const handleToClose = () => {
    setOpen(false);
    setOpenLkp(false);
    setFileError(false);
    // setCheckboxValues();
  };

  useEffect(() => {
    settotalreportdata(updatedState.getTestingReportData);
  }, [updatedState]);

  useEffect(() => {
    getProductType(dispatch);
  }, []);

  const productTypeCM = newPolicyState.ProductType.map((p) => {
    return { label: p.productTitle, value: p.productKey };
  });

  useEffect(() => {
    if (clientState.getClientExclusion.length == 0) {
      getClientgroupData(dispatch);
    }
  }, []);
  const [resetClicked, setResetClicked] = useState(false);
  const resetInputField = () => {
    sethistoricdata([]);
    setClientGroup("");
    setClientGroupType("");
    setIncludeDbHistory(null);
    setPolicy("");
    setVersion("");
    setTestInclude(null);
    dispatch({ type: GET_TESTING_REPORT_DATA, payload: [] });
    dispatch({ type: TEMP_DATA, payload: [] });
    dispatch({ type: CLAIM_HEADER_DATA, payload: [] });
    dispatch({ type: HISTORY_TEMP_DATA, payload: [] });
    dispatch({ type: LINE_LEVEL_DATA, payload: [] });
    setRunAllClicked(false);
    setImportClicked(false);
    dispatch({ type: TOTAL_CLAIMS_DATA, payload: [] });
  };

  const onFilterChanged = (params) => {
    setNumberOfRows(params.api.getDisplayedRowCount());
    setnumberOfHistoricRows(params.api.getDisplayedRowCount());
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  interface GroupedData {
    [key: string]: {
      start: number;
      end: number;
    };
  }
  function commaSeparatedRefDrgClaimSlId(refList) {
    let claimSlId = [];
    refList.map((ref) => {
      if (ref.drgnClaimSlId != undefined) {
        claimSlId.push(ref.drgnClaimSlId);
      }
    });
    return claimSlId.toString();
  }

  function commaSeparatedRefDrgClaimId(refList) {
    let claimId = [];
    refList.map((ref) => {
      if (ref.drgnClaimid != undefined) {
        claimId.push(ref.drgnClaimid);
      }
    });
    return claimId.toString();
  }

  const claimLinesDetails = () => {
    let recommendationMap = [];
    let testClaimLines = [];
    let editedClaimLines = [];
    let finalClaimLines = [];
    let status = "";
    updatedState.totalClaimsData?.map((t) => {
      status = t.claimStatus;
      t.testClaimLines?.map((ts) => {
        testClaimLines.push(ts);
        ts.ipuRecommendationsList?.map((rec) => {
          recommendationMap.push([{ label: ts.claimSlId, value: rec }]);
        });
      });
    });
    testClaimLines?.map((k, l) => {
      recommendationMap?.map((rec) => {
        rec?.map((t, l) => {
          if (t.label == k.claimSlId) {
            editedClaimLines.push({
              policyId: t.value.policyId,
              recommendedMod1: t.value.modifier1,
              recommendedMod2: t.value.modifier2,
              recommendedMod3: t.value.modifier3,
              recommendedMod4: t.value.modifier4,
              recommendedPercent: t.value.percent,
              policyNumber: t.value.policyNumber,
              policyVersion: t.value.policyVersion,
              challengeCode: t.value.challengeCodeId,
              refDrgnSlId: commaSeparatedRefDrgClaimSlId(
                t.value.ipuRecommendationReferencesList
              ),
              reasonCode: t.value.reasonCode,
              refDrgnClaimId: commaSeparatedRefDrgClaimId(
                t.value.ipuRecommendationReferencesList
              ),
              allowedQuantity: t.value.units,
              cptFrom: k.cptFrom,
              claimSlId: k.claimSlId,
              quantity: k.quantity,
              mod1: k.mod1,
              mod2: k.mod2,
              mod3: k.mod3,
              mod4: k.mod4,
              dx1: k.dx1,
              dx2: k.dx2,
              dx3: k.dx3,
              dx4: k.dx4,
              dosFrom: k.dosFrom,
              dosTo: k.dosTo,
              revenueCode: k.revenueCode,
              totalChargeAmount: k.totalChargeAmount,
              payerAllowedProcedureCode: k.payerAllowedProcedureCode,
              payerAllowedUnits: k.payerAllowedUnits,
              payerAllowedModifier1: k.payerAllowedModifier1,
              payerAllowedModifier2: k.payerAllowedModifier2,
              payerAllowedModifier3: k.payerAllowedModifier3,
              payerAllowedModifier4: k.payerAllowedModifier4,
              hcpAllowedAmount: k.hcpAllowedAmount,
              pos: k.pos,
              renderingProviderNpi: k.renderingProviderNpi,
              renderingTaxonomy: k.renderingTaxonomy,
              claimStatus: status,
              rvuPrice: k.rvuPrice
            });
          }
        });
      });
    });

    editedClaimLines.map((k, l) => {
      updatedState.lineLevelData.map((r, m) => {
        if (r.claimSlId === k.claimSlId) {
          finalClaimLines.push({
            scenarioId: r.scenarioId,
            claimSlId: k.claimSlId,
            cptFrom: k.cptFrom,
            payerAllowedProcedureCode: k.payerAllowedProcedureCode,
            quantity: k.quantity,
            allowedQuantity: k.allowedQuantity,
            payerAllowedUnits: k.payerAllowedUnits,
            mod1: k.mod1,
            payerAllowedModifier1: k.payerAllowedModifier1,
            mod2: k.mod2,
            payerAllowedModifier2: k.payerAllowedModifier2,
            mod3: k.mod3,
            payerAllowedModifier3: k.payerAllowedModifier3,
            mod4: k.mod4,
            payerAllowedModifier4: k.payerAllowedModifier4,
            dx1: k.dx1,
            dx2: k.dx2,
            dx3: k.dx3,
            dx4: k.dx4,
            dosFrom: k.dosFrom,
            dosTo: k.dosTo,
            recommendedMod1: k.recommendedMod1,
            recommendedMod2: k.recommendedMod2,
            recommendedMod3: k.recommendedMod3,
            recommendedMod4: k.recommendedMod4,
            recommendedPercent: k.recommendedPercent,
            challengeCode: k.challengeCode ? k.challengeCode : "",
            reasonCode: k.reasonCode ? k.reasonCode : "",
            policyNumber: k.policyNumber ? k.policyNumber : "",
            policyVersion: k.policyVersion,
            refDrgnSlId: k.refDrgnSlId ? k.refDrgnSlId : "",
            refDrgnClaimId: k.refDrgnClaimId ? k.refDrgnClaimId : "",
            revenueCode: k.revenueCode,
            totalChargeAmount: k.totalChargeAmount,
            hcpAllowedAmount: k.hcpAllowedAmount,
            lineLevelNpi: k.renderingProviderNpi,
            lineLevelPos: k.pos,
            lineLevelTaxonomy: k.renderingTaxonomy,
            claimStatus: k.claimStatus,
            rvuPrice: k.rvuPrice
          });
        }
      });
    });
    updatedState.lineLevelData.map((ts) => {
      if (!finalClaimLines.some((item) => item.claimSlId == ts.claimSlId)) {
        finalClaimLines.push(ts);
      }
    });
    return finalClaimLines.sort((a, b) => a.claimSlId - b.claimSlId);
  };

  const exportToCSV = (historicdata, fileName) => {
    let claimData = claimLinesDetails();

    let dataresult = [];
    updatedState.claimHeaderData.map((p, l) => {
      claimData.map((k, l) => {
        if (p.scenarioId === k.scenarioId) {
          dataresult.push({
            "Claim SL ID": k.claimSlId,
            "Scenario ID": k.scenarioId,
            "Scenario Desc": p.scenarioDesc,
            "Positive": p.postiveData,
            "Claim Form Type": p.claimFormType,
            "Member ID": p.memberId,
            "DOB": p.dob,
            "Gender": p.gender,
            "CPT From": k.cptFrom,
            "Allowed Procedure Code": k.payerAllowedProcedureCode,
            "Qty": k.quantity,
            "Allowed Units": k.payerAllowedUnits,
            "Mod 1": k.mod1,
            "Allowed Mod 1": k.payerAllowedModifier1,
            "Mod 2": k.mod2,
            "Allowed Mod 2": k.payerAllowedModifier2,
            "Mod 3": k.mod3,
            "Allowed Mod 3": k.payerAllowedModifier3,
            "Mod 4": k.mod4,
            "Allowed Mod 4": k.payerAllowedModifier4,
            "Dx 1": k.dx1,
            "Dx 2": k.dx2,
            "Dx 3": k.dx3,
            "Dx 4": k.dx4,
            "DOS From": k.dosFrom,
            "DOS To": k.dosTo,
            "POS": p.pos,
            "Zip Code": p.zipCode,
            "Line Level POS": k.lineLevelPos,
            "NPI": p.npi,
            "Line Level NPI": k.lineLevelNpi,
            "TIN": p.tin,
            "Taxonomy": p.taxonomy,
            "Line Level Taxonomy": k.lineLevelTaxonomy,
            "Condition Code": p.conditionCode,
            "Bill Type": p.billType,
            "Rev Code": k.revenueCode,
            "Diag Codes": p.diagsCodes,
            "Total Charge Amount": k.totalChargeAmount,
            "HCP Allowed Amount": k.hcpAllowedAmount,
            "RVU Price": k.rvuPrice,
            "Allowed Qty": k.allowedQuantity,
            "Recommended Percent": k.recommendedPercent,
            "Challenge Code": k.challengeCode ? k.challengeCode : "",
            "Reason Code": k.reasonCode ? k.reasonCode : "",
            "Policy Number": k.policyNumber ? k.policyNumber : "",
            "Policy Version": k.policyVersion,
            "Ref Drgn SL ID": k.refDrgnSlId ? k.refDrgnSlId : "",
            "Ref Drg Claim ID": k.refDrgnClaimId ? k.refDrgnClaimId : "",
            "Claim Status": k.claimStatus,
            "Rec Mod1": k.recommendedMod1,
            "Rec Mod2": k.recommendedMod2,
            "Rec Mod3": k.recommendedMod3,
            "Rec Mod4": k.recommendedMod4,
          });
        }
      });
    });

    let historicSheetData = [];

    historicdata.map((h, i) => {
      historicSheetData.push({
        "Drgn Patient ID": h.drgnPatientId,
        "Drgn Claim ID": h.drgClaimId,
        "Drgn SL ID": h.drgClaimSlId,
        "Procedure Code": h.procedureCode,
        "Payer Allowed Proc Code": h.payerAllowedProcedureCode,
        "Mod 1": h.modifier1,
        "Payer Allowed Mod 1": h.payerAllowedModifier1,
        "Mod 2": h.modifier2,
        "Payer Allowed Mod 2": h.payerAllowedModifier2,
        "Mod 3": h.modifier3,
        "Payer Allowed Mod 3": h.payerAllowedModifier3,
        "Mod 4": h.modifier4,
        "Payer Allowed Mod 4": h.payerAllowedModifier4,
        "Dx Code 1": h.dxCode1,
        "Dx Code 2": h.dxCode2,
        "Dx Code 3": h.dxCode3,
        "Dx Code 4": h.dxCode4,
        "POS or Type of Bill": h.posOrTypeOfBill,
        "Line Level POS": h.lineLevelPos,
        "Cond Code": h.condCode,
        "Claim Form Type": h.claimFormType,
        "DOS From": h.dosFrom,
        "DOS To": h.dosTo,
        "Total Charge Amount": h.totalChargeAmount,
        "HCP Allowed Amount": h.hcpAllowedAmount,
        "Billing Provider ID": h.billingProviderId,
        "Rendering Provider NPI": h.renderingProviderNpi,
        "Line Level NPI": h.lineLevelNpi,
        "Rendering Taxonomy": h.renderingTaxonomy,
        "Line Level Taxonomy": h.lineLevelTaxonomy,
        "Tax Identifier": h.taxIdentifier,
        "Revenue Code": h.revenueCode,
        "Quantity": h.quantity,
        "Payer Allowed Units": h.payerAllowedUnits,
        "Allowed Quantity": h.allowedQuantity,
      });
    });
    const groupedData = dataresult.reduce((acc, obj) => {
      const key = obj["Claim SL ID"];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});

    // Get the unique keys (column names)
    const keys = Array.from(
      new Set(
        Object.values(groupedData)
          .flat()
          .map((obj) => Object.keys(obj))
          .flat()
      )
    );

    if (selectedType == "single") {
      const ws1 = XLSX.utils.json_to_sheet(
        Object.values(groupedData)
          .flat()
          .map((obj) => {
            const result = {};
            keys.forEach((key) => {
              result[key] = obj[key];
            });
            return result;
          })
      );

      // Merge cells based on the 'claimSlId'  starting from the second row
      let currentRow = 1;
      Object.values(groupedData).forEach((group: any[]) => {
        const start = currentRow;
        const end = start + group.length - 1;

        for (let colIndex = 0; colIndex < 41; colIndex++) {
          ws1["!merges"] = ws1["!merges"] || [];
          ws1["!merges"].push({
            s: { r: start, c: colIndex },
            e: { r: end, c: colIndex },
          });
        }

        currentRow = end + 1;
      });

      const ws2 = XLSX.utils.json_to_sheet(historicSheetData);

      const z = {
        "Policy Number": totaltestingreportdata.policyNumber,
        "Policy Version": totaltestingreportdata.policyVersion,
        "Claim Type": totaltestingreportdata.claimType,
        "Procedure Min Age": totaltestingreportdata.minAgeFk?.minMaxAgeDesc,
        "Procedure Max Age": totaltestingreportdata.maxAgeFk?.minMaxAgeDesc,
        "Prod": totaltestingreportdata.isProdb,
        "Deactivated": totaltestingreportdata.deactivated,
        "Disabled": totaltestingreportdata.disabled,
        "Client Group ID": clientGroup,
        "Client Group Type": clientGroupType.label,
      };

      let sheet3 = [z];
      const ws3 = XLSX.utils.json_to_sheet(sheet3);
      const wb = {
        Sheets: { "Procedures Data": ws1, "Historic Data": ws2, "Policies Data": ws3 },
        SheetNames: ["Procedures Data", "Historic Data", "Policies Data"],
      };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } else {
      const ws1 = XLSX.utils.json_to_sheet(
        Object.values(groupedData)
          .flat()
          .map((obj) => {
            const result = {};
            keys.forEach((key) => {
              result[key] = obj[key];
            });
            return result;
          })
      );

      // Merge cells based on the 'claimSlId'starting from the second row
      let currentRow = 1;
      Object.values(groupedData).forEach((group: any[]) => {
        const start = currentRow;
        const end = start + group.length - 1;

        for (let colIndex = 0; colIndex < 41; colIndex++) {
          ws1["!merges"] = ws1["!merges"] || [];
          ws1["!merges"].push({
            s: { r: start, c: colIndex },
            e: { r: end, c: colIndex },
          });
        }

        currentRow = end + 1;
      });

      const ws2 = XLSX.utils.json_to_sheet(historicSheetData);
      const z = {
        "Client Group ID": clientGroup,
        "Client Group Type": clientGroupType.label,
      };
      let sheet3 = [z];
      const ws3 = XLSX.utils.json_to_sheet(sheet3);
      const wb = {
        Sheets: { "Procedures Data": ws1, "Historic Data": ws2, "Policies Data": ws3 },
        SheetNames: ["Procedures Data", "Historic Data", "Policies Data"],
      };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  const handleUploadFile = (file) => {
    var allowedFiles = [".xlsx", ".csv"];
    var regex = new RegExp(
      "([a-zA-Z0-9s_\\.-:()])+(" + allowedFiles.join("|") + ")$"
    );
    if (file != undefined) {
      if (!regex.test(file.name.toLowerCase())) {
        setOpen(false);
        swal.fire({
          icon: "error",
          text: "Please upload valid file",
          confirmButtonColor: navyColor,
          confirmButtonText: "OK",
        });
        return false;
      } else {
        return true;
      }
    }
  };
  const onSelectionChanged = async (event) => {
 let a = event.api.getSelectedRows();
    if(a.length !== 0){
      setClientGroup(a[0].clientGroupId);
      setClientGroupCode(a[0].clientGroupCode);
      setSelectedCheckbox(a);
    }else{
      setClientGroup("");
      setClientGroupCode("");
      setSelectedCheckbox([]);
    }
  };

  const onGridReady = (data) => {
    data.api.forEachLeafNode((s) => {
      if (s.data?.clientGroupId === clientGroup) {
        s.setSelected(true);
      }
    });
  };
  const [exportedData, setExportedData] = useState([]);

  let checkErrorDetails = false;

  function checkDateDob(dob, idx) {
    if (dob.includes("NaN")) {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        text: "Invalid Date format at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
    return checkErrorDetails;
  }

  function checkDatesDos(dos, dosTo, idx, lines) {
    if (dos.includes("NaN") || dosTo.includes("NaN")) {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: lines,
        text: "Invalid date format at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
    return checkErrorDetails;
  }

  function checkNullForClaimLines(data, idx) {
    if (data.cptFrom == null || data.cptFrom == undefined) {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "Claim Lines",
        text: "Please enter CPT Code at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
    if (data.claimSlId == null || data.claimSlId == undefined) {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "Claim Lines",
        text: "Please enter Claim SL Id at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
    if (data.claimFormType == null || data.claimFormType == undefined) {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "Claim Lines",
        text: "Please enter Claim Form Type at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
    if (data.memberId == null || data.memberId == undefined) {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "Claim Lines",
        text: "Please enter MemberId at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
    if (data.scenarioId == null || data.scenarioId == undefined) {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "Claim Lines",
        text: "Please enter Scenario Id at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }

    if (data.dob == undefined) {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "Claim Lines",
        text: "Please enter DOB at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }

    if (data.gender == null || data.gender == undefined) {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "Claim Lines",
        text: "Please enter Gender at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
    if (data.dosFrom == undefined) {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "Claim Lines",
        text: "Please enter DOS From at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
    if (data.dosTo == undefined) {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "Claim Lines",
        text: "Please enter DOS To at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }

    if (data.pos != null && data.claimFormType == "U") {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "Claim Lines",
        text: "Please change Claim Type to H for Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }

    if (data.billType != null && data.claimFormType == "H") {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "Claim Lines",
        text: "Please change Claim Type to U for Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }

    if (data.pos == null && data.claimFormType == "H") {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "Claim Lines",
        text: "Please enter POS  at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
    if (data.billType == null && data.claimFormType == "U") {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "Claim Lines",
        text: "Please enter Bill Type  at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }

    if (data.tin == null || data.tin == undefined) {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "Claim Lines",
        text: "Please enter TIN at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
    if (data.taxonomy == null || data.taxonomy == undefined) {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "Claim Lines",
        text: "Please enter Taxonomy at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
    if (data.diagsCodes == null || data.diagsCodes == undefined) {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "Claim Lines",
        text: "Please enter Diags Codes at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
    if (data.qty == null || data.qty == undefined) {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "Claim Lines",
        text: "Please enter Quantity at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }

    return checkErrorDetails;
  }

  // validation for history lines

  function checkNullForHistoryLines(data, idx) {
    if (data.drgnPatientId == null || data.drgnPatientId == undefined) {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "History Lines",
        text: "Please enter Patient Id at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }

    if (data.drgClaimId == null || data.drgClaimId == undefined) {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "History Lines",
        text: "Please enter Dragon ClaimId at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
    if (data.drgClaimSlId == null || data.drgClaimSlId == undefined) {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "History Lines",
        text: "Please enter Drg Claim SL Id at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
    if (data.procedureCode == null || data.procedureCode == undefined) {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "History Lines",
        text: "Please enter Procedure Code at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
    if (data.posOrTypeOfBill == null || data.posOrTypeOfBill == undefined) {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "History Lines",
        text: "Please enter POS Or Bill Type at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
    if (data.clmFormType == null || data.clmFormType == undefined) {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "History Lines",
        text: "Please enter Claim Form Type at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
    if (data.dosFrom == null || data.dosFrom == undefined) {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "History Lines",
        text: "Please enter DOS From at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
    if (data.dosTo == null || data.dosTo == undefined) {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "History Lines",
        text: "Please enter DOS To at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
    if (data.billingProviderId == null || data.billingProviderId == undefined) {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "History Lines",
        text: "Please enter Billing Provider Id at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
    if (
      data.renderingProviderNpi == null ||
      data.renderingProviderNpi == undefined
    ) {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "History Lines",
        text: "Please enter Rendering Provider NPI at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
    if (data.renderingTaxonomy == null || data.renderingTaxonomy == undefined) {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "History Lines",
        text: "Please enter Rendering Taxonomy at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
    if (data.taxIdentifier == null || data.taxIdentifier == undefined) {
      checkErrorDetails = true;
      swal.fire({
        icon: "error",
        title: "History Lines",
        text: "Please enter Tax Identifier at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
    return checkErrorDetails;
  }


  const handleUpload12 = () => {
    dispatch({ type: TOTAL_CLAIMS_DATA, payload: [] });
    let validation = handleUploadFile(selectedFile);
    if (validation) {
      var files = selectedFile;
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = e.target.result;
        let readedData = XLSX.read(data, { type: "binary", cellDates: true, dateNF: "mm/dd/yyyy" });

        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];
        const wsname1 = readedData.SheetNames[1];
        const ws1 = readedData.Sheets[wsname1];

        let inputData = [];
        let inputData1 = [];
        inputData = XLSX.utils.sheet_to_json(ws);
        inputData1 = XLSX.utils.sheet_to_json(ws1);
        //@ts-ignore
        let excelRows = XLSX.utils.sheet_to_row_object_array(ws);

        //@ts-ignore
        let excelRows1 = XLSX.utils.sheet_to_row_object_array(ws1);

        for (let i = 1; i < excelRows.length + 1; i++) {
          const cellAddress1: any = { c: 6, r: i };
          const cellAddress2: any = { c: 24, r: i };
          const cellAddress3: any = { c: 25, r: i };
          const cell_ref1: string = XLSX.utils.encode_cell(cellAddress1);
          const cell_ref2: string = XLSX.utils.encode_cell(cellAddress2);
          const cell_ref3: string = XLSX.utils.encode_cell(cellAddress3);
          const cell1: any = ws[cell_ref1];
          const cell2: any = ws[cell_ref2];
          const cell3: any = ws[cell_ref3];

          if (cell1) {
            let value: any = cell1.w;
            const parsedDate: Date = new Date(value);

            let convertedDate =
              parsedDate.getMonth() +
              1 +
              "/" +
              parsedDate.getDate() +
              "/" +
              parsedDate.getFullYear();
            cell1.v = convertedDate;
          }
          if (cell2) {
            let value: any = cell2.w;
            const parsedDate: Date = new Date(value);
            let convertedDate =
              parsedDate.getMonth() +
              1 +
              "/" +
              parsedDate.getDate() +
              "/" +
              parsedDate.getFullYear();
            cell2.v = convertedDate;
          }
          if (cell3) {
            let value: any = cell3.w;
            const parsedDate: Date = new Date(value);
            let convertedDate =
              parsedDate.getMonth() +
              1 +
              "/" +
              parsedDate.getDate() +
              "/" +
              parsedDate.getFullYear();
            cell3.v = convertedDate;
          }
        }
        const dataParse = XLSX.utils.sheet_to_json(ws, {
          header: 1,
          blankrows: false,
        });
        let ReportedData = [];
        dataParse.forEach((d, idx) => {
          // if (idx > 0 && d[0] != null) {
          if (idx >= 1) {
            let obj = {
              claimSlId: d[0],
              scenarioId: d[1],
              scenarioDesc: d[2],
              postiveData: d[3],
              claimFormType: d[4],
              memberId: d[5],
              dob: d[6],
              gender: d[7],
              cptFrom: d[8],
              payerAllowedProcedureCode: d[9],
              qty: d[10],
              payerAllowedUnits: d[11],
              mod1: d[12],
              payerAllowedModifier1: d[13],
              mod2: d[14],
              payerAllowedModifier2: d[15],
              mod3: d[16],
              payerAllowedModifier3: d[17],
              mod4: d[18],
              payerAllowedModifier4: d[19],
              dx1: d[20],
              dx2: d[21],
              dx3: d[22],
              dx4: d[23],
              dosFrom: d[24],
              dosTo: d[25],
              pos: d[26],
              zipCode: d[27],
              lineLevelPos: d[28],
              npi: d[29],
              lineLevelNpi: d[30],
              tin: d[31],
              taxonomy: d[32],
              lineLevelTaxonomy: d[33],
              conditionCode: d[34],
              billType: d[35],
              revenueCode: d[36],
              diagsCodes: d[37],
              totalChargeAmount: d[38],
              hcpAllowedAmount: d[39],
            };
            ReportedData.push(obj);
            checkNullForClaimLines(obj, idx);
            if (d[6]) {
              checkDateDob(d[6], idx);
            }
            let lines = "ClaimLines";
            if (d[24] && d[25]) {
              checkDatesDos(d[24], d[25], idx, lines);
            }
          }
        });
        const groupedById = _.groupBy(ReportedData, "scenarioId");
        const result = Object.values(groupedById);
        const unique = ReportedData.filter((obj, index) => {
          return (
            index ===
            ReportedData.findIndex((o) => obj.scenarioId === o.scenarioId)
          );
        });
        // dispatch({ type: CLAIM_HEADER_DATA, payload: unique });
        // setExportedData(ReportedData);
        // dispatch({ type: TEMP_DATA, payload: result });

        setOpen(false);
        for (let i = 1; i < excelRows1.length + 1; i++) {
          const cellAddress: any = { c: 21, r: i };
          const cellAddress1: any = { c: 22, r: i };
          const cell_ref: string = XLSX.utils.encode_cell(cellAddress);
          const cell_ref1: string = XLSX.utils.encode_cell(cellAddress1);
          const cell: any = ws1[cell_ref];
          const cell1: any = ws1[cell_ref1];
          if (cell) {
            let value: any = cell.w;
            const parsedNewDate: Date = new Date(value);
            let convertedDate =
              parsedNewDate.getMonth() +
              1 +
              "/" +
              parsedNewDate.getDate() +
              "/" +
              parsedNewDate.getFullYear();
            cell.v = convertedDate;
          }
          if (cell1) {
            let value: any = cell1.w;
            const parsedNewDate: Date = new Date(value);
            let convertedDate =
              parsedNewDate.getMonth() +
              1 +
              "/" +
              parsedNewDate.getDate() +
              "/" +
              parsedNewDate.getFullYear();
            cell1.v = convertedDate;
          }
        }
        const dataParse1 = XLSX.utils.sheet_to_json(ws1, {
          header: 1,
          blankrows: false,
        });
        let ReportedData1 = [];
        dataParse1.forEach((d, idx) => {
          // if (idx > 0 && d[0] != null) {
          if (idx >= 1) {
            let obj = {
              drgnPatientId: d[0],
              drgClaimId: d[1],
              drgClaimSlId: d[2],
              // ipuClaimLineId: d[1],
              // refDrgnClaimId: d[2],
              procedureCode: d[3],
              payerAllowedProcedureCode: d[4],
              modifier1: d[5],
              payerAllowedModifier1: d[6],
              modifier2: d[7],
              payerAllowedModifier2: d[8],
              modifier3: d[9],
              payerAllowedModifier3: d[10],
              modifier4: d[11],
              payerAllowedModifier4: d[12],
              dxCode1: d[13],
              dxCode2: d[14],
              dxCode3: d[15],
              dxCode4: d[16],
              // reasonCode: d[12],
              // ipuChallengeCode: d[13],
              posOrTypeOfBill: d[17],
              lineLevelPos: d[18],
              condCode: d[19],
              clmFormType: d[20],
              // ipuClmType: d[16],
              dosFrom: d[21],
              dosTo: d[22],
              // ipuChallengeAmt: d[20],
              // drgnChallengeQty: d[21],
              totalChargeAmount: d[23],
              hcpAllowedAmount: d[24],
              billingProviderId: d[25],
              renderingProviderNpi: d[26],
              lineLevelNpi: d[27],
              renderingTaxonomy: d[28],
              lineLevelTaxonomy: d[29],
              taxIdentifier: d[30],
              revenueCode: d[31],
              quantity: d[32],
              payerAllowedUnits: d[33],
              allowedQuantity: d[34],
            };
            ReportedData1.push(obj);
            checkNullForHistoryLines(obj, idx);
          }
          let lines = "historyLines";
          if (d[21] && d[22]) {
            checkDatesDos(d[21], d[22], idx, lines);
          }
        });
        ReportedData1.forEach((k, l) => {
          k.dosFrom = Moment(k.dosFrom).format("YYYY-MM-DD");
          k.dosTo = Moment(k.dosTo).format("YYYY-MM-DD");
        });

        if (checkErrorDetails == false) {
          dispatch({ type: CLAIM_HEADER_DATA, payload: unique });
          setExportedData(ReportedData);
          dispatch({ type: TEMP_DATA, payload: result });

          dispatch({ type: HISTORY_TEMP_DATA, payload: ReportedData1 });

          sethistoricdata(ReportedData1);
          setnumberOfHistoricRows(ReportedData1.length);
          setOpen(false);
        }
      };
      reader.readAsBinaryString(files);
    }
  };

  const location = useLocation();
  const paths = location.pathname.replaceAll("/", "");

  useEffect(() => {
    if (paths == PolicyConstants.TESTING_REPORT) {
      resetInputField();
    }
  }, []);
  function LineLevelData(Id) {
    let array = [];
    let i = updatedState.tempData.length;
    for (let p = 0; p < i; p++) {
      updatedState.tempData[p].find((k, l) => {
        if (k.scenarioId == Id) {
          array.push({
            claimSlId: k.claimSlId,
            cptFrom: k.cptFrom,
            qty: k.qty,
            mod1: k.mod1,
            mod2: k.mod2,
            mod3: k.mod3,
            mod4: k.mod4,
            dx1: k.dx1,
            dx2: k.dx2,
            dx3: k.dx3,
            dx4: k.dx4,
            dosFrom: k.dosFrom,
            dosTo: k.dosTo,
            quantity: k.qty,
            revenueCode: k.revenueCode,
            totalChargeAmount: k.totalChargeAmount,
            allowedQuantity: k.allowedQuantity,
            payerAllowedProcedureCode: k.payerAllowedProcedureCode,
            payerAllowedUnits: k.payerAllowedUnits,
            payerAllowedModifier1: k.payerAllowedModifier1,
            payerAllowedModifier2: k.payerAllowedModifier2,
            payerAllowedModifier3: k.payerAllowedModifier3,
            payerAllowedModifier4: k.payerAllowedModifier4,
            pos: k.lineLevelPos,
            renderingProviderNpi: k.lineLevelNpi,
            renderingTaxonomy: k.lineLevelTaxonomy,
            hcpAllowedAmount: k.hcpAllowedAmount,
            rvuPrice: k.rvuPrice
          });
        }
      });
    }
    return array;
  }
  useEffect(() => {
    updatedState.totalClaimsData.map((k, l) => {
      k.testClaimLines.map((f, l) => {
        exportedData.map((r, p) => {
          if (r.claimSlId === f.claimSlId) {
            r.policyNumber = f.policyNumber;
            r.policyVersion = f.policyVersion;
            r.challengeCode = f.challengeCode;
            r.refDrgnSlId = f.refDrgnSlId;
            r.reasonCode = f.reasonCode;
            r.refDrgnClaimId = f.refDrgnClaimId;
            r.allowedQuantity = f.allowedQuantity;
          }
        });
      });
    });
  }, [updatedState.totalClaimsData]);

  async function sendClaim() {
    let policyId;
    let claimDataDTO = {};
    if (selectedType == "single") {
      if (policy.length > 0 && version.length > 0) {
        policyId = await getPolicyNumber(
          dispatch,
          Number(policy),
          Number(version)
        );
      }
    }
    let scenLengt = [];
    if (selectedType == "single") {
      // claimDataDTO["policyId"] = updatedState?.getTestingReportData.policyId;
      claimDataDTO["policyId"] = policyId?.policyId;
    }
    let claimDTOList = [];
    let runType = "all";
    updatedState.claimHeaderData.map((k, l) => {
      claimDTOList.push({
        postiveData: k.postiveData,
        scenarioId: k.scenarioId,
        patientGender: k.gender,
        ipuPatientId: k.memberId,
        clmFormType: k.claimFormType,
        taxIdentifier: k.tin,
        renderingProviderNpi: k.npi,
        renderingTaxonomy: k.taxonomy,
        policyId: selectedType == "single" ? policyId?.policyId : null,
        dateOfService: Moment(checkHeaderDosFrom(k.scenarioId)).format(
          "YYYY-MM-DD"
        ),
        diags: k.diagsCodes,
        posLkp: k.pos,
        condCode: k.conditionCode,
        zipCode: k.zipCode,
        patientDateOfBirth: Moment(k.dob).format("YYYY-MM-DD"),
        billType: k.billType,
        scenarioDesc: k.scenarioDesc,
        clientGroupId: clientGroup,
        clientGroupCode : clientGroupCode,
        clientGroupType: clientGroupType.label,
        fileHistoricalClaimLines: updatedState.historicTempData,
        isProdb: testInclude == null ? 0 : testInclude,
        includeDBHistory: includeDbHistory == null ? 0 : includeDbHistory,
        testClaimLines: LineLevelData(k.scenarioId),
      });
    });
    if (clientGroupType == "" || clientGroupType == undefined) {
      swal.fire({
        icon: "info",
        text: "Please Select Client Group Type",
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    } else {
      sendClaimData(dispatch, claimDTOList, scenLengt, runType);
    }
  }

  const checkHeaderDosFrom = (id) => {
    let headerDosFrom = "";
    let dosHeaderDate = [];
    exportedData.filter((k, l) => {
      if (k.scenarioId == id) {
        dosHeaderDate.push({
          dosFrom: k.dosFrom,
        });
      }
    });

    for (let i = 0; i < exportedData.length; i++) {
      dosHeaderDate.filter((k, l) => {
        if (exportedData[i].scenarioId == id) {
          const date1 = new Date(exportedData[i].dosFrom);
          const date12 = new Date(k.dosFrom);
          if (date12 < date1) {
            headerDosFrom = k.dosFrom;
          } else if (headerDosFrom == "") {
            headerDosFrom = k.dosFrom;
          }
        }
      });
    }
    return Moment(headerDosFrom).format("MM-DD-YYYY");
  };
  function stringMethod(e) {
    const re = /^[0-9\b]+$/;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }
  const checkHeight = () => {
    let height;
    if (window.screen.height > 900) {
      height = "630px";
    } else if (window.screen.height > 600) {
      height = "490px";
    }
    return height;
  };
  const checkScroll = () => {
    let scroll;
    if (window.screen.height > 900) {
      if (updatedState.claimHeaderData.length >= 3) {
        scroll = "scroll";
      } else {
        scroll = "hidden";
      }
    } else if (window.screen.height > 600) {
      if (updatedState.claimHeaderData.length > 2) {
        scroll = "scroll";
      } else {
        scroll = "hidden";
      }
    }
    return scroll;
  };
  useEffect(() => {
    let col = Object.assign({}, selectedLkpColumns);
    col = clientgroupColumns;
    setselectedLkpColumns(col);

    let clientExclusion = clientState.getClientExclusion.map((k) => {
      return {
        clientCode: k.clientCode,
        clientGroupId: k.clientGroupId,
        clientGroupName: k.clientGroupName,
        clientGroupCode : k.clientGroupCode
      };
    });
    setClientRowData(clientExclusion);
  }, [clientState]);

  let headerLevelData = {
    policyNumber: policy,
    clientGroup: clientGroup,
    clientGroupCode : clientGroupCode,
    clientGroupType: clientGroupType,
    includeDbHistory: includeDbHistory,
    includeTest: testInclude,
    selectedType: selectedType,
    deactivated: totaltestingreportdata?.deactivated == 1 ? 1 : 0,
    disabled: totaltestingreportdata?.disabled == 1 ? 1 : 0,
    resetClicked: resetClicked,
    runAllClicked: runAllClicked,
    importClicked: importClicked,
    policyVersion: version,
  };
  const [showRun, setShowRun] = useState(false);
  useEffect(() => {
    if (
      selectedType == "single" &&
      policy.length > 0 &&
      version.length > 0 &&
      updatedState.claimHeaderData.length > 0
    ) {
      setShowRun(true);
    } else if (
      selectedType == "all" &&
      updatedState.claimHeaderData.length > 0
    ) {
      setShowRun(true);
    } else {
      setShowRun(false);
    }
  });

  useEffect(() => {
    setRunAllClicked(false);
  }, [selectedType]);

  function checkImportBtn() {
    let btn = true;
    if (selectedType == "single") {
      if (policy.length > 0 && version.length > 0) {
        btn = false;
      } else {
        btn = true;
      }
    } else if (selectedType == "all") {
      btn = false;
    }
    return btn;
  }

  return (
    <Template>
      <h5>Testing Report</h5>
      <GridContainer style={{ position: "relative", bottom: "20px" }}>
        <GridItem sm={4} md={4} xs={4} />
        <GridItem sm={3} md={3} xs={3}>
          <RadioButton
            label={"All Policies"}
            checked={selectedType == "all"}
            onChange={() => {
              setSelectedType("all");
            }}
          />
          <RadioButton
            label={"Single Policy"}
            checked={selectedType == "single"}
            onChange={() => {
              setSelectedCheckbox([]);
              setSelectedType("single");
              // TestingReport();
            }}
          />
        </GridItem>
        <GridItem sm={2} md={2} xs={2}>
          <div className="testButtons">
            {selectedType == "all" || selectedType == "single" ? (
              <a
                target="_blank"
                href={
                  "https://advancedpricing.sharepoint.com/sites/AMPSIPU/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FAMPSIPU%2FShared%20Documents%2FAMPS%20IPU%2FData%20Curation%2FReference%20Templates%2FTesting%20Report%20Template&viewid=303e0f89%2D1795%2D4fa7%2Dafed%2D13c5bb4162a5"
                }
              >
                {"Reference Template"}
              </a>
            ) : undefined}
          </div>
        </GridItem>

        {/* <GridItem sm={2} md={2} xs={2} /> */}
        <GridItem sm={3} md={3} xs={3}>
          <div className="testButtons">
            {selectedType == "all" || selectedType == "single" ? (
              <CustomButton
                variant={"contained"}
                disabled={checkImportBtn()}
                style={{
                  backgroundColor: checkImportBtn() ? "#9CAEA4" : navyColor,
                  color: "white",
                  // margin: 5,
                  padding: 4,
                  fontSize: 12,
                  textTransform: "capitalize",
                }}
                onClick={handleClickToOpen}
                startIcon={
                  <FileUploadIcon
                    style={{
                      fontSize: "14px",
                      position: "relative",
                      left: "1px",
                      marginRight: "0px",
                    }}
                  />
                }
              >
                Import
              </CustomButton>
            ) : undefined}
            {showRun ? (
              <CustomButton
                onClick={() => {
                  sendClaim();
                  setRunAllClicked(true);
                }}
                variant={"contained"}
                style={{
                  backgroundColor: successColor,
                  color: "white",
                  margin: 0,
                  padding: 4,
                  fontSize: 12,
                  marginLeft: 6,
                  textTransform: "capitalize",
                }}
                startIcon={
                  <PlayArrowIcon
                    style={{
                      fontSize: "14px",
                      position: "relative",
                      left: "1px",
                      marginRight: "0px",
                    }}
                  />
                }
              >
                Run All
              </CustomButton>
            ) : undefined}
            {updatedState.claimHeaderData?.length > 0 ? (
              <CustomButton
                onClick={(e) =>
                  exportToCSV(
                    historicdata,
                    selectedType == "single"
                      ? updatedState?.getTestingReportData.policyNumber +
                      "/" +
                      updatedState?.getTestingReportData.policyVersion
                      : "all Policy"
                  )
                }
                variant={"contained"}
                style={{
                  backgroundColor: navyColor,
                  color: "white",
                  padding: 4,
                  fontSize: 12,
                  marginLeft: 6,
                  textTransform: "capitalize",
                }}
                startIcon={
                  <FileDownloadIcon
                    style={{
                      fontSize: "14px",
                      position: "relative",
                      left: "1px",
                      marginRight: "0px",
                    }}
                  />
                }
              >
                Export
              </CustomButton>
            ) : undefined}
            {selectedType == "all" || selectedType == "single" ? (
              <CustomButton
                onClick={() => {
                  resetInputField();
                  setResetClicked(true);
                }}
                variant={"contained"}
                style={{
                  backgroundColor: dangerColor,
                  color: "white",
                  margin: 0,
                  padding: 4,
                  fontSize: 12,
                  marginLeft: 6,
                  textTransform: "capitalize",
                }}
                startIcon={
                  <RestartAltIcon
                    style={{
                      fontSize: "14px",
                      position: "relative",
                      left: "1px",
                      marginRight: "0px",
                    }}
                  />
                }
              >
                Reset
              </CustomButton>
            ) : undefined}
          </div>
        </GridItem>
      </GridContainer>
      {selectedType ? (
        <>
          <CustomPaper
            style={{
              paddingLeft: 28,
              paddingRight: 25,
              boxShadow: "none",
              border: "1px solid #D6D8DA",
              marginRight: "10px",
              width: "100%",
              position: "relative",
              bottom: "8px",
              backgroundColor: "#D8E1E4",
            }}
          >
            <div className="main">
              {selectedType == "single" ? (
                <>
                  <span className="mainChild">
                    <CustomInput
                      fullWidth={true}
                      value={policy}
                      // className="mainChild"
                      type={"text"}
                      maxLength={10}
                      labelText={"Policy#"}
                      onKeyPress={(e) => stringMethod(e)}
                      onChange={(event) => {
                        setPolicy(event.target.value);
                      }}
                      // onBlur={(e) => {
                      //   if (policy.length > 0 && version.length > 0) {
                      //     getPolicyNumber(
                      //       dispatch,
                      //       Number(e.target.value),
                      //       Number(version)
                      //     );
                      //   }
                      //   setVersion(e.target.value);
                      // }}
                      variant={"outlined"}
                    />
                  </span>
                  <span className="mainChild">
                    <CustomInput
                      fullWidth={true}
                      labelText={"Ver"}
                      value={version}
                      type={"text"}
                      onKeyPress={(e) => stringMethod(e)}
                      onChange={(event) => {
                        setVersion(event.target.value);
                      }}
                      onBlur={(e) => {
                        if (policy.length > 0 && version.length > 0) {
                          getPolicyNumber(
                            dispatch,
                            Number(policy),
                            Number(e.target.value)
                          );
                        }
                        setVersion(e.target.value);
                      }}
                      variant={"outlined"}
                    />
                  </span>
                  {totaltestingreportdata?.minAgeFk?.minMaxAgeDesc ? (
                    <>
                      <span className="mainChild">
                        <CustomInput
                          fullWidth={true}
                          labelText={"CLM TYPE"}
                          disabled
                          value={totaltestingreportdata?.claimType}
                          variant={"outlined"}
                        />
                      </span>
                      <span className="mainChildMin">
                        <CustomInput
                          fullWidth={true}
                          labelText={"Min Age"}
                          variant={"outlined"}
                          disabled
                          value={
                            totaltestingreportdata?.minAgeFk?.minMaxAgeDesc
                          }
                        />
                      </span>
                      <span className="mainChildMin">
                        <CustomInput
                          fullWidth={true}
                          labelText={"Max Age"}
                          disabled
                          value={
                            totaltestingreportdata?.maxAgeFk?.minMaxAgeDesc
                          }
                          variant={"outlined"}
                        />
                      </span>

                      <CustomCheckBox
                        checked={totaltestingreportdata.isProdb == 1}
                        disabled={true}
                        style={{ marginTop: "20px" }}
                        onChange={(event) => {
                          updatedState.getTestingReportData.isProdb = event
                            .target.checked
                            ? 1
                            : 0;
                        }}
                        label={<span style={{ fontSize: "12px" }}>Prod</span>}
                      />
                      <CustomCheckBox
                        checked={totaltestingreportdata?.deactivated == 1}
                        disabled={true}
                        style={{ marginTop: "20px" }}
                        onChange={(event) => {
                          updatedState.getTestingReportData.deactivated = event
                            .target.checked
                            ? 1
                            : 0;
                        }}
                        label={
                          <span style={{ fontSize: "12px" }}>Deactivated</span>
                        }
                      />
                      <CustomCheckBox
                        checked={totaltestingreportdata?.disabled == 1}
                        disabled={true}
                        style={{ marginTop: "20px" }}
                        onChange={(event) => {
                          updatedState.getTestingReportData.disabled = event
                            .target.checked
                            ? 1
                            : 0;
                        }}
                        label={
                          <span style={{ fontSize: "12px" }}>Disabled</span>
                        }
                      />
                    </>
                  ) : undefined}
                </>
              ) : undefined}

              <span className="mainChildMin">
                <CustomSelect
                  labelText={"Client Group Type"}
                  onSelect={(event) => {
                    if (event != null) {
                      setClientGroupType(event);
                    } else {
                      setClientGroupType("");
                    }
                  }}
                  value={clientGroupType}
                  options={productTypeCM}
                />
              </span>
              <span className="mainChildMin">
                <CustomInput
                  fullWidth={true}
                  labelText={"Client Group Id"}
                  variant={"outlined"}
                  title={"Client Group ID"}
                  value={clientGroup}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      onClick={() => {
                        setOpenLkp(true);
                        setSelectedLkp("Client Group ID");
                      }}
                    >
                      <MoreHoriz
                        style={{
                          cursor: "pointer",
                          fontSize: 15,
                          color: black,
                        }}
                      />
                    </InputAdornment>
                  }
                />
              </span>
              <span className="checkBtn">
                <CustomCheckBox
                  checked={includeDbHistory == 1}
                  value={includeDbHistory}
                  size="small"
                  style={{ position: "relative", bottom: "6%" }}
                  label={
                    <p style={{ fontSize: "12px", display: "table-cell" }}>
                      Include Dragon History
                    </p>
                  }
                  onChange={(event) => {
                    updatedState.includeDB = event.target.checked ? 1 : 0;
                    dispatch({
                      type: INCLUDE_DB,
                      payload: event.target.checked ? 1 : 0,
                    });
                    setIncludeDbHistory(event.target.checked ? 1 : 0);
                  }}
                />
              </span>
              {selectedType == "all" ? (
                <span className="checkBtn">
                  <CustomCheckBox
                    checked={testInclude == 1}
                    value={testInclude}
                    size="small"
                    style={{ position: "relative", bottom: "6%" }}
                    label={
                      <p style={{ fontSize: "12px", display: "table-cell" }}>
                        Include Mock Test
                      </p>
                    }
                    onChange={(event) => {
                      updatedState.getTestingReportData.isProdb = event.target
                        .checked
                        ? 1
                        : 0;
                      dispatch({
                        type: PROD,
                        payload: event.target.checked ? 1 : 0,
                      });
                      setTestInclude(event.target.checked ? 1 : 0);
                    }}
                  />
                </span>
              ) : undefined}
            </div>
          </CustomPaper>
          <div className="testingTabs">
            <Box sx={{ width: "100%" }}>
              {updatedState.claimHeaderData?.length > 0 ? (
                <TabContext value={selectedTab}>
                  <Tabs
                    onChange={(value, newValue) => {
                      setSelectedTab(newValue);
                    }}
                    style={{
                      backgroundColor: navyColor,
                      minHeight: "5px",
                      height: "26px",
                      marginTop: "1%",
                      position: "relative",
                      top: "3px",
                      borderRadius: "20px",
                    }}
                    value={selectedTab}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      className="desc"
                      style={{
                        minHeight: "3px",
                        minWidth: "50%",
                        color:
                          selectedTab == PolicyConstants.CURRENT_LINES
                            ? "black"
                            : "white",
                        backgroundColor:
                          selectedTab == PolicyConstants.CURRENT_LINES
                            ? "#B7C9CD"
                            : navyColor,
                        textTransform: "capitalize",
                        fontSize: 12,
                        marginTop: -4,
                      }}
                      label={<span>Current Lines </span>}
                      value={"currentLines"}
                    />
                    <Tab
                      className="desc"
                      style={{
                        minHeight: "3px",
                        minWidth: "50%",
                        color:
                          selectedTab == PolicyConstants.HISTORY_LINES
                            ? "black"
                            : "white",
                        backgroundColor:
                          selectedTab == PolicyConstants.HISTORY_LINES
                            ? "#B7C9CD"
                            : navyColor,
                        textTransform: "capitalize",
                        fontSize: 12,
                        marginTop: -4,
                      }}
                      label={<span>History Lines </span>}
                      value={"historyLines"}
                      onClick={() =>
                        setnumberOfHistoricRows(historicdata.length)
                      }
                    />
                  </Tabs>
                  <div>
                    <TabPanel
                      style={{
                        overflowY: checkScroll(),
                        height: checkHeight(),
                      }}
                      value="currentLines"
                    >
                      <TestingReportScenario {...headerLevelData} />
                    </TabPanel>
                    <TabPanel value="historyLines">
                      {historicdata.length > 0 ? (
                        <div
                          style={{
                            position: "relative",
                            top: "10px",
                            height:
                              selectedType == "all"
                                ? window.innerHeight / 2.2
                                : window.innerHeight / 2.6,
                          }}
                        >
                          <>
                            <AgGrids
                              rowData={historicdata}
                              columnDefs={HistoricColumns}
                              onGridReady={onGridReady}
                              onFilterChanged={onFilterChanged}
                              gridIconStyle={gridIconStyle}
                            />
                            <small
                              style={{
                                position: "relative",
                                top: "5px",
                                fontSize: "12px",
                              }}
                            >
                              Number of rows : {numberOfHistoricRows}
                            </small>
                          </>
                        </div>
                      ) : undefined}
                    </TabPanel>
                  </div>
                </TabContext>
              ) : undefined}
            </Box>
          </div>
        </>
      ) : undefined}

      <Dialogbox
        open={open}
        onClose={handleToClose}
        title={"Upload File"}
        message={
          <div>
            <input
              type="file"
              onChange={(e) => {
                let file = e.target.files[0];
                setSelectedFile(file);
                let flag = handleUploadFile(e.target.files[0]);
                setFileError(flag);
              }}
            />
          </div>
        }
        actions={
          <ButtonGroup>
            {fileError ? (
              <CustomButton
                onClick={() => {
                  handleToClose();
                  handleUpload12();
                  setResetClicked(false);
                  setRunAllClicked(false);
                  setImportClicked(true);
                }}
                style={{
                  backgroundColor: navyColor,
                  color: "white",
                  padding: 4,
                  fontSize: 12,
                  textTransform: "capitalize",
                  marginRight: 10,
                }}
              >
                Yes
              </CustomButton>
            ) : undefined}
            <CustomButton
              onClick={handleToClose}
              style={{
                backgroundColor: dangerColor,
                color: "white",
                padding: 4,
                fontSize: 12,
                textTransform: "capitalize",
              }}
            >
              No
            </CustomButton>
          </ButtonGroup>
        }
      />

      <Dialogbox
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        disableBackdropClick={true}
        open={openLkp}
        onClose={handleToClose}
        title={selectedLkp}
        message={
          <div style={{ height: window.innerHeight / 1.8, marginTop: "30px" }}>
            <AgGrids
              rowData={clientRowData}
              columnDefs={selectedLkpColumns}
              rowSelection={"single"}
              onSelectionChanged={onSelectionChanged}
              onGridReady={onGridReady}
              gridIconStyle={lkpGridIconStyle}
            />
          </div>
        }
        actions={
          <ButtonGroup style={{ marginTop: "-50px" }}>
            <CustomButton
              variant={"contained"}
              onClick={() => {
                handleToClose();
              }}
              onChange={(event) => { }}
              style={{
                backgroundColor: navyColor,
                color: "white",
                margin: 10,
                padding: 4,
                fontSize: 12,
                textTransform: "capitalize",
              }}
            >
              Yes
            </CustomButton>
            <CustomButton
              onClick={() => {
                handleToClose();
                setClientGroup("");
              }}
              variant={"contained"}
              style={{
                backgroundColor: dangerColor,
                color: "white",
                margin: 10,
                padding: 4,
                fontSize: 12,
                textTransform: "capitalize",
              }}
            >
              No
            </CustomButton>
          </ButtonGroup>
        }
      />
    </Template>
  );
};
export default TestingReport;

import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../components/CustomInput/CustomInput";
import "../../components/FontFamily/fontFamily.css";
import {
  fetchLookupData, postLookupData,
} from "../../redux/actions/LookupsActions";
import { LookUpState } from "../../redux/reducers/LookUpReducer";
import { TaskState } from "../../redux/reducers/TaskReducer";
import "../LookUps/LookUp.css";
import DailogBoxForAddandEdit from "./DailogBoxForAddandEdit";
import { MaxUnitsLookUpInitialState } from "./LookUpInitialState";
import { LookUpColumns } from "./LookupColumns";
import { MAX_UNITS_LKP } from "./LookUpConsts";

const MaxUnitsLookup = (props) => {
  const dispatch = useDispatch();
  const selectedLookup = props.lkpInput.selectedLookup;
  const Role = props.lkpInput.Role;
  const popUp = props.lkpInput.openLkp;
  const fromLkpChilds = props.lkpInput.fromLkpchilds;
  const [rows, setRows] = useState([]);
  const updatedState: LookUpState = useSelector(
    (state: any) => state.lookupReducer
  );
  const [saveLkpValues, setSaveLkpValues] = useState(
    MaxUnitsLookUpInitialState
  );
  const [isEdit, setIsEdit] = useState(false);
  const [isCodeError, setIsCodeError] = useState(false);

  useEffect(() => {
    if (updatedState.MaxUnits.length == 0) {
      let lkpName = MAX_UNITS_LKP;
      fetchLookupData(dispatch,lkpName);
    }
  }, []);
  function stringMethod(e) {
    const re = /^[0-9\b\-]+$/;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  function saveLkpFields() {
    let obj = {};
    Object.entries(saveLkpValues).forEach(([key, val]) => (obj[key] = val));
    let lkpName = MAX_UNITS_LKP;
    postLookupData(dispatch,obj,isEdit,lkpName);
    setSaveLkpValues(MaxUnitsLookUpInitialState);
  }

  const fromLkpEditchilds = (msg) => {
    setIsEdit(msg);
  };

  const resetFields = (resetField) => {
    setSaveLkpValues(MaxUnitsLookUpInitialState);
    setIsCodeError(false);
  };

  function showLKPFields() {
    return (
      <div className="row">
        <div className="col-sm-4"></div>
        <CustomInput
          fullWidth={true}
          labelText={"Max Units LookUp Key"}
          maxLength={5}
          onKeyPress={(e) => {
            stringMethod(e);
          }}
          variant={"outlined"}
          onChange={(e) => {
            let obj = _.cloneDeep(saveLkpValues);
            if (e != undefined) {
              let code = rows.filter((sc, i) => {
                return sc.maxUnitsLkpKey == e.target.value.replace(/^\s+/, "");
              });
              obj.maxUnitsLkpKey = e.target.value;
              if (code.length>0) {
                setIsCodeError(true);
              } else {
                setSaveLkpValues(obj);
                setIsCodeError(false);
              }
            }
          }}
          disabled={isEdit}
          value={isEdit ? saveLkpValues.maxUnitsLkpKey : undefined}
        />
        {isCodeError ? (
          <small style={{ color: "red" }}>Max Units Key already Exists</small>
        ) : undefined}
        <CustomInput
          fullWidth={true}
          labelText={"Description"}
          variant={"outlined"}
          onChange={(e) => {
            let obj = _.cloneDeep(saveLkpValues);
            obj.description = e.target.value.replace(/^\s+/, "");
            setSaveLkpValues(obj);
          }}
          value={saveLkpValues.description}
        />
        <CustomInput
          fullWidth={true}
          labelText={"Max Units Type Key"}
          maxLength={5}
          variant={"outlined"}
          onChange={(e) => {
            let obj = _.cloneDeep(saveLkpValues);
            obj.maxUnitsTypeKey = e.target.value;
            setSaveLkpValues(obj);
            setIsCodeError(false);
          }}
          value={saveLkpValues.maxUnitsTypeKey}
        />
        <CustomInput
          fullWidth={true}
          labelText={"Comments"}
          variant={"outlined"}
          onChange={(e) => {
            let obj = _.cloneDeep(saveLkpValues);
            obj.comments = e.target.value.replace(/^\s+/, "");
            setSaveLkpValues(obj);
          }}
          value={saveLkpValues.comments}
        />
      </div>
    );
  }

  const handleUpdateObj = (updatedObj, edit) => {
    setIsEdit(edit);
    setSaveLkpValues(updatedObj);
  };

  useEffect(() => {

          setTimeout(() => {
            let col = LookUpColumns(
              selectedLookup,
              saveLkpValues,
              handleUpdateObj,
              Role
            );
            props.lkpInput.allLookUpColumns(col);
            let maxUnitsRows = updatedState.MaxUnits?.map((mx, i) => {
              return {
                id: i,
                maxUnitsLkpKey: mx.maxUnitsLkpKey,
                maxUnitsTypeKey: mx.maxUnitsTypeKey,
                description: mx.description,
                comments: mx.comments,
              };
            });
            props.lkpInput.allLookUpRowData(maxUnitsRows);
            setRows(maxUnitsRows)
          }, 10);
  }, [updatedState]);

  return (
    <div>
      <DailogBoxForAddandEdit
        lkpInput={{
          selectedLookup,
          popUp,
          fromLkpChilds,
          showLKPFields,
          saveLkpFields,
          resetFields,
          isEdit,
          fromLkpEditchilds,
          saveLkpValues,
          isCodeError
        }}
      />
    </div>
  );
};

export default MaxUnitsLookup;

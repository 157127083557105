import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { NewPolicyFormState } from "../../redux/reducers/NewPolicyFormReducer";
import { NewPolicyState } from "../../redux/reducers/NewPolicyReducer";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { MODIFIER_PRIORITY_LKP } from "../LookUps/LookUpConsts";
import StarIcon from '@mui/icons-material/Star';

const CategoryFourtyNine = ({ edit, viewMode }) => {
    const formState: NewPolicyFormState = useSelector(
        (state: any) => state.newPolicyForm
    );
    const updatedState: NewPolicyState = useSelector(
        (state: any) => state.newPolicy
    );

    const dispatch = useDispatch();
    const [modifierPriorityData, setModifierPriorityData] = useState([]);
    const _ = require("lodash");

    const modifierPriorityOptions = [
        { value: 1, label: "Modifier Priority Lookup" },
    ];

    return (
        <div>
            <GridContainer>
                <GridItem sm={2} md={2} xs={2}>
                    <small
                        style={{
                            fontSize: 13,
                            color: "black",
                            fontWeight: 400,
                            position: "relative",
                            top: 15,
                        }}
                    >
                        Apply Modifier Priority  {<StarIcon style={{position:"relative", bottom:"4px",fontSize:"7px"}}/>}
                    </small>
                </GridItem>
                <GridItem sm={4} md={4} xs={4}>
                    <div className="modifierPriority">
                        <CustomSelect
                            error={formState.errors?.modifierPriority}
                            options={modifierPriorityOptions}
                            value={modifierPriorityOptions.filter(function (option) {
                                return option.value == formState.modifierPriority;
                            })}
                            isDisabled={!edit ? undefined : viewMode}
                            catTitle={modifierPriorityOptions.filter((option, index) => {
                                if (formState.modifierPriority == option.value) {
                                    return option
                                }
                            })}
                            onSelect={(event) => {
                                setModifierPriorityData(event);
                                dispatch({
                                    type: MODIFIER_PRIORITY_LKP,
                                    payload: event.value,
                                });

                            }}
                        />
                    </div>

                </GridItem>

            </GridContainer>
        </div>
    );
};
export default CategoryFourtyNine;


import { environment } from '../environments/environment';

export const policyConfigUrl = environment.ipu_policy_config_host;
export const dataCurationETL = environment.ipu_data_curation_host;
export const dataCrawler = environment.ipu_data_crawler_host;
export const policyengine = environment.ipu_policy_engine_host;
export const ipuUser = environment.ipu_user_host;
export const emailUrl = environment.ipu_email_host;

export const apiUrl = {
  generateRbrvsReport1: '/dragonManual/generateRBRVSReport1',
  generateRbrvsReport2: '/dragonManual/generateRBRVSReport2',
  generateVaccinationCodes: '/dragonManual/generateVaccinationCodes',
  getTotalNumberOfRowsFromSource:'/search/getTotalNumberOfReferrentialDataRows',
  production: false,
  getChallengeCode: '/policy/challengeCode',
  searchClaim: '/searchClaim/searchClaimData',
  claimRunStatus: '/searchClaim/getClaimRunStatus',
  sendclaimdata: '/policy/runPoliciesOnTestClaim',
  refreshChallengeCache: '/policy/refreshChallengeCodeCache',
  refreshPolicyCache: '/policy/refreshPolicyCache',
  updatePassword: '/users/updatePassword',
  getMfsQuarter: '/mfs/Quarter',
  userById: '/users/getUserById/',
  getUser: '/users/searchUser',
  removeNonExistingUser: '/users/updateNonExistingUser',
  saveCustomPolicyData: '/newPolicy/saveCustomPolicyTabsData',
  getRoles: '/users/getRoles',
  getAllUser: '/users/getAllUsers',
  saveUser: '/users/saveUser',
  updateUser: '/users/updateUser',
  checkUser: '/users/validateUser',
  getRoleByUser: '/users/getUserByRole',
  getRoleById: '/users/getRolesData',
  searchPolicy: '/searchPolicy/policyData',
  getPolicyById: '/newPolicy/policy',
  getMedicalpolicy: '/newPolicy/medicalpolicy',
  getSubPolicy: '/newPolicy/subpolicies',
  getMedicalData: '/newPolicy/Medical',
  getProcs: '/procedures/procedures',
  getDiagnosis: '/Diagnosis/Totaldata',
  postDiagnosis: '/Diagnosis/postDiagnosis',
  editDiagnosis: '/Diagnosis/updateDiagnosis',
  updateHeaderLevelPdx: '/Diagnosis/update',
  deleteDiagnosis: '/Diagnosis/deleteDiagnosis',
  updateHeaderLevelPdxRow: '/Diagnosis/UpdateHeaderLevelPdxRow',
  getActionValue: '/Diagnosis/getActionName',

  getAction: '/procedures/action',
  getChanges: '/Changes/changes',
  getChangesId: '/Changes/GetChanges',
  getProps: '/newPolicy/properties',
  createDescription: '/newPolicy/desc',
  createChanges: '/Changes/savechanges',
  customChanges: '/Changes/saveCustomChanges',

  createProps: '/newPolicy/uploadprop',
  createProperties: '/newPolicy/prop',
  createnewPolicy: '/newPolicy/postNewPolicy',
  getLob: '/newPolicy/lob',
  getProductType: '/newPolicy/producttype',
  getproductData: '/newPolicy/product',
  getProcsById: '/procedures/procedureId',
  getClmLinkLkp: '/procedures/clmLinkLkp',
  getPoliyCptActionLkp: '/procedures/policyCptActionLkp',
  getValid: '/newPolicy/policyValidation',
  getChangesById: '/Changes/GetChangesById',
  testReport: '/newPolicy/policyNumber',
  getDxExcelData: '/Diagnosis/dxExportId',
  createTargetUrl: '/procedures/stagetotarget',
  updateNewPolicy: '/newPolicy/updateNewPolicies',
  updateChanges: '/Changes/updateChanges',
  createProcs: '/procedures/upload',
  saveDaiagnosis: '/Diagnosis/upload',
  postConfiguration: '/configvalidation/generateconfigreport',
  generateconfigreport: '/configvalidation/generateconfigreport',
  generateConfigValidationReportForSingleRule:
    '/configvalidation/generatereport',

  // Target Load

  loadCCIDataToTarget: '/cci/loadCCIToTarget',
  loadMaxUnitsDataToTarget: '/dmuv/loadMaxUnitsToTarget',
  loadIcdMasterAndDetailTarget: '/icd/loadIcdMasterAndDetailToTarget',
  loadIcdDetailTarget: '/icd/loadIcdDetailToTarget',
  HcpcsDetailTarget: '/loadHCPCSDetailToTarget',
  loadHcpcsDataToTarget: '/hcpcs/loadHCPCSMasterAndDetailToTarget',
  CptMasterAndDetailTarget: '/cpt/loadCptMasterAndDetailToTarget',
  icd10CmTarget: '/dragonManual/targetLoadICDCMd  ',
  rbRvsTargetLoad: '/dragonManual/targetLoadRBRVS ',
  icd10PCSTarget: '/dragonManual/targetLoadICDPCSd',
  loadMFSDataintoTarget: '/mfs',
  mfsTarget: '/loadMFSToTarget',
  loadMFSDateBIndedDataintoTarget: '/loadMFSDateBindedToTarget',
  loadAdhocintoTarget: '/adhoc',
  adhocTarget: '/loadAdhocToTarget',
  loadAddonDataintoTarget: '/addoncodes',
  loadOCEDataintoTarget: '/oce',
  addonTarget: '/loadADDONCODESToTarget',
  apcTarget: '/loadAPCToTarget',
  capcTarget: '/loadCAPCToTarget',
  hcpcsTarget: '/loadHCPCSToTarget',
  apcDateBindedDataIntoTarget: '/loadAPCDateBindedToTarget',
  capcDateBindedDataIntoTarget: '/loadCAPCDateBindedToTarget',
  hcpcsDateBindedDataIntoTarget: '/loadHCPCSDateBindedToTarget',
  loadGPCIDataIntoTarget: '/gpci',
  GPCITarget: '/loadGPCIToTarget',
  GPCIDateBindedTarget: '/loadGPCIDateBindedToTarget',
  loadZipDataIntoTarget: '/zipLoader',
  Zip5Target: '/loadZip5ToTarget',
  Zip9Target: '/loadZip9ToTarget',
  Zip5DateBindedTarget: '/loadZip5DateBindedToTarget',
  Zip9DateBindedTarget: '/loadZip9DateBindedToTarget',
  loadCptSameOrSimiliarCodes : '/cptSameOrSimilarCodes',
  loadCptSameOrSimCodeDataToTarget:'/loadCptSameOrSimCodes',



  groupTask: '/tasks/groupTask',
  TotalData: '/tasks/Totaldata',
  Delta: '/tasks/deltaTask',
  update: '/tasks/update',
  updateMyTask: '/tasks/updateMyTask',
  updateTaskStatus: '/tasks/updatetaskstatus',
  errorStatus: '/tasks/completedTask',
 
  myTask: '/tasks/email',
  //curation service
  getQuarter: '/lookUp/getQuarterName',
  // crawler service
  getCrawlerClass: '/lookUp/getCrawlerClassLookUp',
  getFrequency: '/lookUp/getFrequencyLookup',


  getGender: '/newPolicy/gender',
  getNpi: '/newPolicy/npilink',
  getPosLink: '/newPolicy/posLink',
  getCptLink: '/newPolicy/cptLink',

  getRevenueCodeClaimLink: '/newPolicy/revenueCodeClaimLink',
  getTaxlink: '/newPolicy/taxlink',
  getPropSubSpec: '/newPolicy/taxonomy',
  getConfig: '/crawlerConfig/getAllConfig',


  uploadLookup: '/lookUp/upload',
  saveConfig: '/crawlerConfig/saveConfig',
  updateConfig: '/crawlerConfig/updateConfig',
  runCrawler: '/crawler/crawl',
  getAddOnCodes: '/newPolicy/addOnCodes',
  getBwType: '/bwPairs/BwLkp',
  getChangeModifier: '/newPolicy/changeModifier',
  getClaimType: '/newPolicy/claimType',
  loadBwPairsDataIntoTarget: '/bwPairs',
  bwPairsTarget: '/loadBwPairsToTarget',
  loadModifierInteractionDataIntoTarget: '/modifierInteraction',
  ModifierInteractionTarget: '/loadModifierInteractionToTarget',
 

// look ups
  getLookUpData:'/lookUp/getLookUpData',
  postLookUpdata:'/lookUp/postLookUpData',

  // get referential data
  getReferentialDataDetails:'/referentialData/getReferentialDataDetails',
  viewClientSpecficCodesData:'/clientspecificcodes/clientSpecificCodesReferentialData',


  getMaxUnitsLkpData: '/dmuv/getMaxUnitsLkp',
  getModifierIntractionLkpData: '/dmuv/getModifierIntractionLkp',
  getMaiLkpData: '/dmuv/getMaiLkp',
  getMueLkpData: '/dmuv/getMueLkp',


  getCurationVersion: '/lookUp/getCurationPOMVersion',
  getCrawlerVersion: '/lookUp/getCrawlerPOMVersion',
  getConfigVersion: '/lookUp/getConfigPOMVersion',
  getEngineVersion: '/policy/getEnginePOMVersion',
  getEmailVersion: '/email/getEmailPOMVersion',


  uploadManualFile: '/manual/uploadFile',
  uploadPolicyReportFile: '/Report/uploadData',
  getLastQuater: '/lookUp/sourceValue',

  uploadRBRVS: '/dragonManual/uploadRBRVS',
  uploadICD10CMD: '/dragonManual/uploadICD10CM',
  uploadICD10PCS: '/dragonManual/uploadICD10PCS',
  uploadVaccinationCodes: '/dragonManual/uploadVaccinationCodes',

  getByDragonClaimId: '/policy/getitemizedBillLine',
  getPolicyClaim: '/policy/getPolicyClaim',
  getReferenceClaim: '/policy/getPolicyReferenceClaim',
  getReferenceDragonClaimId: '/policy/getReferenceitemizedBillLine',
  searchClaimDataSize: '/searchClaim/searchClaimDataSize',
  lobData: '/newPolicy/Lob',


  medicalTotal: '/policyView/medical',

  subTotal: '/policyView',

  DetailsData: '/newPolicy/DetailsData',

  reasonTree: '/newPolicy/reasonCodeCount',
  policyIdFromReasonCode: '/newPolicy/policyId',

  subPolicyTotal: '/policyView/subPolicyData',

  reasonTotal: '/policyView/reasonCodeData',
  policyTotalData: '/policyView/totalPolicyData',
  usedCat: '/policyView/categoryData',
  policyViewData: '/policyView/policyViewData',

  getBillTypeKey: '/BillType/getBillTypeLinkLkp',
  getPolicyBillType: '/BillType/BillTypeData',
  getSourceBillTypeLkpData: '/BillType/getBillTypeLookUpData',
  postBillTypeData: '/BillType/postBillType',
  deleteBillTypeData: '/BillType/deleteData',
  updateBillTypeFk: '/BillType/updatebilltypefk',
  totalPolicyBillType: '/BillType/totalbilltypedata',
  getPolicyBillTypeActionLkp: '/BillType/getpolicybilltypeactionlkp',
  updateBillTypeAction: '/BillType/updatebilltypeaction',
  saveBillType: '/BillType/upload',


  modiferPayPercentageKey: '/lookUp/getModifierPayPercent',
  policyExclusion: '/clientPolicyExclusion/policyExclusion',
  clientExclusion: '/clientPolicyExclusion/clientExclusion',
  clientPolicyExcluison: '/clientPolicyExclusion/clientPolicyData',
  postClientPolicyExclusion: '/clientPolicyExclusion/saveExclusion',
  deleteClientPolicyExclusion: '/clientPolicyExclusion/deleteExclusion',

  clientAssignmet:'/clientAssignment/getClientAssignmentData',

  filterSubPol: '/policyView/filterSubPol',
  filterReason: '/policyView/filterReason',
  filterdPolicies: '/policyView/filterPolicies',
  ExportPolicyView: '/policyView/exportPolicyData',
  uploadPolicyView: '/policyView/uploadFile',

//new client set up
  saveNewClientSetUpData:'/clientAssignment/saveNewClientData',
  getTotalClientAssignmentData:'/clientAssignment/clientAssignmentData',


  getConditionTypeData: '/condition/actionLookUp',
  getPolicyConditionType: '/condition/conditionTypeData',
  postConditionTypeData: '/condition/postConditionType',
  deleteConditionTypeData: '/condition/deleteData',

  postClientAssignmentData: '/clientAssignment/postClientAssignment',
  editClientAssignmentData: '/clientAssignment/editClientAssignment',
  deleteClientAssignmentData: '/clientAssignment/deleteClientAssignment',
  activeClients:'/clientAssignment/getActiveClientGroups',
};

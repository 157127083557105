import swal from 'sweetalert2';
import { navyColor } from '../../assets/jss/material-kit-react';
import { apiUrl, dataCrawler, dataCurationETL } from '../../configs/apiUrls';
import { store } from '../store';
import { SET_IS_LOADING } from './actionTypes';
import {
  CRAWLER_VALIDATION,
  GET_ALL_CRAWLER_DETAILS,
  GET_CRAWLER,
  GET_CRAWLER_CLASS,
  GET_FREQUENCY,
  RUN_CRAWLER,
  SAVE_CONFIG
} from './CrawlerActionTypes';
export async function errorValidation(dispatch, data) {
  let error = false;
  let errors = {
    name: false,
    url: false,
    xpaths: false,
    xpathDelimeter: false,
    frequency: false,
    lastPublishedDate: false,
    crawlerClass: false,
    emailIds: false,
  };
  if (data.name == undefined || data.name == '') {
    errors.name = true;
    error = true;
  }
  if (data.url == undefined || data.url == '') {
    errors.url = true;
    error = true;
  }
  if (data.xpaths == undefined || data.xpaths == '') {
    errors.xpaths = true;
    error = true;
  }
  if (data.xpathDelimiter == undefined || data.xpathDelimiter == '') {
    errors.xpathDelimeter = true;
    error = true;
  }
  if (data.frequency == undefined || data.frequency == '') {
    errors.frequency = true;
    error = true;
  }
  if (data.lastPublishedDate == null || data.lastPublishedDate == '' || data.lastPublishedDate == 'Invalid date') {
    errors.lastPublishedDate = true;
    error = true
  }
  if (data.crawlerClass == undefined || data.crawlerClass == '') {
    errors.crawlerClass = true;
    error = true;
  }
  if (data.emailIds == undefined || data.emailIds == '') {
    errors.emailIds = true;
    error = true;
  }
  if (error) {
    dispatch({ type: CRAWLER_VALIDATION, payload: errors })
  }
  return error;
}


export async function getCrawler(dispatch: typeof store.dispatch) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(dataCurationETL + apiUrl.groupTask)
    .then((response) => {
      if (!response.ok) {
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_CRAWLER, payload: data });
      dispatch({ type: SET_IS_LOADING, payload: false });
    });
}
export async function getAllCrawlerDetails(dispatch: typeof store.dispatch) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(dataCrawler + apiUrl.getConfig)
    .then((response) => {
      if (!response.ok) {
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_ALL_CRAWLER_DETAILS, payload: data });
      dispatch({ type: SET_IS_LOADING, payload: false });
    });
}

export async function getFrequency(dispatch: typeof store.dispatch) {
  await fetch(dataCrawler + apiUrl.getFrequency)
    .then((response) => {
      if (!response.ok) {
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_FREQUENCY, payload: data });
    });
}

export async function getCrawlerClass(dispatch: typeof store.dispatch) {
  await fetch(dataCrawler + apiUrl.getCrawlerClass)
    .then((response) => {
      if (!response.ok) {
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_CRAWLER_CLASS, payload: data });
    });
}

export async function saveConfig(dispatch: typeof store.dispatch, data, navigate) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  try {
    await fetch(dataCrawler + apiUrl.saveConfig, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
        }
        dispatch({ type: SET_IS_LOADING, payload: false });
        return JSON.stringify(response);
      })
      .then((data) => {
        getAllCrawlerDetails(dispatch);
        dispatch({ type: SAVE_CONFIG, payload: data });
        dispatch({ type: SET_IS_LOADING, payload: false });
        swal.fire({
          icon: 'success',
          text: 'Crawler config saved succesfully',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
        navigate("/viewCrawler")
      });
  } catch {
    swal.fire({
      icon: 'error',
      text: 'Failed to Save crawler data',
      confirmButtonColor: navyColor,
      confirmButtonText: 'OK',
    });
  }
}

export async function updateConfig(dispatch: typeof store.dispatch, data, navigate) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  try {
    await fetch(dataCrawler + apiUrl.updateConfig, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          swal.fire({
            icon: 'error',
            text: 'Failed to update crawler data',
            confirmButtonColor: navyColor,
            confirmButtonText: 'OK',
          });
        }
        dispatch({ type: SET_IS_LOADING, payload: false });
        return JSON.stringify(response);
      })
      .then((data) => {
        getAllCrawlerDetails(dispatch);
        dispatch({ type: SET_IS_LOADING, payload: false });
        dispatch({ type: SAVE_CONFIG, payload: data });
        swal.fire({
          icon: 'success',
          text: 'Crawler config updated succesfully',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
        navigate("/viewCrawler");
      });
  } catch {
    swal.fire({
      icon: 'error',
      text: 'Failed to update crawler data',
      confirmButtonColor: navyColor,
      confirmButtonText: 'OK',
    });
  }
}
export async function crawlerRun(dispatch: typeof store.dispatch, source) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  try {
    await fetch(dataCrawler + apiUrl.runCrawler, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: source,
    })
      .then((response) => {
        if (!response.ok) {
        }
        dispatch({ type: SET_IS_LOADING, payload: false });
        return JSON.stringify(response);
      })
      .then((data) => {
        dispatch({ type: RUN_CRAWLER, payload: data });
        dispatch({ type: SET_IS_LOADING, payload: false });
        swal.fire({
          icon: 'success',
          text: source + ' Crawler Initiated. Email will be sent shortly.',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
      });
  } catch {
    swal.fire({
      icon: 'error',
      text: 'Please Reach Out It Team',
      confirmButtonColor: navyColor,
      confirmButtonText: 'OK',
    });
  }
}

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert2";
import { navyColor } from "../../assets/jss/material-kit-react";
import CustomPaper from "../../components/CustomPaper/CustomPaper";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import TextArea from "../../components/TextArea/TextArea";
import { getLOB, getProductType } from "../../redux/actions";
import {
  LOB,
  NOTES,
  POLICY_EXPLANATION,
  POLICY_SUMMARY,
  PRODUCT_TYPE,
  REFERENCE_SRC_DESC,
  REFERNCE_SRC_TITLE_DESC,
  SOURCE_INDICATOR
} from "../../redux/actions/NewPolicyFormActionTypes";
import { NewPolicyFormState } from "../../redux/reducers/NewPolicyFormReducer";
import { NewPolicyState } from "../../redux/reducers/NewPolicyReducer";

const _ = require("lodash");
const checkTextSize = (event, fieldName, expectedLength) => {
  if(event.length>expectedLength){
            swal.fire({
              icon: "error",
              text: "Please check the length of  "+fieldName+" (more than "+expectedLength+" characters)",
              confirmButtonColor: navyColor,
              confirmButtonText: "OK",
            });
          }
};

const Desc = ({ fromViewPolicy,edit }) => {
  const dispatch = useDispatch();
  const [lob, setLob] = useState([]);
  const [productType, setProductType] = useState([]);

  useEffect(() => {
    if (updatedState.LOB.length == 0) {
      getLOB(dispatch);
    }
    if (updatedState.ProductType.length == 0) {
      getProductType(dispatch);
    }
  }, []);

  const updatedState: NewPolicyState = useSelector(
    (state: any) => state.newPolicy
  );
  useEffect(() => {
    setLob(updatedState.LOB);
    setProductType(updatedState.ProductType);
  }, [updatedState]);
  const LotCM = lob?.map((l) => {
    return { label: l.lobTitle, value: l.lobKey };
  });
  const productTypeCM = productType.map((p) => {
    return { label: p.productTitle, value: p.productKey };
  });

  const formState: NewPolicyFormState = useSelector(
    (state: any) => state.newPolicyForm
  );

  const showClientGrpDescription=()=>{
    let desc=[];
    formState.productType?.map((k,l)=>{
      updatedState.ProductType.map((f,r)=>{
        if(f.productKey==k.value){
          desc.push({label: f.productTitle,
            value : f.productKey})
        }
      })
    })
    return desc;
  }

  return (
    <div>
    <CustomPaper
    style={{
      paddingLeft: 12,
      position:"relative",
      right:20,
      paddingRight: 10,
      paddingTop:10,
      boxShadow: "none",
      border: "1px solid #D6D8DA",
      marginRight: "0px",
    }}
  >
    <GridContainer style={{ marginTop: -20,position:"relative",right:0 }}>
      <GridItem sm={2} md={2} xs={2}>
        <CustomSelect
          isDisabled={!edit ? undefined: fromViewPolicy}
          onSelect={(event) => dispatch({ type: LOB, payload: event })}
          value={formState.lob}
          options={LotCM}
          labelText={"LOB"}
          showStarIcon={true}
          error={formState.errors.lob}
          hoverData={LotCM.map((l)=>{
            return  l.label
            })}
        />
      </GridItem>
      <GridItem sm={2} md={2} xs={2}>
        <CustomSelect
        isMulti
        checkBoxes={true}
          isDisabled={!edit ? undefined: fromViewPolicy}
          onSelect={(event) => dispatch({ type: PRODUCT_TYPE, payload: event })}
          value={showClientGrpDescription()}
          options={productTypeCM}
          error={formState.errors.productType}
          labelText={"Client Group Type"}
          showStarIcon={true}
          hoverData={productTypeCM.map((l)=>{
            return  l.label
            })}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <TextArea
          disabled={!edit ? undefined : fromViewPolicy}
          value={formState.notes}
          error={formState.errors.notes}
          onChange={(event) => {
            checkTextSize(event.target.value,"Notes",4000);
                      if(event.target.value.length>4000)
                      {
                      dispatch({
                        type: NOTES,
                        payload: undefined,
                      });
                    }
                    else{
                      dispatch({
                        type: NOTES,
                        payload: event.target.value,
                      });
                    }
          }}
          fullWidth={true}
          labelText={"Notes"}
          showStarIcon={true}
          variant={"outlined"}
          multiline
          rows={2}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <TextArea
          disabled={!edit ? undefined : fromViewPolicy}
          value={formState.policySummary}
          error={formState.errors.policySummary}
          onChange={(event) => {
            checkTextSize(event.target.value,"Policy Summary",4000);
                      if(event.target.value.length>4000)
                      {
                      dispatch({
                        type: POLICY_SUMMARY,
                        payload: undefined,
                      });
                    }
                    else{
                      dispatch({
                        type: POLICY_SUMMARY,
                        payload: event.target.value,
                      });
                    }
          }}
          fullWidth={true}
          labelText={"Policy Summary"}
          showStarIcon={true}
          variant={"outlined"}
          multiline
          rows={2}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <TextArea
          disabled={!edit ? undefined : fromViewPolicy}
          value={formState.policyExplantion}
          error={formState.errors.policyExplantion}
          onChange={(event) => {
            checkTextSize(event.target.value,"Policy Explantion",4000);
                      if(event.target.value.length>4000)
                      {
                      dispatch({
                        type: POLICY_EXPLANATION,
                        payload: undefined,
                      });
                    }
                    else{
                      dispatch({
                        type: POLICY_EXPLANATION,
                        payload: event.target.value,
                      });
                    }
          }}
          fullWidth={true}
          labelText={"Policy Explanation"}
          showStarIcon={true}
          variant={"outlined"}
          multiline
          rows={2}
        />
      </GridItem>
      <GridItem sm={12} md={12} xs={12}>
        <TextArea
          disabled={!edit ? undefined : fromViewPolicy}
          value={formState.referenceSrcDesc}
          error={formState.errors.referenceSrcDesc}
          onChange={(event) => {
            dispatch({
              type: REFERENCE_SRC_DESC,
              payload: event.target.value,
            });
          }}
          fullWidth={true}
          labelText={"Reference Source Desc"}
          showStarIcon={true}
          variant={"outlined"}
          multiline
          rows={2}
        />
      </GridItem>
      <GridItem sm={12} md={12} xs={12}>
        <TextArea
          disabled={!edit ? undefined : fromViewPolicy}
          value={formState.referenceSrcTitleDesc}
          error={formState.errors.referenceSrcTitleDesc}
          onChange={(event) => {
            dispatch({
              type: REFERNCE_SRC_TITLE_DESC,
              payload: event.target.value,
            });
          }}
          fullWidth={true}
          labelText={"Reference Source Title Desc"}
          showStarIcon={true}
          variant={"outlined"}
          multiline
          rows={2}
        />
      </GridItem>
      <GridItem sm={12} md={12} xs={12}>
        <TextArea
          disabled={!edit ? undefined : fromViewPolicy}
          value={formState.sourceIndicator}
          error={formState.errors.sourceIndicator}
          onChange={(event) => {
            dispatch({
              type: SOURCE_INDICATOR,
              payload: event.target.value,
            });
          }}
          fullWidth={true}
          labelText={"Source Indicator"}
          showStarIcon={true}
          variant={"outlined"}
          multiline
          rows={2}
        />
      </GridItem>
    </GridContainer>
    </CustomPaper>
    </div>
  );
};
export default Desc;

import { Construction } from '@mui/icons-material';
import moment from 'moment';
import {
  apiUrl,
  dataCrawler,
  dataCurationETL,
  emailUrl,
  policyConfigUrl,
  policyengine,
} from '../../configs/apiUrls';
import { NewPolicyState } from '../reducers/NewPolicyReducer';
import { store } from '../store';
import swal from 'sweetalert2';
import {
  CHANGES_ISOPEN_B,
  DELTA_LINK,
  DIALOG,
  ADD_ON_CODES,
  GET_CAT,
  GET_CONFIG_REPORT_FOR_SINGLE_RULE,
  GET_DRGN_CHALLENGE_CODE,
  GET_GENDER,
  GET_LOB,
  GET_MAX_AGE,
  GET_MEDICAL_POLICY,
  GET_MIN_AGE,
  GET_NPI,
  GET_PROCS,
  GET_PROD_TYPE,
  GET_REASON_CODES,
  GET_SUBSPECIALITY,
  GET_SUB_POLICY,
  GET_TAX_LOGIC,
  POST_PROCS,
  PROCS_DATA_TO_EXCEL,
  PROCS_TARGET,
  PROCS_TARGET_SPINNER,
  REFRESH_CHALLENGE_CACHE,
  REFRESH_POLICY_CACHE,
  SEARCH_CLAIM,
  SEARCH_POLICY,
  SET_IS_LOADING,
  PTP_CCI,
  CURATION_VER,
  CONFIG_VER,
  CRAWLER_VER,
  ENGINE_VER,
  EMAIL_VER,
  GET_ALL_CLAIM_TYPE,
  IGNORE_MODIFIER,
  ALLOW_CMS_NCCI_MODIFIERS,
  POST_DIAG,
  DIAGNOSIS_DATA_TO_EXCEL,
  GET_CLAIM_LINK_LKP,
  GET_LOB_DATA,
  GET_MEDICAL_POLICY_DATA,
  GET_POSLINK,
  GET_POLICY_CPT_ACTION_LKP,
  GET_BW_TYPE_DATA,
  GET_REVENUE_CODE_CLAIM_LINK,
  GET_CPTLINK,
  GET_CHANGE_MODIFIER,
} from './actionTypes';
import {
  BW_TYPE_KEY,
  CCI_KEY,
  CHANGE_MODIFIER_KEY,
  EDIT_CHANGES,
  EDIT_POLICY,
  EDIT_POLICY_TABLE_CHANGES,
  MAX_UNITS_TYPE,
  MODIFIER_PAY_PERCENTAGE,
  MOD_INTRACTION_TYPE,
} from './NewPolicyFormActionTypes';
import { navyColor } from '../../assets/jss/material-kit-react';
import { GET_TOTAL_NUMBER_OF_ROWS } from './TaskActionTypes';
import { PolicyConstants } from '../../pages/NewPolicy/PolicyConst';
import { setTimeout } from 'timers';
import {
  CCI_LKP,
  MIN_MAX_AGE_LKP,
  MODIFIER_PRIORITY_LKP,
  MOD_LKP,
  POLICY_CATEGORY_LKP,
  REASON_CODE_LKP,
} from '../../pages/LookUps/LookUpConsts';
const axios = require('axios');

export async function getReasonCodes(dispatch: typeof store.dispatch) {
  await fetch(policyConfigUrl + apiUrl.getLookUpData + '/' + REASON_CODE_LKP)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_REASON_CODES, payload: data });
    });
}

export async function getMedicalPolicy(dispatch: typeof store.dispatch) {
  await fetch(policyConfigUrl + apiUrl.getMedicalpolicy)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_MEDICAL_POLICY, payload: data });
    });
}

export async function getSubPolicy(dispatch: typeof store.dispatch) {
  await fetch(policyConfigUrl + apiUrl.getSubPolicy)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_SUB_POLICY, payload: data });
    });
}

export async function getMedicalData(dispatch: typeof store.dispatch) {
  await fetch(policyConfigUrl + apiUrl.getMedicalData)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_MEDICAL_POLICY_DATA, payload: data });
    });
}

export async function getCAT(dispatch: typeof store.dispatch) {
  await fetch(
    policyConfigUrl + apiUrl.getLookUpData + '/' + POLICY_CATEGORY_LKP
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_CAT, payload: data });
      return data;
    });
}

export async function getProductType(dispatch: typeof store.dispatch) {
  await fetch(policyConfigUrl + apiUrl.getProductType)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_PROD_TYPE, payload: data });
    });
}

export async function getLOB(dispatch: typeof store.dispatch) {
  await fetch(policyConfigUrl + apiUrl.getLob)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_LOB, payload: data });
    });
}
export async function getGender(dispatch: typeof store.dispatch) {
  await fetch(policyConfigUrl + apiUrl.getGender)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_GENDER, payload: data });
    });
}
export async function getMinAge(dispatch: typeof store.dispatch) {
  await fetch(policyConfigUrl + apiUrl.getLookUpData + '/' + MIN_MAX_AGE_LKP)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      let minAge = data.filter((d) => d.minVsMaxB != 0);
      dispatch({ type: GET_MIN_AGE, payload: minAge });
    });
}
export async function getTaxLogic(dispatch: typeof store.dispatch) {
  await fetch(policyConfigUrl + apiUrl.getTaxlink)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_TAX_LOGIC, payload: data });
    });
}

export async function uploadProceduresToStage(
  dispatch: typeof store.dispatch,
  data1
) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  // try{
  try {
    await fetch(policyConfigUrl + apiUrl.createProcs, {
      method: 'POST',
      body: data1,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.text();
      })
      .then((data) => {
        dispatch({ type: SET_IS_LOADING, payload: false });
        dispatch({ type: POST_PROCS, payload: false });
        dispatch({ type: PROCS_TARGET, payload: true });
        dispatch({ type: DELTA_LINK, payload: data });
        return true;
      });
  } catch (error) {}
}

export async function uploadDiagnosisData(
  dispatch: typeof store.dispatch,
  DiagData
) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  // try{
  try {
    await fetch(policyConfigUrl + apiUrl.saveDaiagnosis, {
      method: 'POST',
      body: DiagData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.text();
      })
      .then((DiagData) => {
        dispatch({ type: SET_IS_LOADING, payload: false });
        dispatch({ type: POST_DIAG, payload: false });
        swal.fire({
          icon: 'success',
          text: 'Data saved Successfully',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
        return true;
      });
  } catch (error) {}
}

export async function uploadBillTypeData(
  dispatch: typeof store.dispatch,
  BillTypeData
) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  // try{
  try {
    await fetch(policyConfigUrl + apiUrl.saveBillType, {
      method: 'POST',
      body: BillTypeData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.text();
      })
      .then((BillTypeData) => {
        dispatch({ type: SET_IS_LOADING, payload: false });
        dispatch({ type: POST_DIAG, payload: false });
        swal.fire({
          icon: 'success',
          text: 'Data saved Successfully',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
        return true;
      });
  } catch (error) {}
}

export async function UploadProceduresToTarget(
  dispatch: typeof store.dispatch,
  policyId,
  navigate
) {
  try {
    // dispatch({ type: SET_IS_LOADING, payload: true })
    let result: any = `${policyConfigUrl + apiUrl.createTargetUrl}/${policyId}`;
    await fetch(result, {
      method: 'POST',
      body: policyId,
    });
    // dispatch({ type: SET_IS_LOADING, payload: false })
    getProcs(dispatch, policyId);
    swal.fire({
      icon: 'success',
      text: 'Data saved Successfully',
      confirmButtonColor: navyColor,
      confirmButtonText: 'OK',
    });
    // dispatch({ type: PROCS_TARGET, payload: false })
    dispatch({ type: PROCS_TARGET, payload: false });
  } catch {
    swal.fire({
      icon: 'error',
      text: 'Please Reach out It Team',
      confirmButtonColor: navyColor,
      confirmButtonText: 'OK',
    });
  }
}

export async function getSubSpeciality(dispatch: typeof store.dispatch) {
  await fetch(policyConfigUrl + apiUrl.getPropSubSpec)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_SUBSPECIALITY, payload: data });
    });
}

export async function getDrgnChallengeCode(dispatch: typeof store.dispatch) {
  await fetch(policyengine + apiUrl.getChallengeCode)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_DRGN_CHALLENGE_CODE, payload: data });
    });
}

export async function searchClaimData(dispatch: typeof store.dispatch, data) {
  let d = [];
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(policyConfigUrl + apiUrl.searchClaim, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        dispatch({ type: SET_IS_LOADING, payload: false });
      }
      return response.json();
    })
    .then((claimData) => {
      d = claimData;
      dispatch({ type: SET_IS_LOADING, payload: false });
      dispatch({ type: SEARCH_CLAIM, payload: claimData });
    });
  return d;
}

export async function getClaimDataSize(dispatch: typeof store.dispatch, data) {
  await fetch(policyConfigUrl + apiUrl.searchClaimDataSize, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_TOTAL_NUMBER_OF_ROWS, payload: data });
    });
}

export async function getChangesId(dispatch, policyId) {
  // dispatch({
  //   type: SET_IS_LOADING,
  //   payload: true,
  // });
  return await fetch(
    `${policyConfigUrl + apiUrl.getChangesId + '/' + policyId}`
  )
    .then((response) => {
      if (!response.ok) {
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: CHANGES_ISOPEN_B, payload: data });
      // dispatch({type: SET_IS_LOADING,payload:false});
      return false;
    });
}

export async function getMaxAge(dispatch: typeof store.dispatch) {
  await fetch(policyConfigUrl + apiUrl.getLookUpData + '/' + MIN_MAX_AGE_LKP)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      let maxAge = data.filter((d) => d.minVsMaxB == 0);
      dispatch({ type: GET_MAX_AGE, payload: maxAge });
    });
}

export async function getIgnoreModifier(dispatch: typeof store.dispatch) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(policyConfigUrl + apiUrl.getLookUpData + '/' + MOD_LKP)
    .then((response) => {
      if (!response.ok) {
        // throw new Error(response.statusText);
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json();
    })
    .then((data) => {
      let ignoreMod = data.filter((d) => d.is_59_group == 1);
      dispatch({ type: IGNORE_MODIFIER, payload: ignoreMod });
      dispatch({ type: SET_IS_LOADING, payload: false });
    });
}

export async function getAllowCmsNcciModifiers(
  dispatch: typeof store.dispatch
) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(policyConfigUrl + apiUrl.getLookUpData + '/' + MOD_LKP)
    .then((response) => {
      if (!response.ok) {
        // throw new Error(response.statusText);
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json();
    })
    .then((data) => {
      let isCci = data.filter((d) => d.isCci == 1);
      dispatch({ type: ALLOW_CMS_NCCI_MODIFIERS, payload: isCci });
      dispatch({ type: SET_IS_LOADING, payload: false });
    });
}

export async function getProcs(dispatch: typeof store.dispatch, policyId) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(policyConfigUrl + apiUrl.getProcsById + '/' + policyId)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      // dispatch({ type: SET_IS_LOADING, payload: false })
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_PROCS, payload: data });
      if (data.length > 0) {
        setTimeout(() => {
          dispatch({ type: SET_IS_LOADING, payload: false });
        }, 1000);
      } else {
        dispatch({ type: SET_IS_LOADING, payload: false });
      }
      // dispatch({ type: SET_IS_LOADING, payload: false })
    });
}

export async function getClmLinkLkp(dispatch: typeof store.dispatch) {
  // dispatch({ type: SET_IS_LOADING, payload: true })
  await fetch(policyConfigUrl + apiUrl.getClmLinkLkp)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      // dispatch({ type: SET_IS_LOADING, payload: false })
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_CLAIM_LINK_LKP, payload: data });
      // dispatch({ type: SET_IS_LOADING, payload: false })
    });
}

export async function getPolicyCptActionLkp(dispatch: typeof store.dispatch) {
  // dispatch({ type: SET_IS_LOADING, payload: true })
  await fetch(policyConfigUrl + apiUrl.getPoliyCptActionLkp)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      // dispatch({ type: SET_IS_LOADING, payload: false })
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_POLICY_CPT_ACTION_LKP, payload: data });
      // dispatch({ type: SET_IS_LOADING, payload: false })
    });
}

export async function getNPI(dispatch: typeof store.dispatch) {
  await fetch(policyConfigUrl + apiUrl.getNpi)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_NPI, payload: data });
    });
}

export async function getPOSLINK(dispatch: typeof store.dispatch) {
  await fetch(policyConfigUrl + apiUrl.getPosLink)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_POSLINK, payload: data });
    });
}

export async function getRevenueCodeClaimLink(dispatch: typeof store.dispatch) {
  await fetch(policyConfigUrl + apiUrl.getRevenueCodeClaimLink)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_REVENUE_CODE_CLAIM_LINK, payload: data });
    });
}

export async function getCPTLink(dispatch: typeof store.dispatch) {
  await fetch(policyConfigUrl + apiUrl.getCptLink)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_CPTLINK, payload: data });
    });
}
export async function getPolicyById(dispatch: typeof store.dispatch, policyId) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  if (policyId != undefined) {
    await fetch(policyConfigUrl + apiUrl.getPolicyById + '/' + policyId)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        let clmsss = data?.claimType.split(',');
        let clms = clmsss.map((c) => {
          return { label: c, value: c };
        });
        let ProdtType = data?.productTypeFk.split(',');
        let PrdType = ProdtType.map((k, l) => {
          return { label: k, value: k };
        });
        let ccikey = null,
          billed = null,
          bwTypeKey = null,
          maxUnitsType = null,
          selectedType = null,
          changeModifierKey = null,
          modifierPayPercentage = null,
          modifierPriority = null,
          units_32 = null;
        if (
          data.policyCategoryLkpIdFk.policyCategoryLkpId ==
          PolicyConstants.TWENTY_THREE
        ) {
          ccikey = data?.co1Value;
          billed = null;
        }
        if (
          data?.policyCategoryLkpIdFk.policyCategoryLkpId ==
          PolicyConstants.THIRTY_EIGHT
        ) {
          billed = data?.co1Value;
          ccikey = null;
        }
        if (
          data?.policyCategoryLkpIdFk.policyCategoryLkpId ==
          PolicyConstants.TWELVE
        ) {
          selectedType = data?.co1Value;
          ccikey = null;
        }
        if (
          data?.policyCategoryLkpIdFk.policyCategoryLkpId ==
          PolicyConstants.TWENTY_FIVE
        ) {
          bwTypeKey = data?.co1Value;
          ccikey = null;
        }
        if (
          data?.policyCategoryLkpIdFk.policyCategoryLkpId ==
          PolicyConstants.TWENTY
        ) {
          changeModifierKey = data?.co1Value;
          ccikey = null;
        }
        if (
          data?.policyCategoryLkpIdFk.policyCategoryLkpId ==
          PolicyConstants.THIRTY_FIVE
        ) {
          maxUnitsType = data?.co1Value;
          ccikey = null;
        }
        if (
          data?.policyCategoryLkpIdFk.policyCategoryLkpId ==
          PolicyConstants.FOURTY_FIVE
        ) {
          modifierPayPercentage = data?.co1Value;
          ccikey = null;
        }
        if (
          data?.policyCategoryLkpIdFk.policyCategoryLkpId ==
          PolicyConstants.FOURTY_SIX
        ) {
          modifierPayPercentage = data?.co1Value;
          ccikey = null;
        }
        if (
          data?.policyCategoryLkpIdFk.policyCategoryLkpId ==
          PolicyConstants.THIRTY_TWO
        ) {
          units_32 = data?.co1Value;
          ccikey = null;
        }
        let oobj = {
          policy: data?.policyNumber,
          custom: data?.custom,
          clonedPolicyId: data?.clonedPolicyId,
          clonedPolVer:data?.clonedPolVer,
          version: data?.policyVersion,
          policyId: policyId,
          reason: data?.reasonCodeFk?.reasonCode,
          cat: data?.policyCategoryLkpIdFk.policyCategoryLkpId,
          medicalPolicy: data?.medicalPolicyKeyFK.medicalPolicyKey,
          subPolicy: data?.subPolicyKeyFK.subPolicyKey,
          lob: {
            label: data?.lobFk.lobTitle,
            value: data?.lobFk.lobKey,
          },
          posLink: {
            label: data?.posLinkFk.description,
            value: data?.posLinkFk.posLinkLkpKey,
          },
          cptLink: {
            label: data?.cptLinkFk.description,
            value: data?.cptLinkFk.cptLinkLkpKey,
          },
          revenueCodeClaimLink: {
            label: data?.revenueCodeClaimLinkFk.description,
            value: data?.revenueCodeClaimLinkFk.revenueCodeClaimLinkKey,
          },
          conditionCodeAction: {
            label: data?.conditionCodeActionFk.conditionCode,
            value: data?.conditionCodeActionFk.condCodeKey,
          },
          productType: PrdType,
          gender: {
            label: data?.genderCode.genderDesc,
            value: data?.genderCode.genderCode,
          },
          procedureMaxAge: data?.maxAgeFk.minMaxAgeLkpId,
          procedureMinAge: data?.minAgeFk.minMaxAgeLkpId,
          npi: {
            label: data?.npiLogicFk.description,
            value: data?.npiLogicFk.npiLinkLkpKey,
          },
          taxonomyLogicFk: {
            label: data?.taxonomyLogicFk.description,
            value: data?.taxonomyLogicFk.taxonomyLinkLkpKey,
          },
          enforceBefCategory: data?.enforceBeforeCategory,
          taxIdLogic: {
            label: data?.taxLogicFk.description,
            value: data?.taxLogicFk.taxLinkLkpKey,
          },
          ccikey: ccikey,
          bwTypeKey: bwTypeKey,
          changeModifierKey: changeModifierKey,
          modifierPayPercentage: modifierPayPercentage,
          // modifierPriority:modifierPriority,
          maxUnitsType: maxUnitsType,
          modIntractionType:
            data?.policyCategoryLkpIdFk.policyCategoryLkpId == 35
              ? data?.co2Value
              : '',
          byPassMod:
            data?.policyCategoryLkpIdFk.policyCategoryLkpId == 23
              ? data?.co2Value
              : '',
          mutuallyExclusive: data?.co3Value,
          billedWith: billed,
          selectedType: selectedType,
          denyType: data?.co4Value,
          reference: data?.reference,
          claimType: clms,
          prod: data?.isProdb,
          deactivated: data?.deactivated,
          disabled: data?.disabled,
          ncciB: data?.ncciB,
          group59B: data?.group59B,
          asGroupB: data?.asGroupB,
          tc26ModB: data?.tc26ModB,
          referenceClaimType: data?.referenceClaimType,
          roldDescription: data?.policyDesc,
          notes: data?.notes,
          policySummary: data?.summary,
          policyExplantion: data?.explanation,
          referenceSrcDesc: data?.refSourceDesc,
          referenceSrcTitleDesc: data?.refSourceTitle,
          sourceIndicator: data?.sourceIndicator,
          createdDate: data?.createdDate,
          priority: data?.priority,

          units: units_32,
          duration:
            (data?.policyCategoryLkpIdFk.policyCategoryLkpId == 32 &&
              data?.co2Value === 'CM') ||
            data?.co2Value === 'CY'
              ? ''
              : data?.co2Value,
          frequency:
            (data?.policyCategoryLkpIdFk.policyCategoryLkpId == 32 &&
              data?.co2Value === 'CM') ||
            data?.co2Value === 'CY'
              ? data?.co2Value
              : '',
          ranking: data?.co3Value,
          payment: data?.co4Value,
          durationDropdown:
            (data?.policyCategoryLkpIdFk.policyCategoryLkpId == 32 &&
              data?.co2Value === 'CM') ||
            data?.co2Value === 'CY'
              ? undefined
              : {
                  label: data?.co2Value?.replace(/[0-9]/g, ''),
                  value: data?.co2Value?.replace(/[0-9]/g, ''),
                },
          billTypeLink: {
            label:
              data?.billTypeLinkFk.billTypeCode +
              ' - ' +
              data?.billTypeLinkFk.billTypeLinkDesc,
            value: data?.billTypeLinkFk.billTypeLinkKey,
          },
          billTypeAction: {
            label: data?.billTypeActionFk.policyBillTypeActionCode,
            value: data?.billTypeActionFk.policyBillTypeActionKey,
          },
          claimTypeLinkFk: {
            label: data?.claimTypeLinkFk?.description,
            value: data?.claimTypeLinkFk?.claimTypeLinkLkpKey,
          },
        };
        dispatch({ type: EDIT_POLICY, payload: oobj });
        dispatch({ type: CCI_KEY, payload: ccikey });
        dispatch({ type: BW_TYPE_KEY, payload: bwTypeKey });
        dispatch({ type: CHANGE_MODIFIER_KEY, payload: changeModifierKey });
        dispatch({
          type: MODIFIER_PAY_PERCENTAGE,
          payload: modifierPayPercentage,
        });
        // dispatch({type:MODIFIER_PRIORITY_LKP,payload:modifierPriority});
        dispatch({ type: MAX_UNITS_TYPE, payload: maxUnitsType });
        dispatch({ type: SET_IS_LOADING, payload: false });
        // getChangesById(dispatch, policyId);
        return data;
      });
  }
}

export async function getChangesById(dispatch, policyId) {
  let result = await fetch(
    policyConfigUrl + apiUrl.getChangesById + '/' + policyId
  );
  let r = await result.json();
  let tableData = [];
  let zeroObj = {
    id: '',
    jiraId: '',
    jiraDesc: '',
    jiraLink: '',
    userId: '',
    isJira: '',
    isJiraDesc: '',
    updatedOn: '',
    policyChangesKey: '',
    jiraIsOpen: false,
  };
  r.forEach((f) => {
    if (f.isOpenb == 0) {
      let obj = {
        id: f.jiraId,
        jiraId: f.jiraId,
        jiraDesc: f.jiraDesc,
        jiraLink: f.jiraLink,
        userId: f.userId,
        updatedOn: f.updatedOn,
        policyChangesKey: f.policyChangesKey,
        jiraIsOpen: false,
      };
      tableData.push(obj);
    } else {
      zeroObj.id = f.jiraId;
      zeroObj.jiraDesc = f.jiraDesc;
      zeroObj.jiraId = f.jiraId;
      zeroObj.jiraLink = f.jiraLink;
      zeroObj.userId = f.userId;
      zeroObj.updatedOn = f.updatedOn;
      zeroObj.jiraIsOpen = true;
      zeroObj.policyChangesKey = f.policyChangesKey;
      zeroObj.isJira = f.jiraId;
      zeroObj.isJiraDesc = f.jiraDesc;
    }
  });
  dispatch({ type: EDIT_CHANGES, payload: zeroObj });
  dispatch({ type: EDIT_POLICY_TABLE_CHANGES, payload: tableData });
}
export async function searchPolicy(dispatch: typeof store.dispatch, data) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(policyConfigUrl + apiUrl.searchPolicy, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        // throw new Error(response.statusText);
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json();
    })
    .then((data) => {
      dispatch({ type: SEARCH_POLICY, payload: data });
      dispatch({ type: SET_IS_LOADING, payload: false });
    });
}

export async function getConfigValidationReportForSingleRule(
  dispatch: typeof store.dispatch,
  policyNumber: number,
  version: number,
  policyId: number,
  emailId
) {
  // let userDetails = sessionStorage.getItem('user-info');
  // const user = JSON.parse(userDetails);
  // dispatch({type:SET_IS_LOADING,payload:true})
  swal.fire({
    icon: 'success',
    text: 'Report Generation is in progress You will get an email with the file link',
    confirmButtonColor: navyColor,
    confirmButtonText: 'OK',
  });
  await fetch(
    `${
      policyConfigUrl + apiUrl.postConfiguration
    }/${policyNumber}/${version}/${policyId}/${emailId}`,

    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  ).then((data) => {
    dispatch({ type: GET_CONFIG_REPORT_FOR_SINGLE_RULE, payload: data });
    // dispatch({type:SET_IS_LOADING,payload:false})
    swal.fire({
      icon: 'success',
      text: 'Report Generated Successfully',
      confirmButtonColor: navyColor,
      confirmButtonText: 'OK',
    });
  });
}

export async function getRefreshChallengeCache(
  dispatch: typeof store.dispatch
) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(policyengine + apiUrl.refreshChallengeCache)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json();
    })
    .then((data) => {
      dispatch({ type: REFRESH_CHALLENGE_CACHE, payload: data });
      dispatch({ type: SET_IS_LOADING, payload: false });
      swal.fire({
        icon: 'success',
        text: 'Cache Refreshed Sucessfully',
        confirmButtonColor: navyColor,
        confirmButtonText: 'OK',
      });
    });
}

export async function getRefreshPolicyCache(dispatch: typeof store.dispatch) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(policyengine + apiUrl.refreshPolicyCache)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json();
    })
    .then((data) => {
      dispatch({ type: REFRESH_POLICY_CACHE, payload: data });
      dispatch({ type: SET_IS_LOADING, payload: false });
      swal.fire({
        icon: 'success',
        text: 'Cache Refreshed Sucessfully',
        confirmButtonColor: navyColor,
        confirmButtonText: 'OK',
      });
    });
}

export async function getAddOnCodes(dispatch: typeof store.dispatch) {
  await fetch(policyConfigUrl + apiUrl.getAddOnCodes)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: ADD_ON_CODES, payload: data });
    });
}
export async function getCci(dispatch: typeof store.dispatch) {
  await fetch(policyConfigUrl + apiUrl.getLookUpData + '/' + CCI_LKP)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: PTP_CCI, payload: data });
    });
}
export async function getBwTypeData(dispatch: typeof store.dispatch) {
  await fetch(dataCurationETL + apiUrl.getBwType)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_BW_TYPE_DATA, payload: data });
    });
}
export async function getChangeModifier(dispatch: typeof store.dispatch) {
  await fetch(policyConfigUrl + apiUrl.getChangeModifier)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_CHANGE_MODIFIER, payload: data });
    });
}

// export async function getModifierPayPercentage(dispatch: typeof store.dispatch) {
//   await fetch(policyConfigUrl + apiUrl.getModifierPayPercentage)
//     .then((response) => {
//       if (!response.ok) {
//         throw new Error(response.statusText);
//       }
//       return response.json();
//     })
//     .then((data) => {
//       dispatch({ type: GET_MODIFIER_PAY_PERCENTAGE, payload: data });
//     });
// }

export async function getAllClaimType(dispatch: typeof store.dispatch) {
  await fetch(policyConfigUrl + apiUrl.getClaimType)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_ALL_CLAIM_TYPE, payload: data });
    });
}
export async function getCurationVersion(dispatch: typeof store.dispatch) {
  await fetch(dataCurationETL + apiUrl.getCurationVersion)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      return response.text();
    })
    .then((data) => {
      dispatch({ type: CURATION_VER, payload: data });
    });
}
export async function getPolicyConfigVersion(dispatch: typeof store.dispatch) {
  await fetch(policyConfigUrl + apiUrl.getConfigVersion)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      return response.text();
    })
    .then((data) => {
      dispatch({ type: CONFIG_VER, payload: data });
    });
}
export async function getCrawlerVersion(dispatch: typeof store.dispatch) {
  await fetch(dataCrawler + apiUrl.getCrawlerVersion)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      return response.text();
    })
    .then((data) => {
      dispatch({ type: CRAWLER_VER, payload: data });
    });
}
export async function getEmailVersion(dispatch: typeof store.dispatch) {
  await fetch(emailUrl + apiUrl.getEmailVersion)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.text();
    })
    .then((data) => {
      dispatch({ type: EMAIL_VER, payload: data });
    });
}

export async function getPolicyEngVersion(dispatch: typeof store.dispatch) {
  await fetch(policyengine + apiUrl.getEngineVersion)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.text();
    })
    .then((data) => {
      dispatch({ type: ENGINE_VER, payload: data });
    });
}

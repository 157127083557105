import swal from 'sweetalert2';
import { navyColor } from '../../assets/jss/material-kit-react';
import {
  apiUrl,
  dataCurationETL,
} from '../../configs/apiUrls';
import { store } from '../store';
import { SET_IS_LOADING } from './actionTypes';
import {
  DELTA_CONFIG,
  GET_GROUP_TASK,
  GET_LATEST_TASK,
  GET_MY_TASK,
  UNASSIGN_GROUP_TASK,
} from './TaskActionTypes';

export async function getMyTasks(dispatch: typeof store.dispatch, email) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  // let userDetails = JSON.parse(sessionStorage.getItem('user-info'));
  await fetch(`${dataCurationETL + apiUrl.myTask}/${email}`)
    .then((response) => {
      if (!response.ok) {
        // throw new Error(response.statusText);
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_MY_TASK, payload: data });
      dispatch({ type: SET_IS_LOADING, payload: false });
    });
}
export async function getGroupTasks(dispatch: typeof store.dispatch) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(dataCurationETL + apiUrl.groupTask)
    .then((response) => {
      if (!response.ok) {
        // throw new Error(response.statusText);
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json();
    })
    .then((data) => {
      // if(data.)
      dispatch({ type: GET_GROUP_TASK, payload: data });
      dispatch({ type: SET_IS_LOADING, payload: false });
    });
  }
export async function unAssignGroupTasks(
  dispatch: typeof store.dispatch,
  navigate,
  data,
  roleState,
  emailId
) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  try {
    await fetch(dataCurationETL + apiUrl.updateMyTask, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
        }
        dispatch({ type: SET_IS_LOADING, payload: false });
      })
      .then(async (data) => {
        getMyTasks(dispatch, emailId);
        dispatch({ type: UNASSIGN_GROUP_TASK, payload: data });
        dispatch({ type: SET_IS_LOADING, payload: false });
        let roledata = roleState.roleName;
        let Role = JSON.stringify(roledata);
        let adminIdx = Role.toLocaleLowerCase().search('admin');
        if (adminIdx > 0) {
          await totalData(dispatch);
        } else {
          await getGroupTasks(dispatch);
        }
        swal.fire({
          icon: 'success',
          text: 'Task  Unassigned',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
      });
  } catch {
    swal.fire({
      icon: 'error',
      text: 'Please Reach out It Team',
      confirmButtonColor: navyColor,
      confirmButtonText: 'OK',
    });
  }
}
export async function getDeltaConfigById(
  dispatch: typeof store.dispatch,
  taskId
) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(dataCurationETL + apiUrl.Delta + '/' + taskId)
    .then((response) => {
      if (!response.ok) {
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json();
    })

    .then((data) => {
      if (data && data.length > 0) {
        dispatch({ type: DELTA_CONFIG, payload: data[0] });
        dispatch({ type: SET_IS_LOADING, payload: false });
      }
    });
  }
export async function AssignMyTasks(
  dispatch: typeof store.dispatch,
  data,
  roleState
) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(dataCurationETL + apiUrl.update, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        swal.fire({
          icon: 'error',
          text: 'Please Reach Out IT Team',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
      }
      swal.fire({
        icon: 'success',
        text: 'Task Assigned Successfully ',
        confirmButtonColor: navyColor,
        confirmButtonText: 'OK',
      });
      dispatch({ type: SET_IS_LOADING, payload: false });
    })
    .then(async (data) => {
      let roledata = roleState.roleName;
      let Role = JSON.stringify(roledata);
      let adminIdx = Role.toLocaleLowerCase().search('admin');
      if (adminIdx > 0) {
        await totalData(dispatch);
        dispatch({ type: SET_IS_LOADING, payload: false });
      } else {
        await getGroupTasks(dispatch);
        dispatch({ type: SET_IS_LOADING, payload: false });
      }
    });
  }
export async function UpdateStatus(dispatch: typeof store.dispatch, TASK_Id) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(dataCurationETL + apiUrl.updateTaskStatus + '/' + TASK_Id, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: TASK_Id,
  })
    .then((response) => {
      if (!response.ok) {
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
    })
    .then((data) => {
      dispatch({ type: UNASSIGN_GROUP_TASK, payload: data });
      dispatch({ type: SET_IS_LOADING, payload: false });
    });
}
export async function ErrorStatus(dispatch: typeof store.dispatch, TASK_Id) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(dataCurationETL + apiUrl.errorStatus + '/' + TASK_Id, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },

    body: TASK_Id,
  }).then((response) => {
    if (!response.ok) {
      dispatch({ type: SET_IS_LOADING, payload: false });
    } else {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  });
}
export async function totalData(dispatch: typeof store.dispatch) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(dataCurationETL + apiUrl.TotalData)
    .then((response) => {
      if (!response.ok) {
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json();
    })
    .then((data) => {
      // if(data.)
      dispatch({ type: GET_GROUP_TASK, payload: data });
      dispatch({ type: SET_IS_LOADING, payload: false });
    });
}

export async function getLastQuater(dispatch: typeof store.dispatch, data) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(`${dataCurationETL + apiUrl.getLastQuater}/${data}`)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json();
    })

    .catch((e) => {})

    .then((data) => {
      dispatch({ type: GET_LATEST_TASK, payload: data });
      dispatch({ type: SET_IS_LOADING, payload: false });
    });
}

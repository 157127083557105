import ampslogo from "../../assets/img/amps/ampslogo.jpg";

import { useEffect, useState } from "react";
import CustomInput from "../../components/CustomInput/CustomInput";
import GridItem from "../../components/Grid/GridItem";

import { useDispatch, useSelector } from "react-redux";
import {
  disabledColor,
  skyblueColor,
} from "../../assets/jss/material-kit-react";
import CustomButton from "../../components/CustomButtons/CustomButton";
import CustomHeader4 from "../../components/CustomHeader4/CustomHeader4";
import CustomPaper from "../../components/CustomPaper/CustomPaper";
import GridContainer from "../../components/Grid/GridContainer";
import CustomParagraph from "../../components/LoginFooter/CustomParagraph";
import usePasswordToggle from "../../hooks/usePasswordToggle";
import { getUserRoleById, loginUser } from "../../redux/actions/UserAction";
import { UserState } from "../../redux/reducers/UserReducer";
import "../Login/Login.css";
import { NewPolicyFormState } from "../../redux/reducers/NewPolicyFormReducer";

const Login = (props) => {
  const dispatch = useDispatch();
  const paperStyle = {
    height: "420px",
    width: "500px",
    marginTop: "100px",
    marginLeft: "40px",
    paddingLeft: 40,
  };

  const [passwordInputType, ToggleIcon] = usePasswordToggle();
  const currentYear = new Date().getFullYear();
  const [emailId, setEmailId] = useState("");
  const [password, setPassword] = useState("");
  const loginState: NewPolicyFormState = useSelector(
    (state: any) => state.newPolicyForm
  );
  useEffect(() => {
    // if (sessionStorage.getItem("user-info")) {
    if (loginState.userDetails.emailId) {
      props.onUserLoginSuccess(true);
    }
  }, [loginState.userDetails]);
  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      login();
    }
  };


  let userDetails = loginState.userDetails;
  async function login() {
    let item = { emailId, password };
    let userResults = await loginUser(dispatch, item);
    if (userResults) {
      props.onUserLoginSuccess(true);
      let roleName;
      if (userResults.userId != undefined) {
        roleName = await getUserRoleById(dispatch, userResults.userId);
      }
      props.onRoleNameUpdated(roleName, userDetails.emailId);
    }
  }

  return (
    <CustomParagraph>
      <GridContainer>
        <GridItem sm={2} md={4} xs={6}></GridItem>
        {/* <GridItem sm={2} md={4} xs={6}></GridItem> */}
        <CustomPaper elevation={10} style={paperStyle}>
          <GridItem sm={4} md={4} xs={4}>
            <div className="login">
              <img height={50} width={100} src={ampslogo} />
            </div>
          </GridItem>
          <GridItem sm={4} md={4} xs={4}>
            <div className="vl"></div>
          </GridItem>
          <GridItem sm={4} md={12} xs={4}>
            <div className="amps">
              <CustomHeader4 labelText={"AMPS IPU"} />
              <CustomHeader4 labelText={"Sign In"} />
            </div>
          </GridItem>

          <GridItem sm={10} md={10} xs={10}>
            <hr className="logoheight" />
          </GridItem>
          <div className="inputheight" />
          <GridItem sm={10} md={10} xs={10}>
            <CustomInput
              fullWidth={true}
              isSkyblue
              labelText={"Email Id"}
              // variant={"outlined"}
              type="mail"
              placeholder="Enter your EmailId"
              onChange={(e) => setEmailId(e.target.value)}
              onKeyPress={handleKeypress}
            />
          </GridItem>
          <div className="passwordheight" />
          <GridItem sm={10} md={10} xs={10}>
            <CustomInput
              fullWidth={true}
              labelText={"Password"}
              isSkyblue
              type={passwordInputType}
              endAdornment={ToggleIcon}
              placeholder="Type your Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              onKeyPress={handleKeypress}
            />
          </GridItem>
          <GridItem sm={10} md={10} xs={10}>
            <CustomButton
              onClick={() => {
                login();
              }}
              variant="contained"
              type="submit"
              size="small"
              disabled={emailId.length == 0 || password.length == 0}
              style={{
                marginLeft: "300px",
                marginTop: "50px",
                variant: "outLined",
                backgroundColor:
                  emailId.length == 0 || password.length == 0
                    ? disabledColor
                    : skyblueColor,
                color: "white",
              }}
            >
              LOGIN
            </CustomButton>
          </GridItem>
        </CustomPaper>

        <GridItem sm={4} md={3} xs={10}></GridItem>
        <GridItem sm={4} md={3} xs={10}></GridItem>
        <GridItem sm={2} md={6} xs={12}></GridItem>
      </GridContainer>
    </CustomParagraph>
  );
};
export default Login;

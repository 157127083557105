
export const SPECS_LKP = 'Spec';
export const SUB_SPEC_LKP = 'Sub Spec';
export const MIN_MAX_AGE_LKP = 'Min Max Age';
export const REVENUE_CODE_LKP = 'Revenue Code';  
export const BILL_TYPE_LKP = 'Bill Type';
export const CONDITION_CODE_LKP = 'Condition Code';
export const MOD_LKP = 'Modifier';
export const POS_LKP = 'POS';
export const POLICY_CATEGORY_LKP = 'Policy Category';
export const REASON_CODE_LKP = 'Reason Code';
export const GET_BILL_TYPE_TO_CLM_TYPE_LOOKUP = 'Bill Type to Claim Type';
export const CCI_RATIONALE_LKP = 'CCI Rationale';
export const CCI_LKP = 'CCI';
export const MUE_RATIONALE_LKP = 'MUE Rationale';
export const BO_TYPE_LKP = 'BO Type';
export const BW_TYPE_LKP = 'BW Type';
export const MAX_UNITS_TYPES = 'Max Units Type';
export const MAX_UNITS_LKP = 'Max Units';
export const MODIFIER_PRIORITY_LKP = 'Modifier Priority';
export const MODIFIER_INTERACTION_LKP ='Modifier Interaction';
export const SAME_OR_SIMILAR_CODE_LKP = 'Same Or Similar Code';
export const MODIFIER_PAY_PERCENTAGE_LKP = 'Modifier Pay Percentage Lkp';
export const POST_MOD_LKP_DATA = 'Post Mod Lkp Data';
export const EDIT_MOD_LKP_DATA = 'Edit Mod Lkp Data';
export const MODIFIER_INTRACTION_TYPE = 'modifierInteractionType';
export const CLAIM_TYPE_LINK_LKP = 'claimTypeLinkLkp';
export const MODIFIER_PAY_PERCENTAGE = 'Modifier Pay Percentage';
export const MODIFIER_PAY_PERCENTAGE_LKP_DATA= 'Modifier Pay Percentage Lkp Data';
export const POS_LKP_DATA = 'Pos Lkp Data';




import { CacheProvider } from "@emotion/react";
import { ButtonGroup, CircularProgress } from "@material-ui/core";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  CssBaseline,
  Dialog,
  DialogContent
} from "@material-ui/core";
import { ThemeProvider } from "@mui/material/styles";
import { useState } from "react";
import IdleTimer from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router, Navigate, Route, Routes
} from "react-router-dom";
import LocalizationProvider from "@mui/lab/LocalizationProvider/LocalizationProvider";
import "./App.css";
import { dangerColor, navyColor } from "./assets/jss/material-kit-react";
import CustomButton from "./components/CustomButtons/CustomButton";
import { DashboardLayout } from "./components/dashboardLayout";
import Dialogbox from "./components/Dialog/DialogBox";
import "./components/FontAwesomeIcons";
import AddCrawler from "./pages/AddCrawler/AddCrawler";
import Cache from "./pages/Cache/Cache";
import Claim from "./pages/Claims/Claims";
import ConfigValidationReport from "./pages/ConfigValidationReport/ConfigValidationReports";
import DeltaConfig from "./pages/DeltaConfig/DeltaConfig";
import GroupTask from "./pages/GroupTask/GroupTask";
import Login from "./pages/Login/Login";
import ViewConfig from "./pages/LookUps/ViewConfig";
import MetaDataLoader from "./pages/MetaDataLoader/MetaDataLoader";
import MyTask from "./pages/MyTask/MyTask";
import NewPolicy from "./pages/NewPolicy/NewPolicy";
import RbrvsReport from "./pages/RbrvsReport/RbrvsReport";
import Search from "./pages/search/Search";
import TestingReport from "./pages/TestingReport/TestingReport";
import CreateUser from "./pages/Users/CreateUser";
import UserList from "./pages/Users/UsersList";
import ViewCrawler from "./pages/ViewCrawler/ViewCrawler";
import ViewMeta from "./pages/ViewMeta/ViewMeta";
import ViewPolicy from "./pages/ViewPolicy/ViewPolicy";
import { DIALOG, SET_IS_LOADING } from "./redux/actions/actionTypes";
import { RESET_LOGIN_DETAILS } from "./redux/actions/UserActionTypes";
import { NewPolicyFormState } from "./redux/reducers/NewPolicyFormReducer";
import { UserState } from "./redux/reducers/UserReducer";
import { theme } from "./theme";
import { createEmotionCache } from "./utils/create-emotion-cache";
import PolicyDisplay from "./pages/PolicyDisplay/PolicyDisplay";
import ClientgroupExclusions from "./pages/ClientPolicyExclusion/ClientgroupExclusions";
import AddExclusion from "./pages/ClientPolicyExclusion/AddExclusion";
import PolicyViewExport from "./pages/PolicyDisplay/PolicyViewExport";
import PolicyUpdateReport from "./pages/PolicyUpdateReport/PolicyUpdateReport";
import NewClientSetUp from "./pages/NewClientSetUp/NewClientSetUp";

const clientSideEmotionCache = createEmotionCache();
const App = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const dispatch = useDispatch();

  const [isLoggedIn, setIsloggedIn] = useState(false);
  const [roleName, setRoleName] = useState<string[]>([]);
  const [IsTimedout, setIsTimedout] = useState(false);
  const currentYear = new Date().getFullYear();
  const [user, setUser] = useState("");
  const [message, setMessage] = useState("");
  const updatedState = useSelector((state: any) => state.spinnerReducer);
  const dialogState = useSelector((state: any) => state.dialogReducer);
  const [button, setbutton] = useState(false);
  const roleState: UserState = useSelector((state: any) => state.userReducer);

  const formState: NewPolicyFormState = useSelector(
    (state: any) => state.newPolicyForm
  );
  let emailId = formState.userDetails.emailId;
  let data = roleState.roleName;
  let Role = JSON.stringify(data);
  let researchIdx = Role.toLocaleLowerCase().search("research");
  let adminIdx = Role.toLocaleLowerCase().search("admin");

  if (!isLoggedIn) {
    return (
      <>
        <Dialogbox
          message={dialogState.message}
          title={dialogState.title}
          open={dialogState.isDialog}
          onClose={() => {
            dispatch({ type: DIALOG, payload: false });
          }}
          actions={
            <CustomButton
              style={{ color: "white", fontSize: 12, backgroundColor: navyColor }}
              onClick={() => {
                dispatch({ type: DIALOG, payload: false });
              }}
            >
              Ok
            </CustomButton>
          }
        />

        <Login
          onUserLoginSuccess={(success: boolean) => setIsloggedIn(success)}
          onRoleNameUpdated={(rn, user) => {
            setRoleName(rn);
            setUser(user);
          }}
        />
      </>
    );
  } else {
    return (
      <CacheProvider value={emotionCache}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <IdleTimer
              element={document}
              onIdle={() => {
                setIsTimedout(true);
                // setTimeout(() => {
                //   dispatch({ type: RESET_LOGIN_DETAILS });
                //   localStorage.clear();
                //   sessionStorage.clear();
                //   setIsloggedIn(false);
                //   setIsTimedout(false);
                // }, 5000)
              }}
              debounce={2500}
              timeout={1000 * 60 * 5}
            />
            <Dialogbox
              open={IsTimedout}
              onClose={() => {
                dispatch({ type: DIALOG, payload: false });
                setIsTimedout(false);
              }}
              disableBackdropClick={true}
              title={
                <> <ErrorOutlineRoundedIcon
                  style={{
                    fontSize: "30px",
                    marginLeft: "5px",
                    color: "red",
                  }}
                ></ErrorOutlineRoundedIcon>
                  Session Timeout
                </>
              }
              message={
                <>
                  <p style={{ textAlign: 'center', fontSize: '16px' }}>No recent activity! Do you want to stay on this page?</p>
                </>
              }
              actions={
                <ButtonGroup>
                  <CustomButton
                    onClick={() => {
                      setIsTimedout(false);
                    }}
                    style={{
                      backgroundColor: navyColor,
                      color: "white",
                      fontSize: 12,
                      size: "small",
                      textTransform: "capitalize",
                      marginRight: 10,
                    }}
                  >
                    Yes
                  </CustomButton>
                  <CustomButton
                    onClick={() => {
                      dispatch({ type: RESET_LOGIN_DETAILS });
                      // localStorage.clear();
                      // sessionStorage.clear();
                      setIsloggedIn(false);
                      setIsTimedout(false);
                    }}
                    style={{
                      backgroundColor: dangerColor,
                      color: "white",
                      size: "small",
                      textTransform: "capitalize",
                      fontsize: 12
                    }}
                  >
                    No
                  </CustomButton>
                </ButtonGroup>
              }
            />
            <Router>
              <DashboardLayout
                user={user}
                roleName={roleName}
                onLogout={() => setIsloggedIn(false)}
              >
                <Dialog
                  onClose={() => {
                    dispatch({ type: SET_IS_LOADING, payload: false });
                  }}
                  open={updatedState.isLoading}
                  disableBackdropClick={true}
                >
                  <DialogContent>
                    <CircularProgress />
                  </DialogContent>
                </Dialog>

                <Dialogbox
                  message={dialogState.message}
                  title={dialogState.title}
                  onClose={() => {
                    dispatch({ type: DIALOG, payload: false });
                  }}
                  open={dialogState.isDialog}
                  actions={
                    <CustomButton
                      style={{ color: "white", textTransform: "capitalize", fontSize: 12, backgroundColor: navyColor }}
                      onClick={() => {
                        dispatch({ type: DIALOG, payload: false });
                      }}
                    >
                      Ok
                    </CustomButton>
                  }
                //  title={"warning"}
                />
                <Routes>
                  <Route path="/" element={<Navigate replace to="/search" />} />
                  <Route path="/search" element={<Search />}></Route>
                  <Route path="/policyView" element={<PolicyDisplay />}></Route>
                  <Route path="/ClientgroupExclusions" element={<ClientgroupExclusions/>}></Route>
                  <Route path="/NewClientSetUp" element={<NewClientSetUp/>}></Route>

                  <Route path="/viewConfig" element={
                    <ViewConfig />
                  }></Route>
                  <Route path="/viewMeta" element={
                    <ViewMeta />
                  }></Route>
                  <Route path="/viewPolicy" element={<ViewPolicy />}></Route>
                  <Route path="/claim" element={<Claim />}></Route>
                  {/* <Route path="/viewConfig" element={<LookUpColumns />}></Route> */}

                  <Route
                    path="/testingReport"
                    element={<TestingReport />}
                  ></Route>
                  <Route
                    path="/configValidation"
                    element={<ConfigValidationReport />}
                  ></Route>
                  <Route
                    path="/rbrvsReport"
                    element={<RbrvsReport />}
                  >
                  </Route>
                  {getPrivateRoutes().map((r) => r)}
                </Routes>
              </DashboardLayout>
            </Router>
          </ThemeProvider>
        </LocalizationProvider>
      </CacheProvider>
    );
  }
  function getPrivateRoutes() {
    let routes = [];
    if (adminIdx > 0) {
      routes.push(
        <Route path="/rbrvsReport" element={<RbrvsReport />}></Route>
      );
      routes.push(
        <Route path="/metaDataLoader" element={<MetaDataLoader />}></Route>
      );
      routes.push(<Route path="/policyViewExport" element={<PolicyViewExport />}></Route>);

      routes.push(<Route path="/groupTask" element={<GroupTask
        onLogout={() => setIsloggedIn(false)}
      />}></Route>);
      routes.push(
        <Route
          path="/newPolicy"
          element={
            <NewPolicy
              edit={false}
              fromViewPolicy={false}
              showImportButton={true}
              customCreatedB={false}
            />
          }
        ></Route>
      );
     
      // routes.push(<Route path="/loadConfig" element={<LoadConfig />}></Route>);
      routes.push(
        <Route path="/viewCrawler" element={<ViewCrawler />}></Route>
      );
      routes.push( <Route path="/NewClientSetUp" element={<NewClientSetUp/>}></Route>);

      routes.push(<Route path="/addCrawler" element={<AddCrawler fromViewCrawler={false} />}></Route>);
      routes.push(<Route path="/editCrawler" element={<AddCrawler fromViewCrawler={true} />}></Route>);
      routes.push(<Route path="/userList" element={<UserList />}></Route>);
      routes.push(
        <Route path="/createUser" element={<CreateUser edit={false} />}></Route>
      );
      routes.push(
        <Route path="/editUser" element={<CreateUser edit={true} />}></Route>
      );
      routes.push(
        <Route path="/deltaConfig" element={<DeltaConfig />}></Route>
      );
      routes.push(
        <Route path="/addExclusion" element={<AddExclusion />}></Route>
      );
      routes.push(
        <Route path="/PolicyUpdateReport" element={<PolicyUpdateReport />}></Route>
        );
      routes.push(
        <Route path="/policyViewExport" element={<PolicyViewExport />}></Route>
      );
      routes.push(<Route path="/myTask" element={<MyTask />}></Route>);
      routes.push(
        <Route
          path="/editPolicy"
          element={
            <NewPolicy
              edit={false}
              fromViewPolicy={true}
              showImportButton={true}
              customCreatedB={false}
            />
          }
        ></Route>
      );
      routes.push(<Route path="/cache" element={<Cache />}></Route>);
      return routes;
    }
    if (researchIdx > 0) {
      // let emailId1 = JSON.parse(sessionStorage.getItem("user-info"))?.emailId;
      let emailId1 = emailId;
      routes.push(
        <Route path="/metaDataLoader" element={<MetaDataLoader />}></Route>
      );
      routes.push(<Route path="/groupTask" element={<GroupTask onLogout={() => setIsloggedIn(false)} />}></Route>);
      routes.push(<Route path="/myTask" element={<MyTask />}></Route>);
      routes.push(
        <Route path="/deltaConfig" element={<DeltaConfig />}></Route>
      );
      routes.push(
      <Route path="/PolicyUpdateReport" element={<PolicyUpdateReport />}></Route>);
      routes.push(
        <Route
          path="/newPolicy"
          element={
            <NewPolicy
              edit={false}
              fromViewPolicy={false}
              showImportButton={true}
              customCreatedB={false}
            />
          }
        ></Route>
      );
      if (formState.userId == emailId1 || formState.userId == "") {
        routes.push(
          <Route
            path="/editPolicy"
            element={
              <NewPolicy
                edit={false}
                fromViewPolicy={true}
                showImportButton={true}
                customCreatedB={false}
              />
            }
          ></Route>
        );
      }
      return routes;
    }

    return [];
  }
};

export default App;

export const enum ViewMetaConstants {
  ICD_10_CM = "ICD_10_CM",
  CCI = "CCI",
  OCE_HCPCS = "OCE-HCPCS",
  MFS = "MFS",
  MFS_DATE_BINDED = "MFS DATE BINDED",
  APC_DATE_BINDED = "APC DATE BINDED",
  CAPC_DATE_BINDED = "CAPC DATE BINDED",
  HCPCS_DATE_BINDED = "OCE-HCPCS DATE BINDED",
  HCPCS = "HCPCS",
  ADD_ON_CODES = "ADD ON CODES",
  CPT = "CPT/HCPCS",
  ICD = "ICD",
  BW_Pairs = "BW Pairs",
  Modifier_Interaction = "Modifier Interaction",
  GPCI = "GPCI",
  GPCI_DATE_BINDED = "GPCI DATE BINDED",
  ZIP_5 = "ZIP 5",
  ZIP_9 = "ZIP 9",
  MAX_UNITS = "MAX UNITS",
  ZIP_5_DATE_BINDED = "ZIP 5 DATE BINDED",
  ZIP_9_DATE_BINDED = "ZIP 9 DATE BINDED",
  CLIENT_SPECIFIC = "CLIENT SPECIFIC"
}

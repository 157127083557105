import { IconButton } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import Moment from "moment";
import { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { disabledColor, navyColor } from "../../assets/jss/material-kit-react";
import CustomCheckBox from "../../components/CustomCheckBox/CustomCheckBox";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomLink from "../../components/CustomLink/CustomLink";
import CustomPaper from "../../components/CustomPaper/CustomPaper";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import BootstrapGrid from "../../components/TableGrid/BootStrapGrid";
import {
  JIRA_DESCRIPTION,
  JIRA_ID,
  JIRA_ISOPEN,
  JIRA_LINK,
  TEMP_USER,
  USER_ID,
} from "../../redux/actions/NewPolicyFormActionTypes";
import { getUserList } from "../../redux/actions/UserAction";
import { NewPolicyFormState } from "../../redux/reducers/NewPolicyFormReducer";
import { UserState } from "../../redux/reducers/UserReducer";
import "../NewPolicy/NewPolicy.css";
import { PolicyConstants } from "./PolicyConst";
import { PolicyState } from "../../redux/reducers/PolicyReducer";

const _ = require("lodash");

const colStyle = {
  backgroundColor: navyColor,
  color: "white",
  fontWeight: "400px",
  fontFamily: "Arial, Helvetica, sans-serif",
  width: "130px",
};
const userIdStyle = {
  backgroundColor: navyColor,
  color: "white",
  fontWeight: "400px",
  fontFamily: "Arial, Helvetica, sans-serif",
  width: "150px",
};
const JiraLink = {
  backgroundColor: navyColor,
  color: "white",
  fontWeight: "400px",
  fontFamily: "Arial, Helvetica, sans-serif",
  width: "250px",
};
const columns = [
  {
    dataField: "jiraId",
    text: "Jira ID",
    sort: true,
    headerStyle: colStyle,
    headerTitle: true,
  },
  {
    dataField: "jiraDesc",
    text: "Jira Desc",
    sort: true,
    headerStyle: JiraLink,
    headerTitle: true,
    formatter: (cellValues) => {
      return (
        <span
          style={{ whiteSpace: "nowrap", overflow: "hidden", display: "block" }}
          title={`${cellValues}`}
        >{`${cellValues}`}</span>
      );
    },
  },
  {
    dataField: "jiraLink",
    text: "Jira Link",
    sort: true,
    headerStyle: JiraLink,
    headerTitle: true,
    formatter: (cell) => {
      return (
        <CustomLink
          title={cell}
          href={cell}
          labelText={cell}
          target={"_blank"}
        />
      );
    },
  },
  {
    dataField: "userId",
    text: "User ID",
    sort: true,
    headerStyle: userIdStyle,
    headerTitle: true,
    formatter: (cellValues) => {
      return <span title={`${cellValues}`}>{`${cellValues}`}</span>;
    },
  },
  {
    dataField: "updatedOn",
    text: "Updated On",
    sort: true,
    headerStyle: colStyle,
    formatter: (cell) => Moment(cell).format("MM-DD-YYYY hh:mm:ss"),
    headerTitle: true,
    resizable: false,
  },
];

const Changes = ({ edit, viewMode }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [role1, setRole] = useState(false);
  const [research, setResarch] = useState(false);
  const [isJiraValid, setIsJiraValid] = useState(false);

  const userDataCM = userData?.map((rs) => {
    return { label: rs.emailId, value: rs.emailId };
  });
  const formState: NewPolicyFormState = useSelector(
    (state: any) => state.newPolicyForm
  );
  const policyCreateState: PolicyState = useSelector(
    (state: any) => state.policyCreation
  );
  const roleState: UserState = useSelector((state: any) => state.userReducer);
  // let emailId1 = JSON.parse(sessionStorage.getItem("user-info"))?.emailId;
  let emailId1 = formState.userDetails.emailId;
  // let one = JSON.parse(sessionStorage.getItem("user-info"));
  let emailTest = {
    // label: one.emailId,
    // value: one.emailId,
    label: emailId1,
    value: emailId1,
  };
  const location = useLocation();
  const paths = location.pathname.replaceAll("/", "");
  if (edit) {
    if (formState.userId) {
      formState.userId = formState.userId;
    } else {
      formState.userId = emailId1;
    }
  } else {
    formState.userId = emailId1;
  }

  useEffect(() => {
    if (
      formState.isJira != "" &&
      formState.isJira != undefined &&
      formState.isJiraDesc != "" &&
      formState.isJiraDesc != undefined
    ) {
      setIsJiraValid(true);
    } else {
      setIsJiraValid(false);
    }
  });

  useEffect(() => {
    const changesData = formState.changesTableData?.map((gt) => {
      return {
        ...gt,
        id: gt.jiraId,
        updatedOn: new Date(gt.updatedOn), // Store as Date object
      };
    });
    setData(changesData);
  }, [formState]);
  

  useEffect(() => {
    if (updatedState.users.length == 0) {
      getUserList(dispatch);
    }
  }, []);

  const updatedState: UserState = useSelector(
    (state: any) => state.userReducer
  );
  useEffect(() => {
    setUserData(updatedState.users);
  }, [updatedState]);

  useEffect(() => {
    if (edit) {
      setOnShow(!onShow);
    }
  }, []);
  useEffect(() => {
    if (edit) {
      if (
        formState.jiraId == "" &&
        formState.jiraDescription == "" &&
        formState.changesTableData.length == 0
      ) {
        setOnShow(onShow);
      }
    }
    if (paths == PolicyConstants.VIEW_POLICY) {
      if (
        formState.jiraId == "" &&
        formState.jiraDescription == "" &&
        formState.changesTableData.length == 0
      ) {
        setOnShow(!onShow);
      }
    }
  }, [formState]);

  // let userDetails = sessionStorage.getItem("user-info");

  useEffect(() => {
    let data = roleState.roleName;
    let Role = JSON.stringify(data);
    let adminIdx = Role.toLocaleLowerCase().search("admin");
    let researchIdx = Role.toLocaleLowerCase().search("research");
    if (adminIdx > 0) {
      setRole(true);
    }
    if (researchIdx > 0) {
      setResarch(true);
    }
  }, [roleState]);

  const [onShow, setOnShow] = useState(edit);
  const [showBorder, setShowBorder] = useState(false);
  useEffect(() => {
    if (formState.changesTableData.length == 0 && onShow) {
      setShowBorder(true);
    }
    if (formState.changesTableData.length == 0 && !onShow) {
      setShowBorder(false);
    }
    if (formState.changesTableData.length > 0 && onShow) {
      setShowBorder(true);
    }
  }, [onShow]);

  useEffect(() => {
    //@ts-ignore
    if (formState.jiraIsOpen == 1 ? true : false) {
      setOnShow(onShow);
    }
  }, []);
  function checkIsOpen() {}
  useEffect(() => {
    if (!edit) {
      setOnShow(!onShow);
    }
  }, []);

  return (
    <div>
      <CustomPaper
        style={{
          paddingLeft: 10,
          position: "relative",
          right: 20,
          paddingRight: 8,
          paddingTop: 10,
          paddingBottom: 4,
          boxShadow: "none",
          border: showBorder ? "1px solid #D6D8DA" : "",
        }}
      >
        {edit ? (
          <IconButton
            onClick={() => {
              checkIsOpen();
              setOnShow(!onShow);
            }}
            style={{
              backgroundColor: viewMode ? disabledColor : navyColor,
              float: "right",
              color: "white",
              padding: 5,
              marginTop: -2,
            }}
            disabled={viewMode}
          >
            <Add />
          </IconButton>
        ) : undefined}
        <div
          style={{
            pointerEvents: viewMode ? "none" : "visible",
            opacity: viewMode ? 0.7 : 1,
          }}
        >
          <GridContainer style={{ marginTop: -15 }}>
            {onShow ? (
              <>
                <GridItem sm={1} md={1} xs={1}>
                  <CustomInput
                    error={formState.errors.jiraId}
                    fullWidth={true}
                    disabled={edit && isJiraValid}
                    labelText={"Jira ID"}
                    showStarIcon={true}
                    onChange={(event) => {
                      dispatch({
                        type: JIRA_ID,
                        payload: event.target.value,
                      });
                      dispatch({
                        type: JIRA_LINK,
                        payload:
                          "https://advancedpricing.atlassian.net/browse/" +
                          event.target.value,
                      });
                    }}
                    value={formState.jiraId}
                    variant={"outlined"}
                  />
                </GridItem>
                <GridItem sm={2} md={2} xs={2}>
                  <CustomInput
                    error={formState.errors.jiraDescription}
                    fullWidth={true}
                    disabled={edit && isJiraValid}
                    labelText={"Jira Description"}
                    showStarIcon={true}
                    value={formState.jiraDescription}
                    onChange={(event) => {
                      dispatch({
                        type: JIRA_DESCRIPTION,
                        payload: event.target.value,
                      });
                    }}
                    onBlur={(e) => {
                      dispatch({
                        type: JIRA_DESCRIPTION,
                        payload: e.target.value,
                      });
                    }}
                    variant={"outlined"}
                  />
                </GridItem>
                <GridItem sm={4} md={4} xs={4}>
                  <CustomInput
                    error={formState.errors.jiraLink}
                    disabled={edit && formState.jiraLink?.length > 0}
                    fullWidth={true}
                    value={formState.jiraLink}
                    labelText={"Jira Link"}
                    showStarIcon={true}
                    onChange={(event) => {}}
                    variant={"outlined"}
                  />
                </GridItem>
                {role1 && edit ? (
                  <GridItem sm={3} md={3} xs={3}>
                    <CustomSelect
                      options={userDataCM}
                      labelText={"User ID"}
                      showStarIcon={true}
                      onSelect={(e) => {
                        if (e != null) {
                          dispatch({ type: TEMP_USER, payload: e });
                        }
                      }}
                      value={
                        formState.tmpUser?.label == "" ||
                        formState.tmpUser?.label == undefined
                          ? emailTest
                          : formState.tmpUser
                      }
                    />
                  </GridItem>
                ) : (
                  <GridItem sm={3} md={3} xs={3}>
                    <CustomInput
                      fullWidth={true}
                      labelText={"User ID"}
                      value={edit ? formState.userId : emailId1}
                      onChange={(event) => {
                        dispatch({
                          type: USER_ID,
                          payload: event.target.value,
                        });
                      }}
                      disabled={edit && research}
                      variant={"outlined"}
                    />
                  </GridItem>
                )}
                <GridItem sm={1} md={1} xs={1}>
                  <div style={{ marginTop: 12 }}>
                    <CustomCheckBox
                      style={{ marginLeft: -20, marginTop: 18 }}
                      checked={formState.jiraIsOpen == 1}
                      label={<span style={{ fontSize: "12px" }}>Open</span>}
                      onChange={(event) => {
                        // formState.jiraIsOpen = event.target.checked ? 1 : 0;
                        dispatch({
                          type: JIRA_ISOPEN,
                          payload: event.target.checked,
                        });
                      }}
                    />
                  </div>
                </GridItem>
              </>
            ) : undefined}
          </GridContainer>
        </div>
        {edit && data.length > 0 ? (
          <>
            <div className="table-data">
              <BootstrapGrid
                //@ts-ignore
                columns={columns}
                data={data}
                style={{ height: "100%", textAlign: "center" }}
              />
            </div>
          </>
        ) : undefined}
      </CustomPaper>
      <small style={{ position: "relative", fontSize: "12px", top: "7px" }}>
        Number of rows : {data.length}
      </small>
    </div>
  );
};

export default Changes;

import { SET_SELECTED_POLICY } from "../actions/NewPolicyFormActionTypes";

export interface ViewPolicyState {
    selectedPolicy: any
}

const initialState: ViewPolicyState = {
    selectedPolicy: undefined
};

export default function ViewPolicyReducer(
  state = initialState,
  action: { type: string; payload: any }
): ViewPolicyState {
  switch (action.type) {
    case SET_SELECTED_POLICY:
      return { ...state, selectedPolicy: action.payload };
    default:
      return state;
  }
}
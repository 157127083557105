import { applyMiddleware, configureStore } from "@reduxjs/toolkit";
import NewPolicyReducer from "./reducers/NewPolicyReducer";
import { combineReducers, createStore } from "redux";
import NewPolicyFormReducer from "./reducers/NewPolicyFormReducer";
import MetaDataLoaderReducer from "./reducers/MetaDataLoaderReducer";
import LoadConfigStateReducer from "./reducers/LoadConfigReducer";
import TaskReducer from "./reducers/TaskReducer";
import CrawlerReducer from "./reducers/CrawlerReducer";
import UserReducer from "./reducers/UserReducer";
import LookUpReducer from "./reducers/LookUpReducer";
import TestingReportReducer from "./reducers/TestingReportReducer";
import ViewPolicyReducer from "./reducers/ViewPolicyReducer";
import SpinnerReducer from "./reducers/SpinnerReducer";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from "redux-thunk";
import DialogReducer from "./reducers/DialogReducer";
import SearchPolicyReducer from "./reducers/SearchPolicyReducer";
import ClaimReducer from "./reducers/ClaimReducer";

import UserReducers from "./reducers/UserReducer";
import AggridReducer from "./reducers/AggridReducer";
import PolicyViewReducer from "./reducers/PolicyViewReducer";

import ClientPolicyReducer from "./reducers/ClientPolicyReducer";
import ReferentialDataReducer from "./reducers/ReferentialDataReducer";
import CustomPolicyReducer from "./reducers/CustomPolicyReducer";
import PolicyReducer from "./reducers/PolicyReducer";
import NewClientSetUpReducer from "./reducers/NewClientSetUpReducer";

const persistConfig = {
  key: 'root',
  storage,
};
const persistedReducer = persistReducer(persistConfig, NewPolicyFormReducer);
const crawlerPersistedReducer = persistReducer(persistConfig, CrawlerReducer);
const TasksReducer = persistReducer(persistConfig, TaskReducer);
const userPersistReducer = persistReducer(persistConfig, UserReducers);
const claimReducers = persistReducer(persistConfig, ClaimReducer);
const testingReducer = persistReducer(persistConfig, TestingReportReducer);
const ReferentialReducer = persistReducer(persistConfig, ReferentialDataReducer);
const customPolicyReducer = persistReducer(persistConfig, CustomPolicyReducer);
const policyCreationReducer = persistReducer(persistConfig, PolicyReducer);



const rootReducer = combineReducers({
  newPolicy: NewPolicyReducer,
  newPolicyForm: persistedReducer,
  metaDataLoader: MetaDataLoaderReducer,
  loadConfigLoader: LoadConfigStateReducer,
  taskReducer: TasksReducer,
  crawlerReducer: crawlerPersistedReducer,
  userReducer: userPersistReducer,
  lookupReducer: LookUpReducer,
  testingReportReducer: testingReducer,
  viewPolicy: ViewPolicyReducer,
  spinnerReducer: SpinnerReducer,
  dialogReducer: DialogReducer,
  searchPolicyReducer: SearchPolicyReducer,
  claimReducer: claimReducers,
  policyViewReducer:PolicyViewReducer,
  gridState: AggridReducer,
  clientPolicy:ClientPolicyReducer,
  ReferentialDataReducer:ReferentialReducer,
  customPolicy:customPolicyReducer,
  policyCreation:policyCreationReducer,
  newClientSetUp:NewClientSetUpReducer
});
export const store = createStore(rootReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);


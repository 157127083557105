import { Navigate, useNavigate } from "react-router-dom";
import { navyColor } from "../../assets/jss/material-kit-react";
import { policyConfigUrl, apiUrl } from "../../configs/apiUrls";
import { store } from "../store";
import { SET_IS_LOADING } from "./actionTypes";
import swal from 'sweetalert2';
import { GET_ALL_CHANGES_DATA, GET_TOTAL_CLIENT_ASSIGNMENT_DATA, RESETNEWCLIENT } from "./NewClientSetUpActionTypes";


export async function saveNewClientSetUp(dispatch: typeof store.dispatch,newClientSetUp,navigate ) {

    try {
      dispatch({ type: SET_IS_LOADING, payload: true });
      const response = await fetch(policyConfigUrl + apiUrl.saveNewClientSetUpData , {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        
        body: JSON.stringify(newClientSetUp),
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.statusText}`);
      }else{
        swal.fire({
          icon: 'success',
          text: 'Client Details Added Successfully',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        }).then((res)=>{
          if(res.isConfirmed){
            navigate("/NewClientSetUp")
          }
        })
        dispatch({ type: SET_IS_LOADING, payload: false });
      }
      dispatch({type:RESETNEWCLIENT});
      
    } catch (error) {  
      dispatch({ type: SET_IS_LOADING, payload: false });
      swal.fire({
        icon: 'error',
        text: 'Please Reach out IT Team',
        confirmButtonColor: navyColor,
        confirmButtonText: 'OK',
      });
    }
  }

  export async function getTotalClientAssignmentData(dispatch: typeof store.dispatch) {
    await fetch(`${policyConfigUrl + apiUrl.getTotalClientAssignmentData}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        dispatch({ type: GET_TOTAL_CLIENT_ASSIGNMENT_DATA, payload: data });
      });
  }

  export async function getAllChangesData(dispatch: typeof store.dispatch) {
    await fetch(`${policyConfigUrl + apiUrl.getChanges}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        dispatch({ type: GET_ALL_CHANGES_DATA, payload: data });
      });
  }
import { policyConfigUrl, apiUrl, dataCurationETL } from '../../configs/apiUrls';
import { store } from '../store';
import {
  GET_CLAIM_TYPE_LINK_LKP,
  GET_MODIFIER_PAY_PERCENTAGE,
  SET_IS_LOADING,
} from './actionTypes';
import {
  EDIT_MOD_LKP,
  GET_BILL_TYPE_LOOKUP,
  GET_BO_LOOKUP,
  GET_BW_TYPE_LOOKUP,
  GET_CCI_LOOKUP,
  GET_CONDITION_LOOKUP,
  GET_MAX_UNITS_LKP,
  GET_MAX_UNITS_TYPES,
  GET_MIN_MAX_AGE_LOOKUP,
  GET_MODIFIER_INTERACTION_LKP,
  GET_MODIFIER_INTERACTION_TYPE_LKP,
  GET_MODIFIER_PRIORITY_LKP,
  GET_MOD_LOOKUP,
  GET_MUE_RATIONALE_LOOKUP,
  GET_POLICY_CATEGORY_LOOKUP,
  GET_POS_LOOKUP,
  GET_REASON_LOOKUP,
  GET_REVENUE_LOOKUP,
  GET_SPEC_LOOKUP,
  GET_SUB_SPEC_LOOKUP,
  POST_BILL_TYPE,
  POST_CCI_RATIONALE_LKP,
  POST_CONDITION_CODE,
  POST_MIN_VS_MAX,
  POST_MOD_LKP,
  POST_POLICY_CATEGORY_LKP,
  POST_POS_LKP,
  POST_REASON_CODE_LKP,
  POST_REVENUE_CODE,
  POST_SPEC,
  POST_SUB_SPEC,
} from './LookupActionTypes';
import swal from 'sweetalert2';
import { navyColor } from '../../assets/jss/material-kit-react';
import {
  BILL_TYPE_LKP,
  BO_TYPE_LKP,
  BW_TYPE_LKP,
  CCI_LKP,
  CCI_RATIONALE_LKP,
  CLAIM_TYPE_LINK_LKP,
  CONDITION_CODE_LKP,
  EDIT_MOD_LKP_DATA,
  MAX_UNITS_LKP,
  MAX_UNITS_TYPES,
  MIN_MAX_AGE_LKP,
  MODIFIER_INTERACTION_LKP,
  MODIFIER_INTRACTION_TYPE,
  MODIFIER_PAY_PERCENTAGE_LKP_DATA,
  MODIFIER_PRIORITY_LKP,
  MOD_LKP,
  MUE_RATIONALE_LKP,
  POLICY_CATEGORY_LKP,
  POST_MOD_LKP_DATA,
  POS_LKP_DATA,
  REASON_CODE_LKP,
  REVENUE_CODE_LKP,
  SAME_OR_SIMILAR_CODE_LKP,
  SPECS_LKP,
  SUB_SPEC_LKP,
} from '../../pages/LookUps/LookUpConsts';
import { GET_MAI_LKP_DATA, GET_MOD_INTRACTION_DATA, GET_MUE_LKP_DATA, GET_Max_UNITS_LKP_DATA, GET_RATIONALE_DATA } from './TaskActionTypes';
import {
  GET_MODIFIER_PAY_PERCENTAGE_LKP,
  MODIFIER_PAY_PERCENTAGE,
} from './NewPolicyFormActionTypes';

export async function fetchLookupData(dispatch, lkpName) {
  await fetch(policyConfigUrl + apiUrl.getLookUpData + '/' + lkpName)
    .then((response) => {
      if (!response.ok) {
        // throw new Error(response.statusText);
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json();
    })
    .then((data) => {
      dispatch({ type: getLookupActionType(lkpName), payload: data });
      dispatch({ type: SET_IS_LOADING, payload: false });
    });
}

const getLookupActionType = (lkpName) => {
  switch (lkpName) {
    case SPECS_LKP:
      return GET_SPEC_LOOKUP;
    case SUB_SPEC_LKP:
      return GET_SUB_SPEC_LOOKUP;
    case MIN_MAX_AGE_LKP:
      return GET_MIN_MAX_AGE_LOOKUP;
    case REVENUE_CODE_LKP:
      return GET_REVENUE_LOOKUP;
    case BILL_TYPE_LKP:
      return GET_BILL_TYPE_LOOKUP;
    case CONDITION_CODE_LKP:
      return GET_CONDITION_LOOKUP;
    case MOD_LKP:
      return GET_MOD_LOOKUP;
    case POS_LKP_DATA:
      return GET_POS_LOOKUP;
    case POLICY_CATEGORY_LKP:
      return GET_POLICY_CATEGORY_LOOKUP;
    case REASON_CODE_LKP:
      return GET_REASON_LOOKUP;
    case MODIFIER_PRIORITY_LKP:
      return GET_MODIFIER_PRIORITY_LKP;
    case MODIFIER_INTERACTION_LKP:
      return GET_MODIFIER_INTERACTION_LKP;
    case MODIFIER_INTRACTION_TYPE:
      return GET_MODIFIER_INTERACTION_TYPE_LKP;
    case SAME_OR_SIMILAR_CODE_LKP:
      return SAME_OR_SIMILAR_CODE_LKP;
    case CCI_LKP:
      return GET_CCI_LOOKUP;
    case CCI_RATIONALE_LKP:
      return GET_RATIONALE_DATA;
    case CLAIM_TYPE_LINK_LKP:
      return GET_CLAIM_TYPE_LINK_LKP;
    case MUE_RATIONALE_LKP:
      return GET_MUE_RATIONALE_LOOKUP;
    case BW_TYPE_LKP:
      return GET_BW_TYPE_LOOKUP;
    case MAX_UNITS_TYPES:
      return GET_MAX_UNITS_TYPES;
    case BO_TYPE_LKP:
      return GET_BO_LOOKUP;
    case MAX_UNITS_LKP:
      return GET_MAX_UNITS_LKP;
    case MODIFIER_PAY_PERCENTAGE:
      return GET_MODIFIER_PAY_PERCENTAGE;
    case MODIFIER_PAY_PERCENTAGE_LKP_DATA:
      return GET_MODIFIER_PAY_PERCENTAGE_LKP;
    default:
      return null; // Handle default case if needed
  }
};
export async function postLookupData(
  dispatch: typeof store.dispatch,
  data,
  isEdit,
  lkpName
) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(policyConfigUrl + apiUrl.postLookUpdata + '/' + lkpName, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response;
    })
    .then((data) => {
      if (data.status == 500) {
        swal.fire({
          icon: 'error',
          text: 'Please fill in required fields',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
      } else {
        if (lkpName == EDIT_MOD_LKP_DATA || lkpName == POST_MOD_LKP_DATA) {
          lkpName = MOD_LKP;
          fetchLookupData(dispatch, lkpName);
        } else {
          fetchLookupData(dispatch, lkpName);
        }
        dispatch({ type: postLookupActionType(lkpName), payload: data });
        dispatch({ type: SET_IS_LOADING, payload: false });
        swal.fire({
          icon: 'success',
          text: isEdit
            ? 'Data Updated successfully'
            : 'Data Added successfully',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
      }
    });
}
const postLookupActionType = (lkpName) => {
  switch (lkpName) {
    case SPECS_LKP:
      return POST_SPEC;
    case SUB_SPEC_LKP:
      return POST_SUB_SPEC;
    case MIN_MAX_AGE_LKP:
      return POST_MIN_VS_MAX;
    case REVENUE_CODE_LKP:
      return POST_REVENUE_CODE;
    case BILL_TYPE_LKP:
      return POST_BILL_TYPE;
    case CONDITION_CODE_LKP:
      return POST_CONDITION_CODE;
    case POST_MOD_LKP_DATA:
      return POST_MOD_LKP;
    case EDIT_MOD_LKP_DATA:
      return EDIT_MOD_LKP;
    case POS_LKP_DATA:
      return POST_POS_LKP;
    case POLICY_CATEGORY_LKP:
      return POST_POLICY_CATEGORY_LKP;
    case REASON_CODE_LKP:
      return POST_REASON_CODE_LKP;
    case CCI_RATIONALE_LKP:
      return POST_CCI_RATIONALE_LKP;
    default:
      return null; // Handle default case if needed
  }
};


export async function getMaxUnitsLkpData(dispatch: typeof store.dispatch) {
  await fetch(dataCurationETL + apiUrl.getMaxUnitsLkpData)
    .then((response) => {
      if (!response.ok) {
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_Max_UNITS_LKP_DATA, payload: data });
    });
}

export async function getModIntractionLkpData(dispatch: typeof store.dispatch) {
  await fetch(dataCurationETL + apiUrl.getModifierIntractionLkpData)
    .then((response) => {
      if (!response.ok) {
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_MOD_INTRACTION_DATA, payload: data });
    });
}
export async function getMaiLkpData(dispatch: typeof store.dispatch) {
  await fetch(dataCurationETL + apiUrl.getMaiLkpData)
    .then((response) => {
      if (!response.ok) {
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_MAI_LKP_DATA, payload: data });
    });
}

export async function getMueLkpData(dispatch: typeof store.dispatch) {
  await fetch(dataCurationETL + apiUrl.getMueLkpData)
    .then((response) => {
      if (!response.ok) {
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_MUE_LKP_DATA, payload: data });
    });
}

import { ButtonGroup, InputAdornment, makeStyles } from "@material-ui/core";
import { MoreHoriz } from "@mui/icons-material";
import { Stack } from "@mui/material";
import Moment from "moment";
import React, { useDebugValue, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  black,
  dangerColor,
  navyColor,
} from "../../assets/jss/material-kit-react";
import CustomButton from "../../components/CustomButtons/CustomButton";
import CustomCheckBox from "../../components/CustomCheckBox/CustomCheckBox";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomPaper from "../../components/CustomPaper/CustomPaper";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import Dialogbox from "../../components/Dialog/DialogBox";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import AgGrids from "../../components/TableGrid/AgGrids";
import {
  getAllowCmsNcciModifiers,
  getCAT,
  getCPTLink,
  getGender,
  getIgnoreModifier,
  getMaxAge,
  getMinAge,
  getNPI,
  getPOSLINK,
  getRevenueCodeClaimLink,
  getSubSpeciality,
  getTaxLogic,
} from "../../redux/actions";
import {
  ALLOW_CMS_NCCI_MODIFIER,
  AS_GROUP_B,
  CLAIM_TYPE_LINK,
  CPT_LINK,
  CREATEDDATE,
  ENFORCE_BEFORE_CAT,
  ENFO_CAT_CHECK,
  ENFO_CAT_DESC,
  GENDER,
  IGNORE_MODIFIER_59_GROUPS,
  MAX_AGE_ID_CHECK,
  MAX__AGE_DESC,
  MIN_AGE_DESC,
  MIN_AGE_ID_CHECK,
  NPI,
  POS_LINK,
  PRIORITY,
  PROCEDURE_MAX_AGE,
  PROCEDURE_MIN_AGE,
  REFERENCE_CLAIM_TYPE,
  REVENUE_CODE_CLAIM_LINK,
  TAXONOMY_LOGIC,
  TAX_ID_LOGIC,
  TC_26_MOD_B,
  TEMP_ENFORCE_BEFORE_CAT_CODE,
  TEMP_ENFO_CAT_CHECK,
  TEMP_ENFO_CAT_DESC,
  TEMP_MAX_AGE_DESC,
  TEMP_MAX__AGE_ID_CHECK,
  TEMP_MIN_AGE_DESC,
  TEMP_MIN_AGE_ID_CHECK,
  TEMP_PROCEDURE_MAX_AGE_CODE,
  TEMP_PROCEDURE_MIN_AGE_CODE,
} from "../../redux/actions/NewPolicyFormActionTypes";
import { LookUpState } from "../../redux/reducers/LookUpReducer";
import { NewPolicyFormState } from "../../redux/reducers/NewPolicyFormReducer";
import { NewPolicyState } from "../../redux/reducers/NewPolicyReducer";
import {
  CMSColumns,
  EnforceBeforeCatLKPColumns,
  IgnoreModifierColumns,
  ProcedureMaxAgeColumns,
  ProcedureMinAgeColumns,
} from "./Columns";
import { PolicyConstants } from "./PolicyConst";
import { fetchLookupData } from "../../redux/actions/LookupsActions";
import { CLAIM_TYPE_LINK_LKP } from "../LookUps/LookUpConsts";
import RadioButton from "../../components/RadioButton/RadioButton";
const _ = require("lodash");

const Props = ({ edit, fromViewPolicy }) => {
  const dispatch = useDispatch();
  const [gender, setGender] = useState([]);
  const [minAge, setMinAge] = useState([]);
  const [maxAge, setMaxAge] = useState([]);
  const [createDate, setCreateDate] = useState(null);
  const [taxLogic, setTaxLogic] = useState([]);
  const [subSpeciality, setSubSpeciality] = useState([]);
  const [category, setCategory] = useState([]);
  const [npi, setNpi] = useState([]);
  const [posLink, setPosLink] = useState([]);
  const [revenueCodeClaimLink, setRevenueCodeClaimLink] = useState([]);
  const [cptLink, setCptLink] = useState([]);

  const [formstate, setdos] = React.useState(null);
  const [ignoreMod, setIgnoreMod] = useState(true);

  const [asMod, setASMod] = useState(true);

  const [openLkp, setOpenLkp] = React.useState(false);
  const [openRefTypeLkp, setopenRefTypeLkp] = React.useState(false);

  const [referenceClaimType, setReferenceClaimType] = useState(null);

  const [open, setOpen] = React.useState(false);
  const [proprows, setpropRows] = useState([]);
  const [selectedLkp, setSelectedLkp] = useState("");
  const [ignoreModifier59, setIgnoreModifier59] = useState([]);
  const [allowCmsNcciModifiers, setAllowCmsNcciModifiers] = useState([]);
  const [selectedCheckboxValue, setSelectedCheckboxValue] = useState("");

  const lookupState: LookUpState = useSelector(
    (state: any) => state.lookupReducer
  );

  const handleToClose = () => {
    setOpen(false);
    setOpenLkp(false);
    setopenRefTypeLkp(false);
  };

  const fullWidth = true;
  const maxWidth = "md";
  const [selectedLkpColumns, setselectedLkpColumns] = useState([]);

  useEffect(() => {
    if (updatedState.gender.length == 0) {
      getGender(dispatch);
    }
    if (updatedState.minAge.length == 0) {
      getMinAge(dispatch);
    }
    if (updatedState.taxLogic.length == 0) {
      getTaxLogic(dispatch);
    }
    if (updatedState.subSpeciality.length == 0) {
      getSubSpeciality(dispatch);
    }
    if (updatedState.CAT.length == 0) {
      getCAT(dispatch);
    }
    if (updatedState.npi.length == 0) {
      getNPI(dispatch);
    }
    if (updatedState.posLink.length == 0) {
      getPOSLINK(dispatch);
    }
    if (updatedState.maxAge.length == 0) {
      getMaxAge(dispatch);
    }
    if (updatedState.ignoreModifier.length == 0) {
      getIgnoreModifier(dispatch);
    }
    if (updatedState.allowCmsNcciModifiers.length == 0) {
      getAllowCmsNcciModifiers(dispatch);
    }
    if (updatedState.revenueCodeClaimLink.length == 0) {
      getRevenueCodeClaimLink(dispatch);
    }
    if (updatedState.claimTypeLinkLkp.length == 0) {
      let lkpName = CLAIM_TYPE_LINK_LKP;
      fetchLookupData(dispatch, lkpName);
    }
    if (updatedState.cptLink.length == 0) {
      getCPTLink(dispatch);
    }
  }, []);

  const updatedState: NewPolicyState = useSelector(
    (state: any) => state.newPolicy
  );

  const lkpGridIconStyle = useMemo(
    () => ({
      position: "absolute",
      top: "15px",
      float: "right",
      right: "75px",
      display: "inline",
    }),
    []
  );
  useEffect(() => {
    setGender(updatedState.gender);
    setMinAge(updatedState.minAge);
    setMaxAge(updatedState.maxAge);
    setTaxLogic(updatedState.taxLogic);
    setSubSpeciality(updatedState.subSpeciality);
    setCategory(updatedState.CAT);
    setIgnoreModifier59(updatedState.ignoreModifier);
    setAllowCmsNcciModifiers(updatedState.allowCmsNcciModifiers);
    setNpi(updatedState.npi);
    setPosLink(updatedState.posLink);
    setCptLink(updatedState.cptLink);
    setRevenueCodeClaimLink(updatedState.revenueCodeClaimLink);
    setCreateDate(createDate);
  }, [updatedState]);

  const genderCM = gender?.map((l) => {
    return { label: l.genderDesc, value: l.genderCode };
  });
  const minAgeCM = minAge?.map((a) => {
    return { label: a.minMaxAgeDesc, value: a.minMaxAgeLkpId };
  });
  const maxAgeCM = maxAge?.map((a) => {
    return { label: a.minMaxAgeDesc, value: a.minMaxAgeLkpId };
  });
  const taxLogicCM = taxLogic?.map((a) => {
    return { label: a.description, value: a.taxLinkLkpKey };
  });
  const subSpecCM = subSpeciality?.map((a) => {
    return { label: a.description, value: a.taxonomyLinkLkpKey };
  });
  const catCM = category?.map((a) => {
    return { label: a.policyCategoryDesc, value: a.policyCategoryLkpId };
  });
  const npiCM = npi.map((n) => {
    return { label: n.description, value: n.npiLinkLkpKey };
  });
  const posCM = posLink.map((p) => {
    return { label: p.description, value: p.posLinkLkpKey };
  });
  const revenueCodeClmCM = revenueCodeClaimLink.map((r) => {
    return { label: r.description, value: r.revenueCodeClaimLinkKey };
  });
  const claimTypeLinkLkp = updatedState.claimTypeLinkLkp?.map((r) => {
    return { label: r.description, value: r.claimTypeLinkLkpKey };
  });

  const cptCM = cptLink.map((c) => {
    return { label: c.description, value: c.cptLinkLkpKey };
  });

  const formState: NewPolicyFormState = useSelector(
    (state: any) => state.newPolicyForm
  );
  const useStyles = makeStyles((theme) => ({
    DesktopDatePicker: {
      border: "1px solid red",
      backgroundColor: dangerColor,
    },
  }));
  function stringMethod(e) {
    const re = /^[0-9\b]+$/;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  useEffect(() => {
    if (selectedLkp == PolicyConstants.ENFORCE_BEFORE_CAT_LKP) {
      let col = Object.assign({}, selectedLkpColumns);
      col = EnforceBeforeCatLKPColumns;
      setselectedLkpColumns(col);
      const enforcebeforecategory = category.map((sp, i) => {
        return {
          id: i,
          priority: sp.priority,
          policyCategoryLkpId: sp.policyCategoryLkpId,
          policyCategoryDesc: sp.policyCategoryDesc,
          lastUpdatedAt: Moment(sp.lastUpdatedAt).format("MM-DD-YYYY hh:mm:ss"),
        };
      });
      setpropRows(enforcebeforecategory);
    }

    if (selectedLkp == PolicyConstants.PROCEDURE_MIN_AGE_LKP) {
      let col = Object.assign({}, selectedLkpColumns);
      col = ProcedureMinAgeColumns;
      setselectedLkpColumns(col);
      const minage = minAge.map((sp, i) => {
        return {
          id: i,
          minMaxAgeLkpId: sp.minMaxAgeLkpId,
          minMaxAgeDesc: sp.minMaxAgeDesc,
          ageYears: sp.ageYears,
          ageMonths: sp.ageMonths,
          ageDays: sp.ageDays,
          equalsB: sp.equalsB,
          minVsMaxB: sp.minVsMaxB,
        };
      });
      setpropRows(minage);
    }
    if (selectedLkp == PolicyConstants.PROCEDURE_MAX_AGE_LKP) {
      let col = Object.assign({}, selectedLkpColumns);
      col = ProcedureMaxAgeColumns;
      setselectedLkpColumns(col);
      const maxage = maxAge.map((sp, i) => {
        return {
          id: i,
          minMaxAgeLkpId: sp.minMaxAgeLkpId,
          minMaxAgeDesc: sp.minMaxAgeDesc,
          ageYears: sp.ageYears,
          ageMonths: sp.ageMonths,
          ageDays: sp.ageDays,
          equalsB: sp.equalsB,
          minVsMaxB: sp.minVsMaxB,
        };
      });
      setpropRows(maxage);
    }

    if (selectedLkp == PolicyConstants.IGNORE_MOD) {
      let col = Object.assign({}, selectedLkpColumns);
      col = IgnoreModifierColumns;
      setselectedLkpColumns(col);
      const ignoreModifier = ignoreModifier59.map((sp, i) => {
        return {
          id: i,
          cptMod: sp.cptMod,
          description: sp.description,
        };
      });
      setpropRows(ignoreModifier);
    }

    if (selectedLkp == PolicyConstants.ALLOW_CMS_NCCI_MOD) {
      let col = Object.assign({}, selectedLkpColumns);
      col = CMSColumns;
      setselectedLkpColumns(col);
      const CMS = allowCmsNcciModifiers.map((sp, i) => {
        return {
          id: i,
          cptMod: sp.cptMod,
          description: sp.description,
        };
      });
      setpropRows(CMS);
    }
  }, [selectedLkp]);

  useEffect(() => {
    if (formState.enfoCatDesc == "") {
      updatedState.CAT.map((c, i) => {
        if (c.policyCategoryLkpId === formState.enforceBefCategory) {
          dispatch({ type: ENFO_CAT_DESC, payload: c.policyCategoryDesc });
          dispatch({
            type: ENFORCE_BEFORE_CAT,
            payload: c.policyCategoryLkpId,
          });
          dispatch({ type: TEMP_ENFO_CAT_DESC, payload: c.policyCategoryDesc });
          dispatch({
            type: TEMP_ENFORCE_BEFORE_CAT_CODE,
            payload: c.policyCategoryLkpId,
          });
          dispatch({ type: ENFO_CAT_CHECK, payload: i });
          dispatch({ type: TEMP_ENFO_CAT_CHECK, payload: i });
        }
      });
    }

    if (formState.minAgeDesc == "") {
      updatedState.minAge?.map((c, i) => {
        if (c.minMaxAgeLkpId == formState.procedureMinAge) {
          dispatch({ type: MIN_AGE_DESC, payload: c.minMaxAgeDesc });
          dispatch({
            type: TEMP_PROCEDURE_MIN_AGE_CODE,
            payload: c.minMaxAgeLkpId,
          });
          dispatch({ type: MIN_AGE_ID_CHECK, payload: i });
          dispatch({ type: TEMP_MIN_AGE_ID_CHECK, payload: i });
          dispatch({ type: TEMP_MIN_AGE_DESC, payload: c.minMaxAgeDesc });
        }
      });
    }
    if (formState.maxAgeDesc == "") {
      updatedState.maxAge?.map((c, i) => {
        if (c.minMaxAgeLkpId == formState.procedureMaxAge) {
          dispatch({ type: MAX__AGE_DESC, payload: c.minMaxAgeDesc });
          dispatch({
            type: TEMP_PROCEDURE_MAX_AGE_CODE,
            payload: c.minMaxAgeLkpId,
          });
          dispatch({ type: MAX_AGE_ID_CHECK, payload: i });
          dispatch({ type: TEMP_MAX__AGE_ID_CHECK, payload: i });
          dispatch({ type: TEMP_MAX_AGE_DESC, payload: c.minMaxAgeDesc });
        }
      });
    }
  }, [updatedState]);

  useEffect(() => {
    if (!formState.referenceClaimType) {
      dispatch({ type: REFERENCE_CLAIM_TYPE, payload: 0 });
    }
  }, [dispatch]);

  const setCheckboxValues = () => {
    if (selectedLkp == PolicyConstants.ENFORCE_BEFORE_CAT_LKP) {
      let a = _.cloneDeep(selectedCheckboxValue);
      let id = "";
      let policyCategoryDesc = "";
      let policyCategoryLkpId = "";
      if (a.length > 0) {
        policyCategoryDesc = a[0].policyCategoryDesc;
        policyCategoryLkpId = a[0].policyCategoryLkpId;
        id = a[0].id;
      }
      dispatch({ type: ENFO_CAT_CHECK, payload: id });
      if (!edit) {
        dispatch({
          type: ENFO_CAT_DESC,
          payload: policyCategoryDesc,
        });
        dispatch({
          type: ENFORCE_BEFORE_CAT,
          payload: policyCategoryLkpId,
        });
      }
    }
    if (selectedLkp == PolicyConstants.PROCEDURE_MAX_AGE_LKP) {
      let a = _.cloneDeep(selectedCheckboxValue);
      let maxId = "";
      let MaxAgeDesc = "";
      let MaxAgeLkpId = "";
      if (a.length > 0) {
        MaxAgeDesc = a[0].minMaxAgeDesc;
        MaxAgeLkpId = a[0].minMaxAgeLkpId;
        maxId = a[0].id;
      }
      dispatch({ type: MAX_AGE_ID_CHECK, payload: maxId });
      if (!edit) {
        dispatch({
          type: MAX__AGE_DESC,
          payload: MaxAgeDesc,
        });
        dispatch({
          type: PROCEDURE_MAX_AGE,
          payload: MaxAgeLkpId,
        });
      }
    }
    if (selectedLkp == PolicyConstants.PROCEDURE_MIN_AGE_LKP) {
      let a = _.cloneDeep(selectedCheckboxValue);
      let id = "";
      let minMaxAgeDesc = "";
      let minMaxAgeLkpId = "";
      if (a.length > 0) {
        minMaxAgeDesc = a[0].minMaxAgeDesc;
        minMaxAgeLkpId = a[0].minMaxAgeLkpId;
        id = a[0].id;
      }
      dispatch({ type: MIN_AGE_ID_CHECK, payload: id });
      if (!edit) {
        dispatch({
          type: MIN_AGE_DESC,
          payload: minMaxAgeDesc,
        });
        dispatch({
          type: PROCEDURE_MIN_AGE,
          payload: minMaxAgeLkpId,
        });
      }
    }
  };
  const onSelectionChanged = async (event) => {
    if (!edit) {
      if (selectedLkp == PolicyConstants.ENFORCE_BEFORE_CAT_LKP) {
        let a = event.api.getSelectedRows();
        setSelectedCheckboxValue(a);
      }
    }
    if (!edit) {
      if (selectedLkp == PolicyConstants.PROCEDURE_MIN_AGE_LKP) {
        let a = event.api.getSelectedRows();
        setSelectedCheckboxValue(a);
      }
    }
    if (!edit) {
      if (selectedLkp == PolicyConstants.PROCEDURE_MAX_AGE_LKP) {
        let a = event.api.getSelectedRows();
        setSelectedCheckboxValue(a);
      }
    }
  };

  const onGridReady = (data) => {
    data.api.forEachLeafNode((s) => {
      if (selectedLkp == PolicyConstants.ENFORCE_BEFORE_CAT_LKP) {
        if (s.data?.id === formState.enfoCatCheck) {
          s.setSelected(true);
        }
      }
      if (selectedLkp == PolicyConstants.PROCEDURE_MIN_AGE_LKP) {
        if (s.data?.id === formState.minAgeIdCheck) {
          s.setSelected(true);
        }
      }
      if (selectedLkp == PolicyConstants.PROCEDURE_MAX_AGE_LKP) {
        if (s.data?.id === formState.maxAgeIdCheck) {
          s.setSelected(true);
        }
      }
    });
  };

  const clearDialogValues = () => {
    if (fromViewPolicy) {
      if (selectedLkp == PolicyConstants.ENFORCE_BEFORE_CAT_LKP) {
        dispatch({
          type: ENFO_CAT_DESC,
          payload: formState.temporaryEnfocatDesc,
        });
        dispatch({
          type: ENFORCE_BEFORE_CAT,
          payload: formState.tmpEnforceBeforeCatCode,
        });
        dispatch({
          type: ENFO_CAT_CHECK,
          payload: formState.temporaryEnfoCatCheck,
        });
        // dispatch({ type: ENFORCE_BEFORE_CAT, payload: formState.tmpCatCode });
      }

      if (selectedLkp == PolicyConstants.PROCEDURE_MIN_AGE_LKP) {
        dispatch({
          type: MIN_AGE_DESC,
          payload: formState.temporaryMinAgeDesc,
        });
        dispatch({
          type: PROCEDURE_MIN_AGE,
          payload: formState.tmpProcedureMinAgeCode,
        });
        dispatch({
          type: MIN_AGE_ID_CHECK,
          payload: formState.temporaryMinAgeIdCheck,
        });
      }
      if (selectedLkp == PolicyConstants.PROCEDURE_MAX_AGE_LKP) {
        dispatch({
          type: MAX__AGE_DESC,
          payload: formState.tempoararyMaxAgeDesc,
        });
        dispatch({
          type: PROCEDURE_MAX_AGE,
          payload: formState.tmpProcedureMaxAgeCode,
        });

        dispatch({
          type: MAX_AGE_ID_CHECK,
          payload: formState.temporaryMaxAgeIdCheck,
        });
      }
    }
  };
  // eslint-disable-next-line no-restricted-globals
  const paths = location.pathname.replaceAll("/", "");

  useEffect(() => {
    if (paths == PolicyConstants.VIEW_POLICY) {
      setIgnoreMod(true);
    }
    if (
      paths == PolicyConstants.EDIT_POLICY ||
      paths == PolicyConstants.NEW_POLICY
    ) {
      if (formState.ncciB == 0) {
        setIgnoreMod(true);
      }
      if (formState.ncciB == 1) {
        setIgnoreMod(false);
      }
    }
  }, [formState]);

  useEffect(() => {
    if (paths == PolicyConstants.VIEW_POLICY) {
      setASMod(true);
    }
    if (
      paths == PolicyConstants.EDIT_POLICY ||
      paths == PolicyConstants.NEW_POLICY
    ) {
      setASMod(false);
    }
  }, [formState]);

  return (
    <div>
      <CustomPaper
        style={{
          paddingLeft: 12,
          position: "relative",
          right: 20,
          paddingRight: 10,
          paddingTop: 10,
          paddingBottom: 12,
          boxShadow: "none",
          border: "1px solid #D6D8DA",
          marginRight: "0px",
        }}
      >
        <GridContainer>
          <GridItem sm={4} md={4} xs={4}>
            <Stack component="form" noValidate spacing={2} direction={"row"}>
              <CustomInput
                disabled={true}
                // error={formState.errors.createdDate}
                id="date"
                labelText={"Created Date"}
                type="date"
                variant={"outlined"}
                value={
                  fromViewPolicy
                    ? formState.createdDate
                      ? Moment(formState.createdDate).format("YYYY-MM-DD")
                      : null
                    : ""
                }
                onChange={(event) => {
                  dispatch({ type: CREATEDDATE, payload: event.target.value });
                  setCreateDate(event.target.value);
                }}
                InputProps={{
                  style: {
                    height: 24,
                    width: "100%",
                  },
                }}
              />
              {formState.custom == true ? (
                <CustomInput
                  disabled={true}
                  labelText={"Cloned Policy.Version"}
                  variant={"outlined"}
                  value={
                    formState.clonedPolicyId &&
                    formState.clonedPolicyId !== null
                      ? formState.clonedPolVer
                      : ""
                  }
                />
              ) : undefined}
            </Stack>
          </GridItem>

          <GridItem sm={4} md={4} xs={4}>
            <CustomInput
              error={formState.errors?.enforceBefCategory}
              fullWidth={true}
              labelText={"Enforce Before Category"}
              showStarIcon={true}
              variant={"outlined"}
              value={
                (formState.enforceBefCategory && formState.enfoCatDesc) ||
                (formState.enforceBefCategory == 0 && formState.enfoCatDesc)
                  ? formState.enforceBefCategory + "-" + formState.enfoCatDesc
                  : ""
              }
              endAdornment={
                <InputAdornment
                  position="end"
                  onClick={() => {
                    setOpenLkp(true);
                    setSelectedLkp(PolicyConstants.ENFORCE_BEFORE_CAT_LKP);
                  }}
                >
                  <MoreHoriz
                    style={{
                      cursor: "pointer",
                      fontSize: 15,
                      color: black,
                    }}
                  />
                </InputAdornment>
              }
            />
          </GridItem>
          <GridItem sm={2} md={2} xs={2}>
            <div style={{ position: "absolute", top: "13px" }}>
              <CustomCheckBox
                checked={formState.ncciB == 1}
                // value={formState.ncciB}
                size="small"
                className="checkboxes"
                disabled={!edit ? undefined : fromViewPolicy}
                label={
                  <span
                    style={{
                      fontSize: "12px",
                      position: "relative",
                      bottom: "2.5px",
                    }}
                  >
                    Apply CMS NCCI Modifiers
                  </span>
                }
                onChange={(event) => {
                  if (!event.target.checked) {
                    dispatch({
                      type: IGNORE_MODIFIER_59_GROUPS,
                      payload: 0,
                    });
                  } else {
                    dispatch({
                      type: ALLOW_CMS_NCCI_MODIFIER,
                      payload: 0,
                    });
                  }
                  dispatch({
                    type: ALLOW_CMS_NCCI_MODIFIER,
                    payload: event.target.checked,
                  });
                }}
              />
              <p
                onClick={() => {
                  setOpenLkp(true);
                  setSelectedLkp(PolicyConstants.ALLOW_CMS_NCCI_MOD);
                }}
              >
                <MoreHoriz
                  style={{
                    cursor: "pointer",
                    fontSize: 15,
                    color: "white",
                    position: "absolute",
                    backgroundColor: "gray",
                    left: 180,
                    bottom: 27,
                    borderRadius: 4,
                    height: 13,
                  }}
                />
              </p>
            </div>
          </GridItem>
          <GridItem sm={2} md={2} xs={2} />
          <GridItem sm={4} md={4} xs={4}>
            <CustomSelect
              isDisabled={!edit ? undefined : fromViewPolicy}
              error={formState.errors.gender}
              onSelect={(event) => dispatch({ type: GENDER, payload: event })}
              value={formState.gender}
              options={genderCM}
              hoverData={genderCM.map((l) => {
                return l.label;
              })}
              labelText={"Gender"}
              showStarIcon={true}
            />
          </GridItem>
          <GridItem sm={4} md={4} xs={4}>
            <CustomInput
              maxLength={3}
              disabled={!edit ? undefined : fromViewPolicy}
              error={formState.errors.priority}
              value={formState.priority}
              onChange={(event) => {
                dispatch({
                  type: PRIORITY,
                  payload: event.target.value,
                });
              }}
              onKeyPress={(e) => stringMethod(e)}
              type={"text"}
              fullWidth={true}
              labelText={"Priority in Category"}
              showStarIcon={true}
              variant={"outlined"}
            />
          </GridItem>
          <GridItem sm={2} md={2} xs={2}>
            <div style={{ position: "absolute", bottom: "20px" }}>
              <CustomCheckBox
                disabled={ignoreMod}
                checked={formState.group59B == 1}
                value={formState.group59B}
                size="small"
                className="checkboxes"
                label={
                  <span
                    style={{
                      fontSize: "12px",
                      position: "relative",
                      bottom: "2.5px",
                    }}
                  >
                    Apply Modifier 59 Group
                  </span>
                }
                onChange={(event) => {
                  dispatch({
                    type: IGNORE_MODIFIER_59_GROUPS,
                    payload: event.target.checked,
                  });
                }}
              />
              <p
                onClick={() => {
                  setOpenLkp(true);
                  setSelectedLkp(PolicyConstants.IGNORE_MOD);
                }}
              >
                <MoreHoriz
                  style={{
                    cursor: "pointer",
                    fontSize: 15,
                    color: "white",
                    position: "absolute",
                    backgroundColor: "gray",
                    left: 180,
                    bottom: 27,
                    borderRadius: 4,
                    height: 13,
                  }}
                />
              </p>
            </div>
          </GridItem>
          <GridItem sm={4} md={4} xs={4}>
            <CustomInput
              error={formState.errors?.procedureMinAge}
              fullWidth={true}
              labelText={"Procedure Min Age"}
              showStarIcon={true}
              variant={"outlined"}
              value={
                (formState.procedureMinAge && formState.minAgeDesc) ||
                (formState.procedureMinAge == 0 && formState.minAgeDesc)
                  ? formState.procedureMinAge + " - " + formState.minAgeDesc
                  : ""
              }
              endAdornment={
                <InputAdornment
                  position="end"
                  onClick={() => {
                    setOpenLkp(true);
                    setSelectedLkp(PolicyConstants.PROCEDURE_MIN_AGE_LKP);
                  }}
                >
                  <MoreHoriz
                    style={{
                      cursor: "pointer",
                      fontSize: 15,
                      color: black,
                    }}
                  />
                </InputAdornment>
              }
            />
          </GridItem>
          <GridItem sm={4} md={4} xs={4}>
            <CustomInput
              error={formState.errors?.procedureMaxAge}
              fullWidth={true}
              labelText={"Procedure Max Age"}
              showStarIcon={true}
              variant={"outlined"}
              value={
                (formState.procedureMaxAge && formState.maxAgeDesc) ||
                (formState.procedureMaxAge == 0 && formState.maxAgeDesc)
                  ? formState.procedureMaxAge + "-" + formState.maxAgeDesc
                  : ""
              }
              endAdornment={
                <InputAdornment
                  position="end"
                  onClick={() => {
                    setOpenLkp(true);
                    setSelectedLkp(PolicyConstants.PROCEDURE_MAX_AGE_LKP);
                  }}
                >
                  <MoreHoriz
                    style={{
                      cursor: "pointer",
                      fontSize: 15,
                      color: black,
                    }}
                  />
                </InputAdornment>
              }
            />
          </GridItem>
          <GridItem sm={2} md={2} xs={2}>
            <div
              style={{ position: "absolute", bottom: "55px", float: "left" }}
            >
              <CustomCheckBox
                disabled={asMod}
                checked={formState.asGroupB == 1}
                value={formState.asGroupB}
                size="small"
                className="checkboxes"
                label={
                  <span
                    style={{
                      fontSize: "12px",
                      position: "relative",
                      bottom: "2.5px",
                    }}
                  >
                    Apply AS,80,81 & 82 Logic
                  </span>
                }
                onChange={(event) => {
                  dispatch({
                    type: AS_GROUP_B,
                    payload: event.target.checked,
                  });
                }}
              />
            </div>
          </GridItem>

          {/* <GridItem sm={4} md={4} xs={4} /> */}
          <GridItem sm={4} md={4} xs={4}>
            <CustomSelect
              isDisabled={!edit ? undefined : fromViewPolicy}
              error={formState.errors.npi}
              onSelect={(event) => dispatch({ type: NPI, payload: event })}
              value={formState.npi}
              options={npiCM}
              labelText={"Rendering Provider NPI"}
              showStarIcon={true}
              hoverData={npiCM.map((l) => {
                return l.label;
              })}
            />
          </GridItem>
          <GridItem sm={4} md={4} xs={4}>
            <CustomSelect
              isDisabled={!edit ? undefined : fromViewPolicy}
              error={formState.errors.taxIdLogic}
              onSelect={(event) =>
                dispatch({ type: TAX_ID_LOGIC, payload: event })
              }
              value={formState.taxIdLogic}
              options={taxLogicCM}
              labelText={"Billing Provider Tax ID"}
              showStarIcon={true}
              hoverData={taxLogicCM.map((l) => {
                return l.label;
              })}
            />
          </GridItem>

          <GridItem sm={2} md={2} xs={2}>
            <div
              style={{ position: "absolute", bottom: "80px", float: "left" }}
            >
              <CustomCheckBox
                disabled={asMod}
                checked={formState.tc26ModB == 1}
                value={formState.tc26ModB}
                size="small"
                className="checkboxes"
                label={
                  <span
                    style={{
                      fontSize: "12px",
                      position: "relative",
                      bottom: "2.5px",
                    }}
                  >
                    Apply TC/26 Logic
                  </span>
                }
                onChange={(event) => {
                  dispatch({
                    type: TC_26_MOD_B,
                    payload: event.target.checked,
                  });
                }}
              />
            </div>
          </GridItem>
          {/* <GridItem sm={4} md={4} xs={4} /> */}
          <GridItem sm={4} md={4} xs={4}>
            <CustomSelect
              isDisabled={!edit ? undefined : fromViewPolicy}
              error={formState.errors.taxonomyLogicFk}
              onSelect={(event) =>
                dispatch({ type: TAXONOMY_LOGIC, payload: event })
              }
              value={formState.taxonomyLogicFk}
              options={subSpecCM}
              labelText={"Rendering Provider Taxonomy"}
              showStarIcon={true}
              hoverData={subSpecCM.map((l) => {
                return l.label;
              })}
            />
          </GridItem>
          <GridItem sm={4} md={4} xs={4}>
            <CustomSelect
              isDisabled={!edit ? undefined : fromViewPolicy}
              error={formState.errors.posLink}
              onSelect={(event) => dispatch({ type: POS_LINK, payload: event })}
              value={formState.posLink}
              options={posCM}
              labelText={"POS Link"}
              showStarIcon={true}
              hoverData={posCM.map((l) => {
                return l.label;
              })}
            />
          </GridItem>
          <GridItem sm={4} md={4} xs={4}>
            <div className="crossHeight"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span className="newlab">Cross Claim Form Link</span>
              <p
                onClick={() => {
                  setopenRefTypeLkp(true);
                }}
                style={{ margin: 0, marginLeft: "8px" }} // Optional: Add margin to create space between text and icon
              >
                <MoreHoriz
                  style={{
                    cursor: "pointer",
                    fontSize: 15,
                    color: "white",
                    backgroundColor: "gray",
                    borderRadius: 4,
                  }}
                />
              </p>
              <div className="crossradio">
                <RadioButton
                  disabled={edit}
                  label={"Yes"}
                  checked={formState.referenceClaimType === 1 ? true : false}
                  onChange={(event) => {
                    dispatch({ type: REFERENCE_CLAIM_TYPE, payload: 1 });
                  }}
                />
                <RadioButton
                  disabled={edit}
                  label={"No"}
                  checked={formState.referenceClaimType === 0 ? true : false}
                  onChange={(event) => {
                    dispatch({ type: REFERENCE_CLAIM_TYPE, payload: 0 });
                  }}
                />
              </div>
            </div>
          </GridItem>
          {/* <GridItem sm={4} md={4} xs={4} /> */}
          <GridItem sm={4} md={4} xs={4}>
            <CustomSelect
              isDisabled={!edit ? undefined : fromViewPolicy}
              error={formState.errors.revenueCodeClaimLink}
              onSelect={(event) =>
                dispatch({ type: REVENUE_CODE_CLAIM_LINK, payload: event })
              }
              value={formState.revenueCodeClaimLink}
              options={revenueCodeClmCM}
              labelText={"Revenue Code Claim Link"}
              showStarIcon={true}
              hoverData={revenueCodeClmCM.map((l) => {
                return l.label;
              })}
            />
          </GridItem>
          <GridItem sm={4} md={4} xs={4}>
            <CustomSelect
              isDisabled={!edit ? undefined : fromViewPolicy}
              error={formState.errors.claimTypeLink}
              onSelect={(event) =>
                dispatch({ type: CLAIM_TYPE_LINK, payload: event })
              }
              value={formState.claimTypeLink}
              options={claimTypeLinkLkp}
              labelText={"Claim Type Link"}
              showStarIcon={true}
              hoverData={claimTypeLinkLkp.map((l) => {
                return l.label;
              })}
            />
          </GridItem>

          <GridItem sm={4} md={4} xs={4} />
          <GridItem sm={4} md={4} xs={4}>
            <CustomSelect
              isDisabled={!edit ? undefined : fromViewPolicy}
              error={formState.errors.cptLink}
              onSelect={(event) => dispatch({ type: CPT_LINK, payload: event })}
              value={formState.cptLink}
              options={cptCM}
              labelText={"CPT Link"}
              showStarIcon={true}
              hoverData={cptCM.map((l) => {
                return l.label;
              })}
            />
          </GridItem>
          <Dialogbox
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            disableBackdropClick={true}
            open={openLkp}
            onClose={handleToClose}
            title={selectedLkp}
            message={
              <div
                style={{ height: window.innerHeight / 1.8, marginTop: "-10px" }}
              >
                <AgGrids
                  rowData={proprows}
                  columnDefs={selectedLkpColumns}
                  rowSelection={"single"}
                  onSelectionChanged={onSelectionChanged}
                  onGridReady={onGridReady}
                  gridIconStyle={lkpGridIconStyle}
                />
              </div>
            }
            actions={
              <ButtonGroup style={{ marginTop: "-50px" }}>
                {!edit &&
                !(selectedLkp == PolicyConstants.IGNORE_MOD) &&
                !(selectedLkp == PolicyConstants.ALLOW_CMS_NCCI_MOD) ? (
                  <CustomButton
                    variant={"contained"}
                    onClick={() => {
                      handleToClose();
                      setCheckboxValues();
                    }}
                    onChange={(event) => {}}
                    style={{
                      backgroundColor: navyColor,
                      color: "white",
                      margin: 10,
                      padding: 4,
                      fontSize: 12,
                      textTransform: "capitalize",
                    }}
                  >
                    Yes
                  </CustomButton>
                ) : undefined}
                {!edit &&
                !(selectedLkp == PolicyConstants.IGNORE_MOD) &&
                !(selectedLkp == PolicyConstants.ALLOW_CMS_NCCI_MOD) ? (
                  <CustomButton
                    onClick={() => {
                      handleToClose();
                      clearDialogValues();
                    }}
                    variant={"contained"}
                    style={{
                      backgroundColor: dangerColor,
                      color: "white",
                      margin: 10,
                      padding: 4,
                      fontSize: 12,
                      textTransform: "capitalize",
                    }}
                  >
                    No
                  </CustomButton>
                ) : undefined}
              </ButtonGroup>
            }
          />
          <Dialogbox
            //      fullWidth={fullWidth}
            //      maxWidth={maxWidth}
            disableBackdropClick={true}
            open={openRefTypeLkp}
            onClose={handleToClose}
            title={"Refer Cross Claim Form Type"}
            message={
              <div>
                <p>
                  If Set (Checked) - Claim Types A, F, P will reference claim
                  types I, O, S for historical references
                </p>
                <p>
                  If Not Set (Unchecked - Default) A, F, P will reference only
                  A, F and P. It will not reference I, O, S
                </p>
              </div>
            }
          />
        </GridContainer>
      </CustomPaper>
    </div>
  );
};
export default Props;

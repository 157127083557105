import { navyColor } from "../../assets/jss/material-kit-react";
import CustomButton from "../../components/CustomButtons/CustomButton";
import mitKey from "./ViewConfig"

import {
  BILL_TYPE_LKP,
  BO_TYPE_LKP,
  BW_TYPE_LKP,
  CCI_LKP,
  CCI_RATIONALE_LKP,
  CONDITION_CODE_LKP,
  MAX_UNITS_LKP,
  MAX_UNITS_TYPES,
  MIN_MAX_AGE_LKP,
  MODIFIER_INTERACTION_LKP,
  MODIFIER_PAY_PERCENTAGE_LKP,
  MODIFIER_PRIORITY_LKP,
  MOD_LKP,
  MUE_RATIONALE_LKP,
  POLICY_CATEGORY_LKP,
  POS_LKP,
  REASON_CODE_LKP,
  REVENUE_CODE_LKP,
  SAME_OR_SIMILAR_CODE_LKP,
  SPECS_LKP,
  SUB_SPEC_LKP,
} from "./LookUpConsts";
const _ = require("lodash");

export function LookUpColumns(selectedLKP, columnValues, objects, Role) {
  let columns = [];
  let adminIdx = Role?.toLowerCase().search("admin");
  switch (selectedLKP) {
    case SPECS_LKP:
      {
        columns = [
          {
            field: "specCode",
            headerName: "Spec Code",
            minWidth: 109,
            headerTooltip: "CPT FROM",
          },
          {
            field: "specDesc",
            headerName: "Spec Desc",
            minWidth: 83,
            headerTooltip: "Spec Desc",
            cellRenderer: (row) => {
              return (
                <span
                  title={`${row.data.specDesc}`}
                >{`${row.data.specDesc}`}</span>
              );
            },
          },
          {
            field: "action",
            headerName: "Action",
            minWidth: 90,
            resizable: false,
            hide: adminIdx < 0 ? true : false,
            cellRenderer: (row) => {
              return (
                <CustomButton
                  variant="contained"
                  style={{
                    height: 18,
                    fontSize: "12px",
                    textTransform: "capitalize",
                    color: "white",
                    backgroundColor: navyColor,
                    marginTop: -6,
                  }}
                  onClick={(e) => {
                    let obj = _.cloneDeep(columnValues);
                    obj.specCode = row.data.specCode;
                    obj.specDesc = row.data.specDesc;
                    let isEdit = true;
                    objects(obj, isEdit);
                  }}
                >
                  Edit
                </CustomButton>
              );
            },
          },
        ];
      }
      break;
    case SUB_SPEC_LKP:
      {
        columns = [
          {
            field: "specCode",
            headerName: "Spec code",
            minWidth: 109,
            headerTooltip: "Spec Code",
          },

          {
            field: "subSpecCode",
            headerName: "Sub Spec Code",
            minWidth: 83,
            headerTooltip: "Sub Spec Code",
          },
          {
            field: "subSpecDesc",
            headerName: "Sub Spec Desc",
            minWidth: 83,
            flex: 1,
            filter: true,
            sortable: true,
            headerTooltip: "Sub Spec Desc",
            cellRenderer: (row) => {
              return (
                <span
                  title={`${row.data.subSpecDesc}`}
                >{`${row.data.subSpecDesc}`}</span>
              );
            },
          },
          {
            field: "taxonomyCode",
            headerName: "Taxonomy Code",
            minWidth: 83,
            headerTooltip: "Taxonomy Code",
          },
          {
            field: "cmsSpecialityCode",
            headerName: "CMS Speciality Code",
            minWidth: 83,
            headerTooltip: "CMS Speciality Code",
          },
          {
            field: "miscB",
            headerName: "Misc",
            minWidth: 83,
            flex: 1,
            filter: true,
            sortable: true,
            headerTooltip: "Misc",
          },
          {
            field: "action",
            headerName: "Action",
            minWidth: 90,
            resizable: false,
            hide: adminIdx < 0 ? true : false,
            cellRenderer: (row) => {
              return (
                <CustomButton
                  variant="contained"
                  style={{
                    height: 18,
                    fontSize: "12px",
                    textTransform: "capitalize",
                    backgroundColor: navyColor,
                    color: "white",
                    marginTop: -6,
                  }}
                  onClick={(e) => {
                    let obj = _.cloneDeep(columnValues);
                    obj.specCode = row.data.specCode;
                    obj.subSpecCode = row.data.subSpecCode;
                    obj.subSpecDesc = row.data.subSpecDesc;
                    obj.taxonomyCode = row.data.taxonomyCode;
                    obj.cmsSpecialityCode = row.data.cmsSpecialityCode;
                    obj.deletedB = row.data.deletedB;
                    obj.miscB = row.data.miscB == "NO" ? 0 : 1;
                    let isEdit = true;
                    objects(obj, isEdit);
                  }}
                >
                  Edit
                </CustomButton>
              );
            },
          },
        ];
      }
      break;
    case MIN_MAX_AGE_LKP:
      {
        columns = [
          {
            field: "minMaxAgeDesc",
            headerName: "Min Max Age Desc",
            minWidth: 109,
            headerTooltip: "Min Max Age Desc",
            cellRenderer: (row) => {
              return (
                <span
                  title={`${row.data.minMaxAgeDesc}`}
                >{`${row.data.minMaxAgeDesc}`}</span>
              );
            },
          },

          {
            field: "ageYears",
            headerName: "Age Years",
            minWidth: 83,
            headerTooltip: "Age Years",
          },
          {
            field: "ageMonths",
            headerName: "Age Months",
            minWidth: 83,
            headerTooltip: "Age Months",
          },
          {
            field: "ageDays",
            headerName: "Age Days",
            minWidth: 83,
            headerTooltip: "Age Days",
          },
          {
            field: "equals",
            headerName: "Equals",
            minWidth: 83,
            headerTooltip: "Equals",
          },
          {
            field: "minVsMax",
            headerName: "Min Vs Max",
            minWidth: 83,
            headerTooltip: "Min Vs Max",
          },
          {
            field: "action",
            headerName: "Action",
            minWidth: 90,
            resizable: false,
              hide: adminIdx <0?true:false,
            cellRenderer: (row) => {
              return (
                <CustomButton
                  variant="contained"
                  style={{
                    height: 18,
                    fontSize: "12px",
                    textTransform: "capitalize",
                    backgroundColor: navyColor,
                    color: "white",
                    marginTop: -6,
                  }}
                  onClick={() => {
                    let obj = _.cloneDeep(columnValues);
                    obj.minMaxAgeLkpId = row.data.minMaxAgeLkpId;
                    obj.minMaxAgeDesc = row.data.minMaxAgeDesc;
                    obj.ageYears = row.data.ageYears;
                    obj.ageMonths = row.data.ageMonths;
                    obj.ageDays = row.data.ageDays;
                    obj.equalsB = row.data.equals == "NO" ? 0 : 1;
                    obj.minVsMaxB = row.data.minVsMax == "NO" ? 0 : 1;
                    let isEdit = true;
                    objects(obj, isEdit);
                  }}
                >
                  Edit
                </CustomButton>
              );
            },
          },
        ];
      }
      break;
      case MODIFIER_INTERACTION_LKP:
      {
        columns = [
          {
            field: "mitKey",
            headerName: "MIT Key",
            minWidth: 109,
            headerTooltip: "MIT Key",
            cellRenderer: (row) => {
              return (
                <span
                  title={`${mitKey(row.data.mitKey)}`}
                >{`${row.data.mitKey}`}</span>
              );
            },
          },

          {
            field: "modifier",
            headerName: "Modifier",
            minWidth: 109,
            headerTooltip: "Modifier",
          },

          {
            field: "editsOffSameModifier",
            headerName: "Edits Off Same Modifier",
            minWidth: 109,
            headerTooltip: "Edits Off Same Modifier",
          },

          {
            field: "editsOffOtherModifier",
            headerName: "Edits Off Other Modifier",
            minWidth: 109,
            headerTooltip: "Edits Off Other Modifier",
          },

          {
            field: "editsOffBlankModifier",
            headerName: "Edits Off Blank Modifier",
            minWidth: 109,
            headerTooltip: "Edits Off Blank Modifier",
          },

          {
            field: "otherEditsOffThisModifier",
            headerName: "Other Edits Off This Modifier",
            minWidth: 109,
            headerTooltip: "Other Edits Off This Modifier",
          },

          {
            field: "blankEditsOffThisModifier",
            headerName: "Blank Edits Off This Modifier",
            minWidth: 109,
            headerTooltip: "Blank Edits Off This Modifier",
          },

          {
            field: "modifierException",
            headerName: "Modifier Exception",
            minWidth: 109,
            headerTooltip: "Modifier Exception",
            cellRenderer: (row) => {
              return (
                <span
                  title={`${row.data.modifierException}`}
                >{`${row.data.modifierException}`}</span>
              );
            },
          },
        ];
      }
      break;
    case REVENUE_CODE_LKP:
      {
        columns = [
          {
            field: "revCode",
            headerName: "Rev Code",
            minWidth: 109,
            headerTooltip: "Rev Code",
          },

          {
            field: "revDesc",
            headerName: "Rev Desc",
            minWidth: 83,
            headerTooltip: "Rev Desc",
            cellRenderer: (row) => {
              return (
                <span
                  title={`${row.data.revDesc}`}
                >{`${row.data.revDesc}`}</span>
              );
            },
          },
          {
            field: "action",
            headerName: "Action",
            minWidth: 90,
            resizable: false,
              hide: adminIdx <0?true:false,
            cellRenderer: (row) => {
              return (
                <CustomButton
                  variant="contained"
                  style={{
                    height: 18,
                    fontSize: "12px",
                    textTransform: "capitalize",
                    backgroundColor: navyColor,
                    color: "white",
                    marginTop: -6,
                  }}
                  onClick={() => {
                    let obj = _.cloneDeep(columnValues);
                    obj.revCode = row.data.revCode;
                    obj.revDesc = row.data.revDesc;
                    let isEdit = true;
                    objects(obj, isEdit);
                  }}
                >
                  Edit
                </CustomButton>
              );
            },
          },
        ];
      }
      break;
    case BILL_TYPE_LKP:
      {
        columns = [
          {
            field: "billType",
            headerName: "Bill Type",
            minWidth: 109,
            headerTooltip: "Bill Type",
          },

          {
            field: "billTypeDesc",
            headerName: "Bill Type Desc",
            minWidth: 83,
            headerTooltip: "Bill Type Desc",
            cellRenderer: (row) => {
              return (
                <span
                  title={`${row.data.billTypeDesc}`}
                >{`${row.data.billTypeDesc}`}</span>
              );
            },
          },
          {
            field: "Inpatient",
            headerName: "Inpatient",
            minWidth: 83,
            headerTooltip: "Inpatient",
          },
          {
            field: "startDate",
            headerName: "Start Date",
            minWidth: 83,
            headerTooltip: "Start Date",
          },
          {
            field: "endDate",
            headerName: "End Date",
            minWidth: 83,
            headerTooltip: "End Date",
          },
          {
            field: "claimTypeMatch",
            headerName: "Claim Type Match",
            minWidth: 83,
            headerTooltip: "Claim Type Match",
          },
          {
            field: "action",
            headerName: "Action",
            minWidth: 90,
            hide: adminIdx <0?true:false,
            resizable: false,
            cellRenderer: (row) => {
              return (
                <CustomButton
                  variant="contained"
                  style={{
                    height: 18,
                    fontSize: "12px",
                    textTransform: "capitalize",
                    backgroundColor: navyColor,
                    color: "white",
                    marginTop: -6,
                  }}
                  onClick={() => {
                    let obj = _.cloneDeep(columnValues);
                    obj.billType = row.data.billType;
                    obj.billTypeDesc = row.data.billTypeDesc;
                    obj.inpatientB = row.data.Inpatient == "NO" ? 0 : 1;
                    obj.claimTypeMatch = row.data.claimTypeMatch;
                    obj.startDate = row.data.startDate;
                    obj.endDate = row.data.endDate;
                    let isEdit = true;
                    objects(obj, isEdit);
                  }}
                >
                  Edit
                </CustomButton>
              );
            },
          },
        ];
      }
      break;
    case CONDITION_CODE_LKP:
      {
        columns = [
          {
            field: "condCode",
            headerName: "Cond Code",
            minWidth: 109,
            headerTooltip: "Cond Code",
          },

          {
            field: "condDesc",
            headerName: "Cond Desc",
            minWidth: 83,
            headerTooltip: "Cond Desc",
            cellRenderer: (row) => {
              return (
                <span
                  title={`${row.data.condDesc}`}
                >{`${row.data.condDesc}`}</span>
              );
            },
          },
          {
            field: "startDate",
            headerName: "Start Date",
            minWidth: 83,
            headerTooltip: "Start Date",
          },

          {
            field: "endDate",
            headerName: "End Date",
            minWidth: 83,
            headerTooltip: "End Date",
          },
          {
            field: "action",
            headerName: "Action",
            minWidth: 90,
            resizable: false,
            hide: adminIdx < 0 ? true : false,
            cellRenderer: (row) => {
              return (
                <CustomButton
                  variant="contained"
                  style={{
                    height: 18,
                    fontSize: "12px",
                    textTransform: "capitalize",
                    backgroundColor: navyColor,
                    color: "white",
                    marginTop: -6,
                  }}
                  onClick={() => {
                    let obj = _.cloneDeep(columnValues);
                    obj.condId = row.data.id;
                    obj.condCode = row.data.condCode;
                    obj.condDesc = row.data.condDesc;
                    obj.startDate = row.data.startDate;
                    obj.endDate = row.data.endDate;
                    let isEdit = true;
                    objects(obj, isEdit);
                  }}
                >
                  Edit
                </CustomButton>
              );
            },
          },
        ];
      }
      break;
      case MAX_UNITS_LKP:
        {
          columns = [
            {
              field: "maxUnitsLkpKey",
              headerName: "Max Unit Lookup Key ",
              minWidth: 109,
              headerTooltip: "Max Unit Lookup Key",
            },
  
            {
              field: "description",
              headerName: "Description",
              minWidth: 83,
              headerTooltip: "Description",
            },
  
            {
              field: "comments",
              headerName: "Comments",
              minWidth: 83,
              headerTooltip: "Comments",
            },
  
            {
              field: "maxUnitsTypeKey",
              headerName: "Max Unit Type Key",
              minWidth: 83,
              headerTooltip: "Max Unit Type Key",
            },
  
            {
              field: "action",
              headerName: "Action",
              minWidth: 90,
              hide: adminIdx < 0 ? true : false,
              resizable: false,
              cellRenderer: (row) => {
                return (
                  <CustomButton
                    variant="contained"
                    style={{
                      height: 18,
                      fontSize: "12px",
                      textTransform: "capitalize",
                      backgroundColor: navyColor,
                      color: "white",
                      marginTop: -6
  
                    }}
                    onClick={() => {
                      let obj = _.cloneDeep(columnValues);
                      obj.maxUnitsLkpKey = row.data.maxUnitsLkpKey;
                      obj.maxUnitsTypeKey = row.data.maxUnitsTypeKey;
                      obj.description = row.data.description;
                      obj.comments = row.data.comments;
                      let isEdit = true;
                      objects(obj, isEdit);
                    }}
                  >
                    Edit
                  </CustomButton>
                );
              },
            },
          ];
        }
        break;
    case MOD_LKP:
      {
        columns = [
          {
            field: "cptMod",
            headerName: "CPT MOD",
            minWidth: 109,
            headerTooltip: "CPT MOD",
          },

          {
            field: "description",
            headerName: "Description",
            minWidth: 83,
            headerTooltip: "Description",
            cellRenderer: (row) => {
              return (
                <span
                  title={`${row.data.description}`}
                >{`${row.data.description}`}</span>
              );
            },
          },
          {
            field: "ambulanceMod",
            headerName: "Ambulance MOD",
            minWidth: 83,
            headerTooltip: "Ambulance MOD",
          },
          {
            field: "isCci",
            headerName: "Is CCI ?",
            minWidth: 83,
            headerTooltip: "Is CCI ?",
          },
          {
            field: "is_59_group",
            headerName: "Is 59 Group ?",
            minWidth: 83,
            headerTooltip: "Is 59 Group ?",
          },
          {
            field: "startDate",
            headerName: "Start Date",
            minWidth: 83,
            headerTooltip: "End Date",
          },

          {
            field: "endDate",
            headerName: "End Date",
            minWidth: 83,
            headerTooltip: "End Date",
          },
          {
            field: "action",
            headerName: "Action",
            minWidth: 90,
            hide: adminIdx <0?true:false,
            resizable: false,
            cellRenderer: (row) => {
              return (
                <CustomButton
                  variant="contained"
                  style={{
                    height: 18,
                    fontSize: "12px",
                    textTransform: "capitalize",
                    backgroundColor: navyColor,
                    color: "white",
                    marginTop: -6,
                  }}
                  onClick={() => {
                    let obj = _.cloneDeep(columnValues);
                    obj.cptMod = row.data.cptMod;
                    obj.description = row.data.description;
                    obj.ambulanceModB = row.data.ambulanceMod == "NO" ? 0 : 1;
                    obj.isCci = row.data.isCci == "NO" ? 0 : 1;
                    obj.is_59_group = row.data.is_59_group == "NO" ? 0 : 1;
                    obj.startDate = row.data.startDate;
                    obj.endDate = row.data.endDate;
                    let isEdit = true;
                    objects(obj, isEdit);
                  }}
                >
                  Edit
                </CustomButton>
              );
            },
          },
        ];
      }
      break;
    case POS_LKP:
      {
        columns = [
          {
            field: "posCode",
            headerName: "POS Code",
            minWidth: 109,
            headerTooltip: "POS Code",
          },

          {
            field: "posName",
            headerName: "POS Name",
            minWidth: 83,
            headerTooltip: "POS Name",
            cellRenderer: (row) => {
              return (
                <span
                  title={`${row.data.posName}`}
                >{`${row.data.posName}`}</span>
              );
            },
          },
          {
            field: "posDesc",
            headerName: "POS Desc",
            minWidth: 83,
            headerTooltip: "POS Desc",
            cellRenderer: (row) => {
              return (
                <span
                  title={`${row.data.posDesc}`}
                >{`${row.data.posDesc}`}</span>
              );
            },
          },
          {
            field: "facility",
            headerName: " Facility",
            minWidth: 83,
            headerTooltip: "End Date",
          },
          {
            field: "action",
            headerName: "Action",
            minWidth: 90,
            resizable: false,
            hide: adminIdx <0?true:false,
            cellRenderer: (row) => {
              return (
                <CustomButton
                  variant="contained"
                  style={{
                    height: 18,
                    fontSize: "12px",
                    textTransform: "capitalize",
                    backgroundColor: navyColor,
                    color: "white",
                    marginTop: -6,
                  }}
                  onClick={() => {
                    let obj = _.cloneDeep(columnValues);
                    obj.posCode = row.data.posCode;
                    obj.posName = row.data.posName;
                    obj.posDesc = row.data.posDesc;
                    obj.facilityB = row.data.facility == "NO" ? 0 : 1;
                    let isEdit = true;
                    objects(obj, isEdit);
                  }}
                >
                  Edit
                </CustomButton>
              );
            },
          },
        ];
      }
      break;
    case POLICY_CATEGORY_LKP:
      {
        columns = [
          {
            field: "policyCategoryDesc",
            headerName: "Policy Category Desc",
            minWidth: 109,
            headerTooltip: "Policy Category Desc",
            cellRenderer: (row) => {
              return (
                <span
                  title={`${row.data.policyCategoryDesc}`}
                >{`${row.data.policyCategoryDesc}`}</span>
              );
            },
          },

          {
            field: "priority",
            headerName: "Order of Operation",
            minWidth: 83,
            headerTooltip: "Priority",
          },
          {
            field: "lastUpdatedAt",
            headerName: " Last Updated At",
            minWidth: 83,
            headerTooltip: "Last Updated At",
          },
          {
            field: "action",
            headerName: "Action",
            minWidth: 90,
            hide: adminIdx <0?true:false,
            resizable: false,
            cellRenderer: (row) => {
              return (
                <CustomButton
                  variant="contained"
                  style={{
                    height: 18,
                    fontSize: "12px",
                    textTransform: "capitalize",
                    backgroundColor: navyColor,
                    color: "white",
                    marginTop: -6,
                  }}
                  onClick={() => {
                    let obj = _.cloneDeep(columnValues);
                    obj.policyCategoryLkpId = row.data.policyCategoryLkpId;
                    obj.policyCategoryDesc = row.data.policyCategoryDesc;
                    obj.priority = row.data.priority;
                    obj.hardDenialB = row.data.hardDenial == "NO" ? 0 : 1;
                    obj.lastUpdatedAt = row.data.lastUpdatedAt;
                    let isEdit = true;
                    objects(obj, isEdit);
                  }}
                >
                  Edit
                </CustomButton>
              );
            },
          },
        ];
      }
      break;
    case REASON_CODE_LKP:
      {
        columns = [
          {
            field: "reasonCode",
            headerName: "Reason Code",
            minWidth: 109,
            headerTooltip: "Reason Code",
          },

          {
            field: "reasonDesc",
            headerName: "Reason Desc",
            minWidth: 83,
            headerTooltip: "Reason Desc",
            cellRenderer: (row) => {
              return (
                <span
                  title={`${row.data.reasonDesc}`}
                >{`${row.data.reasonDesc}`}</span>
              );
            },
          },
          {
            field: "ChallengeCode",
            headerName: "Challenge Code",
            minWidth: 83,
            headerTooltip: "Challenge Code",
          },
          {
            field: "ChallengeDesc",
            headerName: "Challenge Desc",
            minWidth: 83,
            headerTooltip: "Challenge Desc",
            cellRenderer: (row) => {
              return (
                <span
                  title={`${row.data.ChallengeDesc}`}
                >{`${row.data.ChallengeDesc}`}</span>
              );
            },
          },
          {
            field: "PCOCode",
            headerName: "PCO Code",
            minWidth: 83,
            headerTooltip: "PCO Code",
          },
          {
            field: "HIPAACode",
            headerName: "HIPAA Code",
            minWidth: 83,
            headerTooltip: "HIPAA Code",
          },
          {
            field: "HIPAADesc",
            headerName: "HIPAA Desc",
            minWidth: 83,
            headerTooltip: "HIPAA Desc",
            cellRenderer: (row) => {
              return (
                <span
                  title={`${row.data.HIPAADesc}`}
                >{`${row.data.HIPAADesc}`}</span>
              );
            },
          },
          {
            field: "deactivated",
            headerName: "Deactivated",
            minWidth: 83,
            headerTooltip: "Deactivated",
          },
          {
            field: "custom",
            headerName: "Custom",
            minWidth: 83,
            headerTooltip: "Custom",
          },
          {
            field: "action",
            headerName: "Action",
            minWidth: 90,
            resizable: false,
              hide: adminIdx <0?true:false,
            cellRenderer: (row) => {
              return (
                <CustomButton
                  variant="contained"
                  style={{
                    height: 18,
                    fontSize: "12px",
                    textTransform: "capitalize",
                    backgroundColor: navyColor,
                    color: "white",
                    marginTop: -6,
                  }}
                  onClick={() => {
                    let obj = _.cloneDeep(columnValues);
                    obj.reasonCode = row.data.reasonCode;
                    obj.reasonDesc = row.data.reasonDesc;
                    obj.pcoCode = row.data.PCOCode;
                    obj.hipaaCode = row.data.HIPAACode;
                    obj.hippaDesc = row.data.HIPAADesc;
                    obj.challengeCode = row.data.ChallengeCode;
                    obj.challengeDesc = row.data.ChallengeDesc;
                    obj.deactivatedb = row.data.deactivated == "NO" ? 0 : 1;
                    obj.customb = row.data.custom == "NO" ? 0 : 1;
                    let isEdit = true;
                    objects(obj, isEdit);
                  }}
                >
                  Edit
                </CustomButton>
              );
            },
          },
        ];
      }
      break;
    case CCI_RATIONALE_LKP:
      {
        columns = [
          {
            field: "cciRationaleKey",
            headerName: "CCI Rationale Key",
            minWidth: 109,
            headerTooltip: "CCI Rationale Key",
          },

          {
            field: "cmsCciRationale",
            headerName: "CMS CCI Rationale",
            minWidth: 83,
            headerTooltip: "CMS CCI Rationale",
            cellRenderer: (row) => {
              return (
                <span
                  title={`${row.data.cmsCciRationale}`}
                >{`${row.data.cmsCciRationale}`}</span>
              );
            },
          },
          {
            field: "cciRationaleDesc",
            headerName: "CCI Rationale Desc",
            minWidth: 83,
            headerTooltip: "CCI Rationale Desc",
            cellRenderer: (row) => {
              return (
                <span
                  title={`${row.data.cciRationaleDesc}`}
                >{`${row.data.cciRationaleDesc}`}</span>
              );
            },
          },
          {
            field: "action",
            headerName: "Action",
            minWidth: 90,
             hide: adminIdx <0?true:false,
            resizable: false,
            cellRenderer: (row) => {
              return (
                <CustomButton
                  variant="contained"
                  style={{
                    height: 18,
                    fontSize: "12px",
                    textTransform: "capitalize",
                    backgroundColor: navyColor,
                    color: "white",
                    marginTop: -6,
                  }}
                  onClick={() => {
                    let obj = _.cloneDeep(columnValues);
                    obj.cciRationaleKey = row.data.cciRationaleKey;
                    obj.cmsCciRationale = row.data.cmsCciRationale;
                    obj.cciRationaleDesc = row.data.cciRationaleDesc;
                    let isEdit = true;
                    objects(obj, isEdit);
                  }}
                >
                  Edit
                </CustomButton>
              );
            },
          },
        ];
      }
      break;
    case CCI_LKP:
      {
        columns = [
          {
            field: "cciKey",
            headerName: "CCI Key",
            minWidth: 109,
            headerTooltip: "CCI Key",
          },

          {
            field: "cciDesc",
            headerName: "CCI Desc",
            minWidth: 83,
            headerTooltip: "CCI Desc",
          },
          {
            field: "cciNotes",
            headerName: "CCI Notes",
            minWidth: 83,
            headerTooltip: "CCI Notes",
          },
          {
            field: "action",
            headerName: "Action",
            minWidth: 90,
            hide: adminIdx < 0 ?true:false,
            resizable: false,
            cellRenderer: (row) => {
              return (
                <CustomButton
                  variant="contained"
                  style={{
                    height: 18,
                    fontSize: "12px",
                    textTransform: "capitalize",
                    backgroundColor: navyColor,
                    color: "white",
                    marginTop: -6,
                  }}
                  onClick={() => {
                    let obj = _.cloneDeep(columnValues);
                    obj.cciKey = row.data.cciKey;
                    obj.cciDesc = row.data.cciDesc;
                    obj.cciNotes = row.data.cciNotes;
                    let isEdit = true;
                    objects(obj, isEdit);
                  }}
                >
                  Edit
                </CustomButton>
              );
            },
          },
        ];
      }
      break;
    case MUE_RATIONALE_LKP:
      {
        columns = [
          {
            field: "mueRationalKey",
            headerName: "MUE Rationale Key",
            minWidth: 109,
            headerTooltip: "MUE Rationale Key",
          },

          {
            field: "description",
            headerName: "Description",
            minWidth: 83,
            headerTooltip: "Description",
          },

          {
            field: "action",
            headerName: "Action",
            minWidth: 90,
            hide: adminIdx < 0 ? true : false,
            resizable: false,
            cellRenderer: (row) => {
              return (
                <CustomButton
                  variant="contained"
                  style={{
                    height: 18,
                    fontSize: "12px",
                    textTransform: "capitalize",
                    backgroundColor: navyColor,
                    color: "white",
                    marginTop: -6,
                  }}
                  onClick={() => {
                    let obj = _.cloneDeep(columnValues);
                    obj.mueRationalKey = row.data.mueRationalKey;
                    obj.description = row.data.description;
                    let isEdit = true;
                    objects(obj, isEdit);
                  }}
                >
                  Edit
                </CustomButton>
              );
            },
          },
        ];
      }
      break;
    case BO_TYPE_LKP:
      {
        columns = [
          {
            field: "boKey",
            headerName: "BO Key",
            minWidth: 109,
            headerTooltip: "BO Key",
          },

          {
            field: "boDesc",
            headerName: "BO Desc",
            minWidth: 83,
            headerTooltip: "BO Desc",
          },

          {
            field: "action",
            headerName: "Action",
            minWidth: 90,
            resizable: false,
            hide: adminIdx <0?true:false,
            cellRenderer: (row) => {
              return (
                <CustomButton
                  variant="contained"
                  style={{
                    height: 18,
                    fontSize: "12px",
                    textTransform: "capitalize", 
                    backgroundColor: navyColor,
                    color: "white",
                    marginTop: -6,
                  }}
                  onClick={() => {
                    let obj = _.cloneDeep(columnValues);
                    obj.boKey = row.data.boKey;
                    obj.boDesc = row.data.boDesc;
                    let isEdit = true;
                    objects(obj, isEdit);
                  }}
                >
                  Edit
                </CustomButton>
              );
            },
          },
        ];
      }
      break;
    case BW_TYPE_LKP:
      {
        columns = [
          {
            field: "bwTypeKey",
            headerName: "BW Type Key",
            minWidth: 109,
            headerTooltip: "BW Type Key",
          },

          {
            field: "description",
            headerName: "Description",
            minWidth: 83,
            headerTooltip: "Description",
          },

          {
            field: "action",
            headerName: "Action",
            minWidth: 90,
            resizable: false,
            hide: adminIdx <0?true:false,
            cellRenderer: (row) => {
              return (
                <CustomButton
                  variant="contained"
                  style={{
                    height: 18,
                    fontSize: "12px",
                    textTransform: "capitalize",
                    backgroundColor: navyColor,
                    color: "white",
                    marginTop: -6,
                  }}
                  onClick={() => {
                    let obj = _.cloneDeep(columnValues);
                    obj.bwTypeKey = row.data.bwTypeKey;
                    obj.description = row.data.description;
                    let isEdit = true;
                    objects(obj, isEdit);
                  }}
                >
                  Edit
                </CustomButton>
              );
            },
          },
        ];
      }
      break;
    case MAX_UNITS_TYPES:
      {
        columns = [
          {
            field: "maxUnitsTypeKey",
            headerName: "Max Units Type Key",
            minWidth: 109,
            headerTooltip: "Max Units Type Key",
          },

          {
            field: "maxUnitsTypeDesc",
            headerName: "Max Unit Type Description",
            minWidth: 83,
            headerTooltip: "Max Unit Type Description",
          },

          {
            field: "action",
            headerName: "Action",
            minWitdh: 90,
            hide: adminIdx <0?true:false,
            resizable: false,
            cellRenderer: (row) => {
              return (
                <CustomButton
                  variant="contained"
                  style={{
                    height: 18,
                    fontSize: "12px",
                    textTransform: "capitalize",
                    backgroundColor: navyColor,
                    color: "white",
                    marginTop: -6,
                  }}
                  onClick={() => {
                    let obj = _.cloneDeep(columnValues);
                    obj.maxUnitsTypeKey = row.data.maxUnitsTypeKey;
                    obj.maxUnitsTypeDesc = row.data.maxUnitsTypeDesc;
                    let isEdit = true;
                    objects(obj, isEdit);
                  }}
                >
                  Edit
                </CustomButton>
              );
            },
          },
        ];
      }
      break;
      case MODIFIER_PRIORITY_LKP:
        {
          columns = [
            {
              field: "modifier",
              headerName: "Modifier",
              minWidth: 109,
              headerTooltip: "Modifier",
            },
  
            {
              field: "priority",
              headerName: "Priority",
              minWidth: 83,
              headerTooltip: "Priority",
            },
  
          ];
        }
        break;
    case MODIFIER_PAY_PERCENTAGE_LKP: {
      columns = [
        {
          field: "mppKey",
          headerName: "MPP Key",
          minWidth: 90,
          headerTooltip: "MPP Key",
        },
        {
          field: "modifier",
          headerName: "Modifier",
          minWidth: 90,
          headerTooltip: "Modifier",
        },
        {
          field: "allowedPercentage",
          headerName: "Allowed Percentage",
          minWidth: 109,
          headerTooltip: "Allowed Percentage",
        },
        {
          field: "preOp",
          headerName: "Pre Op",
          minWidth: 60,
          headerTooltip: "Pre Op",
        },

        {
          field: "intraOp",
          headerName: "Intra Op",
          minWidth: 60,
          headerTooltip: "Intra Op",
        },

        {
          field: "postOp",
          headerName: "Post Op",
          minWidth: 60,
          headerTooltip: "Post Op",
        },
      ];
    }
    break;
    case SAME_OR_SIMILAR_CODE_LKP: {
      columns = [
        {
          field: "cptCode",
          headerName: "Cpt Code",
          minWidth: 109,
          headerTooltip: "Cpt Code",
        },

        {
          field: "sameOrSimCode",
          headerName: "Same Or Similar Code",
          minWidth: 83,
          headerTooltip: "Same Or Similar Code",
        },
        {
          field: "dosFrom",
          headerName: "Dos From",
          minWidth: 83,
          headerTooltip: "Dos From",
        },
        {
          field: "dosTo",
          headerName: "Dos To",
          minWidth: 83,
          headerTooltip: "Dos To",
        },
      ]
    }

  }
  return columns;
}

  
import React, { useEffect, useMemo, useRef, useState } from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import "../PolicyUpdateReport/PolicyUpdateReport.css";
import Typography from "@mui/material/Typography";
import Paragraph from "../../components/Paragraph/Paragraph";
import swal from "sweetalert2";
import { dangerColor, navyColor } from "../../assets/jss/material-kit-react";
import Template from "../../components/Template/Template";
import CustomHeader from "../../components/CustomHeaders/CustomHeader";
import CustomButton from "../../components/CustomButtons/CustomButton";
import { uploadPolicyReport } from "../../redux/actions/NewPolicyAction";
import { useDispatch, useSelector } from "react-redux";
import XLSX from "xlsx";
import * as FileSaver from "file-saver";
import AgGrids from "../../components/TableGrid/AgGrids";

import {
  AllCommunityModules,
  ModuleRegistry,
} from "@ag-grid-community/all-modules";
import { LookUpState } from "../../redux/reducers/LookUpReducer";
import { fetchLookupData, 
} from "../../redux/actions/LookupsActions";
import Header from "../../components/Header/Header";
import { exportedExcelFileData } from "../../components/ExportExcel/ExportExcelFile";
import { SAME_OR_SIMILAR_CODE_LKP } from "../LookUps/LookUpConsts";

const PolicyUpdateReport = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [flag, setFlag] = React.useState(false);
  const clickTimeout = useRef(null);

  const [rows, setRows] = React.useState([]);
  const [numberOfRows, setNumberOfRows] = useState(0);

  const inputRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();

  const updatedState: LookUpState = useSelector(
    (state: any) => state.lookupReducer
  );

  const handleUploadFile = (file) => {
    // if(checkQuaterValue == null || checkQuaterValue == undefined){
    var allowedFiles = [".xlsx"];
    var regex = new RegExp(
      "([a-zA-Z0-9s_\\.-:()])+(" + allowedFiles.join("|") + ")$"
    );
    if (!regex.test(file.name.toLowerCase())) {
      swal.fire({
        icon: "error",
        text: "Please upload valid file(.xlsx)",
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
      setFlag(false);
    } else {
      setFlag(true);
    }
  };

  const resetInputField = () => {
    setSelectedFile(undefined);
    setFlag(false);
    setRows([]);
    setNumberOfRows(0);
  };

  let exportedData = [];
  let exportedresultData = [];

  const PolicyUpdateReportColumns = [
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      headerTooltip: "Status",
    },
    {
      field: "cptCode",
      headerName: "CPT Code",
      minWidth: 100,
      headerTooltip: "Cpt Code",
    },
    {
      field: "sameOrSimCode",
      headerName: "Same/Sim Code",
      minWidth: 90,
      headerTooltip: "Same/Sim Code",
    },
    {
      field:"mod1",
      headerName:"Mod1",
      minWidth:60,
      headerTooltip:"mod1",
    },
    {
      field: "action",
      headerName: "ACTION",
      minWidth: 80,
      headerTooltip: "action",
    },
    {
      field: "policyNumber",
      headerName: "Policy #",
      minWidth: 60,
      headerTooltip: "Policy Number",
    },
    {
      field: "medicalPolicy",
      headerName: "Medical Policy",
      minWidth: 140,
      headerTooltip: "Medical Policy",
      cellRenderer: (cellValues) => {
        return (
          <span
            title={`${cellValues.data.medicalPolicy}`}
          >{`${cellValues.data.medicalPolicy}`}</span>
        );
      },
    },
    {
      field: "subPolicy",
      headerName: "Sub-Policy",
      minWidth: 140,
      headerTooltip: "Sub-Policy",
      cellRenderer: (cellValues) => {
        return (
          <span
            title={`${cellValues.data.subPolicy}`}
          >{`${cellValues.data.subPolicy}`}</span>
        );
      },
    },
    {
      field:"exclusion",
      headerName:"Exclusion",
      minWidth:60,
      headerTooltip:"Exclusion",
    }
  ];
  let checkError = false;

  const checkNullValues = (data, idx) => {
    if (data.status != "NEW" && data.status != "DEL" && data.status != "CHG") {
      checkError = true;
      swal.fire({
        icon: "error",
        title: "Status",
        text: "Status should be NEW or DEL or CHG",
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
    if (data.status == null && data.status == undefined) {
      checkError = true;
      swal.fire({
        icon: "error",
        title: "Status",
        text: "Please enter Status at Row " + idx,
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }

    if (data.cptCode == null || data.cptCode == undefined) {
      checkError = true;
      swal.fire({
        icon: "error",
        title: "Cpt code",
        text: "Please enter CPT Code at Row " + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
    if (data.cptCode.length < 5) {
      checkError = true;
      swal.fire({
        icon: "error",
        title: "Cpt code",
        text: "CPT Code Should be 5 characters at Row" + (idx + 1),
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }

    return checkError;
  };

  const checkHeaderName = (data) => {
    if (data.length > 2) {
      checkError = true;
      swal.fire({
        icon: "error",
        text: "Please Upload Valid File ",
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }

    if (data[0] != "Status") {
      checkError = true;
      swal.fire({
        icon: "error",
        text: "Please Upload Valid File ",
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
    if (data[1] != "CPT Code") {
      checkError = true;
      swal.fire({
        icon: "error",
        text: "Please Upload Valid File",
        confirmButtonColor: navyColor,
        confirmButtonText: "OK",
      });
    }
  };
  const uploadPolicyReportFile = () => {
    var files = selectedFile;
    var reader = new FileReader();
    reader.onload = async function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary", cellDates: true });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      const options = { header: 1 };

      let inputData = [];
      inputData = XLSX.utils.sheet_to_json(ws, options);

      const header = inputData.shift();
      checkHeaderName(header);
      //@ts-ignore
      let excelRows = XLSX.utils.sheet_to_row_object_array(ws);
      if (checkError == false) {
        const dataParse = XLSX.utils.sheet_to_json(ws, {
          header: 1,
          blankrows: false,
        });

        let ReportedData = [];
        let UploadedData = [];
        dataParse.forEach((d, idx) => {
          if (idx >= 1) {
            let obj = {
              status: d[0],
              cptCode: String(d[1]),
            };
            ReportedData.push(obj);
            checkNullValues(obj, idx);
          }
        });

        if (checkError == false) {
          let PolicyUpdateReport = await uploadPolicyReport(
            dispatch,
            ReportedData
          );
          let exportedData = [];
          ReportedData.filter((st) => {
            PolicyUpdateReport.filter((pol) => {
              if (st.status == "NEW") {
                if (st.cptCode == sameSimCodes(pol.cptCode) || st.cptCode == pol.cptCode) {
                  exportedData.push({
                    status: st.status,
                    policyNumber: pol.policyNumber,
                    cptCode: sameSimCodes(pol.cptCode)?sameSimCodes(pol.cptCode):pol.cptCode,
                    sameOrSimCode: gettingSameOrSimCodesOnly(pol.cptCode)?gettingSameOrSimCodesOnly(pol.cptCode):"",
                    medicalPolicy: pol.medicalPolicy,
                    subPolicy: pol.subPolicy,
                    action: pol.action,
                    mod1:pol.mod1,
                    exclusion:pol.exclusion == 0 ? "NO": "YES"
                  });
                }
              } else if (st.status == "CHG" || st.status == "DEL") {
                if (st.cptCode == pol.cptCode) {
                  exportedData.push({
                    status: st.status,
                    policyNumber: pol.policyNumber,
                    cptCode: pol.cptCode,
                    sameOrSimCode: pol.cptCode,
                    medicalPolicy: pol.medicalPolicy,
                    subPolicy: pol.subPolicy,
                    action: pol.action,
                    mod1:pol.mod1,
                    exclusion:pol.exclusion == 0 ? "NO": "YES"
                  });
                }
              }
            });
          });
          const obj1Ids = new Set(exportedData.map((item) => item.cptCode));

          // Filter obj2 to include only items with IDs not present in obj1
          const UnmatchedResults = ReportedData.filter(
            (item) => !obj1Ids.has(item.cptCode)
          );

          UnmatchedResults.map((F) => {
            exportedData.push({
              status: F.status,
              policyNumber: " ",
              cptCode: F.cptCode,
              sameOrSimCode:F.status == "NEW"? unmatchedCodes(F.cptCode): F.cptCode,
              medicalPolicy:
                unmatchedCodes(F.cptCode) == ""
                  ? "No Same/Sim Mapping Found"
                  : "No Policy Impact Found",
              subPolicy: " ",
              action: "N/A",
              mod1:" ",
              exclusion:" "
            });
          });
          setRows(exportedData);
          setNumberOfRows(exportedData.length);
        }
      }
    };
    reader.readAsBinaryString(files);
  };
  function ShowEmptyDetails() {}
  useEffect(() => {
    if (updatedState.SameOrSimilarCodes.length == 0) {
      let lkpName = SAME_OR_SIMILAR_CODE_LKP;
      fetchLookupData(dispatch,lkpName);
    }
  }, []);

  const sameSimCodes = (code) => {
    let sameSimCode = "";
    updatedState.SameOrSimilarCodes.map((k) => {
      if (k.sameOrSimCode == code) {
        sameSimCode = k.cptCode;
      }
    });
    return sameSimCode;
  };

  const gettingSameOrSimCodesOnly = (code) => {
    let sameSimCode = "";
    updatedState.SameOrSimilarCodes.map((k) => {
      if (k.sameOrSimCode == code) {
        sameSimCode = k.sameOrSimCode;
      }
    });
    return sameSimCode;
  };

  const unmatchedCodes = (code) => {
    let sameSimCode = "";
    updatedState.SameOrSimilarCodes.map((k) => {
      if (k.cptCode == code) {
        sameSimCode = k.sameOrSimCode;
      }
    });
    return sameSimCode;
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const sameorsimeCodeheader = ["Status", "CPT Code"];
  const samesimData = [
    ["NEW", "98765"],
    ["DEL", "97654"],
    ["CHG", "98653"],
  ];

  function DownloadReferenceTemplate() {
    clearTimeout(clickTimeout.current);
    clickTimeout.current = setTimeout(() => {
      // const ws = XLSX.utils.json_to_sheet(RefTemplateObj);
      const ws = XLSX.utils.aoa_to_sheet([
        sameorsimeCodeheader,
        ...samesimData,
      ]);

      const wb = {
        Sheets: { CptCodes: ws },
        SheetNames: ["CptCodes"],
        Headers: ["Status", "CPT Code"],
      };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      let fileName = "Reference Cpt Code Template";
      FileSaver.saveAs(data, fileName + fileExtension);
    }, 250);
  }

  const env = process.env.NODE_ENV;
 
  const openDropbox = () => {
    clearTimeout(clickTimeout.current);

    if (window.location.href.indexOf("local") > -1) {
      let loc =
      "https://advancedpricing.sharepoint.com/sites/AMPSIPU/Shared%20Documents/Forms/AllItems.aspx?newTargetListUrl=%2Fsites%2FAMPSIPU%2FShared%20Documents&viewpath=%2Fsites%2FAMPSIPU%2FShared%20Documents%2FForms%2FAllItems%2Easpx&id=%2Fsites%2FAMPSIPU%2FShared%20Documents%2FAMPS%5FIPU%5FLOCAL%2FData%5FCuration%2FReference%20Templates%2FSame%20or%20Similar%20Codes%20Template&viewid=303e0f89%2D1795%2D4fa7%2Dafed%2D13c5bb4162a5"
      window.open(loc);
    } else if (window.location.href.indexOf("dev") > -1) {
      let Dev =
      "https://advancedpricing.sharepoint.com/sites/AMPSIPU/Shared%20Documents/Forms/AllItems.aspx?newTargetListUrl=%2Fsites%2FAMPSIPU%2FShared%20Documents&viewpath=%2Fsites%2FAMPSIPU%2FShared%20Documents%2FForms%2FAllItems%2Easpx&id=%2Fsites%2FAMPSIPU%2FShared%20Documents%2FAMPS%5FIPU%5FDEV%2FData%5FCuration%2FReference%20Templates%2FSame%20or%20Similar%20Codes%20Template&viewid=303e0f89%2D1795%2D4fa7%2Dafed%2D13c5bb4162a5"
          window.open(Dev);
    } else if (window.location.href.indexOf("qa") > -1) {
      let QA =
      "https://advancedpricing.sharepoint.com/sites/AMPSIPU/Shared%20Documents/Forms/AllItems.aspx?newTargetListUrl=%2Fsites%2FAMPSIPU%2FShared%20Documents&viewpath=%2Fsites%2FAMPSIPU%2FShared%20Documents%2FForms%2FAllItems%2Easpx&id=%2Fsites%2FAMPSIPU%2FShared%20Documents%2FAMPS%5FIPU%5FQA%2FData%5FCuration%2FReference%20Templates%2FSame%20or%20Similar%20Codes%20Template&viewid=303e0f89%2D1795%2D4fa7%2Dafed%2D13c5bb4162a5"
      window.open(QA);
    }
    let production =
      "https://advancedpricing.sharepoint.com/sites/AMPSIPU/Shared%20Documents/Forms/AllItems.aspx?newTargetListUrl=%2Fsites%2FAMPSIPU%2FShared%20Documents&viewpath=%2Fsites%2FAMPSIPU%2FShared%20Documents%2FForms%2FAllItems%2Easpx&id=%2Fsites%2FAMPSIPU%2FShared%20Documents%2FAMPS%20IPU%2FData%20Curation%2FReference%20Templates%2FSame%20or%20Similar%20Codes%20Template&viewid=303e0f89%2D1795%2D4fa7%2Dafed%2D13c5bb4162a5";

    if (env === "production") {
      window.open(production);
    }
  };

  const gridIconStyle = useMemo(
    () => ({
      position: "absolute",
      top: "70px",
      float: "right",
      right: "35px",
      display: "inline",
    }),
    []
  );

  const onFilterChanged = (params) => {
    setNumberOfRows(params.api.getDisplayedRowCount());
  };

  const ExportPolicyReportData = (obj) => {
    const ws = XLSX.utils.json_to_sheet(obj);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    let fileName = "Policy Details Report";
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Template>
    <div className="row">
      <div className="col-sm-6">
        {" "}
        <CustomHeader labelText={"Policy Update Report"} />
      </div>
      <div className="col-sm-3" />
      <div className="col-sm-2">
        <button
          type="button"
          id="actionButton"
          style={{
            border: "none",
            textDecorationLine: "underline",
            backgroundColor: "white",
            textDecorationColor: "blue",
          }}
          onClick={DownloadReferenceTemplate}
          onDoubleClick={openDropbox}
        >
          ReferenceTemplate
        </button>
      </div>
    </div>
    <div className="row" style={{ marginTop: "3%" }}>
      <div className="col-sm-3" />
      <div className="col-sm-2">
        <Paragraph labelText={"Upload Source File"} />
      </div>
      <div className="col-sm-2">
        <div className="choosefile">
          <input
            ref={inputRef}
            type="file"
            onChange={(e) => {
              let file = e.target.files[0];
              setSelectedFile(file);
              handleUploadFile(e.target.files[0]);
            }}
          />
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-3" />
      <div className="col-sm-2" />
      <div className="col-sm-4">
        {flag == true ? (
          <CustomButton
            variant="contained"
            onClick={() => {
              uploadPolicyReportFile();
            }}
            style={{
              backgroundColor: navyColor,
              color: "white",
              fontSize: 12,
              textTransform: "capitalize",
            }}
          >
            Run
          </CustomButton>
        ) : undefined}
        <CustomButton
          variant="contained"
          style={{
            backgroundColor: dangerColor,
            color: "white",
            marginLeft: 10,
            fontSize: 12,
            textTransform: "capitalize",
          }}
          onClick={() => {
            inputRef.current.value = "";
            resetInputField();
          }}
        >
          Reset
        </CustomButton>
      </div>
    </div>
    <div className="row">
      {rows.length > 0 ? (
        <div>
          <CustomHeader labelText={"Report Details"} />
          <div className="grid" style={{height: rows.length>20 ?"400px":"200px"}}>
            <AgGrids
              //@ts-ignore
              rowData={rows}
              columnDefs={PolicyUpdateReportColumns}
              gridIconStyle={gridIconStyle}
              onFilterChanged={onFilterChanged}
            />
          </div>
        </div>
      ) : undefined}
      {rows.length > 0 ? (
        <small
          style={{ position: "relative", top: "30px", fontSize: "12px" }}
        >
          Number of rows : {numberOfRows}
        </small>
      ) : undefined}
    </div>  
    <div className="row">
      <div className="export">
            {numberOfRows > 0 ? (
              <CustomButton
                onClick={() => {
                  exportedExcelFileData(rows,"Policy Details Report","PolicyUpdateData")
                }}
                variant={"contained"}
                style={{
                  backgroundColor: navyColor,
                  color: "white",
                  padding: 4,
                  fontSize: 12,
                  textTransform: "capitalize",
                  // marginTop: -8,
                  float: "right",
                }}
              >
                Export
              </CustomButton>
            ) : undefined}
      </div> 
      </div> 
  </Template>
  );
};
export default PolicyUpdateReport;

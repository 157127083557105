import PropTypes from "prop-types";




export default function Template(props) {
  const { children, className, onScroll } = props;
  const currentYear = new Date().getFullYear();


  return (
    <div style={{ padding: "5px" }}>
      <div style={{ minHeight: window.innerHeight / 1.17 }}>{children}</div>
      <p
        style={{
          width: "100%",
          textAlign: "center",
          color: "darkgrey",
          fontSize: 13,
          marginBottom: 0,
        }}
      >
        ©CPT copyright {currentYear} American Medical Association. All rights
        reserved.
      </p>
    </div>
  );
}
Template.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  dangerColor,
  navyColor,
  successColor,
  goldColor,
  yellow,
} from "../../assets/jss/material-kit-react";
import CustomButton from "../../components/CustomButtons/CustomButton";
import {
  DIALOG_CAT_RESET_STATE,
  DIALOG_REASON_RESET_STATE,
  JIRA_DESCRIPTION,
  JIRA_ID,
  JIRA_LINK,
  RESET_STATE,
} from "../../redux/actions/NewPolicyFormActionTypes";
import { NewPolicyFormState } from "../../redux/reducers/NewPolicyFormReducer";
import { UserState } from "../../redux/reducers/UserReducer";
import NewPolicy from "../NewPolicy/NewPolicy";
import { getConfigValidation } from "../../redux/actions/TestingReport";
import Dialogbox from "../../components/Dialog/DialogBox";
import { ButtonGroup } from "@material-ui/core";
import CustomInput from "../../components/CustomInput/CustomInput";
import "../ViewPolicy/ViewPolicy.css";
import CustomCheckBox from "../../components/CustomCheckBox/CustomCheckBox";
import { CustomPolicyState } from "../../redux/reducers/CustomPolicyReducer";
import {
  CLONE_ASSIGNMENT_CHECK,
  CUSTOM_JIRAID,
  CUSTOM_JIRA_DESCRIPTION,
  CUSTOM_JIRA_LINK,
  CUSTOM_NEW_POLICY_DATE,
  CUSTOM_POLICY_FORM_REST_STATE,
} from "../../redux/actions/CustomPolicyActionType";
import {
  onSaveCustomPolicy,
  validateCustomPolicyForm,
} from "../../redux/actions/CustomPolicyAction";
import { NewPolicyState } from "../../redux/reducers/NewPolicyReducer";

const ViewPolicy = () => {
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();
  const [hide, sethide] = useState(true);

  const [button, setbutton] = useState(false);
  const [clickOnConfirmation, setClickOnConfirmation] = useState(false);
  const [clickOnCreatedB, setClickOnCreatedB] = useState(false);
  const roleState: UserState = useSelector((state: any) => state.userReducer);
  const customFormState: CustomPolicyState = useSelector(
    (state: any) => state.customPolicy
  );
  const [openPopUp, setOpenPopUp] = useState(false);
  const fullWidth = true;
  const maxWidth = "sm";
  useEffect(() => {
    return () => {
      localStorage.setItem("jiraId", "");
    };
  }, []);
  let data = roleState.roleName;
  let Role = JSON.stringify(data);
  let testIdx = Role.toLocaleLowerCase().search("testing");
  let auditIdx = Role.toLocaleLowerCase().search("audit");
  let adminIdx = Role.toLocaleLowerCase().search("admin");
  let researchIdx = Role.toLocaleLowerCase().search("research");
  const formState: NewPolicyFormState = useSelector(
    (state: any) => state.newPolicyForm
  );

  let emailId = formState.userDetails.emailId;

  async function onConfigData() {
    let obj = {
      selectedType: "SINGLE_POLICY",
      policyId: formState.policyId,
      policyNumber: formState.policy,
      policyVersion: formState.version,
      emailId: emailId,
    };
    await getConfigValidation(dispatch, obj);
  }

  const updatedState: NewPolicyState = useSelector(
    (state: any) => state.newPolicy
  );

  const navigate = useNavigate();

  const navigate1 = () => {
    dispatch({ type: DIALOG_REASON_RESET_STATE });
    dispatch({ type: DIALOG_CAT_RESET_STATE });
    dispatch({ type: RESET_STATE });
    navigate("/search");
  };

  const onBack = () => {
    navigate("/editPolicy");
    setDisabled(false);
  };
  useEffect(() => {
    if (adminIdx > 0) {
      setbutton(false);
    } else if (formState.userId !== emailId) {
      setbutton(true);
    } else if (formState.userId == "" || formState.userId === emailId) {
      setbutton(false);
    }

    if (researchIdx > 0) {
      if (formState.userId !== emailId) {
        setbutton(true);
      }
      if (formState.userId === "") {
        setbutton(false);
      }
      if (
        adminIdx > 0 ||
        formState.userId == "" ||
        formState.userId === emailId
      ) {
        setbutton(false);
      }
    }
  }, [roleState.roleName, formState]);

  useEffect(() => {
    if (adminIdx > 0 || researchIdx > 0) {
      sethide(true);
    } else if (testIdx > 0 || auditIdx > 0) {
      sethide(false);
    } else {
      sethide(true);
    }
  },[roleState]);

  const handleToClose = () => {
    setClickOnCreatedB(false);
    setOpenPopUp(false);
    dispatch({ type: CUSTOM_POLICY_FORM_REST_STATE });
    setClickOnConfirmation(false);
  };
  function showFields() {
    return (
      <>
        <div className="row rowVp">
          <div className="col-sm-6">
            <CustomInput
              error={customFormState.customPolicyErrors?.customJiraId}
              fullWidth={true}
              labelText={"Jira ID"}
              showStarIcon={true}
              value={customFormState.customJiraId}
              onChange={(event) => {
                dispatch({
                  type: CUSTOM_JIRAID,
                  payload: event.target.value,
                });
              }}
              variant={"outlined"}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <CustomInput
              error={customFormState.customPolicyErrors?.customJiraDesc}
              fullWidth={true}
              labelText={"Jira Description"}
              showStarIcon={true}
              value={customFormState.customJiraDesc}
              onChange={(event) => {
                dispatch({
                  type: CUSTOM_JIRA_DESCRIPTION,
                  payload: event.target.value,
                });
                dispatch({
                  type: CUSTOM_JIRA_LINK,
                  payload:
                    "https://advancedpricing.atlassian.net/browse/" +
                    event.target.value,
                });
              }}
              variant={"outlined"}
            />
          </div>
        </div>
        <div className="row rowVp">
          <CustomCheckBox
            checked={customFormState.cloneAssignmentCheck}
            size="small"
            disabled={true}
            onChange={(event) => {
              dispatch({
                type: CLONE_ASSIGNMENT_CHECK,
                payload: event.target.checked,
              });
            }}
            label={
              <span
                style={{
                  fontSize: "13px",
                  position: "relative",
                  fontWeight: "400",
                  bottom: "2px",
                }}
              >
                Clone Client Assignment Tab
              </span>
            }
          />
        </div>
        <div className="row">
          <div className="col-sm-6">
            <CustomInput
              disabled={!customFormState.cloneAssignmentCheck}
              error={customFormState.customPolicyErrors?.customNewPolicyDate}
              labelText={"Process Start Date"}
              type="date"
              variant={"outlined"}
              value={customFormState.customNewPolicyDate}
              onChange={(event) => {
                dispatch({
                  type: CUSTOM_NEW_POLICY_DATE,
                  payload: event.target.value,
                });
              }}
            />
          </div>
        </div>
      </>
    );
  }
  function checkFormValidation(formState) {
    let flag = validateCustomPolicyForm(customFormState, dispatch);
    if (!flag) {
      setClickOnConfirmation(true);
    }
    if (clickOnConfirmation == true) {
      saveCustomPolicy(formState);
    }
  }

  let clonedPolicy = formState.policyId;
  async function saveCustomPolicy(formState) {
    customFormState.customPolicyForm = formState;
    formState.custom = 1;
    formState.jiraId = customFormState.customJiraId;
    formState.jiraDescription = customFormState.customJiraDesc;
    formState.jiraLink = customFormState.customJiraLink;
    formState.userId = formState.userDetails.emailId;
    // formState.isOpenb = 1;
    formState.jiraIsOpen = 1;
    formState.policy = formState.policy;
    formState.policyId = "";
    formState.clonedPolicyId = clonedPolicy;
    const date = new Date();
    let createdDate = customFormState.customNewPolicyDate?customFormState.customNewPolicyDate:date.getFullYear()+"-"+date.getMonth()+"-"+date.getDate();
    let cloned = customFormState.cloneAssignmentCheck;
    await onSaveCustomPolicy(dispatch, formState,createdDate,cloned, navigate,true);
    handleToClose();
  }


  return (
    <>
      <div>
        <div style={{ height: "3px" }}></div>
        {!disabled ? undefined : (
          <CustomButton
            variant={"contained"}
            style={{
              backgroundColor: dangerColor,
              color: "white",
              textTransform: "capitalize",
              fontSize: 12,
              padding: 4,
              float: "right",
              marginLeft: "15px",
            }}
            color={"secondary"}
            onClick={() => navigate1()}
          >
            Back
          </CustomButton>
        )}
        {!disabled ? undefined : (
          <CustomButton
            variant={"contained"}
            style={{
              float: "right",
              color: "white",
              textTransform: "capitalize",
              padding: 4,
              backgroundColor: goldColor,
              fontSize: 12,
            }}
            onClick={() => onConfigData()}
          >
            Validate
          </CustomButton>
        )}
        {disabled ?  (
          <CustomButton
            variant={"contained"}
            style={{
              backgroundColor: !hide ? "#BBCBCF" : successColor,
              color: "white",
              textTransform: "capitalize",
              fontSize: 12,
              padding: 4,
              float: "right",
              marginRight: "15px",
            }}
            disabled={!hide}
            color={"secondary"}
            onClick={() => {
              setClickOnCreatedB(true);
              setOpenPopUp(true);
            }}
          >
            Create
          </CustomButton>
        ):undefined}
        {disabled && hide ? (
          <CustomButton
            variant={"contained"}
            style={{
              float: "right",
              marginRight: 10,
              textTransform: "capitalize",
              padding: 4,
              fontSize: 12,
              backgroundColor: button ? "#BBCBCF" : navyColor,
              color: "white",
            }}
            onClick={() => onBack()}
            disabled={button}
          >
            Edit
          </CustomButton>
        ) : undefined}

        <NewPolicy
          edit={disabled}
          fromViewPolicy={true}
          showImportButton={false}
          customCreatedB={clickOnCreatedB}
        />
      </div>
      <Dialogbox
        disableBackdropClick={true}
        open={openPopUp}
        onClose={handleToClose}
        fullWidth={clickOnConfirmation ? "" : fullWidth}
        maxWidth={clickOnConfirmation ? "" : maxWidth}
        title={clickOnConfirmation ? "Confirm" : "New Policy Version"}
        message={
          clickOnConfirmation
            ? "Do you want to continue with the Policy Creation?"
            : showFields()
        }
        actions={
          <ButtonGroup>
            <CustomButton
              variant={"contained"}
              onClick={() => {
                checkFormValidation(formState);
              }}
              style={{
                backgroundColor: clickOnConfirmation ? navyColor : successColor,
                color: "white",
                margin: 10,
                padding: 4,
                fontSize: 12,
                textTransform: "capitalize",
              }}
            >
              {clickOnConfirmation ? "Ok" : "Continue"}
            </CustomButton>
            <CustomButton
              variant={"contained"}
              onClick={() => {
                if (clickOnConfirmation) {
                  setClickOnConfirmation(false);
                } else {
                  dispatch({ type: CUSTOM_POLICY_FORM_REST_STATE });
                  handleToClose();
                }
              }}
              style={{
                backgroundColor: dangerColor,
                color: "white",
                margin: 10,
                padding: 4,
                fontSize: 12,
                textTransform: "capitalize",
              }}
            >
              Cancel
            </CustomButton>
          </ButtonGroup>
        }
      />
    </>
  );
};
export default ViewPolicy;

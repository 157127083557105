export const CUSTOM_JIRAID = "customJiraId"
export const CUSTOM_JIRA_DESCRIPTION = "customJiraDescription"
export const CUSTOM_JIRA_LINK = "customJiraLink"
export const CUSTOM_NEW_POLICY_DATE = "customNewPolicyDate"
export const CUSTOM_POLICY_FORM = "customPolicyForm"
export const CLONE_ASSIGNMENT_CHECK = "cloneAssignmentCheck"
export const CUSTOM_POLICY_FORM_REST_STATE = "customPolicyFormResetState"
export const CUSTOM_POLICY_VALIDATION = "customPolicyValidation"
export const CUSTOM_POLICY_IS_OPEN = "customPolicyIsOpen"


import { Column } from '@ag-grid-community/core';
import { act } from 'react-dom/test-utils';
import { GET_CHANGE_MODIFIER, GET_REASON_CODES } from '../actions/actionTypes';
import {
  CAT,
  CLAIM_TYPE,
  CREATEDDATE,
  DEACTIVATED,
  DISABLED,
  EDIT_CHANGES,
  EDIT_POLICY,
  EDIT_POLICY_TABLE_CHANGES,
  ENFORCE_BEFORE_CAT,
  GENDER,
  GET_POLICY_NUMBER,
  JIRA_DESCRIPTION,
  JIRA_ID,
  JIRA_ISOPEN,
  JIRA_LINK,
  LOB,
  MEDICAL_POLICY,
  NEW_POLICY_VALIDATION,
  NOTES,
  NPI,
  POS_LINK,
  POLICY,
  POLICY_CHANGES_KEY,
  POLICY_EXPLANATION,
  POLICY_ID,
  POLICY_SUMMARY,
  PRIORITY,
  PROCEDURE_MAX_AGE,
  PROCEDURE_MIN_AGE,
  PROD,
  PRODUCT_TYPE,
  REASON,
  REFERENCE,
  REFERENCE_SRC_DESC,
  REFERNCE_SRC_TITLE_DESC,
  RESET_STATE,
  ROLE_DESCRIPTION,
  SOURCE_INDICATOR,
  SUB_POLICY,
  TAX_ID_LOGIC,
  USER_ID,
  VERSION,
  CHANGES_ISOPEN_B,
  TEMP_USER,
  REASON_DESC,
  CAT_DESC,
  CAT_CHECK,
  REASON_CHECK,
  TEMP_CAT_DESC,
  TEMP_CAT_CHECK,
  TEMP_REASON_DESC,
  TEMP_REASON_CHECK,
  TEMP_CAT_CODE,
  TEMP_REASON_CODE,
  IDX,
  DIALOG_CAT_RESET_STATE,
  DIALOG_REASON_RESET_STATE,
  ERROR_RESET_STATE,
  CCI_KEY,
  BY_PASS_MOD,
  MUTUALLY_EXCLUSIVE,
  DENY,
  MEDICAL_CHECK,
  MEDICAL_DESC,
  DIALOG_MEDICAL_RESET_STATE,
  TEMP_MEDICAL_CHECK,
  TEMP_MEDICAL_DESC,
  TEMP_MEDICAL_POLICY_CODE,
  SUBPOLICY_CHECK,
  DIALOG_SUB_POLICY_RESET_STATE,
  SUBPOLICY_DESC,
  TEMP_SUBPOLICY_CHECK,
  TEMP_SUBPOLICY_CODE,
  TEMP_SUBPOLICY_DESC,
  ENFO_CAT_CHECK,
  ENFO_CAT_DESC,
  TEMP_ENFORCE_BEFORE_CAT_CODE,
  TEMP_ENFO_CAT_CHECK,
  TEMP_ENFO_CAT_DESC,
  DIALOG_ENFO_CAT_RESET_STATE,
  MIN_AGE_ID_CHECK,
  MIN_AGE_DESC,
  TEMP_MIN_AGE_ID_CHECK,
  TEMP_MIN_AGE_DESC,
  DIALOG_MIN_AGE_RESET_STATE,
  TEMP_PROCEDURE_MIN_AGE_CODE,
  MAX_AGE_ID_CHECK,
  MAX__AGE_DESC,
  TEMP_MAX__AGE_ID_CHECK,
  TEMP_MAX_AGE_DESC,
  DIALOG_MAX_AGE_RESET_STATE,
  TEMP_PROCEDURE_MAX_AGE_CODE,
  IS_JIRA_OPEN,
  BILLED_WITH,
  ALLOW_CMS_NCCI_MODIFIER,
  IGNORE_MODIFIER_59_GROUPS,
  NEW_POLICY_TABS,
  PATHS,
  LINE_OR_HEADER_OR_PRINC,
  USER_DETAILS,
  GET_ACTION_DATA,
  GET_BILL_TYPE_DATA,
  POLICY_BILL_TYPE_DATA,
  GET_SOURCE_BILL_TYPE_LKP_DATA,
  BILL_TYPE_LINK,
  GET_POLICY_BILL_TYPE_ACTION_LKP,
  BILL_TYPE_ACTION,
  CONDITION_CODE_ACTION,
  TAXONOMY_LOGIC,
  BW_TYPE_KEY,
  REVENUE_CODE_CLAIM_LINK,
  MAX_UNITS_TYPE,
  MOD_INTRACTION_TYPE,
  CLAIM_TYPE_LINK,
  TC_26_MOD_B,
  AS_GROUP_B,
  CPT_LINK,
  CHANGE_MODIFIER_KEY,
  MODIFIER_PAY_PERCENTAGE,
  UNITS,
  FREQUENCY,
  DURATION,
  RANKING,
  PAYMENT,
  DURATION_DROPDOWN,
  FREQUENCY_RESET,
  CUSTOM,
  CLONED_POLICY_ID,
  REFERENCE_CLAIM_TYPE,
  CLONED_POL_VER

} from '../actions/NewPolicyFormActionTypes';
import { RESET_LOGIN_DETAILS } from '../actions/UserActionTypes';
import { MODIFIER_PRIORITY_LKP } from '../../pages/LookUps/LookUpConsts';

export interface NewPolicyFormState {
  // accountUser: any;
  policyId: number;
  policy: any;
  version: any;
  custom:any;
  clonedPolicyId:any;
  clonedPolVer:any;
  // reason: { label: string; value: string };
  // cat: { label: string; value: number };
  cat: any;
  // reason: { label: string; value: string };
  reason: any;
  // cat: { label: string; value: number };
  // medicalPolicy: { label: string; value: number };
  medicalPolicy: any;
  // subPolicy: { label: string; value: number };
  subPolicy: any;
  reference: string;
  cliamType: { label: string; value: string }[];
  prod: any;
  deactivated: any;
  disabled: any;
  roldDescription: string;
  lob: { label: string; value: number };
  productType: { label: string; value: String }[];
  notes: string;
  policySummary: string;
  policyExplantion: string;
  referenceSrcDesc: string;
  referenceSrcTitleDesc: string;
  sourceIndicator: string;
  createdDate: Date;
  jiraId: string;
  jiraDescription: string;
  jiraLink: string;
  userId: string;
  policyChangesKey: number;
  jiraIsOpen: any;
  gender: { label: string; value: number };
  // procedureMinAge: { label: string; value: number };
  procedureMinAge: any;
  taxIdLogic: { label: string; value: number };
  taxonomyLogicFk: { label: string; value: number };
  // enforceBefCategory: { label: string; value: number };
  enforceBefCategory: any;
  priority: any;
  // procedureMaxAge: { label: string; value: number };
  procedureMaxAge: any;
  npi: { label: string; value: number };
  posLink: { label: string; value: number };
  revenueCodeClaimLink: { label: string; value: number };
  cptLink: { label: string; value: number };

  allowCms: any;
  changesTableData: any[];
  changesisopenb: any;
  tmpUser: { label: string; value: string };
  reasonDesc: any;
  catDesc: any;
  catCheck: any;
  tmpCatCode: any;
  reasonCheck: any;
  temporaryCatDesc: any;
  tmpCatCheck: any;
  temporaryReasonDesc: any;
  tmpReasonCheck: any;
  tmpReasonCode: any;
  isJira: any;
  isJiraDesc: any;
  billedWith: { label: string; value: string };
  ccikey: any;
  bwTypeKey: any;
  changeModifierKey: any;
  modifierPayPercentage: any;
  maxUnitsType: any;
  modIntractionType: any;
  byPassMod: any;
  denyType: any;
  mutuallyExclusive: any;
  ncciB: any;
  group59B: any;
  asGroupB: any;
  referenceClaimType:any;
  tc26ModB: any;
  lineOrHeaderOrPrincipal: any;
  modifierPriority: any;

  paths: String;

  tmpMedicalPolicyCode: any;
  medicalDesc: any;
  medicalCheck: any;
  temporaryMedicalDesc: any;
  temporaryMedicalCheck: any;
  temporaryMedicalPolicy: any;

  subpolicyDesc: any;
  subpolicyCheck: any;
  temporarysubpolicyCheck: any;
  temporarysubpolicyDesc: any;
  temporarysubpolicy: any;
  tmpSubPolicyCode: any;

  enfoCatCheck: any;
  enfoCatDesc: any;
  temporaryEnfoCatCheck: any;
  temporaryEnfocatDesc: any;
  tmpEnforceBeforeCatCode: any;

  tmpProcedureMinAgeCode: any;
  minAgeIdCheck: any;
  minAgeDesc: any;
  temporaryMinAgeIdCheck: any;
  temporaryMinAgeDesc: any;

  tmpProcedureMaxAgeCode: any;
  maxAgeIdCheck: any;
  maxAgeDesc: any;
  temporaryMaxAgeIdCheck: any;
  tempoararyMaxAgeDesc: any;
  newPolicyTabs: any;
  actionData: any;
  userDetails: any;

  billTypeLink: any;
  billTypeAction: any;
  conditionCodeAction: { label: string; value: number };
  claimTypeLink: any;

  units: any;
  frequency: any;
  duration: any;
  ranking: any;
  payment: any;
  durationDropdown: { label: string; value: string };

  errors: {
    policy: boolean;
    npi: boolean;
    posLink: boolean;
    revenueCodeClaimLink: boolean;
    cptLink: boolean;
    version: boolean;
    reason: boolean;
    cat: boolean;
    policyId: boolean;
    medicalPolicy: boolean;
    subPolicy: boolean;
    reference: boolean;
    cliamType: boolean;
    prod: boolean;
    deactivated: boolean;
    ncciB: boolean;
    asGroupB: boolean;
    referenceClaimType: boolean;
    tc26ModB: boolean;
    group59B: boolean;
    disabled: boolean;
    roldDescription: boolean;
    lob: boolean;
    productType: boolean;
    notes: boolean;
    policySummary: boolean;
    policyExplantion: boolean;
    referenceSrcDesc: boolean;
    referenceSrcTitleDesc: boolean;
    sourceIndicator: boolean;
    createdDate: boolean;
    gender: boolean;
    procedureMinAge: boolean;
    taxIdLogic: boolean;
    taxonomyLogicFk: boolean;
    enforceBefCategory: boolean;
    priority: boolean;
    procedureMaxAge: boolean;
    jiraId: boolean;
    jiraDescription: boolean;
    jiraLink: boolean;
    allowCms: boolean;
    tmpUser: boolean;
    catDesc: boolean;
    cciKey: boolean;
    bwTypeKey: boolean;
    maxUnitsType: boolean;
    modIntractionType: boolean;
    byPassMod: boolean;
    denyType: boolean;
    mutuallyExclusive: boolean;
    enfoCatDesc: boolean;
    billedWith: boolean;
    lineOrHeaderOrPrincipal: boolean;
    claimTypeLink: boolean;
    changeModifierKey: boolean;
    modifierPayPercentage: boolean;
    modifierPriority;
    units: boolean;
    frequency: boolean;
    duration: boolean;
    ranking: boolean;
    payment: boolean;
    durationDropdown: boolean;
  };
}
const initialState: NewPolicyFormState = {
  policy: '',
  npi: undefined,
  version: '',
  reason: '',
  cat: '',
  policyId: undefined,
  custom:false,
  clonedPolicyId:'',
  clonedPolVer:'',
  medicalPolicy: undefined,
  subPolicy: undefined,
  reference: '',
  cliamType: [],
  prod: false,
  deactivated: false,
  disabled: false,
  roldDescription: '',
  lob: undefined,
  productType: [],
  notes: '',
  policySummary: '',
  policyExplantion: '',
  referenceSrcDesc: '',
  referenceSrcTitleDesc: '',
  sourceIndicator: '',
  createdDate: undefined,
  gender: undefined,
  procedureMinAge: undefined,
  taxIdLogic: undefined,
  taxonomyLogicFk: undefined,
  enforceBefCategory: undefined,
  priority: undefined,
  procedureMaxAge: undefined,
  isJira: '',
  isJiraDesc: '',
  jiraId: '',
  jiraDescription: '',
  jiraLink: '',
  userId: '',
  reasonDesc: '',
  catDesc: '',
  temporaryCatDesc: '',
  tmpCatCode: '',
  temporaryReasonDesc: '',
  // accountUser:'',
  policyChangesKey: undefined,
  changesTableData: [],
  jiraIsOpen: undefined,
  changesisopenb: '',
  tmpUser: undefined,
  catCheck: [],
  reasonCheck: [],
  tmpCatCheck: [],
  tmpReasonCheck: [],
  tmpReasonCode: '',
  allowCms: false,
  medicalDesc: '',
  medicalCheck: [],
  temporaryMedicalDesc: '',
  temporaryMedicalCheck: [],
  temporaryMedicalPolicy: '',
  subpolicyDesc: '',
  subpolicyCheck: [],
  temporarysubpolicyCheck: [],
  temporarysubpolicyDesc: '',
  temporarysubpolicy: '',
  enfoCatCheck: [],
  enfoCatDesc: '',
  temporaryEnfoCatCheck: [],
  temporaryEnfocatDesc: '',
  tmpEnforceBeforeCatCode: '',
  minAgeIdCheck: [],
  minAgeDesc: '',
  temporaryMinAgeIdCheck: [],
  temporaryMinAgeDesc: '',
  tmpProcedureMinAgeCode: '',
  maxAgeIdCheck: [],
  maxAgeDesc: '',
  temporaryMaxAgeIdCheck: [],
  tempoararyMaxAgeDesc: '',
  tmpProcedureMaxAgeCode: '',
  byPassMod: '',
  denyType: '',
  mutuallyExclusive: '',
  ccikey: undefined,
  bwTypeKey: undefined,
  maxUnitsType: undefined,
  modIntractionType: undefined,
  billedWith: undefined,
  newPolicyTabs: '',
  billTypeLink: '',
  billTypeAction: '',
  conditionCodeAction: undefined,
  ncciB: false,
  asGroupB: false,
  referenceClaimType:false,
  tc26ModB: false,
  group59B: false,
  lineOrHeaderOrPrincipal: '',
  paths: '',
  actionData: [],
  userDetails: [],
  posLink: undefined,
  revenueCodeClaimLink: undefined,
  cptLink: undefined,
  claimTypeLink: undefined,
  changeModifierKey: undefined,
  modifierPayPercentage: undefined,
  modifierPriority: undefined,
  units: '',
  frequency: '',
  duration: '',
  ranking: '',
  payment: '',
  durationDropdown: undefined,
  errors: {
    policy: false,
    npi: false,
    version: false,
    reason: false,
    cat: false,
    policyId: false,
    medicalPolicy: false,
    subPolicy: false,
    reference: false,
    cliamType: false,
    prod: false,
    deactivated: false,
    ncciB: false,
    asGroupB: false,
    referenceClaimType:false,
    tc26ModB: false,
    group59B: false,
    disabled: false,
    roldDescription: false,
    lob: false,
    productType: false,
    notes: false,
    policySummary: false,
    policyExplantion: false,
    referenceSrcDesc: false,
    referenceSrcTitleDesc: false,
    sourceIndicator: false,
    createdDate: false,
    gender: false,
    procedureMinAge: false,
    taxIdLogic: false,
    taxonomyLogicFk: false,
    enforceBefCategory: false,
    priority: false,
    procedureMaxAge: false,
    jiraId: false,
    jiraDescription: false,
    jiraLink: false,
    tmpUser: false,
    catDesc: false,
    allowCms: false,
    cciKey: false,
    bwTypeKey: false,
    maxUnitsType: false,
    modIntractionType: false,
    byPassMod: false,
    denyType: false,
    mutuallyExclusive: false,
    enfoCatDesc: false,
    billedWith: false,
    lineOrHeaderOrPrincipal: false,
    posLink: false,
    revenueCodeClaimLink: false,
    cptLink: false,
    claimTypeLink: false,
    changeModifierKey: false,
    modifierPayPercentage: false,
    modifierPriority: false,
    units: false,
    frequency: false,
    duration: false,
    ranking: false,
    payment: false,
    durationDropdown: false,
  },
  tmpMedicalPolicyCode: undefined,
  tmpSubPolicyCode: undefined,
};

export default function NewPolicyFormReducer(
  state = initialState,
  action: { type: string; payload: any }
): NewPolicyFormState {
  switch (action.type) {
    case POLICY:
      return {
        ...state,
        policy: action.payload,
        errors: { ...state.errors, policy: false },
      };
    case VERSION:
      return {
        ...state,
        version: action.payload,
        errors: { ...state.errors, version: false },
      };
    case REASON:
      return {
        ...state,
        reason: action.payload,
        errors: { ...state.errors, reason: false },
      };
    case CAT:
      return {
        ...state,
        cat: action.payload,
        errors: { ...state.errors, cat: false },
      };
    case TEMP_CAT_DESC:
      return {
        ...state,
        temporaryCatDesc: action.payload,
      };
    case TEMP_REASON_DESC:
      return {
        ...state,
        temporaryReasonDesc: action.payload,
      };
    case MEDICAL_POLICY:
      return {
        ...state,
        medicalPolicy: action.payload,
        errors: { ...state.errors, medicalPolicy: false },
      };
    case MEDICAL_DESC:
      return {
        ...state,
        medicalDesc: action.payload,
      };
    case MEDICAL_CHECK:
      return {
        ...state,
        medicalCheck: action.payload,
      };
    case NEW_POLICY_TABS:
      return { ...state, newPolicyTabs: action.payload };

    case TEMP_MEDICAL_CHECK:
      return {
        ...state,
        temporaryMedicalCheck: action.payload,
      };
    case TEMP_MEDICAL_DESC:
      return {
        ...state,
        temporaryMedicalDesc: action.payload,
      };
    case TEMP_MEDICAL_POLICY_CODE:
      return {
        ...state,
        tmpMedicalPolicyCode: action.payload,
      };

    case SUB_POLICY:
      return {
        ...state,
        subPolicy: action.payload,
        errors: { ...state.errors, subPolicy: false },
      };
    case PATHS:
      return {
        ...state,
        paths: action.payload,
      };
    case SUBPOLICY_DESC:
      return {
        ...state,
        subpolicyDesc: action.payload,
      };
    case SUBPOLICY_CHECK:
      return {
        ...state,
        subpolicyCheck: action.payload,
      };
    case TEMP_SUBPOLICY_CHECK:
      return {
        ...state,
        temporarysubpolicyCheck: action.payload,
      };
    case TEMP_SUBPOLICY_DESC:
      return {
        ...state,
        temporarysubpolicyDesc: action.payload,
      };
    case TEMP_SUBPOLICY_CODE:
      return {
        ...state,
        tmpSubPolicyCode: action.payload,
      };
    case REFERENCE:
      return {
        ...state,
        reference: action.payload,
        errors: { ...state.errors, reference: false },
      };
    case CLAIM_TYPE:
      return {
        ...state,
        cliamType: action.payload,
        errors: { ...state.errors, cliamType: false },
      };
    case PROD:
      return {
        ...state,
        prod: action.payload,
        errors: { ...state.errors, prod: false },
      };
    case DEACTIVATED:
      return { ...state, deactivated: action.payload };

    case ALLOW_CMS_NCCI_MODIFIER:
      return { ...state, ncciB: action.payload };

    case AS_GROUP_B:
      return { ...state, asGroupB: action.payload };

      case REFERENCE_CLAIM_TYPE:
        return {...state,referenceClaimType: action.payload};

    case TC_26_MOD_B:
      return { ...state, tc26ModB: action.payload };

    case IGNORE_MODIFIER_59_GROUPS:
      return { ...state, group59B: action.payload };

    case DISABLED:
      return { ...state, disabled: action.payload };
    case ROLE_DESCRIPTION:
      return {
        ...state,
        roldDescription: action.payload,
        errors: { ...state.errors, roldDescription: false },
      };
    case LOB:
      return {
        ...state,
        lob: action.payload,
        errors: { ...state.errors, lob: false },
      };
    case PRODUCT_TYPE:
      return {
        ...state,
        productType: action.payload,
        errors: { ...state.errors, productType: false },
      };
    case NOTES:
      return {
        ...state,
        notes: action.payload,
        errors: { ...state.errors, notes: false },
      };
    case POLICY_SUMMARY:
      return {
        ...state,
        policySummary: action.payload,
        errors: { ...state.errors, policySummary: false },
      };
    case POLICY_EXPLANATION:
      return {
        ...state,
        policyExplantion: action.payload,
        errors: { ...state.errors, policyExplantion: false },
      };
    case REFERENCE_SRC_DESC:
      return {
        ...state,
        referenceSrcDesc: action.payload,
        errors: { ...state.errors, referenceSrcDesc: false },
      };
    case REFERNCE_SRC_TITLE_DESC:
      return {
        ...state,
        referenceSrcTitleDesc: action.payload,
        errors: { ...state.errors, referenceSrcTitleDesc: false },
      };
    case SOURCE_INDICATOR:
      return {
        ...state,
        sourceIndicator: action.payload,
        errors: { ...state.errors, sourceIndicator: false },
      };
    case CREATEDDATE:
      return {
        ...state,
        createdDate: action.payload,
        errors: { ...state.errors, createdDate: false },
      };
    case GENDER:
      return {
        ...state,
        gender: action.payload,
        errors: { ...state.errors, gender: false },
      };
    case PROCEDURE_MIN_AGE:
      return {
        ...state,
        procedureMinAge: action.payload,
        errors: { ...state.errors, procedureMinAge: false },
      };
    case MIN_AGE_ID_CHECK:
      return {
        ...state,
        minAgeIdCheck: action.payload,
      };
    case MIN_AGE_DESC:
      return {
        ...state,
        minAgeDesc: action.payload,
      };
    case TEMP_MIN_AGE_ID_CHECK:
      return {
        ...state,
        temporaryMinAgeIdCheck: action.payload,
      };
    case TEMP_MIN_AGE_DESC:
      return {
        ...state,
        temporaryMinAgeDesc: action.payload,
      };
    case TEMP_PROCEDURE_MIN_AGE_CODE:
      return {
        ...state,
        tmpProcedureMinAgeCode: action.payload,
      };

    case TAX_ID_LOGIC:
      return {
        ...state,
        taxIdLogic: action.payload,
        errors: { ...state.errors, taxIdLogic: false },
      };
    case TAXONOMY_LOGIC:
      return {
        ...state,
        taxonomyLogicFk: action.payload,
        errors: { ...state.errors, taxonomyLogicFk: false },
      };
    case ENFORCE_BEFORE_CAT:
      return {
        ...state,
        enforceBefCategory: action.payload,
        // errors: { ...state.errors, enforceBefCategory: false },
      };
    case TEMP_ENFORCE_BEFORE_CAT_CODE:
      return {
        ...state,
        tmpEnforceBeforeCatCode: action.payload,
      };
    case ENFO_CAT_CHECK:
      return {
        ...state,
        enfoCatCheck: action.payload,
      };
    case ENFO_CAT_DESC:
      return {
        ...state,
        enfoCatDesc: action.payload,
        errors: { ...state.errors, enfoCatDesc: false },
      };
    case TEMP_ENFO_CAT_CHECK:
      return {
        ...state,
        temporaryEnfoCatCheck: action.payload,
      };
    case TEMP_ENFO_CAT_DESC:
      return {
        ...state,
        temporaryEnfocatDesc: action.payload,
      };
    case PRIORITY:
      return {
        ...state,
        priority: action.payload,
        errors: { ...state.errors, priority: false },
      };
    case PROCEDURE_MAX_AGE:
      return {
        ...state,
        procedureMaxAge: action.payload,
        errors: { ...state.errors, procedureMaxAge: false },
      };
    case MAX_AGE_ID_CHECK:
      return {
        ...state,
        maxAgeIdCheck: action.payload,
      };
    case MAX__AGE_DESC:
      return {
        ...state,
        maxAgeDesc: action.payload,
      };
    case TEMP_MAX__AGE_ID_CHECK:
      return {
        ...state,
        temporaryMaxAgeIdCheck: action.payload,
      };
    case TEMP_MAX_AGE_DESC:
      return {
        ...state,
        tempoararyMaxAgeDesc: action.payload,
      };
    case TEMP_PROCEDURE_MAX_AGE_CODE:
      return {
        ...state,
        tmpProcedureMaxAgeCode: action.payload,
      };
    case NPI:
      return {
        ...state,
        npi: action.payload,
        errors: { ...state.errors, npi: false },
      };
    case POS_LINK:
      return {
        ...state,
        posLink: action.payload,
        errors: { ...state.errors, posLink: false },
      };
    case REVENUE_CODE_CLAIM_LINK:
      return {
        ...state,
        revenueCodeClaimLink: action.payload,
        errors: { ...state.errors, revenueCodeClaimLink: false },
      };
    case CPT_LINK:
      return {
        ...state,
        cptLink: action.payload,
        errors: { ...state.errors, cptLink: false },
      };
    case JIRA_DESCRIPTION:
      return {
        ...state,
        jiraDescription: action.payload,
        errors: { ...state.errors, jiraDescription: false },
      };
    case JIRA_ID:
      return {
        ...state,
        jiraId: action.payload,
        errors: { ...state.errors, jiraId: false },
      };
    case JIRA_LINK:
      return {
        ...state,
        jiraLink: action.payload,
        errors: { ...state.errors, jiraLink: false },
      };
    case JIRA_ISOPEN:
      return { ...state, jiraIsOpen: action.payload };
    case USER_ID:
      return {
        ...state,
        userId: action.payload,
        tmpUser: { label: action.payload, value: action.payload },
        // errors: { ...state.errors, tmpUser: false },
      };
    case TEMP_USER:
      return {
        ...state,
        tmpUser: action.payload,
        errors: { ...state.errors, tmpUser: false },
      };
    case POLICY_ID:
      return {
        ...state,
        policyId: action.payload,
      };
    case CUSTOM:
      return {
        ...state,
        custom : action.payload,
      }
      case CLONED_POLICY_ID:
        return {
          ...state,
          clonedPolicyId : action.payload,
        }
      case CLONED_POL_VER:
        return {
          ...state,
          clonedPolVer:action.payload,
        }
    case CHANGES_ISOPEN_B:
      return {
        ...state,
        changesisopenb: action.payload,
      };
    case REASON_DESC:
      return {
        ...state,
        reasonDesc: action.payload,
      };
    case CAT_CHECK:
      return {
        ...state,
        catCheck: action.payload,
      };
    case TEMP_CAT_CODE:
      return {
        ...state,
        tmpCatCode: action.payload,
      };
    case TEMP_CAT_CHECK:
      return {
        ...state,
        tmpCatCheck: action.payload,
      };
    case TEMP_REASON_CHECK:
      return {
        ...state,
        tmpReasonCheck: action.payload,
      };
    case REASON_CHECK:
      return {
        ...state,
        reasonCheck: action.payload,
      };
    case CAT_DESC:
      return {
        ...state,
        catDesc: action.payload,
        errors: { ...state.errors, catDesc: false },
      };
    case TEMP_REASON_CODE:
      return {
        ...state,
        tmpReasonCode: action.payload,
      };
    case CCI_KEY:
      return {
        ...state,
        ccikey: action.payload,
        errors: { ...state.errors, cciKey: false },
      };
    case BW_TYPE_KEY:
      return {
        ...state,
        bwTypeKey: action.payload,
        errors: { ...state.errors, bwTypeKey: false },
      };
    case CHANGE_MODIFIER_KEY:
      return {
        ...state,
        changeModifierKey: action.payload,
        errors: { ...state.errors, changeModifierKey: false },
      };
    case MODIFIER_PAY_PERCENTAGE:
      return {
        ...state,
        modifierPayPercentage: action.payload,
        errors: { ...state.errors, modifierPayPercentage: false },
      };
    case MODIFIER_PRIORITY_LKP:
      return {
        ...state,
        modifierPriority: action.payload,
        errors: { ...state.errors, modifierPriority: false },
      };
    case MAX_UNITS_TYPE:
      return {
        ...state,
        maxUnitsType: action.payload,
        errors: { ...state.errors, maxUnitsType: false },
      };
    case MOD_INTRACTION_TYPE:
      return {
        ...state,
        modIntractionType: action.payload,
        errors: { ...state.errors, modIntractionType: false },
      };

    case BY_PASS_MOD:
      return {
        ...state,
        byPassMod: action.payload,
      };
    case MUTUALLY_EXCLUSIVE:
      return {
        ...state,
        mutuallyExclusive: action.payload,
      };
    case BILL_TYPE_LINK:
      return {
        ...state,
        billTypeLink: action.payload,
      };
    case BILL_TYPE_ACTION:
      return {
        ...state,
        billTypeAction: action.payload,
      };
    case CONDITION_CODE_ACTION:
      return {
        ...state,
        conditionCodeAction: action.payload,
      };
    case DENY:
      return {
        ...state,
        denyType: action.payload,
      };
    case BILLED_WITH:
      return {
        ...state,
        billedWith: action.payload,
        errors: { ...state.errors, billedWith: false },
      };
    case LINE_OR_HEADER_OR_PRINC:
      return {
        ...state,
        lineOrHeaderOrPrincipal: action.payload,
        errors: { ...state.errors, lineOrHeaderOrPrincipal: false },
      };
    case USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case GET_ACTION_DATA:
      return {
        ...state,
        actionData: action.payload,
      };

    case UNITS:
      return {
        ...state,
        units: action.payload,
        errors: { ...state.errors, units: false },
      };
    case FREQUENCY:
      return {
        ...state,
        frequency: action.payload,
      };
    case DURATION:
      return {
        ...state,
        duration: action.payload,
        errors: { ...state.errors, duration: false },
      };

    case DURATION_DROPDOWN:
      return {
        ...state,
        durationDropdown: action.payload,
      };
    case RANKING:
      return {
        ...state,
        ranking: action.payload,
      };
    case PAYMENT:
      return {
        ...state,
        payment: action.payload,
      };

    case RESET_LOGIN_DETAILS:
      //@ts-ignore
      return {
        ...state,
        userDetails: [],
      };
    case CLAIM_TYPE_LINK:
      return {
        ...state,
        claimTypeLink: action.payload,
        errors: { ...state.errors, claimTypeLink: false },
      };

    case EDIT_POLICY:
      return {
        ...state,
        tmpUser: { label: action.payload.userId, value: action.payload.userId },
        policy: action.payload.policy,
        version: action.payload.version,
        policyId: action.payload.policyId,
        custom:action.payload.custom,
        clonedPolicyId:action.payload.clonedPolicyId,
        clonedPolVer:action.payload.clonedPolVer,
        reason: action.payload.reason,
        reference: action.payload.reference,
        cat: action.payload.cat,
        cliamType: action.payload.claimType,
        prod: action.payload.prod,
        lob: action.payload.lob,
        billedWith: action.payload.billedWith,
        ccikey: action.payload.ccikey,
        bwTypeKey: action.payload.bwTypeKey,
        changeModifierKey: action.payload.changeModifierKey,
        maxUnitsType: action.payload.maxUnitsType,
        modIntractionType: action.payload.modIntractionType,
        denyType: action.payload.denyType,
        mutuallyExclusive: action.payload.mutuallyExclusive,
        byPassMod: action.payload.byPassMod,
        lineOrHeaderOrPrincipal: action.payload.selectedType,
        productType: action.payload.productType,
        gender: action.payload.gender,
        procedureMaxAge: action.payload.procedureMaxAge,
        procedureMinAge: action.payload.procedureMinAge,
        npi: action.payload.npi,
        taxonomyLogicFk: action.payload.taxonomyLogicFk,
        enforceBefCategory: action.payload.enforceBefCategory,
        taxIdLogic: action.payload.taxIdLogic,
        deactivated: action.payload.deactivated,
        ncciB: action.payload.ncciB,
        asGroupB: action.payload.asGroupB,
        referenceClaimType: action.payload.referenceClaimType,
        tc26ModB: action.payload.tc26ModB,
        group59B: action.payload.group59B,
        disabled: action.payload.disabled,
        roldDescription: action.payload.roldDescription,
        notes: action.payload.notes,
        policySummary: action.payload.policySummary,
        policyExplantion: action.payload.policyExplantion,
        referenceSrcDesc: action.payload.referenceSrcDesc,
        referenceSrcTitleDesc: action.payload.referenceSrcTitleDesc,
        sourceIndicator: action.payload.sourceIndicator,
        createdDate: action.payload.createdDate,
        priority: action.payload.priority,
        medicalPolicy: action.payload.medicalPolicy,
        subPolicy: action.payload.subPolicy,
        billTypeLink: action.payload.billTypeLink,
        billTypeAction: action.payload.billTypeAction,
        posLink: action.payload.posLink,
        revenueCodeClaimLink: action.payload.revenueCodeClaimLink,
        conditionCodeAction: action.payload.conditionCodeAction,
        claimTypeLink: action.payload.claimTypeLinkFk,
        cptLink: action.payload.cptLink,

        units: action.payload.units,
        frequency: action.payload.frequency,
        duration: action.payload.duration,
        ranking: action.payload.ranking,
        payment: action.payload.payment,
        durationDropdown: action.payload.durationDropdown,
      };
    case EDIT_CHANGES:
      return {
        ...state,
        isJira: action.payload.isJira,
        isJiraDesc: action.payload.isJiraDesc,
        jiraId: action.payload.jiraId,
        jiraDescription: action.payload.jiraDesc,
        jiraLink: action.payload.jiraLink,
        jiraIsOpen: action.payload.jiraIsOpen,
        userId: action.payload.userId,
        tmpUser: { label: action.payload.userId, value: action.payload.userId },
        policyChangesKey: action.payload.policyChangesKey,
      };
    case NEW_POLICY_VALIDATION:
      return { ...state, errors: action.payload };
    case GET_POLICY_NUMBER:
      return { ...state, errors: action.payload };
    case EDIT_POLICY_TABLE_CHANGES:
      return { ...state, changesTableData: action.payload };
    case RESET_STATE:
      //@ts-ignore
      return {
        ...state,
        policy: '',
        npi: null,
        version: '',
        reason: null,
        cat: '',
        policyId: null,
        custom: undefined,
        medicalPolicy: null,
        subPolicy: null,
        reference: '',
        cliamType: [],
        prod: undefined,
        deactivated: undefined,
        disabled: undefined,
        roldDescription: '',
        lob: null,
        productType: [],
        notes: '',
        policySummary: '',
        policyExplantion: '',
        referenceSrcDesc: '',
        referenceSrcTitleDesc: '',
        sourceIndicator: '',
        createdDate: null,
        gender: null,
        procedureMinAge: null,
        taxIdLogic: null,
        taxonomyLogicFk: null,
        enforceBefCategory: null,
        priority: '',

        procedureMaxAge: null,
        jiraId: '',
        jiraDescription: '',
        jiraLink: '',
        userId: '',
        policyChangesKey: null,
        jiraIsOpen: undefined,
        errors: initialState.errors,
        reasonDesc: '',
        catDesc: '',
        catCheck: [],
        reasonCheck: [],
        tmpCatCheck: [],
        tmpReasonCheck: [],
        temporaryCatDesc: '',
        temporaryReasonDesc: '',
        clonedPolicyId:null,
        clonedPolVer:null,
        tmpCatCode: '',
        tmpReasonCode: '',
        medicalDesc: '',
        medicalCheck: [],
        subpolicyCheck: [],
        subpolicyDesc: '',
        temporaryMedicalCheck: [],
        temporaryMedicalDesc: '',
        temporarysubpolicyCheck: [],
        temporarysubpolicyDesc: '',
        tmpMedicalPolicyCode: '',
        tmpSubPolicyCode: '',
        enfoCatCheck: [],
        enfoCatDesc: '',
        tmpEnforceBeforeCatCode: '',
        temporaryEnfoCatCheck: [],
        temporaryEnfocatDesc: '',
        minAgeIdCheck: [],
        minAgeDesc: '',
        tmpProcedureMinAgeCode: '',
        temporaryMinAgeIdCheck: [],
        temporaryMinAgeDesc: '',
        maxAgeIdCheck: [],
        maxAgeDesc: '',
        tempoararyMaxAgeDesc: '',
        temporaryMaxAgeIdCheck: [],
        tmpProcedureMaxAgeCode: '',
        ncciB: undefined,
        asGroupB: undefined,
        referenceClaimType: undefined,
        tc26ModB: undefined,
        group59B: undefined,
        changeModifierKey: undefined,
        mutuallyExclusive: '',
        denyType: '',
        byPassMod: '',
        ccikey: undefined,
        bwTypeKey: undefined,
        maxUnitsType: undefined,
        modIntractionType: undefined,
        billedWith: undefined,
        lineOrHeaderOrPrincipal: '',
        posLink: undefined,
        revenueCodeClaimLink: undefined,
        cptLink: undefined,
        newPolicyTabs: '',
        billTypeAction: undefined,
        billTypeLink: undefined,
        conditionCodeAction: undefined,
        // actionData:undefined,
        changesTableData: [],
        changesisopenb: '',
        claimTypeLink: undefined,
        units: '',
        frequency: '',
        duration: '',
        durationDropdown: undefined,
        payment: '',
        ranking: '',
      };
    case ERROR_RESET_STATE:
      return {
        ...state,
        errors: initialState.errors,
      };
    case FREQUENCY_RESET:
      //@ts-ignore
      return {
        ...state,
        duration: '',
        frequency: '',
        durationDropdown: undefined,
      };
    case DIALOG_CAT_RESET_STATE:
      //@ts-ignore
      return {
        ...state,
        cat: '',
        catDesc: '',
        catCheck: [],
        tmpCatCheck: [],
        tmpCatCode: '',
        temporaryCatDesc: '',
      };
    case DIALOG_REASON_RESET_STATE:
      //@ts-ignore
      return {
        ...state,
        reason: null,
        reasonDesc: '',
        reasonCheck: [],
        tmpReasonCheck: [],
        temporaryReasonDesc: '',
        tmpReasonCode: '',
      };
    case DIALOG_MEDICAL_RESET_STATE:
      return {
        ...state,
        medicalDesc: '',
        medicalCheck: [],
        temporaryMedicalCheck: [],
        temporaryMedicalDesc: '',
        tmpMedicalPolicyCode: '',
        medicalPolicy: '',
      };

    case DIALOG_SUB_POLICY_RESET_STATE:
      return {
        ...state,
        subpolicyDesc: '',
        subpolicyCheck: [],
        temporarysubpolicyCheck: [],
        temporarysubpolicyDesc: '',
        tmpSubPolicyCode: '',
        subPolicy: '',
      };
    case DIALOG_ENFO_CAT_RESET_STATE:
      return {
        ...state,
        enfoCatDesc: '',
        enfoCatCheck: [],
        enforceBefCategory: null,
        temporaryEnfoCatCheck: [],
        temporaryEnfocatDesc: '',
        tmpEnforceBeforeCatCode: '',
      };
    case DIALOG_MIN_AGE_RESET_STATE:
      return {
        ...state,
        minAgeIdCheck: [],
        minAgeDesc: '',
        temporaryMinAgeIdCheck: [],
        temporaryMinAgeDesc: '',
        tmpProcedureMinAgeCode: '',
        procedureMinAge: '',
      };
    case DIALOG_MAX_AGE_RESET_STATE:
      return {
        ...state,
        maxAgeIdCheck: [],
        maxAgeDesc: '',
        temporaryMaxAgeIdCheck: [],
        tempoararyMaxAgeDesc: '',
        tmpProcedureMaxAgeCode: '',
        procedureMaxAge: '',
      };
    default:
      return state;
  }
}

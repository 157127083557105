import { disabledColor, navyColor } from '../../assets/jss/material-kit-react';
import swal from 'sweetalert2';
import { MetaLoaderConstants } from './MetaLoaderConst';

export function handleUploadFile(file) {
  if (file?.name.includes('NCCI') || file?.name.includes('cci')) {
    var allowedFiles = ['.zip'];
  } else {
    var allowedFiles = ['.xlsx', '.csv', '.zip', '.txt', '.pdf'];
  }
  var regex = new RegExp(
    '([a-zA-Z0-9s_\\.-:()])+(' + allowedFiles.join('|') + ')$'
  );
  if (!regex.test(file.name.toLowerCase())) {
    swal.fire({
      icon: 'error',
      text: 'Please upload valid file',
      confirmButtonColor: navyColor,
      confirmButtonText: 'OK',
    });
    return false;
  } else {
    return true;
  }
  // }
}

export const checkQauterOrYear = (
  selectedMetaDataLoader,
  selectedMaxLkpValue
) => {
  let showQuaterOrYear;
  if (selectedMetaDataLoader == 'Modifier Interaction') {
    showQuaterOrYear = 'Modifier Interaction Type';
  } else if (selectedMetaDataLoader == 'BW Pairs') {
    showQuaterOrYear = 'BW Key';
  } else if (
    selectedMetaDataLoader == MetaLoaderConstants.ICD_10_CM_DRGN ||
    selectedMetaDataLoader == MetaLoaderConstants.ICD_10_PCS ||
    selectedMetaDataLoader == MetaLoaderConstants.ZIP_5 ||
    selectedMetaDataLoader == MetaLoaderConstants.ZIP_9 ||
    selectedMetaDataLoader == MetaLoaderConstants.ZIP_5_DATE_BINDED ||
    selectedMetaDataLoader == MetaLoaderConstants.ZIP_9_DATE_BINDED
  ) {
    showQuaterOrYear =  ' Year';
  } else if (
    selectedMetaDataLoader == MetaLoaderConstants.RBRVS ||
    selectedMetaDataLoader == MetaLoaderConstants.SAME_OR_SIMILAR ||
    selectedMetaDataLoader == MetaLoaderConstants.ADHOC_CPT_HCPCS
  ) {
    showQuaterOrYear = '';
  } else if (
    selectedMetaDataLoader == MetaLoaderConstants.MAX_UNITS ||
    selectedMetaDataLoader == MetaLoaderConstants.CCI
  ) {
    showQuaterOrYear =   ' Quarter';
  } else {
    showQuaterOrYear =  ' Quarter';
  }
  return showQuaterOrYear;
};

export const checkDisable = (
  selectedMetaDataLoader,
  selectedFile,
  selectedQuarter,
  selectedBwKey
) => {
  let disable;
  if (
    selectedMetaDataLoader &&
    (selectedQuarter || selectedBwKey) &&
    selectedFile
  ) {
    disable = navyColor;
  } else if (
    (selectedMetaDataLoader == MetaLoaderConstants.ADHOC_CPT_HCPCS ||
      selectedMetaDataLoader == MetaLoaderConstants.RBRVS ||
      selectedMetaDataLoader == MetaLoaderConstants.SAME_OR_SIMILAR) &&
    selectedFile
  ) {
    disable = navyColor;
  } else {
    disable = disabledColor;
  }

  return disable;
};

export const checkFileDisable = (
  selectedBwKey,
  selectedMetaDataLoader,
  selectedQuarter,
  selectedFile
) => {
  let boolean = false;
  if (
    selectedMetaDataLoader &&
    (selectedQuarter || selectedBwKey) &&
    selectedFile
  ) {
    boolean = false;
  } else if (
    (selectedMetaDataLoader == MetaLoaderConstants.ADHOC_CPT_HCPCS ||
      selectedMetaDataLoader == MetaLoaderConstants.RBRVS ||
      selectedMetaDataLoader == MetaLoaderConstants.SAME_OR_SIMILAR) &&
    selectedFile
  ) {
    boolean = false;
  } else {
    boolean = true;
  }
  return boolean;
};

export const checkRbrsvsOrSameOrSimOrAdhocCpt = (selectedMetaDataLoader) => {
  let flag = true;
  if (
    selectedMetaDataLoader == MetaLoaderConstants.RBRVS ||
    selectedMetaDataLoader == MetaLoaderConstants.ADHOC_CPT_HCPCS ||
    selectedMetaDataLoader == MetaLoaderConstants.SAME_OR_SIMILAR
  ) {
    flag = false;
  } else {
    flag = true;
  }
  return flag;
};

export function alphaOrder(a, b) {
  const name1 = a.Name.toUpperCase();
  const name2 = b.Name.toUpperCase();

  let comparison = 0;

  if (name1 > name2) {
    comparison = 1;
  } else if (name1 < name2) {
    comparison = -1;
  }
  return comparison;
}

const currentYear = new Date().getFullYear();
let nextOneYear = currentYear + 1;
let nextTwoYear = nextOneYear + 1;
let previousOneYear = currentYear - 1;
let previousTwoYear = previousOneYear - 1;
let previousThreeYear = previousTwoYear - 1;
let previousFourYear = previousThreeYear - 1;

export let Years = [
  { value: '2019', Name: '2019' },
  { value: previousFourYear, Name: previousFourYear },
  { value: previousThreeYear, Name: previousThreeYear },
  { value: previousTwoYear, Name: previousTwoYear },
  { value: previousOneYear, Name: previousOneYear },
  { value: currentYear, Name: currentYear },
  { value: nextOneYear, Name: nextOneYear },
  { value: nextTwoYear, Name: nextTwoYear },
];

var nextQuarter = String(currentYear).slice(-2);
let array = ['A', 'B', 'C', 'D'];

export let newQuarter = [];
let alpha = '';
let futureQuarter = Number(nextQuarter) + 1;

for (let i = 19; i <= futureQuarter; i++) {
  for (let j = 0; j < array.length; j++) {
    if (i == 18) {
      alpha = 'A';
      newQuarter.push({ value: i + '' + alpha, Name: i + '' + alpha });
      break;
    }
    alpha = array[j];
    newQuarter.push({ value: i + '' + alpha, Name: i + '' + alpha });
  }
}

import { clientCode } from "../Claims/ClaimHeaderNames";

export const newClientSetUpFields = {
    jiraId:undefined,
    jiraDescription:undefined,
    clientCode:undefined,
    clientGroupCode:[],
    policyClientCode:undefined,
    policyClientGroupCode:[],
    clientStartDate:undefined,
    policyIds:undefined,
    medicalPolicyKeys:[],
    policyClientGroupDetails:[],
    existPolicyId : []
}
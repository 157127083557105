export const GET_SPEC_LOOKUP= "getSpecLookUp"
export const GET_SUB_SPEC_LOOKUP= "getSubSpecLookUp"
export const GET_REVENUE_LOOKUP= "getRevenueLookUp"
export const GET_MOD_LOOKUP= "getModLookUp"
export const GET_POS_LOOKUP= "getPosLookUp"
export const GET_POLICY_CATEGORY_LOOKUP= "getPolicyCategoryLookUp"
export const GET_BILL_TYPE_LOOKUP= "getBillTypeLookUp"
export const GET_REASON_LOOKUP= "getReasonLookUp"
export const GET_CONDITION_LOOKUP= "getConditionLookUp"
export const GET_MIN_MAX_AGE_LOOKUP= "getMinMaxAgeLookUp"
export const GET_CCI_RATIONALE_LOOKUP= "getCciRationaleLookUp"
export const GET_CCI_LOOKUP= "getCciLookUp"
export const GET_MUE_RATIONALE_LOOKUP = "getMueRationaleLookUp"
export const GET_BO_LOOKUP = "getBoLookUp"
export const GET_BW_TYPE_LOOKUP = "getBwTypeLookUp"
export const GET_MAX_UNITS_TYPES = "getMaxUnitsTypes"
export const GET_MAX_UNITS_LKP = "getMaxUnitsLookUp"
export const GET_MODIFIER_PRIORITY_LKP="getModifierPriorityLookUp"
export const GET_MODIFIER_INTERACTION_LKP="getModifierInteractionLookUp"
export const GET_MODIFIER_INTERACTION_TYPE_LKP="getModifierInteractionTypeLookUp"



export const POST_SPEC = "postSpec"
export const POST_SUB_SPEC = "postSubSpec"
export const POST_MIN_VS_MAX = "postMinVsMax"
export const POST_REVENUE_CODE = "postRevenueCode"
export const POST_BILL_TYPE = "postBillType"
export const START_DATE = "startDate"
export const POST_CONDITION_CODE = "postConditionCode"
export const POST_MOD_LKP = "postModLkp"
export const EDIT_MOD_LKP = "editModLkp"
export const POST_POS_LKP = "postPosLkp"
export const POST_POLICY_CATEGORY_LKP = "postPolicyCategoryLkp"
export const POST_REASON_CODE_LKP = "postReasonCode"
export const POST_CCI_RATIONALE_LKP = "postCciRationale"













import { ButtonGroup, Grid, IconButton } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dangerColor, navyColor } from "../../assets/jss/material-kit-react";
import CustomButton from "../../components/CustomButtons/CustomButton";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import AgGrids from "../../components/TableGrid/AgGrids";
import Moment from "moment";

import { NewPolicyFormState } from "../../redux/reducers/NewPolicyFormReducer";
import { NewPolicyState } from "../../redux/reducers/NewPolicyReducer";
import { Add } from "@material-ui/icons";
import Dialogbox from "../../components/Dialog/DialogBox";
import { CONDITION_CODE_ACTION } from "../../redux/actions/NewPolicyFormActionTypes";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import React from "react";

import { LookUpState } from "../../redux/reducers/LookUpReducer";
import {
  getConditionTypeData,
  getPolicyConditionTypeData,
  postConditionTypeData,
  DeleteConditionTypeData,
} from "../../redux/actions/ConditionCode";
import {
  AllCommunityModules,
  ModuleRegistry,
} from "@ag-grid-community/all-modules";
import swal from "sweetalert2";
import { fetchLookupData } from "../../redux/actions/LookupsActions";
import { CONDITION_CODE_LKP } from "../LookUps/LookUpConsts";

const _ = require("lodash");

const ConditionCode = ({ fromViewPolicy, policyId, edit }) => {
  const dispatch = useDispatch();
  const fullWidth = true;
  const maxWidth = "md";
  const [deletedByKey, setDeletedByKey] = useState("");

  const [policyConditionData, setpolicyConditionData] = useState([]);
  const [updatePolicyConditionData, setUpdatePolicyConditionData] =
    useState(false);

  const [sourceConditionTypeLkpData, setsourceConditionTypeLkpData] = useState(
    []
  );
  const [openBillType, setopenBillType] = useState(false);
  const [popup, setPopUp] = useState(false);
  const [postPopup, setpostPopup] = useState(false);
  const [billTypeKeyPopup, setbillTypeKeyPopup] = useState(false);

  const [numberOfRows, setNumberOfRows] = useState(0);

  const [selectedData, setSelectedData] = useState("");

  const [selectedPolicyAction, setSelectedPolicyAction] = useState(undefined);
  const [selectedActionValue, setselectedActionValue] = useState("");
  const [unMatchedBillType, setUnMatchedBillType] = useState([]);
  const [open, setOpen] = React.useState(false);

  const formState: NewPolicyFormState = useSelector(
    (state: any) => state.newPolicyForm
  );

  const newpolicyState: NewPolicyState = useSelector(
    (state: any) => state.newPolicy
  );

  const updatedState: LookUpState = useSelector(
    (state: any) => state.lookupReducer
  );

  const openDeletePopUp = () => {
    setPopUp(true);
    setUpdatePolicyConditionData(false);
  };

  const openpostPopup = () => {
    setpostPopup(true);
    setUpdatePolicyConditionData(false);
  };

  const handletoclose = () => {
    setopenBillType(false);
    setPopUp(false);
    setpostPopup(false);
    setbillTypeKeyPopup(false);
    setOpen(false);
  };

  const closePopUp = () => {
    setUpdatePolicyConditionData(true);
    setPopUp(false);
    setOpen(false);
  };

  const openPopUp = () => {
    setopenBillType(true);
  };

  let id;

  const columnDefs = [
    {
      field: "condCode",
      headerName: "Condition Code",
      headerTooltip: "Condition Code",
    },
    {
      field: "condDesc",
      headerName: "Condition Code Desc",
      headerTooltip: "Condition Code Desc",
      cellRenderer: (cellValues) => {
        return (
          <span
            title={`${cellValues.data?.condDesc}`}
          >{`${cellValues.data?.condDesc}`}</span>
        );
      },
    },
    {
      headerName: "Action",
      minWidth: 130,
      filter: false,
      cellRenderer: (row) => {
        return (
          <ButtonGroup>
            <CustomButton
              disabled={fromViewPolicy}
              variant="contained"
              style={{
                height: 18,
                marginTop: 1,
                fontSize: "11px",
                textTransform: "capitalize",
                backgroundColor: "red",
                color: "white",
              }}
              onClick={() => {
                id = row.data.key;
                setDeletedByKey(row.data.key);
                openDeletePopUp();
              }}
            >
              Delete
            </CustomButton>
          </ButtonGroup>
        );
      },
    },
  ];
  const ConditionLKPColumns = [
    {
      field: "condCode",
      headerName: "Cond Code",
      minWidth: 109,
      headerTooltip: "Cond Code",
      checkboxSelection: true,
    },

    {
      field: "condDesc",
      headerName: "Cond Desc",
      minWidth: 83,
      headerTooltip: "Cond Desc",
      cellRenderer: (row) => {
        return (
          <span title={`${row.data.condDesc}`}>{`${row.data.condDesc}`}</span>
        );
      },
    },
  ];

  useEffect(() => {
    if (updatedState.condition.length == 0) {
      let lkpName = CONDITION_CODE_LKP;
      fetchLookupData(dispatch,lkpName);
    }
    getConditionTypeData(dispatch); 
    
    if (policyId != null) {
      getPolicyConditionTypeData(dispatch, policyId);
    }
  }, []);


  useEffect(() => {
    let policyConditionData = newpolicyState.policyConditiontypedata.map(
      (k) => {
        return {
          key: k.policyConditionCodeKey,
          condCode: k.conditionCode,
          condDesc: k.conditionCodeDesc,
        };
      }
    );

    setpolicyConditionData(policyConditionData);
    setNumberOfRows(
      newpolicyState.policyConditiontypedata
        ? newpolicyState.policyConditiontypedata.length
        : 0
    );
    setUpdatePolicyConditionData(true);
  }, [newpolicyState.policyConditiontypedata]);

  useEffect(() => {
    let conditionCodeLkpData = updatedState.condition?.map((bd, i) => {
      return {
        condId: bd.condId,
        condCode: bd.condCode,
        condDesc: bd.condDesc,
        startDate: Moment(bd.startDate).format("MM-DD-YYYY"),
        endDate: Moment(bd.endDate).format("MM-DD-YYYY"),
      };
    });
    setsourceConditionTypeLkpData(conditionCodeLkpData);
  }, [updatedState.condition]);

  useEffect(() => {
    let obj = [];
    var d = require("lodash");
    obj = d.differenceWith(
      sourceConditionTypeLkpData,
      policyConditionData,
      function (s, d) {
        return s["condDesc"] === d["condDesc"];
      }
    );
    setUnMatchedBillType(obj);
  }, [sourceConditionTypeLkpData, policyConditionData]);

  const onFilterChanged = (params) => {
    setNumberOfRows(params.api.getDisplayedRowCount());
  };

  ModuleRegistry.registerModules(AllCommunityModules);

  const gridRef = useRef(null);

  const onGridReady = (params) => {
    let filteredData = [];

    const dataSource = {
      rowCount: null,
      getRows: async (params) => {
        try {
          let sortType = "";
          let sortableColumn = "";
          let ConditionType = {};
          if (!(params.filterModel == null || undefined)) {
            ConditionType = {
              condCode: params.filterModel.condCode
                ? params.filterModel.condCode.filter
                : "",
              condDesc: params.filterModel.condDesc
                ? params.filterModel.condDesc.filter
                : "",
              isSort: sortType != "" ? sortType : "",
              sortColumn: sortableColumn != "" ? sortableColumn : "",
            };
          }

          let StringData = [];
          if (filteredData.length == 0) {
            filteredData = policyConditionData;
          }
          if (
            ConditionType["condCode"].length > 0 ||
            ConditionType["condDesc"].length > 0
          ) {
            StringData = checkConditionTypeData(ConditionType);
            filteredData = StringData;
          } else {
            filteredData = policyConditionData;
          }
          if (filteredData.length == 0) {
            swal.fire({
              icon: "info",
              text: "No data found.",
              confirmButtonColor: navyColor,
              confirmButtonText: "OK",
            });
          }
          setNumberOfRows(filteredData.length);
          setTimeout(() => {
            const rowsThisPage = filteredData.slice(
              params.startRow,
              params.endRow
            );
            let lastRow = -1;
            if (filteredData.length <= params.endRow) {
              lastRow = filteredData.length;
            }
            params.successCallback(rowsThisPage, lastRow);
          }, 500);
        } catch (error) {
          params.failCallback();
        }
      },
    };
    params.api?.setDatasource(dataSource);
  };

  const checkConditionTypeData = (ConditionType) => {
    let ConditionTypeValues = ConditionType["condCode"]
      .split(/,(?=\S)|,$/)
      .filter(Boolean);
    let conditionTypeData = [];
    newpolicyState.policyConditiontypedata.filter((k, i) => {
      if (
        ConditionTypeValues.some((value) => k.conditionCode == value) &&
        k.conditionCodeDesc
          .toLowerCase()
          .includes(ConditionType["condDesc"].toLowerCase())
      ) {
        conditionTypeData.push({
          condCode: k.conditionCode,
          condDesc: k.conditionCodeDesc,
        });
      }
    });
    return conditionTypeData;
  };

  const ConditionTypeActionLkpData = newpolicyState.getConditionTypeData?.map(
    (bd) => {
      return {
        label: bd.conditionCode,
        value: bd.condCodeKey,
      };
    }
  );

  let tableData = [];
  const onSelectionChanged = async (event) => {
    let a = event.api.getSelectedRows();
    tableData.push(a);
    setSelectedData(a);
  };

  return (
    <>
      {fromViewPolicy || edit ? (
        <div style={{ marginTop: "-20px" }}>
          <IconButton
            onClick={openPopUp}
            style={{
              backgroundColor: navyColor,
              float: "right",
              color: "white",
              padding: 5,
              opacity: fromViewPolicy ? 0.7 : 1,
              pointerEvents: fromViewPolicy ? "none" : "visible",
            }}
          >
            <Add />
          </IconButton>
        </div>
      ) : undefined}
      <GridContainer>
        <GridItem sm={4} md={4} xs={4}>
          {fromViewPolicy || edit ? (
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <CustomSelect
                isDisabled={!edit ? undefined : fromViewPolicy}
                hoverData={ConditionTypeActionLkpData.map((l) => {
                  return l.label;
                })}
                options={ConditionTypeActionLkpData}
                labelText={"Condition Code Applicable"}
                onSelect={(e) => {
                  if (e == null) {
                    setSelectedPolicyAction(undefined);
                  }
                  if (e != null) {
                    dispatch({ type: CONDITION_CODE_ACTION, payload: e });
                    setSelectedPolicyAction(e);
                  }
                }}
                value={formState.conditionCodeAction}
              ></CustomSelect>
            </div>
          ) : undefined}
        </GridItem>

        <GridItem sm={8} md={8} xs={8} spacing={12}>
          {policyConditionData.length > 0 &&
          updatePolicyConditionData == true ? (
            <div
              style={{
                height: window.innerHeight / 2.6,
                opacity: fromViewPolicy ? 0.7 : 1,
              }}
            >
              <AgGrids
                columnDefs={columnDefs}
                onFilterChanged={onFilterChanged}
                ref={gridRef}
                onGridReady={onGridReady}
                cacheOverflowSize={2}
                maxConcurrentDatasourceRequests={1}
                infiniteInitialRowCount={1}
                cacheBlockSize={1000}
                maxBlocksInCache={1}
                modules={AllCommunityModules}
                debug={true}
                rowBuffer={0}
                animateRows={true}
                rowModelType={"infinite"}
              />
              {policyConditionData.length > 0 ? (
                <small style={{ position: "relative", fontSize: "12px" }}>
                  Number of rows : {numberOfRows}
                </small>
              ) : undefined}
            </div>
          ) : undefined}
        </GridItem>
      </GridContainer>
      {fromViewPolicy || edit ? (
        <div
          style={{
            float: "right",
            opacity: fromViewPolicy ? 0.7 : 1,
            pointerEvents: fromViewPolicy ? "none" : "visible",
          }}
        ></div>
      ) : undefined}

      <Dialogbox
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openBillType}
        onClose={handletoclose}
        style={{ overflowY: "scroll" }}
        disableBackdropClick={true}
        title={"Policy Condition Type"}
        message={
          <div style={{ height: window.innerHeight / 1.8, marginTop: "-10px" }}>
            <AgGrids
              columnDefs={ConditionLKPColumns}
              rowData={unMatchedBillType}
              rowSelection={"multiple"}
              onSelectionChanged={onSelectionChanged}
            />
          </div>
        }
        actions={
          <ButtonGroup style={{ marginTop: "-30px" }}>
            <CustomButton
              variant={"contained"}
              onClick={() => {
                openpostPopup();
              }}
              style={{
                backgroundColor: navyColor,
                color: "white",
                margin: 10,
                padding: 4,
                fontSize: 12,
                textTransform: "capitalize",
              }}
            >
              Yes
            </CustomButton>
            <CustomButton
              onClick={() => {
                handletoclose();
              }}
              variant={"contained"}
              style={{
                backgroundColor: dangerColor,
                color: "white",
                margin: 10,
                padding: 4,
                fontSize: 12,
                textTransform: "capitalize",
              }}
            >
              No
            </CustomButton>
          </ButtonGroup>
        }
      />

      <Dialogbox
        open={postPopup}
        onClose={handletoclose}
        disableBackdropClick={true}
        title={"Confirm"}
        message={"Would you like to Add This Record ?"}
        actions={
          <ButtonGroup>
            <CustomButton
              onClick={() => {
                handletoclose();
                postConditionTypeData(dispatch, selectedData, policyId);
              }}
              style={{
                backgroundColor: navyColor,
                color: "white",
                marginRight: 10,
                padding: 4,
                fontSize: 12,
                textTransform: "capitalize",
              }}
            >
              Yes
            </CustomButton>
            <CustomButton
              onClick={handletoclose}
              style={{
                backgroundColor: dangerColor,
                color: "white",
                padding: 4,
                fontSize: 12,
                textTransform: "capitalize",
              }}
            >
              No
            </CustomButton>
          </ButtonGroup>
        }
      />

      <Dialogbox
        open={popup}
        onClose={handletoclose}
        disableBackdropClick={true}
        title={"Confirm"}
        message={"Would you like to Delete This Record ?"}
        actions={
          <ButtonGroup>
            <CustomButton
              onClick={() => {
                DeleteConditionTypeData(dispatch, deletedByKey, policyId);
                handletoclose();
              }}
              style={{
                backgroundColor: navyColor,
                color: "white",
                marginRight: 10,
                padding: 4,
                fontSize: 12,
                textTransform: "capitalize",
              }}
            >
              Yes
            </CustomButton>
            <CustomButton
              onClick={closePopUp}
              style={{
                backgroundColor: dangerColor,
                color: "white",
                padding: 4,
                fontSize: 12,
                textTransform: "capitalize",
              }}
            >
              No
            </CustomButton>
          </ButtonGroup>
        }
      />
    </>
  );
};
export default ConditionCode;

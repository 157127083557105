export const GET_REASON_CODES = "getReasonCodes"
export const GET_CAT = "getCat"
export const GET_MEDICAL_POLICY = "getMedicalPolicy"
export const GET_SUB_POLICY = "getSubPolicy"
export const GET_PROD_TYPE = "getProductType"
export const GET_LOB = "getLob"
export const GET_GENDER = "getGender"
export const GET_MIN_AGE = "getMinAge"
export const GET_TAX_LOGIC = "getTaxLogic"
export const GET_SUBSPECIALITY = "getSubspeciality"
export const GET_MAX_AGE = "getMaxAge"
export const GET_NPI = "getNpi"
export const GET_POSLINK = "getPosLink"
export const GET_CPTLINK = "getCptLink"

export const GET_REVENUE_CODE_CLAIM_LINK = "getRevenueCodeClaimLink"

export const SEARCH_POLICY = "searchPolicy"
export const SEARCH_CLAIM = "searchClaim"

export const GET_MEDICAL_POLICY_DATA = "getMedicalPolicyData"


export const GET_PROCS = "getProcs"
export const GET_CLAIM_LINK_LKP = "getClmLinkLkp"
export const GET_POLICY_CPT_ACTION_LKP = "getPolicyCptActionLkp"

export const POST_PROCS = "postprocedures"
export const POST_DIAG = "postdiagnosis"
export const PROCS_TARGET = "procstarget"
export const PROCS_TARGET_SPINNER = "targetSpinner"
export const PROCS_DATA_TO_EXCEL = "datatoexcel"
export const DIAGNOSIS_DATA_TO_EXCEL = "diagnosisDataToExcel"

export const GET_CONFIG_REPORT_FOR_SINGLE_RULE = "getconfoigreportforsinglerule"
export const GET_POLICY_BY_ID = "getPolicyById"
export const GET_DRGN_CHALLENGE_CODE = "getDrgnChallengeCode"
export const REFRESH_CHALLENGE_CACHE = "refreshChallengeCache"
export const REFRESH_POLICY_CACHE = "refreshPolicyCache"
export const SET_IS_LOADING = "setIsLoading"
export const CHANGES_ISOPEN_B = "getChangesId"
export const DIALOG = "setdialogbox"
export const DELTA_LINK = "deltaLink"
export const ADD_ON_CODES = "getAddOnCodes"
export const PTP_CCI = "getCci"
export const GET_ALL_CLAIM_TYPE = "getAllClaimType"
export const CURATION_VER = "getCurationVer"
export const CONFIG_VER = "getConfigVer"
export const CRAWLER_VER = "getCrawlerVer"
export const ENGINE_VER = "getEngineVer"
export const EMAIL_VER = "getEmailVer"
export const IGNORE_MODIFIER = "getIgnoreModifier"
export const ALLOW_CMS_NCCI_MODIFIERS = "getAllowCmsNcciModifiers"

export const GRID_COLUMNS = "gridColumns";
export const GET_LOB_DATA = "getLobData";
export const GET_BW_TYPE_DATA = "getBwTypeData";
export const GET_CHANGE_MODIFIER = "getChangeModifier";
export const GET_MODIFIER_PAY_PERCENTAGE = "getModifierPayPercentage";
export const GET_CLAIM_TYPE_LINK_LKP = "getClaimTypeLinkLkp";














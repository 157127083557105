import { useState } from "react";
import { store } from "../../redux/store";
import { PolicyConstants } from "./PolicyConst";

let checkbox;

function paths() {
  var newState = store.getState();
  let pathName = newState.newPolicyForm.paths;
  return pathName;
}
store.subscribe(getPath);



function getPath() {
  let pathName = paths();
  if (
    pathName.includes(PolicyConstants.EDIT_POLICY) ||
    pathName.includes(PolicyConstants.SEARCH) ||
    pathName.includes(PolicyConstants.NEW_POLICY)
  ) {
    checkbox = true;
  } else if (pathName.includes(PolicyConstants.VIEW_POLICY)) {
    checkbox = false;
  } else {
    checkbox = false;
  }
  return checkbox;
}

export const ReasonLKPColumns = [
  {
    field: "reasonCode",
    headerName: "Reason Code",
    minWidth: 100,
    headerTooltip: "Reason Code",
    checkboxSelection: getPath,
  },

  {
    field: "reasonDesc",
    headerName: "Reason Desc",
    minWidth: 129,
    headerTooltip: "Reason Desc",
    cellRenderer: (cellValues) => {
      return (
        <span
          title={`${cellValues.data.reasonDesc}`}
        >{`${cellValues.data.reasonDesc}`}</span>
      );
    },
  },
  {
    field: "challengeCode",
    headerName: "Challenge Code",
    minWidth: 80,
    headerTooltip: "Challenge Code",
  },
  {
    field: "challengeDesc",
    headerName: "Challenge Desc",
    minWidth: 109,
    headerTooltip: "Challenge Desc",
    cellRenderer: (cellValues) => {
      return (
        <span
          title={`${cellValues.data.challengeDesc}`}
        >{`${cellValues.data.challengeDesc}`}</span>
      );
    },
  },
  {
    field: "pcoCode",
    headerName: "PCO Code",
    minWidth: 80,
    headerTooltip: "PCO Code",
  },
  {
    field: "hipaaCode",
    headerName: "HIPPA Code",
    minWidth: 80,
    headerTooltip: "HIPPA Code",
  },
  {
    field: "hippaDesc",
    headerName: "HIPPA Desc",
    minWidth: 109,
    headerTooltip: "HIPPA Desc",
    cellRenderer: (cellValues) => {
      return (
        <span
          title={`${cellValues.data.hippaDesc}`}
        >{`${cellValues.data.hippaDesc}`}</span>
      );
    },
  },
];

export const PolicyCatLKPColumns = [
  {
    field: "policyCategoryLkpId",
    headerName: "Policy Category LKP Id",
    minWidth: 80,
    headerTooltip: "Policy Category LKP Id",
    checkboxSelection: getPath,
  },
  {
    field: "policyCategoryDesc",
    headerName: "Policy Category Desc",
    minWidth: 109,
    headerTooltip: "Policy Category Desc",
    cellRenderer: (cellValues) => {
      return (
        <span
          title={`${cellValues.data.policyCategoryDesc}`}
        >{`${cellValues.data.policyCategoryDesc}`}</span>
      );
    },
  },
  {
    field: "priority",
    headerName: "Order of Operation",
    minWidth: 70,
    headerTooltip: "Priority",
  },
];

export const MedicalPolicyLKPColumns = [
  {
    field: "medicalPolicykey",
    headerName: "Medical Policy Key",
    minWidth: 109,
    headerTooltip: "Medical Policy Key",
    checkboxSelection: getPath,
  },

  {
    field: "medicalPolicyDesc",
    headerName: "Medical Policy Desc",
    minWidth: 109,
    headerTooltip: "Medical Policy Desc",
    cellRenderer: (cellValues) => {
      return (
        <span
          title={`${cellValues.data.medicalPolicyDesc}`}
        >{`${cellValues.data.medicalPolicyDesc}`}</span>
      );
    },
  },
];

export const SubPolicyLKPColumns = [
  {
    field: "subPolicyKey",
    headerName: "SubPolicy Key",
    minWidth: 109,
    headerTooltip: "SubPolicy Key",
    checkboxSelection: getPath,
  },

  {
    field: "subPolicyDesc",
    headerName: "SubPolicy Desc",
    minWidth: 109,
    headerTooltip: "SubPolicy Desc",
    cellRenderer: (cellValues) => {
      return (
        <span
          title={`${cellValues.data.subPolicyDesc}`}
        >{`${cellValues.data.subPolicyDesc}`}</span>
      );
    },
  },
];

export const EnforceBeforeCatLKPColumns = [
  {
    field: "policyCategoryLkpId",
    headerName: "Policy Category LKP Id",
    minWidth: 80,
    headerTooltip: "Policy Category LKP Id",
    checkboxSelection: getPath,
  },
  {
    field: "policyCategoryDesc",
    headerName: "Policy Category Desc",
    minWidth: 109,
    headerTooltip: "Policy Category Desc",
    cellRenderer: (cellValues) => {
      return (
        <span
          title={`${cellValues.data.policyCategoryDesc}`}
        >{`${cellValues.data.policyCategoryDesc}`}</span>
      );
    },
  },
  {
    field: "priority",
    headerName: "Order of Operation",
    minWidth: 109,
    headerTooltip: "priority",
  },
  // {
  //   field: "lastUpdatedAt",
  //   headerName: "Last Updated At",
  //   minWidth: 109,
  //   headerTooltip: "LastUpdatedAt",
  //   resizable: false,
  // },
];
export const ProcedureMinAgeColumns = [
  {
    field: "minMaxAgeLkpId",
    headerName: "Min Max Age Lkp Id",
    minWidth: 109,
    headerTooltip: "Min Max Age Lkp Id",
    checkboxSelection: getPath,
  },

  {
    field: "minMaxAgeDesc",
    headerName: "Description",
    minWidth: 109,
    headerTooltip: "Description",
    cellRenderer: (cellValues) => {
      return (
        <span
          title={`${cellValues.data.minMaxAgeDesc}`}
        >{`${cellValues.data.minMaxAgeDesc}`}</span>
      );
    },
  },
  {
    field: "ageYears",
    headerName: "Age Years",
    minWidth: 109,
    headerTooltip: "Age Years",
  },
  {
    field: "ageMonths",
    headerName: "Age Months",
    minWidth: 109,
    headerTooltip: "Age Months",
  },
  {
    field: "ageDays",
    headerName: "Age Days",
    minWidth: 109,
    headerTooltip: "Age Days",
  },
  {
    field: "equalsB",
    headerName: "Equals",
    minWidth: 109,
    headerTooltip: "EqualsB",
  },
  {
    field: "minVsMaxB",
    headerName: "MinVsMax",
    minWidth: 109,
    headerTooltip: "MinVsMaxB",
    resizable: false,
  },
];
export const ProcedureMaxAgeColumns = [
  {
    field: "minMaxAgeLkpId",
    headerName: "Min Max Age Lkp Id",
    minWidth: 109,
    headerTooltip: "Min Max Age Lkp Id",
    checkboxSelection: getPath,
  },

  {
    field: "minMaxAgeDesc",
    headerName: "Description",
    minWidth: 109,
    headerTooltip: "Description",
    cellRenderer: (cellValues) => {
      return (
        <span
          title={`${cellValues.data.minMaxAgeDesc}`}
        >{`${cellValues.data.minMaxAgeDesc}`}</span>
      );
    },
  },
  {
    field: "ageYears",
    headerName: "Age Years",
    minWidth: 109,
    headerTooltip: "ageYears",
  },
  {
    field: "ageMonths",
    headerName: "Age Months",
    minWidth: 109,
    headerTooltip: "AgeMonths",
  },
  {
    field: "ageDays",
    headerName: "Age Days",
    minWidth: 109,
    headerTooltip: "AgeDays",
  },
  {
    field: "equalsB",
    headerName: "Equals",
    minWidth: 109,
    headerTooltip: "EqualsB",
  },
  {
    field: "minVsMaxB",
    headerName: "MinVsMax",
    minWidth: 109,
    headerTooltip: "MinVsMaxB",
    resizable: false,
  },
];
export const IgnoreModifierColumns = [
  {
    field: "cptMod",
    headerName: "CPT MOD",
    minWidth: 109,
    headerTooltip: "CPT MOD",
  },

  {
    field: "description",
    headerName: "Description",
    minWidth: 109,
    resizable: false,
    headerTooltip: "Description",
    cellRenderer: (cellValues) => {
      return (
        <span
          title={`${cellValues.data.description}`}
        >{`${cellValues.data.description}`}</span>
      );
    },
  },
];
export const CMSColumns = [
  {
    field: "cptMod",
    headerName: "CPT MOD",
    minWidth: 109,
    headerTooltip: "CPT MOD",
  },

  {
    field: "description",
    headerName: "Description",
    minWidth: 109,
    resizable: false,
    headerTooltip: "Description",
    cellRenderer: (cellValues) => {
      return (
        <span
          title={`${cellValues.data.description}`}
        >{`${cellValues.data.description}`}</span>
      );
    },
  },
];


import { HTML5_FMT } from "moment";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/components/customInputStyle.js";
import { dangerColor, navyColor } from "../../assets/jss/material-kit-react.js";
import './CustomHeader4.css'
export default function CustomHeader4(props){
const {
    labelText,
    style,
  
} = props;
return(
<h4 className="title">{labelText}</h4>
);

}

CustomHeader4.propTypes ={
labelText:PropTypes.node,
style:PropTypes.any,
};
import { navyColor } from '../../assets/jss/material-kit-react';
import { apiUrl, policyConfigUrl } from '../../configs/apiUrls';
import { store } from '../store';
import swal from 'sweetalert2';
import {
  DELETE_CLIENT_EXCLUSION_DATA,
  GET_CLIENT_EXCLUSION,
  GET_CLIENT_POLICY_EXCLUISON,
  GET_POLICY_EXCLUSION,
  POST_CLIENT_EXCLUSION_DATA,
} from './ClientPolicyExclusionActionTypes';
import { SET_IS_LOADING } from './actionTypes';

export async function getPolicyExclusionData(dispatch: typeof store.dispatch) {
  await fetch(policyConfigUrl + apiUrl.policyExclusion)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_POLICY_EXCLUSION, payload: data });
    });
}

export async function getClientgroupData(dispatch: typeof store.dispatch) {
  await fetch(policyConfigUrl + apiUrl.clientExclusion)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_CLIENT_EXCLUSION, payload: data });
    });
}
export async function getClientPolicyData(dispatch: typeof store.dispatch) {
  await fetch(policyConfigUrl + apiUrl.clientPolicyExcluison)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_CLIENT_POLICY_EXCLUISON, payload: data });
    });
}

export async function postclientExclusionData(
  dispatch: typeof store.dispatch,
  data
) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  try {
    await fetch(policyConfigUrl + apiUrl.postClientPolicyExclusion, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
        }
        dispatch({ type: SET_IS_LOADING, payload: false });
        return JSON.stringify(response);
      })
      .then((data) => {
        dispatch({ type: POST_CLIENT_EXCLUSION_DATA, payload: data });
        getClientPolicyData(dispatch);
        getClientgroupData(dispatch);
        swal.fire({
          icon: 'success',
          text: 'Data Added successfully',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
      });
  } catch {
    swal.fire({
      icon: 'error',
      text: 'Please Reach out It Team',
      confirmButtonColor: navyColor,
      confirmButtonText: 'OK',
    });
  }
}

export async function DeleteclientExclusionData(
  dispatch: typeof store.dispatch,
  data
) {
  dispatch({ type: SET_IS_LOADING, payload: true });

  let DeleteData = data.map((data) => {
    return {
      policyId: data.policyId,
    };
  });
  try {
    await fetch(policyConfigUrl + apiUrl.deleteClientPolicyExclusion, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
        }
        dispatch({ type: SET_IS_LOADING, payload: false });
        return JSON.stringify(response);
      })
      .then((data) => {
        dispatch({ type: DELETE_CLIENT_EXCLUSION_DATA, payload: data });
        getClientPolicyData(dispatch);
        getClientgroupData(dispatch);
        swal.fire({
          icon: 'success',
          text: 'Data Deleted successfully',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
      });
  } catch {
    swal.fire({
      icon: 'error',
      text: 'Please Reach out It Team',
      confirmButtonColor: navyColor,
      confirmButtonText: 'OK',
    });
  }
}

import { apiUrl, policyConfigUrl } from '../../configs/apiUrls';
import { NewPolicyFormState } from '../reducers/NewPolicyFormReducer';
import {
  NEW_POLICY_VALIDATION,
  CHANGES_ISOPEN_B,
  POLICY_ID,
  GET_DIAGNOSIS_DATA,
  POST_DIAGNOSIS_DATA,
  EDIT_DIAGNOSIS_DATA,
  GET_ACTION_DATA,
  POST_HEADER_PDX,
  GET_BILL_TYPE_DATA,
  POLICY_BILL_TYPE_DATA,
  GET_SOURCE_BILL_TYPE_LKP_DATA,
  POST_BILL_TYPE_DATA,
  GET_POLICY_BILL_TYPE_ACTION_LKP,
  MODIFIER_PAY_PERCENTAGE_DATA,
} from './NewPolicyFormActionTypes';
import { store } from '../store';
import { getChangesById, getPolicyById, searchPolicy } from '.';
import { SET_IS_LOADING } from './actionTypes';
import swal from 'sweetalert2';
import { navyColor } from '../../assets/jss/material-kit-react';
import { PolicyConstants } from '../../pages/NewPolicy/PolicyConst';
import { getClientAssignmentData } from './clientAssignmentAction';
import Moment from "moment";

// Helper Functions

export function mapAndSort(arr) {
  return (
    arr
      ?.map((c) => c.value)
      .sort()
      .join(',') || ''
  );
}
function createdDateForNewPolicy(formState, edit) {
  let date = null;
  if (formState.custom === 1 || !edit) {
    date = null;
  } else {
    date = formState.createdDate;
  }
  return date;
}

export function createPolicyObject(
  formState,
  clm,
  cgtype,
  edit,
  policyCreateState,
  forClientTabPolicyId
) {
  return {
    claimType: clm,
    createdBy: 'string',
    createdDate: createdDateForNewPolicy(formState, edit),
    deactivated: formState.deactivated ? 1 : 0,
    disabled: formState.disabled ? 1 : 0,
    ncciB: formState.ncciB ? 1 : 0,
    group59B: formState.group59B ? 1 : 0,
    asGroupB: formState.asGroupB ? 1 : 0,
    referenceClaimType: formState.referenceClaimType,
    tc26ModB: formState.tc26ModB ? 1 : 0,
    enforceBeforeCategory: formState.enforceBefCategory,
    explanation: formState.policyExplantion,
    genderCode: formState.gender.value,
    isProdb: formState.prod ? 1 : 0,
    lobFk: formState.lob.value,
    maxAgeFk: formState.procedureMaxAge,
    medicalPolicyKey: formState.medicalPolicy,
    minAgeFk: formState.procedureMinAge,
    notes: formState.notes,
    npiLogicFk: formState.npi.value,
    posLinkFk: formState.posLink.value,
    cptLinkFk: formState.cptLink.value,
    revenueCodeClaimLinkFk: formState.revenueCodeClaimLink.value,
    policyCategoryLkpId: formState.cat,
    policyDesc: formState.roldDescription,
    policyId: edit ? formState.policyId : '',
    policyNumber: edit ? formState.policy : '',
    policyVersion: edit ? (formState.version ? formState.version : 0) : 0,
    custom: formState.custom ? 1 : 0,
    clonedPolicyId: formState.clonedPolicyId
      ? formState.clonedPolicyId
      : formState.policyId,
    forClientTabPolicyId: edit?'':forClientTabPolicyId,
    priority: formState.priority,
    productTypeFk: cgtype,
    reasonCodeFk: formState.reason,
    refSourceDesc: formState.referenceSrcDesc,
    refSourceTitle: formState.referenceSrcTitleDesc,
    reference: formState.reference,
    sourceIndicator: formState.sourceIndicator,
    subPolicyKey: formState.subPolicy,
    taxonomyLogicFk: formState.taxonomyLogicFk.value,
    summary: formState.policySummary,
    taxLogicFk: formState.taxIdLogic.value,
    co1Value: getCciOrAddoN(formState),
    co2Value: getCol2(formState),
    co3Value: PolicyConstants.TWENTY_THREE
      ? formState.mutuallyExclusive
      : getCol3(formState),
    co4Value: PolicyConstants.TWENTY_THREE
      ? formState.denyType
      : getCol4(formState),
    updatedBy: '',
    updatedOn: '',
    policyChangesKey: '',
    billTypeLinkFk:
      formState.billTypeLink == '' || formState.billTypeLink == undefined
        ? 0
        : formState.billTypeLink.value,
    billTypeActionFk:
      formState.billTypeAction == '' || formState.billTypeAction == undefined
        ? 0
        : formState.billTypeAction.value,
    conditionCodeActionFk:
      formState.conditionCodeAction == undefined
        ? 0
        : formState.conditionCodeAction.value,
    claimTypeLinkFk: formState.claimTypeLink.value,
    addAllActiveClients: edit?'':policyCreateState.addAllActiveClients,
  };
}

export function createChangesObject(formState, data) {
  let changes = {
    jiraId: formState.jiraId,
    jiraDesc: formState.jiraDescription,
    jiraLink: formState.jiraLink,
    userId: formState.userId,
    policyId: data,
    isOpenb: formState.jiraIsOpen ? 1 : 0,
    policyChangesKey: formState.policyChangesKey,
  };
  return changes;
}

export function getCciOrAddoN(formState) {
  switch (formState.cat) {
    case PolicyConstants.TWENTY_THREE:
      return formState.ccikey;
    case PolicyConstants.THIRTY_EIGHT:
      return formState.billedWith;
    case PolicyConstants.TWELVE:
      return formState.lineOrHeaderOrPrincipal;
    case PolicyConstants.TWENTY_FIVE:
      return formState.bwTypeKey;
    case PolicyConstants.THIRTY_FIVE:
      return formState.maxUnitsType;
    case PolicyConstants.TWENTY:
      return formState.changeModifierKey;
    case PolicyConstants.FOURTY_FIVE:
    case PolicyConstants.FOURTY_SIX:
      return formState.modifierPayPercentage;
    case PolicyConstants.THIRTY_TWO:
      return formState.units || null;
    default:
      return null;
  }
}

export function getCol2(formState) {
  switch (formState.cat) {
    case PolicyConstants.TWENTY_THREE:
      return formState.byPassMod;
    case PolicyConstants.THIRTY_FIVE:
      return formState.modIntractionType;
    case PolicyConstants.THIRTY_TWO:
      return formState.frequency === 'RD'
        ? formState?.duration?.replace(/[a-zA-Z]/g, '') +
            formState?.durationDropdown?.value
        : formState.frequency;
    default:
      return null;
  }
}

export function getCol3(formState) {
  switch (formState.cat) {
    case PolicyConstants.TWENTY_THREE:
      return formState.mutuallyExclusive;
    case PolicyConstants.THIRTY_TWO:
      return formState.ranking;
    default:
      return null;
  }
}

export function getCol4(formState) {
  switch (formState.cat) {
    case PolicyConstants.TWENTY_THREE:
      return formState.denyType;
    case PolicyConstants.THIRTY_TWO:
      return formState.payment;
    default:
      return null;
  }
}

export async function onSaveNewPolicy(
  dispatch,
  edit,
  formState,
  navigate,
  policyCreateState,
  forClientTabPolicyId
) {
  let error = validateNewPolicyForm(formState, dispatch);
  if (error) return;
  const date = Moment(new Date()).format("YYYY-MM-DD");
  
  try {
    const clm = mapAndSort(formState.cliamType);
    const cgtype = mapAndSort(formState.productType);
    dispatch({ type: SET_IS_LOADING, payload: true });
    const response = await fetch(
      `${policyConfigUrl + apiUrl.saveCustomPolicyData}/${
        policyCreateState.newPolicyStartDate?policyCreateState.newPolicyStartDate:date
      }/${policyCreateState.cloneClientAssignmentTab}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          createPolicyObject(formState, clm, cgtype, edit, policyCreateState,forClientTabPolicyId)
        ),
      }
    );
    if (!response.ok) {
      throw new Error('Failed to create new policy');
    }
    const data = await response.json();
    await handleChanges(dispatch, formState, data, navigate);
  } catch (e) {
    swal.fire({
      icon: 'error',
      text: 'Please Reach Out IT Team',
      confirmButtonColor: navyColor,
      confirmButtonText: 'OK',
    });
  } finally {
    dispatch({ type: SET_IS_LOADING, payload: false });
  }
}

export async function handleChanges(dispatch, formState, data, navigate) {
  try {
    let changesObj = createChangesObject(formState, data);
    const response = await fetch(policyConfigUrl + apiUrl.createChanges, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(changesObj),
    });

    if (!response.ok) {
      throw new Error('Failed to create changes');
    }

    const changesData = await response.json();
    await navigateAndFetch(dispatch, navigate, changesData.policyId.policyId);
  } catch (e) {
    console.error(e);
  }
}

export async function navigateAndFetch(dispatch, navigate, policyId) {
  try {
    navigate('/viewPolicy');
    getPolicyById(dispatch, policyId);
    getChangesById(dispatch, policyId);
    getClientAssignmentData(dispatch, policyId);
    swal.fire({
      icon: 'success',
      text: 'Policy Saved Successfully',
      confirmButtonColor: navyColor,
      confirmButtonText: 'OK',
    });
  } catch (e) {
    console.error(e);
  } finally {
    dispatch({ type: SET_IS_LOADING, payload: false });
  }
}

export function validateNewPolicyForm(formState: NewPolicyFormState, dispatch) {
  let error = false;
  let errors = {
    // policy: false,
    npi: false,
    // version: false,
    reason: false,
    cat: false,
    policyId: false,
    medicalPolicy: false,
    subPolicy: false,
    reference: false,
    cliamType: false,
    prod: false,
    deactivated: false,
    ncciB: false,
    group59B: false,
    asGroupB: false,
    tc26ModB: false,
    disabled: false,
    roldDescription: false,
    lob: false,
    productType: false,
    notes: false,
    policySummary: false,
    policyExplantion: false,
    referenceSrcDesc: false,
    referenceSrcTitleDesc: false,
    sourceIndicator: false,
    createdDate: false,
    gender: false,
    procedureMinAge: false,
    taxIdLogic: false,
    taxonomyLogicFk: false,
    enforceBefCategory: false,
    priority: false,
    procedureMaxAge: false,
    jiraId: false,
    jiraDescription: false,
    jiraLink: false,
    tmpUser: false,
    reasonDesc: false,
    catDesc: false,
    enfoCatDesc: false,
    cciKey: false,
    billedWith: false,
    lineOrHeaderOrPrincipal: false,
    posLink: false,
    cptLink: false,
    revenueCodeClaimLink: false,
    bwTypeKey: false,
    maxUnitsType: false,
    modIntractionType: false,
    claimTypeLink: false,
    changeModifierKey: false,
    modifierPayPercentage: false,
    units: false,
    duration: false,
    durationDropdown: false,
    forClonePolicyAndVersion: false,
    newPolicyStartDate: false,
    // modifierPriority:false,
  };
  // if (formState.policy == undefined || formState.policy?.length == 0) {
  //   errors.policy = true;
  //   error = true;
  // }
  if (formState.cat === PolicyConstants.THIRTY_TWO) {
    if (
      formState.units == undefined ||
      formState.units == null ||
      formState.units == ''
    ) {
      errors.units = true;
      error = true;
    }
    if (
      formState.frequency == 'RD' &&
      (formState.duration == undefined ||
        formState.duration == null ||
        formState.duration == '')
    ) {
      errors.duration = true;
      error = true;
    }
  }
  if (formState.cat === PolicyConstants.TWENTY_THREE) {
    if (formState.ccikey == undefined || formState.ccikey == null) {
      errors.cciKey = true;
      error = true;
    }
  }
  if (formState.cat == PolicyConstants.THIRTY_EIGHT) {
    if (formState.billedWith == undefined || formState.billedWith == null) {
      errors.billedWith = true;
      error = true;
    }
  }
  if (formState.cat == PolicyConstants.TWELVE) {
    if (
      formState.lineOrHeaderOrPrincipal == '' ||
      formState.lineOrHeaderOrPrincipal == null
    ) {
      errors.lineOrHeaderOrPrincipal = true;
      error = true;
    }
  }
  if (formState.cat == PolicyConstants.TWENTY_FIVE) {
    if (formState.bwTypeKey == undefined || formState.bwTypeKey == null) {
      errors.bwTypeKey = true;
      error = true;
    }
  }
  if (formState.cat == PolicyConstants.TWENTY) {
    if (
      formState.changeModifierKey == undefined ||
      formState.changeModifierKey == null
    ) {
      errors.changeModifierKey = true;
      error = true;
    }
  }
  if (formState.cat == PolicyConstants.FOURTY_FIVE) {
    if (
      formState.modifierPayPercentage == undefined ||
      formState.modifierPayPercentage == null
    ) {
      errors.modifierPayPercentage = true;
      error = true;
    }
  }
  if (formState.cat == PolicyConstants.FOURTY_SIX) {
    if (
      formState.modifierPayPercentage == undefined ||
      formState.modifierPayPercentage == null
    ) {
      errors.modifierPayPercentage = true;
      error = true;
    }
  }
  if (formState.cat == PolicyConstants.THIRTY_FIVE) {
    if (formState.maxUnitsType == undefined || formState.maxUnitsType == null) {
      errors.maxUnitsType = true;
      error = true;
    }
    if (
      formState.modIntractionType == undefined ||
      formState.modIntractionType == null
    ) {
      errors.modIntractionType = true;
      error = true;
    }
  }
  if (formState.npi == undefined) {
    errors.npi = true;
    error = true;
  }

  if (formState.reason == undefined) {
    errors.reason = true;
    error = true;
  }
  if (formState.catDesc == undefined || formState.catDesc == '') {
    errors.catDesc = true;
    error = true;
  }

  if (formState.medicalPolicy == undefined || formState.medicalPolicy == '') {
    errors.medicalPolicy = true;
    error = true;
  }

  if (formState.subPolicy == undefined || formState.subPolicy == '') {
    errors.subPolicy = true;
    error = true;
  }

  if (formState.reference == undefined || formState.reference == '') {
    errors.reference = true;
    error = true;
  }

  if (formState.cliamType == undefined || formState.cliamType.length == 0) {
    errors.cliamType = true;
    error = true;
  }

  if (
    formState.roldDescription == undefined ||
    formState.roldDescription == ''
  ) {
    errors.roldDescription = true;
    error = true;
  }

  if ( formState.lob === null || formState.lob === undefined) {
    errors.lob = true;
    error = true;
  }

  if (formState.productType.length === 0) {
    errors.productType = true;
    error = true;
  }
  // if (formState.createdDate == undefined) {
  //   errors.createdDate = true;
  //   error = true;
  // }

  if (formState.gender == undefined) {
    errors.gender = true;
    error = true;
  }

  if (
    formState.procedureMinAge == undefined ||
    formState.procedureMinAge == null
  ) {
    errors.procedureMinAge = true;
    error = true;
  }

  if (
    formState.procedureMaxAge == undefined ||
    formState.procedureMaxAge == ''
  ) {
    errors.procedureMaxAge = true;
    error = true;
  }

  if (formState.taxIdLogic == undefined) {
    errors.taxIdLogic = true;
    error = true;
  }

  if (formState.taxonomyLogicFk == undefined) {
    errors.taxonomyLogicFk = true;
    error = true;
  }

  if (formState.posLink == undefined) {
    errors.posLink = true;
    error = true;
  }

  if (formState.cptLink == undefined) {
    errors.cptLink = true;
    error = true;
  }
  if (formState.revenueCodeClaimLink == undefined) {
    errors.revenueCodeClaimLink = true;
    error = true;
  }
  if (
    formState.enforceBefCategory == null ||
    formState.enforceBefCategory?.length == 0
  ) {
    errors.enforceBefCategory = true;
    error = true;
  }

  // if (formState.enfoCatDesc == "" || formState.enfoCatDesc?.length == 0) {
  //   errors.enfoCatDesc = true;
  //   error = true;
  // }

  if (formState.priority == undefined || formState.priority?.length == 0) {
    errors.priority = true;
    error = true;
  }

  if (
    formState.notes == undefined ||
    formState.notes == '' ||
    formState.notes == ' '
  ) {
    errors.notes = true;
    error = true;
  }

  if (formState.policySummary == undefined || formState.policySummary == '') {
    errors.policySummary = true;
    error = true;
  }

  if (
    formState.policyExplantion == undefined ||
    formState.policyExplantion == ''
  ) {
    errors.policyExplantion = true;
    error = true;
  }

  if (
    formState.referenceSrcDesc == undefined ||
    formState.referenceSrcDesc == ''
  ) {
    errors.referenceSrcDesc = true;
    error = true;
  }

  if (
    formState.referenceSrcTitleDesc == undefined ||
    formState.referenceSrcTitleDesc == ''
  ) {
    errors.referenceSrcTitleDesc = true;
    error = true;
  }

  if (
    formState.sourceIndicator == undefined ||
    formState.sourceIndicator == ''
  ) {
    errors.sourceIndicator = true;
    error = true;
  }

  if (formState.jiraId == undefined || formState.jiraId == '') {
    errors.jiraId = true;
    error = true;
  }
  if (
    formState.jiraDescription == undefined ||
    formState.jiraDescription == ''
  ) {
    errors.jiraDescription = true;
    error = true;
  }
  if (formState.jiraLink == undefined || formState.jiraLink == '') {
    errors.jiraLink = true;
    error = true;
  }

  if (formState.claimTypeLink == undefined || formState.claimTypeLink == '') {
    errors.claimTypeLink = true;
    error = true;
  }

  if (error) {
    dispatch({ type: NEW_POLICY_VALIDATION, payload: errors });
  }
  return error;
}

export async function onUpdateNewPolicy(
  dispatch,
  edit,
  formState,
  navigate,
  roleState
) {
  let error = validateNewPolicyForm(formState, dispatch);
  if (error) return;

  try {
    const clm = mapAndSort(formState.cliamType);
    const cgtype = mapAndSort(formState.productType);
    dispatch({ type: SET_IS_LOADING, payload: true });
    const response = await fetch(policyConfigUrl + apiUrl.updateNewPolicy, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        createPolicyObject(formState, clm, cgtype, edit, null,null)
      ),
    });

    if (!response.ok) {
      throw new Error('Failed to create new policy');
    }

    const data = await response.json();
    await handleChanges(dispatch, formState, data, navigate);
  } catch (e) {
    swal.fire({
      icon: 'error',
      text: 'Please Reach Out IT Team',
      confirmButtonColor: navyColor,
      confirmButtonText: 'OK',
    });
  } finally {
    dispatch({ type: SET_IS_LOADING, payload: false });
  }
}

export async function getDiagnosisData(dispatch: typeof store.dispatch, id) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(`${policyConfigUrl + apiUrl.getDiagnosis}/${id}`)
    .then((response) => {
      if (!response.ok) {
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_DIAGNOSIS_DATA, payload: data });
      dispatch({ type: SET_IS_LOADING, payload: false });
    });
}
export async function postdiag(dispatch: typeof store.dispatch, data) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  let ok = {
    policyId: parseInt(data.policyId),
    diagFrom: data.diagFrom.replace(/\./g, ''),
    diagTo: data.diagTo.replace(/\./g, ''),
    dosFrom: data.dosFrom,
    dosTo: data.dosTo,
    action: data.action,
    exclusion: data.exclusion,
    headerLevel: data.headerLevel,
    principalDx: data.principalDx,
  };
  await fetch(policyConfigUrl + apiUrl.postDiagnosis, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(ok),
  })
    .then((response) => {
      if (!response.ok) {
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json;
    })
    .then(
      (data) => {
        getDiagnosisData(dispatch, ok.policyId);
        dispatch({ type: POST_DIAGNOSIS_DATA, payload: data });
        dispatch({ type: SET_IS_LOADING, payload: false });
        swal.fire({
          icon: 'success',
          text: 'Data Added successfully',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
      }
      // });
    );
}

export async function Editdiag(
  dispatch: typeof store.dispatch,
  data,
  policyId
) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  let editedDiagdata = {
    diagFrom: data.diagFrom.replace(/\./g, ''),
    diagTo: data.diagTo.replace(/\./g, ''),
    dosFrom: data.dosFrom,
    dosTo: data.dosTo,
    action: data.action,
    exclusion: data.exclusion,
    headerLevel: data.headerLevel,
    principalDx: data.principalDx,
    actionKeyValue: data.actionKeyValue,
    policyDiagnosisKey: data.policyDiagnosisKey,
  };
  await fetch(policyConfigUrl + apiUrl.editDiagnosis, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(editedDiagdata),
  })
    .then((response) => {
      if (!response.ok) {
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json;
    })
    .then((data) => {
      getDiagnosisData(dispatch, policyId);
      dispatch({ type: EDIT_DIAGNOSIS_DATA, payload: data });
      dispatch({ type: SET_IS_LOADING, payload: false });
      swal.fire({
        icon: 'success',
        text: 'Data Updated successfully',
        confirmButtonColor: navyColor,
        confirmButtonText: 'OK',
      });
    });
}

export async function deleteDiagnosis1(
  dispatch: typeof store.dispatch,
  data,
  policyId
) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(policyConfigUrl + apiUrl.deleteDiagnosis + '/' + data, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: data,
  })
    .then((response) => {
      if (!response.ok) {
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.text();
    })
    .then((data) => {
      getDiagnosisData(dispatch, policyId);
      dispatch({ type: SET_IS_LOADING, payload: false });
      swal.fire({
        icon: 'success',
        text: 'Data Deleted successfully',
        confirmButtonColor: navyColor,
        confirmButtonText: 'OK',
      });
    });
}

export async function getPolicyAction(dispatch: typeof store.dispatch) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(`${policyConfigUrl + apiUrl.getActionValue}`)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json();
    })

    .catch((e) => {})

    .then((data) => {
      dispatch({ type: GET_ACTION_DATA, payload: data });
      dispatch({ type: SET_IS_LOADING, payload: false });
    });
}

export async function HeaderLevelPdx(
  dispatch: typeof store.dispatch,
  data,
  roleState
) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(policyConfigUrl + apiUrl.updateHeaderLevelPdx, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        swal.fire({
          icon: 'error',
          text: 'Please Reach Out IT Team',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
      }
      swal.fire({
        icon: 'success',
        text: 'Task Assigned Successfully ',
        confirmButtonColor: navyColor,
        confirmButtonText: 'OK',
      });
      dispatch({ type: SET_IS_LOADING, payload: false });
    })
    .then((data) => {
      dispatch({ type: POST_HEADER_PDX, payload: data });
    });
}

export async function getBillTypeData(dispatch: typeof store.dispatch) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(policyConfigUrl + apiUrl.getBillTypeKey)
    .then((response) => {
      if (!response.ok) {
        // throw new Error(response.statusText);
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json();
    })
    .then((data) => {
      // if(data.)
      dispatch({ type: GET_BILL_TYPE_DATA, payload: data });
      dispatch({ type: SET_IS_LOADING, payload: false });
    });
}
export async function getPolicyBillTypeData(
  dispatch: typeof store.dispatch,
  policyId
) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(`${policyConfigUrl + apiUrl.getPolicyBillType}/${policyId}`)
    .then((response) => {
      if (!response.ok) {
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json();
    })
    .then((data) => {
      dispatch({ type: POLICY_BILL_TYPE_DATA, payload: data });
      dispatch({ type: SET_IS_LOADING, payload: false });
    });
}

export async function getSourceBillTypeLkpData(
  dispatch: typeof store.dispatch
) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(policyConfigUrl + apiUrl.getSourceBillTypeLkpData)
    .then((response) => {
      if (!response.ok) {
        // throw new Error(response.statusText);
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json();
    })
    .then((data) => {
      // if(data.)
      dispatch({ type: GET_SOURCE_BILL_TYPE_LKP_DATA, payload: data });
      dispatch({ type: SET_IS_LOADING, payload: false });
    });
}

export async function postBillTypeData(
  dispatch: typeof store.dispatch,
  data,
  policyId
) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  let ok = data.map((k, i) => {
    return {
      policyId: parseInt(policyId),
      billType: k.billTypeLkp,
      billTypeDesc: k.billTypeDescLkp,
    };
  });
  await fetch(policyConfigUrl + apiUrl.postBillTypeData, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(ok),
  })
    .then((response) => {
      if (!response.ok) {
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json;
    })
    .then((data) => {
      getPolicyBillTypeData(dispatch, policyId);
      dispatch({ type: POST_BILL_TYPE_DATA, payload: data });
      dispatch({ type: SET_IS_LOADING, payload: false });
      swal.fire({
        icon: 'success',
        text: 'BillType data added successfully',
        confirmButtonColor: navyColor,
        confirmButtonText: 'OK',
      });
    });
}

export async function DeleteBillTypeData(
  dispatch: typeof store.dispatch,
  data,
  policyId
) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(policyConfigUrl + apiUrl.deleteBillTypeData + '/' + data, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: data,
  })
    .then((response) => {
      if (!response.ok) {
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.text();
    })
    .then((data) => {
      getPolicyBillTypeData(dispatch, policyId);
      dispatch({ type: SET_IS_LOADING, payload: false });
      swal.fire({
        icon: 'success',
        text: 'BillType data deleted successfully',
        confirmButtonColor: navyColor,
        confirmButtonText: 'OK',
      });
    });
}

export async function ModifierPayPercentageData(
  dispatch: typeof store.dispatch,
  data
) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(`${policyConfigUrl + apiUrl.modiferPayPercentageKey}/${data}`)
    .then((response) => {
      if (!response.ok) {
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json();
    })
    .then((data) => {
      dispatch({ type: MODIFIER_PAY_PERCENTAGE_DATA, payload: data });
      dispatch({ type: SET_IS_LOADING, payload: false });
    });
}

export async function getPolicyBillTypeActionLkp(
  dispatch: typeof store.dispatch
) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(policyConfigUrl + apiUrl.getPolicyBillTypeActionLkp)
    .then((response) => {
      if (!response.ok) {
        // throw new Error(response.statusText);
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_POLICY_BILL_TYPE_ACTION_LKP, payload: data });
      dispatch({ type: SET_IS_LOADING, payload: false });
    });
}
export async function uploadPolicyReport(
  dispatch: typeof store.dispatch,
  data
) {
  let Getdata = [];
  dispatch({ type: SET_IS_LOADING, payload: true });
  try {
    await fetch(policyConfigUrl + apiUrl.uploadPolicyReportFile, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
        }
        return response.json();
      })
      .then((data) => {
        dispatch({ type: SET_IS_LOADING, payload: false });
        Getdata = data;
      });
    return Getdata;
  } catch {
    swal.fire({
      icon: 'error',
      text: 'Please Reach out It Team',
      confirmButtonColor: navyColor,
      confirmButtonText: 'OK',
    });
  }
}

import FileSaver from 'file-saver';
import swal from 'sweetalert2';
import { navyColor } from '../../assets/jss/material-kit-react';
import { apiUrl, dataCurationETL } from '../../configs/apiUrls';
import { MetaLoaderConstants } from '../../pages/MetaDataLoader/MetaLoaderConst';
import { store } from '../store';
import { SET_IS_LOADING } from './actionTypes';
import { GET_MFS_QUARTER } from './MetaDataLoaderActionTypes';
import { GET_GROUP_TASK } from './TaskActionTypes';

export async function genearteRbrvsReport1(dispatch: typeof store.dispatch) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(dataCurationETL + apiUrl.generateRbrvsReport1, {
    method: 'GET',
  })
    .then((response) => {
      if (!response.ok) {
      }
      return response.blob();
    })
    .then(function (blob) {
      FileSaver.saveAs(blob, 'RBRVS missing acq_cost.csv');
      dispatch({ type: SET_IS_LOADING, payload: false });
    });
}

export async function genearteRbrvsReport2(dispatch: typeof store.dispatch) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(dataCurationETL + apiUrl.generateRbrvsReport2, { method: 'GET' })
    .then((response) => {
      if (!response.ok) {
      }
      return response.blob();
    })
    .then(function (blob) {
      FileSaver.saveAs(blob, '0 pricing values which has 0 acq_cost.csv');
      dispatch({ type: SET_IS_LOADING, payload: false });
    });
}

export async function generateVaccinationCodes(
  dispatch: typeof store.dispatch,
  data
) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(dataCurationETL + apiUrl.generateVaccinationCodes, {
    method: 'POST',
    body: data,
  })
    .then((response) => {
      if (!response.ok) {
      }
      return response.blob();
    })
    .then(function (blob) {
      FileSaver.saveAs(blob, 'Vaccination codes.csv');
      dispatch({ type: SET_IS_LOADING, payload: false });
    });
}

export async function getMfsQuaterLoader(dispatch: typeof store.dispatch) {
  await fetch(dataCurationETL + apiUrl.getQuarter)
    .then((response) => {
      if (!response.ok) {
      }
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_MFS_QUARTER, payload: data });
    });
}

export async function uploadMetaLoader(
  dispatch: typeof store.dispatch,
  data1,
  sourceName,
  navigate
) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  if (
    !(
      sourceName == MetaLoaderConstants.RBRVS ||
      sourceName == MetaLoaderConstants.ICD_10_PCS ||
      sourceName == MetaLoaderConstants.ICD_10_CM_DRGN ||
      sourceName == MetaLoaderConstants.ADHOC_CPT_HCPCS
    )
  ) {
    fetch(dataCurationETL + apiUrl.uploadManualFile, {
      method: 'POST',
      body: data1,
    });
    setTimeout(() => {
      dispatch({ type: SET_IS_LOADING, payload: false });
      swal.fire({
        icon: 'success',
        text: "File Uploaded Successfully. You'll receive an Email Notification shortly!",
        confirmButtonColor: navyColor,
        confirmButtonText: 'OK',
      });
    }, 2000);
  }
  if (sourceName == MetaLoaderConstants.RBRVS) {
    dispatch({ type: SET_IS_LOADING, payload: true });
    fetch(dataCurationETL + apiUrl.uploadRBRVS, {
      method: 'POST',
      body: data1,
    });
    setTimeout(() => {
      dispatch({ type: SET_IS_LOADING, payload: false });
      swal.fire({
        icon: 'success',
        text: "File Uploaded Successfully. You'll receive an Email Notification shortly!",
        confirmButtonColor: navyColor,
        confirmButtonText: 'OK',
      });
    }, 2000);
  }
  if (sourceName == MetaLoaderConstants.ICD_10_PCS) {
    dispatch({ type: SET_IS_LOADING, payload: true });
    fetch(dataCurationETL + apiUrl.uploadICD10PCS, {
      method: 'POST',
      body: data1,
    });
    setTimeout(() => {
      dispatch({ type: SET_IS_LOADING, payload: false });
      swal.fire({
        icon: 'success',
        text: "File Uploaded Successfully. You'll receive an Email Notification shortly!",
        confirmButtonColor: navyColor,
        confirmButtonText: 'OK',
      });
    }, 2000);
  }
  if (sourceName == MetaLoaderConstants.ICD_10_CM_DRGN) {
    dispatch({ type: SET_IS_LOADING, payload: true });
    fetch(dataCurationETL + apiUrl.uploadICD10CMD, {
      method: 'POST',
      body: data1,
    });
    setTimeout(() => {
      dispatch({ type: SET_IS_LOADING, payload: false });
      swal.fire({
        icon: 'success',
        text: "File Uploaded Successfully. You'll receive an Email Notification shortly!",
        confirmButtonColor: navyColor,
        confirmButtonText: 'OK',
      });
    }, 2000);
  }
  if (sourceName == MetaLoaderConstants.ADHOC_CPT_HCPCS) {
    dispatch({ type: SET_IS_LOADING, payload: true });
    fetch(dataCurationETL + apiUrl.uploadManualFile, {
      method: 'POST',
      body: data1,
    });
    setTimeout(() => {
      dispatch({ type: SET_IS_LOADING, payload: false });
      swal.fire({
        icon: 'success',
        text: "File Uploaded Successfully. You'll receive an Email Notification shortly!",
        confirmButtonColor: navyColor,
        confirmButtonText: 'OK',
      });
    }, 2000);
  }
}

export async function loadOCEDataintoTarget(
  dispatch: typeof store.dispatch,
  data
) {
  const sourceName = data.sourceName.toLowerCase().replaceAll('_', '');
  let apc = sourceName.toLowerCase();
  let capc = sourceName.toLowerCase();
  let oce = sourceName.toLowerCase();

  let apc1,
    capc1,
    oce1 = false;
  if (apc.includes('apc')) {
    apc1 = true;
    capc1 = false;
    oce1 = false;
  }
  if (capc.includes('capc')) {
    apc1 = false;
    capc1 = true;
    oce1 = false;
  }
  if (oce.includes('oceh')) {
    apc1 = false;
    capc1 = false;
    oce1 = true;
  }
  if (apc1 == true) {
    try {
      dispatch({ type: SET_IS_LOADING, payload: true });
      swal.fire({
        icon: 'info',
        text: 'Target Load in Progress. Email will be sent shortly.',
        confirmButtonColor: navyColor,
        confirmButtonText: 'OK',
      });
      await fetch(
        dataCurationETL + apiUrl.loadOCEDataintoTarget + apiUrl.apcTarget,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      )
        .then((response) => {
          if (!response.ok) {
          }
          dispatch({ type: SET_IS_LOADING, payload: false });

          return response.json();
        })
        .then((data) => {
          dispatch({ type: GET_GROUP_TASK, payload: data });
          dispatch({ type: SET_IS_LOADING, payload: false });
          swal.fire({
            icon: 'success',
            text: 'Uploaded the Target Data Successfully',
            confirmButtonColor: navyColor,
            confirmButtonText: 'OK',
          });
        });
    } catch {}
  } else if (capc1 == true) {
    try {
      dispatch({ type: SET_IS_LOADING, payload: true });
      swal.fire({
        icon: 'info',
        text: 'Target Load in Progress. Email will be sent shortly.',
        confirmButtonColor: navyColor,
        confirmButtonText: 'OK',
      });
      await fetch(
        dataCurationETL + apiUrl.loadOCEDataintoTarget + apiUrl.capcTarget,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      )
        .then((response) => {
          if (!response.ok) {
          }
          dispatch({ type: SET_IS_LOADING, payload: false });
          return response.json();
        })
        .then((data) => {
          dispatch({ type: GET_GROUP_TASK, payload: data });
          dispatch({ type: SET_IS_LOADING, payload: false });
          swal.fire({
            icon: 'success',
            text: 'Uploaded the target Data successfully:',
            confirmButtonColor: navyColor,
            confirmButtonText: 'OK',
          });
        });
    } catch {}
  } else if (oce1 == true) {
    try {
      dispatch({ type: SET_IS_LOADING, payload: true });
      swal.fire({
        icon: 'info',
        text: 'Target Load in Progress. Email will be sent shortly.',
        confirmButtonColor: navyColor,
        confirmButtonText: 'OK',
      });
      await fetch(
        dataCurationETL + apiUrl.loadOCEDataintoTarget + apiUrl.hcpcsTarget,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      )
        .then((response) => {
          if (!response.ok) {
          }
          dispatch({ type: SET_IS_LOADING, payload: false });
          return response.json();
        })
        .then((data) => {
          dispatch({ type: GET_GROUP_TASK, payload: data });
          dispatch({ type: SET_IS_LOADING, payload: false });
          swal.fire({
            icon: 'success',
            text: 'Uploaded the target Data successfully',
            confirmButtonColor: navyColor,
            confirmButtonText: 'OK',
          });
        });
    } catch {}
  }
}
export async function loadOCEDateBindedDataintoTarget(
  dispatch: typeof store.dispatch,
  data
) {
  const sourceName = data.sourceName.toLowerCase().replaceAll('_', '');
  let apc2 = sourceName.toLowerCase();
  let capc2 = sourceName.toLowerCase();
  let hcpcs2 = sourceName.toLowerCase();

  let apc1, capc1, hcpcs1;
  if (apc2.includes('apc date binded')) {
    apc1 = true;
    capc1 = false;
    hcpcs1 = false;
  }
  if (capc2.includes('capc date binded')) {
    apc1 = false;
    capc1 = true;
    hcpcs1 = false;
  }
  if (hcpcs2.includes('hcpcs')) {
    apc1 = false;
    capc1 = false;
    hcpcs1 = true;
  }
  if (apc1 == true) {
    try {
      dispatch({ type: SET_IS_LOADING, payload: true });
      swal.fire({
        icon: 'info',
        text: 'Target Load in Progress. Email will be sent shortly.',
        confirmButtonColor: navyColor,
        confirmButtonText: 'OK',
      });
      await fetch(
        dataCurationETL +
          apiUrl.loadOCEDataintoTarget +
          apiUrl.apcDateBindedDataIntoTarget,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      )
        .then((response) => {
          if (!response.ok) {
          }
          dispatch({ type: SET_IS_LOADING, payload: false });

          return response.json();
        })
        .then((data) => {
          dispatch({ type: GET_GROUP_TASK, payload: data });
          dispatch({ type: SET_IS_LOADING, payload: false });
          swal.fire({
            icon: 'success',
            text: 'Uploaded the Target Data Successfully',
            confirmButtonColor: navyColor,
            confirmButtonText: 'OK',
          });
        });
    } catch {}
  } else if (capc1 == true) {
    try {
      dispatch({ type: SET_IS_LOADING, payload: true });
      swal.fire({
        icon: 'info',
        text: 'Target Load in Progress. Email will be sent shortly.',
        confirmButtonColor: navyColor,
        confirmButtonText: 'OK',
      });
      await fetch(
        dataCurationETL +
          apiUrl.loadOCEDataintoTarget +
          apiUrl.capcDateBindedDataIntoTarget,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      )
        .then((response) => {
          if (!response.ok) {
          }
          dispatch({ type: SET_IS_LOADING, payload: false });
          return response.json();
        })
        .then((data) => {
          dispatch({ type: GET_GROUP_TASK, payload: data });
          dispatch({ type: SET_IS_LOADING, payload: false });
          swal.fire({
            icon: 'success',
            text: 'Uploaded the target Data successfully:',
            confirmButtonColor: navyColor,
            confirmButtonText: 'OK',
          });
        });
    } catch {}
  } else if (hcpcs1 == true) {
    try {
      dispatch({ type: SET_IS_LOADING, payload: true });
      swal.fire({
        icon: 'info',
        text: 'Target Load in Progress. Email will be sent shortly.',
        confirmButtonColor: navyColor,
        confirmButtonText: 'OK',
      });
      await fetch(
        dataCurationETL +
          apiUrl.loadOCEDataintoTarget +
          apiUrl.hcpcsDateBindedDataIntoTarget,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      )
        .then((response) => {
          if (!response.ok) {
          }
          dispatch({ type: SET_IS_LOADING, payload: false });

          return response.json();
        })
        .then((data) => {
          dispatch({ type: GET_GROUP_TASK, payload: data });
          dispatch({ type: SET_IS_LOADING, payload: false });
          swal.fire({
            icon: 'success',
            text: 'Uploaded the Target Data Successfully',
            confirmButtonColor: navyColor,
            confirmButtonText: 'OK',
          });
        });
    } catch {}
  }
}

export async function loadDataToTarget(
  dispatch: typeof store.dispatch,
  data,
  LoaderName
) {
  try {
    dispatch({ type: SET_IS_LOADING, payload: true });
    swal.fire({
      icon: 'info',
      text: 'Target Load in Progress. Email will be sent shortly.',
      confirmButtonColor: navyColor,
      confirmButtonText: 'OK',
    });
    let apiEndPoint = '';
    switch (LoaderName) {
      case MetaLoaderConstants.MFS:
        apiEndPoint = apiUrl.loadMFSDataintoTarget+apiUrl.mfsTarget;
        break;
      case MetaLoaderConstants.MFS_DATE_BINDED:
        apiEndPoint = apiUrl.loadMFSDataintoTarget+apiUrl.loadMFSDateBIndedDataintoTarget;
        break;
      case MetaLoaderConstants.CCI_MEDICAID_HOSPITAL:
      case MetaLoaderConstants.CCI_MEDICAID_PRACT:
      case MetaLoaderConstants.CCI_MEDICARE_HOSPITAL:
      case MetaLoaderConstants.CCI_MEDICARE_PRACT:
        apiEndPoint = apiUrl.loadCCIDataToTarget;
        break;
      case MetaLoaderConstants.DMUV_PROFESSIONAL:
      case MetaLoaderConstants.DMUV_OUTPATIENT:
      case MetaLoaderConstants.ANNUAL_MAX_UNITS:
      case MetaLoaderConstants.DMUV_PROFESSIONAL_AUTO:
      case MetaLoaderConstants.DMUV_OUTPATIENT_AUTO:
        apiEndPoint = apiUrl.loadMaxUnitsDataToTarget;
        break;
      case MetaLoaderConstants.ADHOC_CPT_HCPCS:
        apiEndPoint = apiUrl.loadAdhocintoTarget + apiUrl.adhocTarget;
        break;
      case MetaLoaderConstants.HCPCS:
        apiEndPoint = apiUrl.loadHcpcsDataToTarget;
        break;
      case MetaLoaderConstants.ICD_10_CM:
        apiEndPoint = apiUrl.loadIcdMasterAndDetailTarget;
        break;
      case MetaLoaderConstants.BW_Pairs:
        apiEndPoint = apiUrl.loadBwPairsDataIntoTarget + apiUrl.bwPairsTarget;
        break;
      case MetaLoaderConstants.CPT:
        if (LoaderName != MetaLoaderConstants.ADHOC) {
          apiEndPoint = apiUrl.CptMasterAndDetailTarget;
        }
        break;
      case MetaLoaderConstants.RBRVS:
        apiEndPoint = apiUrl.rbRvsTargetLoad;
        break;
      case MetaLoaderConstants.ICD_10_PCS:
      case MetaLoaderConstants.ICD_10_PCS_DRGN_AUTO:
        apiEndPoint = apiUrl.icd10PCSTarget;
        break;
      case MetaLoaderConstants.ICD_10_CM_DRGN:
        apiEndPoint = apiUrl.icd10CmTarget;
        break;
      case MetaLoaderConstants.ADD_ON_CODES:
      case MetaLoaderConstants.ADDON_CODE_TYPE_2:
      case MetaLoaderConstants.ADDON_CODE_TYPE_3:
        apiEndPoint = apiUrl.loadAddonDataintoTarget + apiUrl.addonTarget;
        break;
      case MetaLoaderConstants.Modifier_Interaction:
        apiEndPoint = apiUrl.loadModifierInteractionDataIntoTarget + apiUrl.ModifierInteractionTarget;
        break;
      case MetaLoaderConstants.GPCI:
        apiEndPoint = apiUrl.loadGPCIDataIntoTarget + apiUrl.GPCITarget;
        break;
      case MetaLoaderConstants.GPCI_DATE_BINDED:
        apiEndPoint = apiUrl.loadGPCIDataIntoTarget + apiUrl.GPCIDateBindedTarget;
        break;
      case MetaLoaderConstants.ZIP_5:
        apiEndPoint = apiUrl.loadZipDataIntoTarget + apiUrl.Zip5Target;
        break;
      case MetaLoaderConstants.ZIP_9:
        apiEndPoint = apiUrl.loadZipDataIntoTarget + apiUrl.Zip9Target;
        break;
      case MetaLoaderConstants.ZIP_5_DATE_BINDED:
        apiEndPoint = apiUrl.loadZipDataIntoTarget + apiUrl.Zip5DateBindedTarget;
        break;
      case MetaLoaderConstants.ZIP_9_DATE_BINDED:
        apiEndPoint = apiUrl.loadZipDataIntoTarget + apiUrl.Zip9DateBindedTarget;
        break;
      case MetaLoaderConstants.SAME_OR_SIMILAR:
        apiEndPoint = apiUrl.loadCptSameOrSimiliarCodes + apiUrl.loadCptSameOrSimCodeDataToTarget;
        break;
      default:
        apiEndPoint = null;
    }
    await fetch(dataCurationETL + apiEndPoint, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
        }
        dispatch({ type: SET_IS_LOADING, payload: false });

        return response.json();
      })
      .then((data) => {
        dispatch({ type: GET_GROUP_TASK, payload: data });
        dispatch({ type: SET_IS_LOADING, payload: false });
        swal.fire({
          icon: 'success',
          text: 'Uploaded the Target Data Successfully',
          confirmButtonColor: navyColor,
          confirmButtonText: 'OK',
        });
      });
  } catch {}
}

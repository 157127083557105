import {
  apiUrl, policyConfigUrl, policyengine,
  // ipuClaimRetrivel
} from '../../configs/apiUrls';
import { SET_IS_LOADING } from './actionTypes';
import { store } from '../store';
import { GET_DRGN_CLAIM_REVIW_DATA, GET_POLICY_CLAIM, GET_REFERENCE_POLICY_CLAIM, GET_REFERENCE_SEARCH_CLAIM, GET_REF_DRGN_CLAIM_REVIW_DATA } from './claimActionTypes';

export async function getDragonClaimId(dispatch: typeof store.dispatch, data) {
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(`${policyengine + apiUrl.getByDragonClaimId}/${data}`)
    .then((response) => {
      if (!response.ok) {
      }
      dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_DRGN_CLAIM_REVIW_DATA, payload: data });
      dispatch({ type: SET_IS_LOADING, payload: false });
      return data;
    });
}

export async function getPolicyClaim(dispatch: typeof store.dispatch, data) {
  // dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(`${policyengine + apiUrl.getPolicyClaim}/${data}`)
    .then((response) => {
      if (!response.ok) {
      }
      // dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_POLICY_CLAIM, payload: data });
      // dispatch({ type: SET_IS_LOADING, payload: false });
    });
}
export async function getRefernceClaim(dispatch: typeof store.dispatch, data) {
  // dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(`${policyengine + apiUrl.getReferenceClaim}/${data}`)
    .then((response) => {
      if (!response.ok) {
      }
      // dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_REF_DRGN_CLAIM_REVIW_DATA, payload: data });
      // dispatch({ type: SET_IS_LOADING, payload: false });
      return data;
    });
}
export async function getReferncePolicyClaim(dispatch: typeof store.dispatch, data) {
  // dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(`${policyengine + apiUrl.getReferenceDragonClaimId}/${data}`)
    .then((response) => {
      if (!response.ok) {
      }
      // dispatch({ type: SET_IS_LOADING, payload: false });
      return response.json();
    })
    .then((data) => {
      dispatch({ type: GET_REFERENCE_POLICY_CLAIM, payload: data });
      // dispatch({ type: SET_IS_LOADING, payload: false });
      return data;
    });
}

export async function searchRefClaimData(dispatch: typeof store.dispatch, data) {
  let d = [];
  dispatch({ type: SET_IS_LOADING, payload: true });
  await fetch(policyConfigUrl + apiUrl.searchClaim, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        dispatch({ type: SET_IS_LOADING, payload: false });
      }
      return response.json();
    })
    .then((refclaimData) => {
      d = refclaimData;
      dispatch({ type: SET_IS_LOADING, payload: false });
      dispatch({ type: GET_REFERENCE_SEARCH_CLAIM, payload: refclaimData });
    });
  return d;
}

import {
    GET_CAT,
    GET_GENDER,
    GET_LOB,
    GET_MAX_AGE,
    GET_MEDICAL_POLICY,
    GET_MIN_AGE,
    GET_NPI,
    GET_PROCS,
    GET_PROD_TYPE,
    GET_REASON_CODES,
    GET_SUBSPECIALITY,
    GET_SUB_POLICY,
    GET_TAX_LOGIC,
    SEARCH_POLICY,
    POST_PROCS,
    PROCS_TARGET,
    PROCS_TARGET_SPINNER,
    PROCS_DATA_TO_EXCEL,
    GET_CONFIG_REPORT_FOR_SINGLE_RULE,
    GET_POLICY_BY_ID,
    SEARCH_CLAIM,
    GET_DRGN_CHALLENGE_CODE,
    CHANGES_ISOPEN_B,
    DELTA_LINK,
    ADD_ON_CODES,
    PTP_CCI,
    CONFIG_VER,
    CRAWLER_VER,
    CURATION_VER,
    EMAIL_VER,
    ENGINE_VER,
    GET_ALL_CLAIM_TYPE,
    IGNORE_MODIFIER,
    ALLOW_CMS_NCCI_MODIFIERS,
    DIAGNOSIS_DATA_TO_EXCEL,
    GET_CLAIM_LINK_LKP,
    GET_LOB_DATA,
  } from '../actions/actionTypes';
  import {
    GET_ACTION_DATA,
    GET_DIAGNOSIS_DATA,
  } from '../actions/NewPolicyFormActionTypes';
import { GET_DETAILS_TOTAL_DATA, GET_FILTERD_POLICIES_DATA, GET_FILTERD_REASON_DATA, GET_FILTERD_SUB_POLICIES_DATA, GET_MEDICAL_TOTAL_DATA, GET_POLICY_DETAILS_FROM_REASON, GET_REASON_TREE_DATA, GET_SUB_TOTAL_DATA, GET_USED_CAT, TEMP_TOTAL } from '../actions/policyViewType';
  
  export interface PolicyViewState {
    getLobData: any[];
    getProductData: any[];
    getMedicalTotalData: any[];
    getSubTotalData: any[];
    getDetailsTotaldata: any[];
    getUsedCat: any[];
    getReasonTreeData: any[];
    getPolicyDetailsFromReason: any[];
    tempTotal:any[];
    getFilterdSubPoliciesData:any[];
    getFilterReasonData:any[];
    getFilerdPoliciesData:any[];
  }
  const initialState: PolicyViewState = {
    getLobData: [],
    getProductData: [],
    getMedicalTotalData: [],
    getSubTotalData: [],
    getDetailsTotaldata: [],
    getUsedCat: [],
    getReasonTreeData: [],
    getPolicyDetailsFromReason: [],
    tempTotal:[],
    getFilterdSubPoliciesData:[],
    getFilterReasonData:[],
    getFilerdPoliciesData:[],
  
  };
  
  export default function PolicyViewReducer(
    state = initialState,
    action: { type: string; payload: any }
  ): PolicyViewState {
    switch (action.type) {
      case GET_MEDICAL_TOTAL_DATA:
        return { ...state, getMedicalTotalData: action.payload };
      case GET_SUB_TOTAL_DATA:
        return { ...state, getSubTotalData: action.payload };
      case GET_DETAILS_TOTAL_DATA:
        return { ...state, getDetailsTotaldata: action.payload };
      case GET_USED_CAT:
        return { ...state, getUsedCat: action.payload };
      case GET_REASON_TREE_DATA:
        return { ...state, getReasonTreeData: action.payload };
      case GET_POLICY_DETAILS_FROM_REASON:
        return { ...state, getPolicyDetailsFromReason: action.payload };
        case TEMP_TOTAL:
        return { ...state, tempTotal: action.payload };
        case GET_FILTERD_SUB_POLICIES_DATA:
        return { ...state, getFilterdSubPoliciesData: action.payload };
        case GET_FILTERD_REASON_DATA:
        return { ...state, getFilterReasonData: action.payload };
        case GET_FILTERD_POLICIES_DATA:
        return { ...state, getFilerdPoliciesData: action.payload };
      default:
        return state;
    }
  }
  
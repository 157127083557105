import { useLocation } from "react-router-dom";

export const enum PolicyConstants {
  CAT_OPT_TAB = "catOpt",
  DESC_TAB = "Description",
  DETAIL_TAB = "Details",
  CHANGES_TAB = "Changes",
  CPT_HCPCS_TAB = "CPT/HCPCS",
  VIEW_POLICY = "viewPolicy",
  EDIT_POLICY = "editPolicy",
  NEW_POLICY = "newPolicy",
  REASON_CODE_LKP = "Reason Code LookUp",
  POLICY_CAT_LKP = "Policy Category LookUp",
  MEDICAL_POLICY_LKP = "Medical Policy LookUp",
  SUB_POLICY_LKP = "SubPolicy LookUp",
  ENFORCE_BEFORE_CAT_LKP = "Enforce Before Category LookUp",
  PROCEDURE_MIN_AGE_LKP = "Procedure MinAge LookUp",
  PROCEDURE_MAX_AGE_LKP = "Procedure MaxAge LookUp",
  IGNORE_MOD = "Ignore Modifier",
  ALLOW_CMS_NCCI_MOD = "Allow CMS NCCI Modifiers",
  REFER_CROSS_CLAIM_FORM = "Refer Cross Claim Form",
  TWENTY_THREE = 23,
  TWENTY_FIVE = 25,
  THIRTY_EIGHT = 38,
  THIRTY_FIVE = 35,
  TWENTY = 20,
  TWELVE = 12,
  FOURTY_FIVE = 45,
  FOURTY_SIX = 46,
  FOURTY_NINE = 49,
  THIRTY_TWO = 32,
  SEARCH = "search",
  DIAGNOSIS_TAB = "Diagnosis",
  BILL_TYPE = "BillType",
  CONDITION_TYPE = "ConditionCode",
  CLIENT_ASSIGNMENT = "ClientAssignment",
  CLAIM = "claim",
  CURRENT_LINES = "currentLines",
  HISTORY_LINES = "historyLines",
  TESTING_REPORT = "testingReport",
  Create_From_Policy_Version="Create From Policy.Version"
}
export const intialPolicyCreationFileds={
  jiraId:undefined,
  jiraDescription:undefined,
  policyAndVersion:undefined,
  addAllActiveClients:false,
  cloneClientAssignmentTab:false,
  forClonedPolicyAndVersion:undefined,
  newPolicyStartDate:undefined,
}





import { SAME_OR_SIMILAR_CODE_LKP } from "../../pages/LookUps/LookUpConsts";
import { GET_BILL_TYPE_LOOKUP,  GET_BO_LOOKUP,  GET_BW_TYPE_LOOKUP,  GET_CCI_LOOKUP,  GET_CONDITION_LOOKUP, GET_MAX_UNITS_LKP, GET_MAX_UNITS_TYPES, GET_MIN_MAX_AGE_LOOKUP, GET_MODIFIER_INTERACTION_LKP, GET_MODIFIER_INTERACTION_TYPE_LKP, GET_MODIFIER_PRIORITY_LKP, GET_MOD_LOOKUP, GET_MUE_RATIONALE_LOOKUP, GET_POLICY_CATEGORY_LOOKUP, GET_POS_LOOKUP, GET_REASON_LOOKUP, GET_REVENUE_LOOKUP, GET_SPEC_LOOKUP, GET_SUB_SPEC_LOOKUP, POST_SPEC } from "../actions/LookupActionTypes";
import { GET_MODIFIER_PAY_PERCENTAGE_LKP, MODIFIER_PAY_PERCENTAGE_DATA } from "../actions/NewPolicyFormActionTypes";

export interface LookUpState {
    specs: any[]
    subSpecs:any[]
    minMax:any[]
    revenue:any[]
    mod:any[]
    pos:any[]
    condition:any[]
    reason:any[]
    policyCategory:any[]
    billType:any[]
    postSpec :any[]
    cci:any[]
    mue:any[]
    Bo:any[]
    BwType:any[]
    MaxUnitsTypes:any[]
    MaxUnits:any[]
    ModifierPriority:any[]
    ModifierInteraction:any[]
    ModifierInteractionType:any
    SameOrSimilarCodes:any[],
    getModifierPayPercentageLkp: any[],
    ModifierPayPercentageData: any[],


}

const initialState: LookUpState = {
  specs: [],
  subSpecs: [],
  minMax: [],
  revenue: [],
  mod: [],
  pos: [],
  condition: [],
  reason: [],
  policyCategory: [],
  billType: [],
  postSpec: [],
  cci: [],
  mue: [],
  Bo: [],
  BwType:[],
  MaxUnitsTypes:[],
  MaxUnits:[],
  ModifierPriority:[],
  ModifierInteraction:[],
  ModifierInteractionType:[],
  SameOrSimilarCodes:[],
  getModifierPayPercentageLkp: [],
  ModifierPayPercentageData:[],
};

export default function LookUpReducer(
  state = initialState,
  action: { type: string; payload: any }): LookUpState {
  switch (action.type) {
    case GET_SPEC_LOOKUP:
      return { ...state, specs: action.payload };
      case GET_SUB_SPEC_LOOKUP:
      return { ...state, subSpecs: action.payload };
      case GET_REVENUE_LOOKUP:
      return { ...state, revenue: action.payload };
      case GET_MIN_MAX_AGE_LOOKUP:
      return { ...state, minMax: action.payload };
      case GET_REASON_LOOKUP:
      return { ...state, reason: action.payload };
      case GET_BILL_TYPE_LOOKUP:
      return { ...state, billType: action.payload };
      case GET_MOD_LOOKUP:
      return { ...state, mod: action.payload };
      case GET_POS_LOOKUP:
      return { ...state, pos: action.payload };
      case GET_POLICY_CATEGORY_LOOKUP:
      return { ...state, policyCategory: action.payload };
      case GET_CONDITION_LOOKUP:
      return { ...state, condition: action.payload };
      case POST_SPEC:
      return { ...state, postSpec: action.payload };
      case GET_CCI_LOOKUP:
      return { ...state, cci: action.payload };
      case GET_MUE_RATIONALE_LOOKUP:
      return {...state, mue: action.payload};
      case GET_BO_LOOKUP:
      return {...state, Bo: action.payload};
      case GET_BW_TYPE_LOOKUP:
      return {...state,BwType: action.payload};
      case GET_MAX_UNITS_TYPES:
      return {...state,MaxUnitsTypes: action.payload};
      case GET_MAX_UNITS_LKP:
      return {...state,MaxUnits: action.payload};
      case GET_MODIFIER_PRIORITY_LKP:
      return {...state,ModifierPriority: action.payload}
      case GET_MODIFIER_INTERACTION_LKP:
      return {...state,ModifierInteraction: action.payload}
      case GET_MODIFIER_INTERACTION_TYPE_LKP:
        return {...state,ModifierInteractionType: action.payload}
        case SAME_OR_SIMILAR_CODE_LKP:
          return {...state,SameOrSimilarCodes: action.payload}
          case GET_MODIFIER_PAY_PERCENTAGE_LKP:
            return{...state, getModifierPayPercentageLkp: action.payload };
            case MODIFIER_PAY_PERCENTAGE_DATA:
            return{...state, ModifierPayPercentageData: action.payload };
    default:
      return state;
  }
}
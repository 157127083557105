import { ButtonGroup, DialogContent, IconButton } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import * as FileSaver from "file-saver";
import Moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert2";
import XLSX from "xlsx";
import {
  dangerColor,
  disabledColor,
  navyColor,
} from "../../assets/jss/material-kit-react";
import CustomButton from "../../components/CustomButtons/CustomButton";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomPaper from "../../components/CustomPaper/CustomPaper";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import Dialogbox from "../../components/Dialog/DialogBox";
import GridItem from "../../components/Grid/GridItem";
import RadioButton from "../../components/RadioButton/RadioButton";
import AgGrids from "../../components/TableGrid/AgGrids";
import { uploadDiagnosisData } from "../../redux/actions";
import { DIALOG } from "../../redux/actions/actionTypes";
import {
  deleteDiagnosis1,
  Editdiag,
  getDiagnosisData,
  getPolicyAction,
  postdiag,
} from "../../redux/actions/NewPolicyAction";
import { NewPolicyFormState } from "../../redux/reducers/NewPolicyFormReducer";
import { NewPolicyState } from "../../redux/reducers/NewPolicyReducer";
import {
  AllCommunityModules,
  ModuleRegistry,
} from "@ag-grid-community/all-modules";
import { filterdReasonData } from "../../redux/actions/PolicyViewAction";
import { exportedExcelFileData } from "../../components/ExportExcel/ExportExcelFile";
const _ = require("lodash");
export interface Diagnosis {
  diagFrom: String;
  diagTo: String;
  dosFrom: Date;
  dosTo: Date;
  action: any;
  exclusion: any;
  headerLevel: any;
  principalDx: any;
  actionKeyValue: any;
}
const Diagnosis = ({ edit, policyId, showImportButton, fromViewPolicy }) => {
  const [diagData, setdiagData] = useState([]);
  const [exportData, setExportData] = useState([]);

  const [selectedData, setSelectedData] = useState<Diagnosis[]>([]);

  const [open, setOpen] = React.useState(false);
  const [openedit, setOpenedit] = React.useState(false);
  const [opendelete, setOpendelete] = React.useState(false);
  const [openHeaderPdx, setopenHeaderPdx] = React.useState(false);

  const dispatch = useDispatch();
  const [diagFromExist, setdiagFromExist] = useState(false);

  const [diagToExist, setdiagToExist] = useState(false);
  const [numberOfRows, setNumberOfRows] = useState(0);
  const [createDate, setCreateDate] = useState("");
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [fileError, setFileError] = useState(false);
  const [updatedData, setUpdatedData] = useState([]);

  const formState: NewPolicyFormState = useSelector(
    (state: any) => state.newPolicyForm
  );

  function ProcsData() {
    setOpen(true);
    setSelectedFile(undefined);
  }

  const handleUploadFile = (file) => {
    var allowedFiles = [".xlsx", ".csv"];
    var regex = new RegExp(
      "([a-zA-Z0-9s_\\.-:()])+(" + allowedFiles.join("|") + ")$"
    );
    if (file != undefined) {
      if (!regex.test(file.name.toLowerCase())) {
        setOpen(false);
        swal.fire({
          icon: "error",
          text: "Please upload valid file",
          confirmButtonColor: navyColor,
          confirmButtonText: "OK",
        });
        return false;
      } else {
        return true;
      }
    }
  };

  const onFileUpload = async () => {
    const formData: FormData = new FormData();
    formData.append("uploadfile", selectedFile);
    let validation = handleUploadFile(selectedFile);
    if (validation) {
      let user = formState.userDetails;
      formData.append("emailId", user.emailId);
      let fileReader = new FileReader();
      fileReader.readAsArrayBuffer(selectedFile);
      fileReader.onload = async (e) => {
        let arrayBuffer: any = [];
        arrayBuffer = fileReader.result;
        let data = new Uint8Array(arrayBuffer);
        let arr = new Array();
        for (var i = 0; i != data.length; ++i)
          arr[i] = String.fromCharCode(data[i]);
        let bstr = arr.join("");
        let workbook = XLSX.read(bstr, { type: "binary" });
        let first_sheet_name = workbook.SheetNames[0];
        let worksheet = workbook.Sheets[first_sheet_name];
        var arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        let array: any = [];
        let exportarray: any = [];
        array = exportarray.concat(arraylist);
        let uniqueCodes = [];
        let error = false;
        array.forEach((o, i) => {
          if (!checkIsPresent(uniqueCodes, o.DIAGFROM + "-" + o.DIAGTO)) {
            uniqueCodes.push(o.DIAGFROM + "-" + o.DIAGTO);
          } else {
            setOpen(false);
            error = true;
            swal.fire({
              icon: "error",
              title: "Error",
              text:
                "Duplicate Row at " +
                (i + 2) +
                " - " +
                "Dx From is : " +
                o.DIAGFROM +
                " and " +
                "Dx To is : " +
                o.DIAGTO,
              confirmButtonColor: navyColor,
              confirmButtonText: "OK",
            });
          }
        });
        let importPolicyId = undefined;
        array.forEach((d) => {
          if (formState.policyId != d.POLICYID) {
            importPolicyId = d.POLICYID;
            if (d.POLICYID == undefined) {
              importPolicyId = -1;
            }
          } else if (importPolicyId == undefined) {
            importPolicyId = policyId;
          }
        });
        if (importPolicyId == policyId) {
          if (!error) {
            setOpen(false);
            await uploadDiagnosisData(dispatch, formData);
            getDiagnosisData(dispatch, formState.policyId);
          }
        } else {
          setOpen(false);
          if (importPolicyId == -1) {
            swal.fire({
              icon: "error",
              title: "Error",
              text: "Policy Id is blank.",
              confirmButtonColor: navyColor,
              confirmButtonText: "OK",
            });
          } else {
            swal.fire({
              icon: "error",
              title: "Error",
              text: "Policy Id not matched.",
              confirmButtonColor: navyColor,
              confirmButtonText: "OK",
            });
          }
        }
      };
    }
  };

  function checkIsPresent(uniqueCodes, value) {
    const index = uniqueCodes.indexOf(value);
    return index >= 0;
  }

  const onSelectionChanged = async (event) => {
    let a = event.api.getSelectedRows();
    setSelectedData(a);

    return event.api.getSelectedRows().length > 0
      ? setopenHeaderPdx(true)
      : undefined;
  };

  const updatedState: NewPolicyState = useSelector(
    (state: any) => state.newPolicy
  );

  const DiagnosisColums = {
    policyDiagnosisKey: undefined,
    policyId: undefined,
    diagFrom: undefined,
    diagTo: undefined,
    dosFrom: undefined,
    dosTo: undefined,
    action: undefined,
    exclusion: 0,
    headerLevel: 0,
    principalDx: 0,
    actionKeyValue: undefined,
  };
  const [Diagnosis, setDiagnosis] = useState(DiagnosisColums);
  const [isEdit, setIsEdit] = useState(false);
  const [deletedById, setDeletedById] = useState(undefined);
  const [actionValue, setActionValue] = useState(undefined);

  const [action, setAction] = useState("");
  const [checkSameDiag, setCheckSameDiag] = useState("");
  const [checkSameDiagTo, setCheckSameDiagTo] = useState("");

  const [updatedDiagsData, setUpdatedDiagsData] = useState(false);

  const columnDefs = [
    {
      field: "diagFrom",
      headerName: "Dx From",
      minWidth: 100,
      headerTooltip: "Diag From",
    },
    {
      field: "diagTo",
      headerName: "Dx To",
      minWidth: 100,
      headerTooltip: "Diag To",
    },
    {
      field: "dosFrom",
      headerName: "Dos From",
      minWidth: 150,
      headerTooltip: "Dos From",
    },
    {
      field: "dosTo",
      headerName: "Dos To",
      minWidth: 150,
      headerTooltip: "Dos To",
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 100,
      headerTooltip: "Action",
    },

    {
      field: "exclusion",
      headerTooltip: "Exclusion",
      headerName: "Exclusion",
      minWidth: 60,
      cellStyle: (isFlag) =>
        isFlag.value == "1"
          ? { "pointer-events": "none" }
          : { "pointer-events": "none" },

      cellRenderer: (data) => {
        return (
          <input
            style={{ width: 15, height: 15 }}
            type="checkbox"
            disabled
            checked={data.value == 1 ? true : false}
          />
        );
      },
    },

    {
      field: "headerLevel",
      headerName: "Header Level",
      minWidth: 130,
      headerTooltip: "Header Level",
      cellStyle: (isFlag) =>
        isFlag.value == "1"
          ? { "pointer-events": "none" }
          : { "pointer-events": "none" },

      cellRenderer: (data) => {
        return (
          <input
            style={{ width: 15, height: 15 }}
            type="checkbox"
            disabled
            checked={data.value == 1 ? true : false}
          />
        );
      },
    },
    {
      field: "principalDx",
      headerName: "Principal Dx",
      minWidth: 130,
      headerTooltip: "Principal Dx",
      cellStyle: (isFlag) =>
        isFlag.value == "1"
          ? { "pointer-events": "none" }
          : { "pointer-events": "none" },

      cellRenderer: (data) => {
        return (
          <input
            style={{ width: 15, height: 15 }}
            type="checkbox"
            disabled
            checked={data.value == 1 ? true : false}
          />
        );
      },
    },

    {
      field: "button",
      headerName: "",
      minWidth: 170,
      resizable: false,
      cellRenderer: (row) => {
        return (
          <ButtonGroup>
            <CustomButton
              variant="contained"
              style={{
                height: 18,
                marginTop: 1,
                fontSize: "11px",
                textTransform: "capitalize",
                backgroundColor: navyColor,
                color: "white",
                opacity: fromViewPolicy ? 0.7 : 1,
              }}
              onClick={() => {
                let obj = _.cloneDeep(Diagnosis);
                obj.policyDiagnosisKey = row.data.policyDiagnosisKey;
                obj.diagFrom = row.data.diagFrom;
                obj.diagTo = row.data.diagTo;
                obj.dosFrom = row.data.dosFrom
                  ? Moment(row.data.dosFrom).format("YYYY-MM-DD")
                  : "";
                obj.dosTo = row.data.dosTo
                  ? Moment(row.data.dosTo).format("YYYY-MM-DD")
                  : "";
                obj.action = mapAction(row.data.action);
                obj.exclusion = row.data.exclusion;
                obj.headerLevel = row.data.headerLevel;
                obj.principalDx = row.data.principalDx;
                obj.actionKeyValue = {
                  label: row.data.action,
                  value: mapAction(row.data.action),
                };
                setAction(obj.action);
                setDiagnosis(obj);
                setCheckSameDiag(row.data.diagFrom);
                setCheckSameDiagTo(row.data.diagTo);
                setIsEdit(true);
                if (obj != null) {
                  setopenHeaderPdx(false);
                  editData();
                }
              }}
            >
              Edit
            </CustomButton>

            <CustomButton
              variant="contained"
              style={{
                height: 18,
                marginTop: 1,
                marginLeft: 3,
                fontSize: "11px",
                textTransform: "capitalize",
                backgroundColor: dangerColor,
                color: "white",
                opacity: fromViewPolicy ? 0.7 : 1,
              }}
              onClick={() => {
                let obj = _.cloneDeep(Diagnosis);
                obj.policyDiagnosisKey = JSON.stringify(
                  row.data.policyDiagnosisKey
                );
                setDeletedById(row.data.policyDiagnosisKey);
                deleteData();
              }}
            >
              Delete
            </CustomButton>
          </ButtonGroup>
        );
      },
    },
  ];
  const DeletedMethod = () => {
    setUpdatedDiagsData(false);
    deleteDiagnosis1(dispatch, deletedById, policyId);
    setOpendelete(false);
  };

  const handleClickToOpen = () => {
    setdiagFromExist(false);
    setdiagToExist(false);
    setOpenedit(false);
    setCheckSameDiag("");
    setCheckSameDiagTo("");
    setUpdatedDiagsData(true);
  };
  const handleClickToOpenEdit = () => {
    setOpenedit(true);
    setUpdatedDiagsData(false);
  };

  const handleClickToCloseEdit = () => {
    setOpenedit(false);
    setOpendelete(false);
    setopenHeaderPdx(false);
    setCheckSameDiag("");
    setCheckSameDiagTo("");
    setdiagFromExist(false);
    setUpdatedDiagsData(true);
  };
  const handleClickToOpendelete = () => {
    setOpendelete(true);
  };

  function editData() {
    handleClickToOpenEdit();
  }

  function deleteData() {
    handleClickToOpendelete();
  }

  function resetLookup() {
    setDiagnosis(DiagnosisColums);
  }

  const handleToClose = () => {
    setOpen(false);
    setOpenedit(false);
    setOpendelete(false);
    setopenHeaderPdx(false);
    setCheckSameDiag("");
    setCheckSameDiagTo("");
    setdiagFromExist(false);
  };

  let id = formState.policyId;

  useEffect(() => {
    if (id != null) {
      getDiagnosisData(dispatch, id);
    }
  }, []);

  useEffect(() => {
    setUpdatedData(updatedState.dxDataToExcel);
  }, [updatedState]);

  useEffect(() => {
    getPolicyAction(dispatch);
  }, []);

  const actionData = formState.actionData?.map((ad) => {
    return {
      label: ad.policyDiagnosisActionCode,
      value: ad.policyDiagnosisActionKey,
    };
  });
  const mapActionValue = (value) => {
    let v;
    formState.actionData?.map((val) => {
      if (val.policyDiagnosisActionKey == value) {
        v = val.policyDiagnosisActionCode;
      }
    });
    return v;
  };

  const gridRef = useRef();
  let exportedDiagsData;
  useEffect(() => {
    if (updatedState.diagnosisData) {
      let data;
      data = updatedState.diagnosisData.map((d, i) => {
        return {
          policyDiagnosisKey: d.policyDiagnosisKey,
          policyId: d.policyId,
          diagFrom:
            d.diagFrom.length > 3
              ? d.diagFrom.substring(0, 3) + "." + d.diagFrom.substring(3)
              : d.diagFrom,
          diagTo:
            d.diagTo.length > 3
              ? d.diagTo.substring(0, 3) + "." + d.diagTo.substring(3)
              : d.diagTo,
          dosFrom: d.dosFrom ? Moment(d.dosFrom).format("MM-DD-YYYY") : "",
          dosTo: d.dosTo ? Moment(d.dosTo).format("MM-DD-YYYY") : "",
          action: mapActionValue(d.action),
          exclusion: d.exclusion,
          headerLevel: d.headerLevel,
          principalDx: d.principalDx,
        };
      });
      setdiagData(data);
      setNumberOfRows(
        updatedState.diagnosisData ? updatedState.diagnosisData.length : 0
      );
      setUpdatedDiagsData(true);

      exportedDiagsData = updatedState.diagnosisData.map((d, i) => {
        return {
          policyId: d.policyId,
          diagFrom: d.diagFrom,
          diagTo: d.diagTo,
          dosFrom: d.dosFrom ? Moment(d.dosFrom).format("YYYY-MM-DD") : "",
          dosTo: d.dosTo ? Moment(d.dosTo).format("YYYY-MM-DD") : "",
          action: d.action,
          exclusion: d.exclusion,
          headerLevel: d.headerLevel,
          principalDx: d.principalDx,
        };
      });

      setExportData(exportedDiagsData);
    }
  }, [updatedState.diagnosisData]);

  const onFilterChanged = (params) => {
    setNumberOfRows(params.api.getDisplayedRowCount());
  };

  let checkFileds = false;

  const [fieldError, setFiledError] = useState(false);

  function check() {
    if (
      Diagnosis.diagFrom == undefined ||
      Diagnosis.diagTo == undefined ||
      Diagnosis.dosFrom == undefined ||
      Diagnosis.dosTo == undefined ||
      Diagnosis.action == undefined
    ) {
      checkFileds = true;
      setFiledError(true);
    }
    if (Diagnosis.diagFrom == undefined || Diagnosis.diagFrom == "") {
      checkFileds = true;
      setFiledError(true);
    }
    if (Diagnosis.diagTo == undefined || Diagnosis.diagTo == "") {
      checkFileds = true;
      setFiledError(true);
    }
    if (Diagnosis.dosFrom == undefined || Diagnosis.dosFrom == "") {
      checkFileds = true;
      setFiledError(true);
    }
    if (Diagnosis.dosTo == undefined || Diagnosis.dosTo == "") {
      checkFileds = true;
      setFiledError(true);
    }
    if (Diagnosis.action == undefined || Diagnosis.action == "") {
      checkFileds = true;
      setFiledError(true);
    }
    return checkFileds;
  }
  async function saveDiag() {
    setUpdatedDiagsData(false);
    let policyid = policyId;
    let obj = {};
    let error = check();

    if (error) {
      dispatch({
        type: DIALOG,
        payload: true,
        title: "Error",
        message: "Please fill in required fields",
      });
    } else {
      Diagnosis.policyId = parseInt(policyid);
      Object.entries(Diagnosis).forEach(
        ([key, val]) => (obj[key] = val?.toString())
      );
      if (!isEdit) {
        setFiledError(false);
        postdiag(dispatch, obj);
      } else {
        setFiledError(false);
        Editdiag(dispatch, obj, policyId);
      }
      setOpenedit(false);
    }
  }

  function stringMethod1(e) {
    const re = /^[a-zA-Z0-9.]+$/;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  function mapAction(data) {
    let diagnosis = actionData.find((a) => a.label === data);
    if (diagnosis !== undefined) {
      return diagnosis.value;
    }
  }
  function checkValid() {
    let code = false;
    updatedState.diagnosisData.find((sc) => {
      if (sc.diagFrom == Diagnosis.diagFrom) {
        if (checkSameDiag != Diagnosis.diagFrom) {
          code = true;
        }
      }
    });
    return code;
  }

  function checkToValid() {
    let code = false;
    updatedState.diagnosisData.find((sc) => {
      if (sc.diagTo == Diagnosis.diagTo) {
        if (checkSameDiagTo != Diagnosis.diagTo) {
          code = true;
        }
      }
    });
    return code;
  }

  function stringToDateFormat(date) {
    let formattedDate = "";
    const newDate = new Date(date);
    if (
      newDate.getFullYear != undefined &&
      newDate.getDate != undefined &&
      newDate.getMonth != undefined
    ) {
      formattedDate = Moment(newDate).format("YYYY-MM-DD");
    }
    return formattedDate;
  }

  ModuleRegistry.registerModules(AllCommunityModules);

  const onGridReady = async (params) => {
    gridRef.current = params.api;

    const dataSource = {
      rowCount: null,
      getRows: async (params) => {
        let rows = [];
        let sortType = "";
        let sortableColumn = "";
        let Diagnosis = {};
        if (!(params.filterModel == null || undefined)) {
          Diagnosis = {
            diagFrom: params.filterModel.diagFrom
              ? params.filterModel.diagFrom.filter
              : "",
            diagTo: params.filterModel.diagTo
              ? params.filterModel.diagTo.filter
              : "",
            dosFrom: params.filterModel.dosFrom
              ? stringToDateFormat(params.filterModel.dosFrom.filter)
              : "",
            dosTo: params.filterModel.dosTo
              ? stringToDateFormat(params.filterModel.dosTo.filter)
              : "",
            action: params.filterModel.action
              ? params.filterModel.action.filter
              : "",
            exclusion: params.filterModel.exclusion
              ? params.filterModel.exclusion.filter
              : "",
            headerLevel: params.filterModel.headerLevel
              ? params.filterModel.headerLevel.filter
              : "",
            principalDx: params.filterModel.principalDx
              ? params.filterModel.principalDx.filter
              : "",
            isSort: sortType != "" ? sortType : "",
            sortColumn: sortableColumn != "" ? sortableColumn : "",
          };
        }

        let filteredData = [];
        let StringData = [];
        let IntegerData = [];
        if (filteredData.length == 0) {
          filteredData = diagData;
        }

        if (
          Diagnosis["diagFrom"].length > 0 ||
          Diagnosis["diagTo"].length > 0 ||
          Diagnosis["action"].length > 0
        ) {
          StringData = checkFilterDiagsData(Diagnosis);
          filteredData = StringData;
        }
        if (
          Diagnosis["exclusion"].length > 0 ||
          Diagnosis["headerLevel"].length > 0 ||
          Diagnosis["principalDx"].length > 0
        ) {
          IntegerData = checkIntegerData(Diagnosis);
          filteredData = IntegerData;
        }
        let dosData = [];
        if (Diagnosis["dosFrom"].length > 0 || Diagnosis["dosTo"].length > 0) {
          dosData = checkFilterdDOSData(Diagnosis);
          filteredData = dosData;
        }

        if (filteredData.length == 0) {
          swal.fire({
            icon: "info",
            text: "No data found.",
            confirmButtonColor: navyColor,
            confirmButtonText: "OK",
          });
        }

        exportedDiagsData = filteredData.map((d, i) => {
          return {
            policyId: d.policyId,
            diagFrom: d.diagFrom,
            diagTo: d.diagTo,
            dosFrom: d.dosFrom ? Moment(d.dosFrom).format("YYYY-MM-DD") : "",
            dosTo: d.dosTo ? Moment(d.dosTo).format("YYYY-MM-DD") : "",
            action: d.action,
            exclusion: d.exclusion,
            headerLevel: d.headerLevel,
            principalDx: d.principalDx,
          };
        });
        setExportData(exportedDiagsData);

        setNumberOfRows(filteredData.length);
        setTimeout(() => {
          const rowsThisPage = filteredData.slice(
            params.startRow,
            params.endRow
          );
          let lastRow = -1;
          if (filteredData.length <= params.endRow) {
            lastRow = filteredData.length;
          }
          params.successCallback(rowsThisPage, lastRow);
        }, 500);
      },
    };
    params.api.setDatasource(dataSource);
  };

  const checkFilterDiagsData = (Diags) => {
    let DxFromValues =
      Diags["diagFrom"] != ""
        ? Diags["diagFrom"].split(/,(?=\S)|,$/).filter(Boolean)
        : Diags["diagFrom"].split(",");
    let DxToValues =
      Diags["diagTo"] != ""
        ? Diags["diagTo"].split(/,(?=\S)|,$/).filter(Boolean)
        : Diags["diagTo"].split(",");

    let filteredDiagsData = [];
    let j = updatedState.diagnosisData.filter((d, i) => {
      if (
        DxFromValues.some((value) =>
          d.diagFrom
            .toLowerCase()
            .includes(value.toLowerCase().replace(".", ""))
        ) &&
        DxToValues.some((value) =>
          d.diagTo.toLowerCase().includes(value.toLowerCase().replace(".", ""))
        ) &&
        mapActionValue(d.action).includes(Diags["action"])
      ) {
        filteredDiagsData.push({
          policyDiagnosisKey: d.policyDiagnosisKey,
          policyId: d.policyId,
          diagFrom:
            d.diagFrom.length > 3
              ? d.diagFrom.substring(0, 3) + "." + d.diagFrom.substring(3)
              : d.diagFrom,
          diagTo:
            d.diagTo.length > 3
              ? d.diagTo.substring(0, 3) + "." + d.diagTo.substring(3)
              : d.diagTo,
          dosFrom: d.dosFrom ? Moment(d.dosFrom).format("MM-DD-YYYY") : "",
          dosTo: d.dosTo ? Moment(d.dosTo).format("MM-DD-YYYY") : "",
          action: mapActionValue(d.action),
          exclusion: d.exclusion,
          headerLevel: d.headerLevel,
          principalDx: d.principalDx,
        });
      }
    });
    return filteredDiagsData;
  };
  const checkIntegerData = (days) => {
    let j = updatedState.diagnosisData.filter((f, i) => {
      if (days["exclusion"].length > 0) {
        if (f.exclusion == days["exclusion"]) {
          return { f };
        }
      }
      if (days["headerLevel"].length > 0) {
        if (f.headerLevel == days["headerLevel"]) {
          return { f };
        }
      }
      if (days["principalDx"].length > 0) {
        if (f.principalDx == days["principalDx"]) {
          return { f };
        }
      }
    });
    return j;
  };

  const checkFilterdDOSData = (dos) => {
    let j = updatedState.diagnosisData.filter((f, i) => {
      if (dos["dosFrom"] != "") {
        if (
          Moment(f.dosFrom).format("MM-DD-YYYY") ==
          Moment(dos["dosFrom"]).format("MM-DD-YYYY")
        ) {
          return { f };
        }
      }
      if (dos["dosTo"] != "") {
        if (
          Moment(f.dosTo).format("MM-DD-YYYY") ==
          Moment(dos["dosTo"]).format("MM-DD-YYYY")
        ) {
          return { f };
        }
      }
    });
    return j;
  };

  return edit ? (
    <div style={{ height: window.innerHeight / 2.2 }}>
      <CustomPaper
        style={{
          paddingLeft: 10,
          position: "relative",
          right: 20,
          paddingRight: 8,
          paddingTop: 0,
          paddingBottom: 15,
          boxShadow: "none",
          border: diagData.length > 0 ? "1px solid #D6D8DA" : "",
        }}
      >
        <IconButton
          onClick={() => {
            editData();
            setIsEdit(false);
            resetLookup();
          }}
          style={{
            backgroundColor: navyColor,
            float: "right",
            color: "white",
            padding: 5,
            marginTop: 2,
          }}
        >
          <Add />
        </IconButton>

        {diagData.length > 0 && updatedDiagsData == true ? (
          <div
            style={{
              height: window.innerHeight / 2.2,
              opacity: fromViewPolicy ? 0.7 : 1,
            }}
          >
            <AgGrids
              ref={gridRef}
              columnDefs={columnDefs}
              onFilterChanged={onFilterChanged}
              onGridReady={onGridReady}
              maxConcurrentDatasourceRequests={1}
              modules={AllCommunityModules}
              cacheOverflowSize={2}
              infiniteInitialRowCount={1}
              cacheBlockSize={1000}
              maxBlocksInCache={1}
              debug={true}
              rowBuffer={0}
              animateRows={true}
              rowModelType={"infinite"}
            />
          </div>
        ) : undefined}
      </CustomPaper>
      {diagData.length > 0 ? (
        <small style={{ position: "relative", fontSize: "12px" }}>
          Number of rows : {numberOfRows}
        </small>
      ) : undefined}
      <div
        style={{
          float: "right",
          top: diagData.length > 0 ? 5 : -9,
          position: "relative",
          right: 25,
        }}
      >
        <CustomButton
          onClick={() => {
            ProcsData();
          }}
          variant={"contained"}
          disabled={!showImportButton}
          style={{
            backgroundColor: !showImportButton ? disabledColor : navyColor,
            color: "white",
            padding: 3,
            fontSize: 12,
            textTransform: "capitalize",
            marginLeft: 10,
          }}
        >
          Import
        </CustomButton>
        {diagData.length > 0 ? (
          <CustomButton
            onClick={() => {
              exportedExcelFileData(
                exportData,
                formState.policy + "/" + formState.version,
                "Diagnosis"
              );
            }}
            variant={"contained"}
            style={{
              backgroundColor: navyColor,
              color: "white",
              padding: 3,
              fontSize: 12,
              textTransform: "capitalize",
              marginLeft: 10,
            }}
          >
            Export
          </CustomButton>
        ) : undefined}
      </div>

      <Dialogbox
        open={fromViewPolicy ? false : openedit}
        onClose={handleToClose}
        style={{ overflowY: "scroll" }}
        disableBackdropClick={true}
        title={isEdit ? "Edit Diagnosis Data" : "Add Diagnosis Data"}
        message={
          <DialogContent>
            <GridItem sm={12} md={12} xs={12}>
              <CustomInput
                fullWidth={true}
                error={
                  Diagnosis.diagFrom == undefined || Diagnosis.diagFrom == ""
                    ? fieldError
                    : false
                }
                labelText={"Dx From"}
                showStarIcon={true}
                onKeyPress={(e) => {
                  stringMethod1(e);
                }}
                variant={"outlined"}
                maxLength={8}
                onBlur={(e) => {
                  if (e.target.value.length != 0) {
                    if (checkValid()) {
                      setdiagFromExist(true);
                    } else {
                      setdiagFromExist(false);
                    }
                  }
                }}
                onChange={(e) => {
                  let obj = _.cloneDeep(Diagnosis);
                  obj.diagFrom = e.target.value;
                  setDiagnosis(obj);
                }}
                value={isEdit ? Diagnosis.diagFrom : undefined}
              />

              {diagFromExist ? (
                <small style={{ color: "red" }}>Diag From already Exists</small>
              ) : undefined}
              <CustomInput
                fullWidth={true}
                error={
                  Diagnosis.diagTo == undefined || Diagnosis.diagTo == ""
                    ? fieldError
                    : false
                }
                labelText={"Dx TO"}
                showStarIcon={true}
                onKeyPress={(e) => {
                  stringMethod1(e);
                }}
                variant={"outlined"}
                maxLength={8}
                onBlur={(e) => {
                  if (e.target.value.length != 0) {
                    if (checkToValid()) {
                      setdiagToExist(true);
                    } else {
                      setdiagToExist(false);
                    }
                  }
                }}
                onChange={(e) => {
                  let obj = _.cloneDeep(Diagnosis);

                  obj.diagTo = e.target.value;
                  setDiagnosis(obj);
                }}
                value={isEdit ? Diagnosis.diagTo : undefined}
              />
              {diagToExist ? (
                <small style={{ color: "red" }}>Diag To already Exists</small>
              ) : undefined}
              <CustomInput
                id="date"
                error={
                  Diagnosis.dosFrom == undefined || Diagnosis.dosFrom == ""
                    ? fieldError
                    : false
                }
                type="date"
                labelText={"Dos From"}
                showStarIcon={true}
                variant={"outlined"}
                onChange={(e) => {
                  let obj = _.cloneDeep(Diagnosis);
                  obj.dosFrom = e.target.value;
                  setDiagnosis(obj);
                  setCreateDate(e.target.value);
                }}
                value={
                  isEdit
                    ? Moment(Diagnosis.dosFrom).format("YYYY-MM-DD")
                    : undefined
                }
              />
              <CustomInput
                id="date"
                type="date"
                error={
                  Diagnosis.dosTo == undefined || Diagnosis.dosTo == ""
                    ? fieldError
                    : false
                }
                labelText={"Dos To"}
                showStarIcon={true}
                variant={"outlined"}
                value={
                  isEdit
                    ? Moment(Diagnosis.dosTo).format("YYYY-MM-DD")
                    : undefined
                }
                onChange={(e) => {
                  let obj = _.cloneDeep(Diagnosis);
                  obj.dosTo = e.target.value;
                  setDiagnosis(obj);
                  setCreateDate(e.target.value);
                }}
              />
              <div className="view">
                <div
                  style={{
                    width: 150,
                    marginLeft: 25,
                    marginBottom: 5,
                    marginTop: -3,
                  }}
                >
                  <CustomSelect
                  labelText = {"Action"}
                  showStarIcon={true}
                    options={actionData}
                    error={
                      Diagnosis.action == undefined || Diagnosis.action == ""
                        ? fieldError
                        : false
                    }
                    onSelect={(e) => {
                      if (e != null) {
                        let obj = _.cloneDeep(Diagnosis);
                        obj.action = e.value;
                        obj.actionKeyValue = e;
                        setDiagnosis(obj);
                      }
                      if (isEdit) {
                        let obj = _.cloneDeep(Diagnosis);
                        obj.action = e.value;
                        obj.actionKeyValue = e;
                        setDiagnosis(obj);
                      }
                    }}
                    value={Diagnosis.actionKeyValue}
                  />
                </div>
              </div>

              <div className="height"></div>
              <span>Exclusion ?</span>

              <RadioButton
                label={"Yes"}
                checked={Diagnosis.exclusion == 1 ? true : false}
                onChange={(e) => {
                  let obj = _.cloneDeep(Diagnosis);
                  if (e.target.checked) {
                    obj.exclusion = 1;
                  }
                  Diagnosis.exclusion = obj;
                  setDiagnosis(obj);
                }}
              />
              <RadioButton
                label={"No"}
                checked={Diagnosis.exclusion == 0 ? true : false}
                onChange={(e) => {
                  let obj = _.cloneDeep(Diagnosis);
                  if (e.target.checked) {
                    obj.exclusion = 0;
                  }
                  Diagnosis.exclusion = obj;
                  setDiagnosis(obj);
                }}
              />

              <div className="height"></div>
              <span>Header Level ?</span>

              <RadioButton
                label={"Yes"}
                checked={Diagnosis.headerLevel == 1 ? true : false}
                onChange={(e) => {
                  let obj = _.cloneDeep(Diagnosis);
                  if (e.target.checked) {
                    obj.headerLevel = 1;
                  }
                  Diagnosis.headerLevel = obj;
                  setDiagnosis(obj);
                }}
              />
              <RadioButton
                label={"No"}
                checked={Diagnosis.headerLevel == 0 ? true : false}
                onChange={(e) => {
                  let obj = _.cloneDeep(Diagnosis);
                  if (e.target.checked) {
                    obj.headerLevel = 0;
                  }
                  Diagnosis.headerLevel = obj;
                  setDiagnosis(obj);
                }}
              />

              <div className="height"></div>
              <span>principal_dx ?</span>

              <RadioButton
                label={"Yes"}
                checked={Diagnosis.principalDx == 1 ? true : false}
                onChange={(e) => {
                  let obj = _.cloneDeep(Diagnosis);
                  if (e.target.checked) {
                    obj.principalDx = 1;
                  }
                  Diagnosis.principalDx = obj;
                  setDiagnosis(obj);
                }}
              />
              <RadioButton
                label={"No"}
                checked={Diagnosis.principalDx == 0 ? true : false}
                onChange={(e) => {
                  let obj = _.cloneDeep(Diagnosis);
                  if (e.target.checked) {
                    obj.principalDx = 0;
                  }
                  Diagnosis.principalDx = obj;
                  setDiagnosis(obj);
                }}
              />
            </GridItem>

            <ButtonGroup>
              <CustomButton
                onClick={saveDiag}
                disabled={diagFromExist == true || diagToExist == true}
                style={{
                  backgroundColor:
                    diagFromExist == true || diagToExist == true
                      ? disabledColor
                      : navyColor,
                  color: "white",
                  margin: 10,
                  padding: 4,
                  fontSize: 12,
                  textTransform: "capitalize",
                }}
              >
                save
              </CustomButton>
              <CustomButton
                onClick={() => {
                  handleClickToOpen();
                }}
                style={{
                  backgroundColor: dangerColor,
                  color: "white",
                  margin: 10,
                  padding: 4,
                  fontSize: 12,
                  textTransform: "capitalize",
                }}
              >
                cancel
              </CustomButton>
            </ButtonGroup>
          </DialogContent>
        }
      />
      <Dialogbox
        open={fromViewPolicy ? false : opendelete}
        onClose={handleToClose}
        disableBackdropClick={true}
        title={"Confirm"}
        message={"Would you like to Delete This Record ?"}
        actions={
          <ButtonGroup>
            <CustomButton
              onClick={DeletedMethod}
              style={{
                backgroundColor: navyColor,
                color: "white",
                marginRight: 10,
                padding: 4,
                fontSize: 12,
                textTransform: "capitalize",
              }}
            >
              Yes
            </CustomButton>
            <CustomButton
              onClick={handleClickToCloseEdit}
              style={{
                backgroundColor: dangerColor,
                color: "white",
                padding: 4,
                fontSize: 12,
                textTransform: "capitalize",
              }}
            >
              No
            </CustomButton>
          </ButtonGroup>
        }
      />
      <Dialogbox
        open={open}
        onClose={handleToClose}
        title={"Upload Source File"}
        message={
          <input
            type="file"
            accept=".xlsx"
            onChange={(event) => {
              let file = event.target.files[0];
              setSelectedFile(file);
              let flag = handleUploadFile(event.target.files[0]);
              setFileError(flag);
            }}
          />
        }
        actions={
          <ButtonGroup>
            {selectedFile ? (
              <CustomButton
                onClick={() => {
                  onFileUpload();
                }}
                style={{
                  backgroundColor: navyColor,
                  color: "white",
                  marginRight: 10,
                  padding: 4,
                  fontSize: 12,
                  textTransform: "capitalize",
                }}
              >
                upload
              </CustomButton>
            ) : undefined}
            <CustomButton
              onClick={handleToClose}
              style={{
                backgroundColor: dangerColor,
                color: "white",
                textTransform: "capitalize",
                fontSize: 12,
                padding: 4,
              }}
            >
              cancel
            </CustomButton>
          </ButtonGroup>
        }
      />
    </div>
  ) : (
    <></>
  );
};

export default Diagnosis;

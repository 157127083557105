import XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const fileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

export const exportedExcelFileData = (inputData, fileName, sheetType) => {
  let objectKeysToLowerCase = function (input) {
    if (typeof input !== 'object') return input;
    if (Array.isArray(input)) return input.map(objectKeysToLowerCase);
    return Object.keys(input).reduce(function (newObj, key) {
      let val = input[key];
      let newVal =
        typeof val === 'object' && val !== null
          ? objectKeysToLowerCase(val)
          : val;
      newObj[key.toLocaleUpperCase()] = newVal;
      return newObj;
    }, {});
  };
  let newObj = objectKeysToLowerCase(inputData);

  const ws = XLSX.utils.json_to_sheet(newObj);
  const wb = {
    Sheets: { [sheetType.toString()]: ws },
    SheetNames: [sheetType.toString()],
  };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export const specLkpInitialState = {
    specCode: undefined,
    specDesc: undefined
}


export const subSpecInitialState = {
    specCode: undefined,
    subSpecCode: undefined,
    subSpecDesc: undefined,
    cmsSpecialityCode:undefined,
    taxonomyCode:undefined,
    miscB: undefined
}

export const MinMaxAgeInitialState = {
    minMaxAgeDesc: undefined,
    ageYears: undefined,
    ageMonths: undefined,
    ageDays: undefined,
    equalsB: undefined,
    minVsMaxB: undefined
}

export const revenueCodeLkpInitialState = {
    revCode: undefined,
    revDesc: undefined
}

export const billTypeInitialState = {
    billType: undefined,
    billTypeDesc: undefined,
    inpatientB: undefined,
    startDate: undefined,
    endDate: undefined,
    claimTypeMatch:undefined
}

export const conditionLkpInitialState = {
    condCode: undefined,
    condDesc: undefined,
    startDate: undefined,
    endDate: undefined
}

export const modLkpInitialState = {
    cptMod: undefined,
    description: undefined,
    ambulanceModB: undefined,
    isCci: undefined,
    is_59_group: undefined,
    startDate: undefined,
    endDate: undefined,
}

export const posLkpInitialState = {
    posCode: undefined,
    posName: undefined,
    posDesc: undefined,
    facilityB: undefined,
}
export const policyCategoryLkpInitialState = {
    policyCategoryLkpId: undefined,
    policyCategoryDesc: undefined,
    priority: undefined,
    hardDenialB: undefined,
    lastUpdatedAt: undefined
}

export const billTypeToClmTypeLkpInitialState = {
    billType: undefined,
    description: undefined,
    claimTypeMatch: undefined,
}

export const reasonCodeLkpInitialState = {
    reasonCode: undefined,
    reasonDesc: undefined,
    hipaaCode: undefined,
    hippaDesc: undefined,
    challengeCode: undefined,
    challengeDesc: undefined,
    customb: undefined,
    pcoCode: undefined,
    deactivatedb: undefined
}

export const CciRationaleLkpInitialState = {
    cciRationaleKey: undefined,
    cmsCciRationale: undefined,
    cciRationaleDesc: undefined,
}

export const CciLkpInitialState = {
    cciKey: undefined,
    cciDesc: undefined,
    cciNotes: undefined,
}

export const MueLkpInitialState = {
    mueRationalKey: undefined,
    description: undefined,
}

export const BoLookUpInitialState = {
    boKey: undefined,
    boDesc: undefined,
}

export const BwTypeLookUpInitialState = {
    bwTypeKey: undefined,
    description: undefined,
}

export const MaxUnitsTypesInitialState = {
    maxUnitsTypeKey: undefined,
    maxUnitsTypeDesc: undefined,
}

export const MaxUnitsLookUpInitialState = {
    maxUnitsLkpKey: undefined,
    maxUnitsTypeKey: undefined,
    description: undefined,
    comments: undefined,
}
   

export const ModifierPriorityLookUpInitialState = {
    modifier: undefined,
    priority: undefined,
}

export const ModifierInteractionLookUpInitialState = {
    mitKey: undefined,
    modifier: undefined,
    hitsOffSameModifier: undefined,
    hitsOffOtherModifier: undefined,
    hitsOffBlankModifier: undefined,
    otherHitsOffThisModifier: undefined,
    blankHitsOffThisModifier: undefined,
    modifierException: undefined,
}

export const SameOrSimilarCodeLkpInitialState = {
    cptCode:undefined,
    sameOrSimCode:undefined,
    dosFrom:undefined,
    dosTo:undefined
}
export const modifierPayPercentageInitialState={
    
}


import CustomPaper from "../../components/CustomPaper/CustomPaper";
import { NewPolicyFormState } from "../../redux/reducers/NewPolicyFormReducer";
import "./NewPolicy.css";
import { PolicyConstants } from "./PolicyConst";
import CategoryFourtyFive from "../CategoryOptions/CategoryFourtyFive";
import CategoryFourtySix from "../CategoryOptions/CategoryFourtySix";
import CategoryTwenty from "../CategoryOptions/CategoryTwenty";
import CategoryTwelve from "../CategoryOptions/CategoryTwelve";
import CategoryThirtyFive from "../CategoryOptions/CategoryThirtyFive";
import CategoryThirtyEight from "../CategoryOptions/CategoryThirtyEight";
import CategoryTwentyFive from "../CategoryOptions/CategoryTwentyFive";
import CategoryTwentyThree from "../CategoryOptions/CategoryTwentyThree";
import { useSelector } from "react-redux";
import CategoryFourtyNine from "../CategoryOptions/CategoryFourtyNine";
import CategoryThirtyTwo from "../CategoryOptions/CategoryThirtyTwo";

const CatOpt = ({ edit, viewMode }) => {
  const formState: NewPolicyFormState = useSelector(
    (state: any) => state.newPolicyForm
  );
  const showCategories = () => {
    if (formState.cat == PolicyConstants.TWENTY_THREE) {
      return <CategoryTwentyThree edit={edit} viewMode={viewMode} />
    }
    if (formState.cat == PolicyConstants.THIRTY_FIVE) {
      return <CategoryThirtyFive edit={edit} viewMode={viewMode} />
    }
    if (formState.cat == PolicyConstants.THIRTY_EIGHT) {
      return <CategoryThirtyEight edit={edit} viewMode={viewMode} />
    }
    if (formState.cat == PolicyConstants.FOURTY_FIVE) {
      return <CategoryFourtyFive edit={edit} viewMode={viewMode} />
    }
    if (formState.cat == PolicyConstants.TWENTY_FIVE) {
      return <CategoryTwentyFive edit={edit} viewMode={viewMode} />
    }
    if (formState.cat == PolicyConstants.TWELVE) {
      return <CategoryTwelve edit={edit} viewMode={viewMode} />
    }
    if (formState.cat == PolicyConstants.TWENTY) {
      return <CategoryTwenty edit={edit} viewMode={viewMode} />
    }
    if (formState.cat == PolicyConstants.FOURTY_SIX) {
      return <CategoryFourtySix edit={edit} viewMode={viewMode} />
    }
    if (formState.cat == PolicyConstants.FOURTY_NINE) {
      return <CategoryFourtyNine edit={edit} viewMode={viewMode} />
    }
    if (formState.cat == PolicyConstants.THIRTY_TWO) {
      return <CategoryThirtyTwo edit={edit} viewMode={viewMode} />
    }
  }

  return (
    <div>
      <CustomPaper
        style={{
          paddingLeft: 10,
          position: "relative",
          right: 20,
          paddingRight: 0,
          paddingTop: 10,
          paddingBottom: 6,
          boxShadow: "none",
          height: "380px",
          border:
          formState.cat == PolicyConstants.THIRTY_TWO || formState.cat == PolicyConstants.TWENTY_THREE || formState.cat == PolicyConstants.THIRTY_EIGHT || formState.cat == PolicyConstants.TWELVE
              || formState.cat == PolicyConstants.TWENTY_FIVE || formState.cat == PolicyConstants.TWENTY || formState.cat == PolicyConstants.FOURTY_FIVE
              || formState.cat == PolicyConstants.FOURTY_SIX || formState.cat == PolicyConstants.FOURTY_NINE
              ? "1px solid #D6D8DA"
              : "",
        }}
      >
        <div>{showCategories()}</div>
      </CustomPaper>
    </div>
  );
};

export default CatOpt;

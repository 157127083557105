import { ButtonGroup, Grid, IconButton } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dangerColor, navyColor } from "../../assets/jss/material-kit-react";
import CustomButton from "../../components/CustomButtons/CustomButton";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import AgGrids from "../../components/TableGrid/AgGrids";
import {
  DeleteBillTypeData,
  getBillTypeData,
  getPolicyBillTypeActionLkp,
  getPolicyBillTypeData,
  getSourceBillTypeLkpData,
  postBillTypeData,
} from "../../redux/actions/NewPolicyAction";
import { NewPolicyFormState } from "../../redux/reducers/NewPolicyFormReducer";
import { NewPolicyState } from "../../redux/reducers/NewPolicyReducer";
import { Add } from "@material-ui/icons";
import Dialogbox from "../../components/Dialog/DialogBox";
import {
  BILL_TYPE_ACTION,
  BILL_TYPE_LINK,
} from "../../redux/actions/NewPolicyFormActionTypes";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import React from "react";
import swal from "sweetalert2";
import XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { uploadBillTypeData } from "../../redux/actions";
import {
  AllCommunityModules,
  ModuleRegistry,
  ServerSideTransactionResultStatus,
} from "@ag-grid-community/all-modules";
import { Api } from "@mui/icons-material";
import { exportedExcelFileData } from "../../components/ExportExcel/ExportExcelFile";

const _ = require("lodash");

const BillType = ({ fromViewPolicy, policyId, edit, showImportButton }) => {
  const dispatch = useDispatch();
  const fullWidth = true;
  const maxWidth = "md";
  const [deletedById, setDeletedById] = useState("");

  const [data, setData] = useState([]);

  const [exportData, setExportData] = useState([]);

  const [sourceBillTypeLkpData, setsourceBillTypeLkpData] = useState([]);
  const [openBillType, setopenBillType] = useState(false);
  const [popup, setPopUp] = useState(false);
  const [postPopup, setpostPopup] = useState(false);
  const [billTypeKeyPopup, setbillTypeKeyPopup] = useState(false);

  const [selectedData, setSelectedData] = useState("");
  const [selectedValue, setselectedValue] = useState("");
  const [selectedPolicyBillType, setSelectedPolicyBillType] =
    useState(undefined);
  const [selectedPolicyAction, setSelectedPolicyAction] = useState(undefined);
  const [selectedActionValue, setselectedActionValue] = useState("");
  const [unMatchedBillType, setUnMatchedBillType] = useState([]);
  const [billTypeLinkLkp, setBillTypeLinkLkp] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [fileError, setFileError] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const formState: NewPolicyFormState = useSelector(
    (state: any) => state.newPolicyForm
  );

  const newpolicyState: NewPolicyState = useSelector(
    (state: any) => state.newPolicy
  );

  const openDeletePopUp = () => {
    setPopUp(true);
    setUpdateData(false);
  };

  const openpostPopup = () => {
    setpostPopup(true);
    setUpdateData(false);
  };

  const openbillTypeKeyPopup = () => {
    setbillTypeKeyPopup(true);
  };

  const handletoclose = () => {
    setopenBillType(false);
    setPopUp(false);
    setpostPopup(false);
    setbillTypeKeyPopup(false);
    setOpen(false);
  };
  const closePopUp = () => {
    setUpdateData(true);
    setPopUp(false);
  };

  let id;

  useEffect(() => {
    if (policyId != null) {
      getPolicyBillTypeData(dispatch, policyId);
    }
    getBillTypeData(dispatch);
    getPolicyBillTypeActionLkp(dispatch);
    getSourceBillTypeLkpData(dispatch);
  }, []);
  const billTypeLkpData = newpolicyState.getbilltypedata?.map((bd) => {
    return {
      label: bd.billTypeCode + "  -  " + bd.billTypeLinkDesc,
      value: bd.billTypeLinkKey,
    };
  });

  const billTypeActionLkpData = newpolicyState.getPolicyBillTypeActionLkp?.map(
    (p, i) => {
      return {
        label: p.policyBillTypeActionCode,
        value: p.policyBillTypeActionKey,
      };
    }
  );
  const gridRef = useRef(null);
  let exportd: any;

  useEffect(() => {
    if (newpolicyState.policyBillTypeData.length > 0) {
      setData(newpolicyState.policyBillTypeData);
      exportd = newpolicyState.policyBillTypeData.map((k) => {
        return {
          policyId: k.policyId,
          billType: k.billType,
          billTypeDesc: k.billTypeDesc,
        };
      });
      setExportData(exportd);
      setNumberOfRows(newpolicyState.policyBillTypeData.length);
      setUpdateData(true);
    }
  }, [newpolicyState.policyBillTypeData]);

  useEffect(() => {
    let sourceBillTypeLkpData1 = newpolicyState.getSourceBillTypeLkpData?.map(
      (bd, i) => {
        return {
          billTypeLkp: bd.billType,
          billTypeDescLkp: bd.billTypeDesc,
          inpatientB: bd.inpatientB,
          startDate: bd.startDate,
          endDate: bd.endDate,
          claimTypeMatch: bd.claimTypeMatch,
        };
      }
    );

    setsourceBillTypeLkpData(sourceBillTypeLkpData1);
  }, [newpolicyState.getSourceBillTypeLkpData]);

  useEffect(() => {
    let obj = [];
    var d = require("lodash");
    obj = d.differenceWith(sourceBillTypeLkpData, data, function (s, d) {
      return s["billTypeLkp"] === d["billType"];
    });
    setUnMatchedBillType(obj);
  }, [data, sourceBillTypeLkpData]);

  const columnDefs = [
    {
      field: "billType",
      headerName: "Bill Type",
      headerTooltip: "Bill Type",
    },
    {
      field: "billTypeDesc",
      headerName: "Bill Type Desc",
      headerTooltip: "Bill Type Desc",
    },
    {
      headerName: "Action",
      minWidth: 130,
      cellRenderer: (row) => {
        return (
          <ButtonGroup>
            <CustomButton
              disabled={fromViewPolicy}
              variant="contained"
              style={{
                height: 18,
                marginTop: 1,
                fontSize: "11px",
                textTransform: "capitalize",
                backgroundColor: "red",
                color: "white",
              }}
              onClick={() => {
                id = row.data.policyBillTypeKey;
                setDeletedById(row.data.policyBillTypeKey);
                openDeletePopUp();
              }}
            >
              Delete
            </CustomButton>
          </ButtonGroup>
        );
      },
    },
  ];
  const billTypeColmn = [
    {
      field: "billTypeLkp",
      headerName: "Bill Type",
      minWidth: 100,
      headerTooltip: "Bill Type",
      checkboxSelection: true,
    },
    {
      field: "billTypeDescLkp",
      headerName: "Bill Type Desc",
      minWidth: 100,
      headerTooltip: "Bill Type Desc",
    },
  ];

  const openPopUp = () => {
    setopenBillType(true);
  };

  const onFilterChanged = (params) => {
    setNumberOfRows(params.api.getDisplayedRowCount());
  };
  ModuleRegistry.registerModules(AllCommunityModules);
  const [storeParam, setStoreParams] = useState();

  let filteredData = [];

  const onGridReady = (params) => {
    let checkData = newpolicyState.policyBillTypeData;
    const dataSource = {
      rowCount: null,
      getRows: async (params) => {
        try {
          let sortType = "";
          let sortableColumn = "";
          let BillType = {};
          if (!(params.filterModel == null || undefined)) {
            BillType = {
              billType: params.filterModel.billType
                ? params.filterModel.billType.filter
                : "",
              billTypeDesc: params.filterModel.billTypeDesc
                ? params.filterModel.billTypeDesc.filter
                : "",
              isSort: sortType != "" ? sortType : "",
              sortColumn: sortableColumn != "" ? sortableColumn : "",
            };
          }

          let StringData = [];
          if (filteredData.length == 0) {
            filteredData = newpolicyState.policyBillTypeData;
          }
          if (
            BillType["billType"].length > 0 ||
            BillType["billTypeDesc"].length > 0
          ) {
            StringData = checkBillTypeData(BillType);
            filteredData = StringData;
          } else {
            filteredData = newpolicyState.policyBillTypeData;
          }
          if (filteredData.length == 0) {
            swal.fire({
              icon: "info",
              text: "No data found.",
              confirmButtonColor: navyColor,
              confirmButtonText: "OK",
            });
          }
          exportd = filteredData.map((k) => {
            return {
              policyId: k.policyId,
              billType: k.billType,
              billTypeDesc: k.billTypeDesc,
            };
          });
          setExportData(exportd);
          setNumberOfRows(filteredData.length);
          setTimeout(() => {
            const rowsThisPage = filteredData.slice(
              params.startRow,
              params.endRow
            );
            let lastRow = -1;
            if (filteredData.length <= params.endRow) {
              lastRow = filteredData.length;
            }
            params.successCallback(rowsThisPage, lastRow);
          }, 500);
        } catch (error) {
          params.failCallback();
        }
      },
    };
    params.api?.setDatasource(dataSource);
  };

  let tableBillType;

  const checkBillTypeData = (billType) => {
    let billTypeValues = billType["billType"]
      .split(/,(?=\S)|,$/)
      .filter(Boolean);
    let p = newpolicyState.policyBillTypeData.filter((k, i) => {
      if (
        billTypeValues.some((value) =>
          k.billType.toLowerCase().includes(value.toLowerCase())
        ) &&
        k.billTypeDesc
          .toLowerCase()
          .includes(billType["billTypeDesc"].toLowerCase())
      ) {
        return { k };
      }
    });
    return p;
  };

  function billTypeData() {
    setOpen(true);
    setSelectedFile(undefined);
  }
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const [numberOfRows, setNumberOfRows] = useState(0);

  const handleUploadFile = (file) => {
    var allowedFiles = [".xlsx", ".csv"];
    var regex = new RegExp(
      "([a-zA-Z0-9s_\\.-:()])+(" + allowedFiles.join("|") + ")$"
    );
    if (file != undefined) {
      if (!regex.test(file.name.toLowerCase())) {
        setOpen(false);
        swal.fire({
          icon: "error",
          text: "Please upload valid file",
          confirmButtonColor: navyColor,
          confirmButtonText: "OK",
        });
        return false;
      } else {
        return true;
      }
    }
  };

  const onFileUpload = async () => {
    const formData: FormData = new FormData();
    formData.append("uploadfile", selectedFile);
    let validation = handleUploadFile(selectedFile);
    if (validation) {
      let fileReader = new FileReader();
      fileReader.readAsArrayBuffer(selectedFile);
      fileReader.onload = async (e) => {
        let arrayBuffer: any = [];
        arrayBuffer = fileReader.result;
        let uploaddata = new Uint8Array(arrayBuffer);
        let arr = new Array();
        for (var i = 0; i != uploaddata.length; ++i)
          arr[i] = String.fromCharCode(uploaddata[i]);
        let bstr = arr.join("");
        let workbook = XLSX.read(bstr, { type: "binary" });
        let first_sheet_name = workbook.SheetNames[0];
        let worksheet = workbook.Sheets[first_sheet_name];
        var arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        let array: any = [];
        let exportarray: any = [];
        array = exportarray.concat(arraylist);
        let uniqueCodes = [];
        let error = false;
        array.forEach((o, i) => {
          if (!checkIsPresent(uniqueCodes, o.BILLTYPE + "-" + o.BILLTYPEDESC)) {
            uniqueCodes.push(o.BILLTYPE + "-" + o.BILLTYPEDESC);
          } else {
            setOpen(false);
            error = true;
            swal.fire({
              icon: "error",
              title: "Error",
              text:
                "Duplicate Row at " +
                (i + 2) +
                " - " +
                "BILLTYPE is : " +
                o.BILLTYPE +
                " and " +
                "BILLTYPEDESC is : " +
                o.BILLTYPEDESC,
              confirmButtonColor: navyColor,
              confirmButtonText: "OK",
            });
          }
        });
        let importPolicyId = undefined;
        array.forEach((d) => {
          if (formState.policyId != d.POLICYID) {
            importPolicyId = d.POLICYID;
            if (d.POLICYID == undefined) {
              importPolicyId = -1;
            }
          } else if (importPolicyId == undefined) {
            importPolicyId = policyId;
          }
        });
        if (importPolicyId == policyId) {
          if (!error) {
            setOpen(false);
            await uploadBillTypeData(dispatch, formData);
            getPolicyBillTypeData(dispatch, formState.policyId);
          }
        } else {
          setOpen(false);
          if (importPolicyId == -1) {
            swal.fire({
              icon: "error",
              title: "Error",
              text: "Policy Id is blank.",
              confirmButtonColor: navyColor,
              confirmButtonText: "OK",
            });
          } else {
            swal.fire({
              icon: "error",
              title: "Error",
              text: "Policy Id not matched.",
              confirmButtonColor: navyColor,
              confirmButtonText: "OK",
            });
          }
        }
      };
    }
  };

  function checkIsPresent(uniqueCodes, value) {
    const index = uniqueCodes.indexOf(value);
    return index >= 0;
  }

  let tableData = [];
  const onSelectionChanged = async (event) => {
    let a = event.api.getSelectedRows();
    tableData.push(a);
    setSelectedData(a);
  };

  return (
    <>
      {fromViewPolicy || edit ? (
        <div style={{ marginTop: "-20px" }}>
          <IconButton
            onClick={openPopUp}
            style={{
              backgroundColor: navyColor,
              float: "right",
              color: "white",
              padding: 5,
              opacity: fromViewPolicy ? 0.7 : 1,
              pointerEvents: fromViewPolicy ? "none" : "visible",
            }}
          >
            <Add />
          </IconButton>
        </div>
      ) : undefined}
      <GridContainer>
        <GridItem sm={4} md={4} xs={4}>
          {fromViewPolicy || edit ? (
            <div
              style={{
                marginRight: 60,
                marginTop: "10px",
              }}
            >
              <CustomSelect
                isDisabled={!edit ? undefined : fromViewPolicy}
                options={billTypeLkpData}
                labelText={"Bill Type Link"}
                hoverData={billTypeLkpData.map((l) => {
                  return l.label;
                })}
                onSelect={(e) => {
                  if (e == null) {
                    setSelectedPolicyBillType(undefined);
                  }
                  if (e != null) {
                    dispatch({ type: BILL_TYPE_LINK, payload: e });
                    setselectedValue(e.value);
                    setSelectedPolicyBillType(e);
                  }
                }}
                value={formState.billTypeLink}
              ></CustomSelect>
              <CustomSelect
                isDisabled={!edit ? undefined : fromViewPolicy}
                hoverData={billTypeActionLkpData.map((l) => {
                  return l.label;
                })}
                options={billTypeActionLkpData}
                labelText={"Bill Type Action"}
                onSelect={(e) => {
                  if (e == null) {
                    setSelectedPolicyAction(undefined);
                  }
                  if (e != null) {
                    dispatch({ type: BILL_TYPE_ACTION, payload: e });
                    setselectedActionValue(e.value);
                    setSelectedPolicyAction(e);
                  }
                }}
                value={formState.billTypeAction}
              ></CustomSelect>
            </div>
          ) : undefined}
        </GridItem>

        <GridItem sm={8} md={8} xs={8} spacing={12}>
          {data.length > 0 && updateData == true ? (
            <div
              style={{
                height: window.innerHeight / 2.6,
              }}
            >
              <AgGrids
                columnDefs={columnDefs}
                ref={gridRef}
                onFilterChanged={onFilterChanged}
                onGridReady={(params) => {
                  onGridReady(params);
                }}
                cacheOverflowSize={2}
                maxConcurrentDatasourceRequests={1}
                infiniteInitialRowCount={1}
                cacheBlockSize={1000}
                maxBlocksInCache={1}
                modules={AllCommunityModules}
                debug={true}
                rowBuffer={0}
                animateRows={true}
                rowModelType={"infinite"}
              />

              <small
                style={{ position: "relative", fontSize: "12px", top: "7px" }}
              >
                Number of rows : {numberOfRows}
              </small>
            </div>
          ) : undefined}
        </GridItem>
      </GridContainer>
      {fromViewPolicy || edit ? (
        <div
          style={{
            float: "right",
            opacity: fromViewPolicy ? 0.7 : 1,
            pointerEvents: fromViewPolicy ? "none" : "visible",
            marginTop: "30px",
          }}
        >
          <ButtonGroup>
            <CustomButton
              variant={"contained"}
              disabled={!showImportButton}
              onClick={() => {
                billTypeData();
              }}
              onChange={(event) => {}}
              style={{
                backgroundColor: navyColor,
                color: "white",
                margin: 5,
                padding: 4,
                fontSize: 12,
                textTransform: "capitalize",
              }}
            >
              Import
            </CustomButton>
            {data.length > 0 ? (
              <CustomButton
                variant={"contained"}
                onClick={() => {
                  exportedExcelFileData(
                    exportData,
                    formState.policy + "/" + formState.version,
                    "BillType"
                  );
                }}
                onChange={(event) => {}}
                style={{
                  backgroundColor: navyColor,
                  color: "white",
                  margin: 5,
                  padding: 4,
                  fontSize: 12,
                  textTransform: "capitalize",
                }}
              >
                Export
              </CustomButton>
            ) : undefined}
          </ButtonGroup>
        </div>
      ) : undefined}

      <Dialogbox
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openBillType}
        onClose={handletoclose}
        style={{ overflowY: "scroll" }}
        disableBackdropClick={true}
        title={"Policy Bill Type"}
        message={
          <div style={{ height: window.innerHeight / 1.8, marginTop: "-10px" }}>
            <AgGrids
              columnDefs={billTypeColmn}
              rowData={unMatchedBillType}
              rowSelection={"multiple"}
              onSelectionChanged={onSelectionChanged}
              // onGridReady={onGridReady}
              // isRowSelectable={isRowSelectable}
            />
          </div>
        }
        actions={
          <ButtonGroup style={{ marginTop: "-30px" }}>
            <CustomButton
              variant={"contained"}
              onClick={() => {
                openpostPopup();
                // handletoclose();
                // postBillTypeData(dispatch, selectedData, policyId);
              }}
              style={{
                backgroundColor: navyColor,
                color: "white",
                margin: 10,
                padding: 4,
                fontSize: 12,
                textTransform: "capitalize",
              }}
            >
              Yes
            </CustomButton>
            <CustomButton
              onClick={() => {
                handletoclose();
              }}
              variant={"contained"}
              style={{
                backgroundColor: dangerColor,
                color: "white",
                margin: 10,
                padding: 4,
                fontSize: 12,
                textTransform: "capitalize",
              }}
            >
              No
            </CustomButton>
          </ButtonGroup>
        }
      />
      <Dialogbox
        open={open}
        onClose={handletoclose}
        title={"Upload Source File"}
        message={
          <input
            type="file"
            accept=".xlsx"
            onChange={(event) => {
              let file = event.target.files[0];
              setSelectedFile(file);
              let flag = handleUploadFile(event.target.files[0]);
              setFileError(flag);
            }}
          />
        }
        actions={
          <ButtonGroup>
            {selectedFile ? (
              <CustomButton
                onClick={() => {
                  onFileUpload();
                }}
                style={{
                  backgroundColor: navyColor,
                  color: "white",
                  marginRight: 10,
                  padding: 4,
                  fontSize: 12,
                  textTransform: "capitalize",
                }}
              >
                upload
              </CustomButton>
            ) : undefined}
            <CustomButton
              onClick={handletoclose}
              style={{
                backgroundColor: dangerColor,
                color: "white",
                textTransform: "capitalize",
                fontSize: 12,
                padding: 4,
              }}
            >
              cancel
            </CustomButton>
          </ButtonGroup>
        }
      />

      <Dialogbox
        open={postPopup}
        onClose={handletoclose}
        disableBackdropClick={true}
        title={"Confirm"}
        message={"Would you like to Add This Record ?"}
        actions={
          <ButtonGroup>
            <CustomButton
              onClick={() => {
                handletoclose();
                postBillTypeData(dispatch, selectedData, policyId);
              }}
              style={{
                backgroundColor: navyColor,
                color: "white",
                marginRight: 10,
                padding: 4,
                fontSize: 12,
                textTransform: "capitalize",
              }}
            >
              Yes
            </CustomButton>
            <CustomButton
              onClick={handletoclose}
              style={{
                backgroundColor: dangerColor,
                color: "white",
                //  margin: 10,
                padding: 4,
                fontSize: 12,
                textTransform: "capitalize",
              }}
            >
              No
            </CustomButton>
          </ButtonGroup>
        }
      />

      <Dialogbox
        open={popup}
        onClose={handletoclose}
        disableBackdropClick={true}
        title={"Confirm"}
        message={"Would you like to Delete This Record ?"}
        actions={
          <ButtonGroup>
            <CustomButton
              onClick={async () => {
                DeleteBillTypeData(dispatch, deletedById, policyId);
                handletoclose();
              }}
              style={{
                backgroundColor: navyColor,
                color: "white",
                marginRight: 10,
                padding: 4,
                fontSize: 12,
                textTransform: "capitalize",
              }}
            >
              Yes
            </CustomButton>
            <CustomButton
              onClick={closePopUp}
              style={{
                backgroundColor: dangerColor,
                color: "white",
                //  margin: 10,
                padding: 4,
                fontSize: 12,
                textTransform: "capitalize",
              }}
            >
              No
            </CustomButton>
          </ButtonGroup>
        }
      />
    </>
  );
};
export default BillType;

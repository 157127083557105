import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { CHANGE_MODIFIER_KEY } from "../../redux/actions/NewPolicyFormActionTypes";
import { NewPolicyFormState } from "../../redux/reducers/NewPolicyFormReducer";
import { NewPolicyState } from "../../redux/reducers/NewPolicyReducer";
import "./CategoryOptions.css";
import { getChangeModifier } from "../../redux/actions";
import { PolicyConstants } from "../NewPolicy/PolicyConst";
import StarIcon from '@mui/icons-material/Star';

const CategoryTwenty = ({ edit, viewMode }) => {
    const formState: NewPolicyFormState = useSelector(
        (state: any) => state.newPolicyForm
    );
const updatedState: NewPolicyState = useSelector(
        (state: any) => state.newPolicy
    );
 const dispatch = useDispatch();
    useEffect(() => {
        if (updatedState.getChangeModifier.length == 0) {
            getChangeModifier(dispatch);
        }
    }, []);

    const [changeModifier, setChangeModifierKey] = useState({});

    const changeModifierLkp = updatedState.getChangeModifier?.map((k) => {
        return { label: k.description, value: k.changeModifierKey }
    })

    useEffect(() => {
        if (formState.cat === PolicyConstants.TWENTY) {
            if (
                formState.changeModifierKey == "" ||
                formState.changeModifierKey == null
            ) {
                dispatch({
                    type: CHANGE_MODIFIER_KEY,
                    payload: changeModifierLkp[0],
                });
            }
        }
    }, [formState]);

    return (
        <div>
            <GridContainer>
                <GridItem sm={2} md={2} xs={2}>
                    <small
                        style={{
                            fontSize: 13,
                            color: "black",
                            fontWeight: 400,
                            position: "relative",
                            top: 15,
                        }}
                    >
                        Change Modifier Logic {<StarIcon style={{position:"relative", bottom:"4px",fontSize:"7px"}}/>}
                    </small>
                </GridItem>
                <GridItem sm={4} md={4} xs={4}>
                    <div className="changeModifier">
                        <CustomSelect
                            error={formState.errors?.changeModifierKey}
                            options={changeModifierLkp}
                            value={changeModifierLkp.filter(function (option) {
                                return option.value == formState.changeModifierKey;
                            })}
                            isDisabled={!edit ? undefined : viewMode}
                            catTitle={changeModifierLkp.filter((option, index) => {
                                if (formState.changeModifierKey == option.value) {
                                    return option
                                }
                            })}
                            onSelect={(event) => {
                                setChangeModifierKey(event);
                                dispatch({
                                    type: CHANGE_MODIFIER_KEY,
                                    payload: event.value,

                                });
                            }}
                        />
                    </div>

                </GridItem>

            </GridContainer>
        </div>
    );
};
export default CategoryTwenty;

import { Message } from "@mui/icons-material";
import { act } from "react-dom/test-utils";
import { DIALOG } from "../actions/actionTypes";

export interface DialogReducerState {
    isDialog: boolean
    message:string
    title:string
}

const initialState: DialogReducerState = {
    isDialog: false,
    message:'',
    title:'',
};

export default function DialogReducer(
  state = initialState,
  action: { type: string; payload: any }
): DialogReducerState {
  switch (action.type) {
    case DIALOG:
      //@ts-ignore
      return { ...state, isDialog: action.payload, message:action.message,title:action.title };
    default:
      return state;
  }
}

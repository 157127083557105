export const POLICY = 'policy';
export const POLICY_ID = 'policyId';
export const CUSTOM = 'custom';
export const CLONED_POLICY_ID = 'clonedPolicyId';
export const CLONED_POL_VER = 'clonedPolVer';
export const VERSION = 'version';
export const REASON = 'reason';
export const CAT = 'CAT';
export const MEDICAL_POLICY = 'medicalPolicy';
export const SUB_POLICY = 'subPolicy';
export const REFERENCE = 'refernce';
export const CLAIM_TYPE = 'cliamType';
export const PROD = 'prod';
export const DEACTIVATED = 'deactivated';
export const DISABLED = 'disabled';
export const ROLE_DESCRIPTION = 'roleDescription';
export const LOB = 'lob';
export const PRODUCT_TYPE = 'productType';
export const NOTES = 'notes';
export const POLICY_SUMMARY = 'policySummary';
export const POLICY_EXPLANATION = 'policyExaplantion';
export const REFERENCE_SRC_DESC = 'refernceSrcDesc';
export const REFERNCE_SRC_TITLE_DESC = 'refernceSrcTitleDesc';
export const SOURCE_INDICATOR = 'sourceIndicator';
export const CREATEDDATE = 'createdDate';
export const GENDER = 'gender';
export const PROCEDURE_MIN_AGE = 'prodMinAge';
export const TAX_ID_LOGIC = 'taxIdLogic';
export const TAXONOMY_LOGIC = 'taxonomyLogicFk';
export const ENFORCE_BEFORE_CAT = 'enforceBefCat';
export const PRIORITY = 'priority';
export const PROCEDURE_MAX_AGE = 'procedureMaxAge';
export const NPI = 'npi';
export const POS_LINK = 'posLink';
export const REVENUE_CODE_CLAIM_LINK = 'revenueCodeClaimLink';
export const CPT_LINK = 'cptLink';

export const JIRA_ID = 'jiraId';
export const JIRA_DESCRIPTION = 'jiraDescription';
export const JIRA_LINK = 'jiraLink';
export const POLICY_CHANGES_KEY = 'policyChangesKey';
export const JIRA_ISOPEN = 'jiraIsOpen';
export const IS_JIRA_OPEN = 'isJiraOpen';
export const USER_ID = 'userId';
export const TEMP_USER = 'tmpUser';
export const SET_SELECTED_POLICY = 'setSelectedPolicy';
export const EDIT_POLICY = 'editPolicy';
export const EDIT_CHANGES = 'editChanges';
export const NEW_POLICY_VALIDATION = 'newPolicyValidation';
export const GET_POLICY_NUMBER = 'getPolicyNumber';
export const ALLOW_CMS_NCCI_MODIFIER = 'allowCmsNcciModifiers';
export const IGNORE_MODIFIER_59_GROUPS = 'ignoreModifier59Groups';
export const AS_GROUP_B = 'asgroupb';
export const TC_26_MOD_B = 'ts26modb';
export const REFERENCE_CLAIM_TYPE = 'referenceClaimType';
export const EDIT_POLICY_TABLE_CHANGES = 'editPolicyTable';
export const UPDATE_NEW_POLICY = 'updateNewPolicy';
export const RESET_STATE = 'resetState';
export const DIALOG_CAT_RESET_STATE = 'dialogCatResetState';
export const DIALOG_REASON_RESET_STATE = 'dialogReasonCatResetState';
export const CHANGES_ISOPEN_B = 'getChangesId';
export const REASON_DESC = 'reasonDesc';
export const CAT_DESC = 'catDesc';
export const CAT_CHECK = 'catCheck';
export const REASON_CHECK = 'reasonCheck';
export const TEMP_CAT_DESC = 'tempCatDesc';
export const TEMP_CAT_CHECK = 'tempCatCheck';
export const TEMP_REASON_DESC = 'tempReasonDesc';
export const TEMP_REASON_CHECK = 'tempReasonCheck';
export const TEMP_CAT_CODE = 'tempCatCode';
export const TEMP_REASON_CODE = 'tempReasonCode';
export const IDX = 'idx';
export const ERROR_RESET_STATE = 'errorResetState';
export const CCI_KEY = 'ccikey';
export const BW_TYPE_KEY = 'bwTypeKey';
export const MAX_UNITS_TYPE = 'maxUnitsType';
export const MOD_INTRACTION_TYPE = 'modIntractionType';
export const BY_PASS_MOD = 'byPassMod';
export const MUTUALLY_EXCLUSIVE = 'mutuallyExclusive';
export const DENY = 'denyType';
export const IS_JIRA = 'isJira';
export const IS_JIRA_DESC = 'isJIraDesc';
export const BILLED_WITH = 'billedWith';
export const BILL_TYPE_LINK = 'billTypeLink';
export const BILL_TYPE_ACTION = 'billTypeAction';
export const CHANGE_MODIFIER_KEY = 'changeModifierKey';
export const MODIFIER_PAY_PERCENTAGE = 'Modifier Pay Percentage';
export const CONDITION_CODE_ACTION = 'conditionCodeAction';

// export const BOTH  = "both"
// export const HEADER_LEVEL  = "headerLevel"
// export const LINE_LEVEL = "lineLevel"
export const LINE_OR_HEADER_OR_BOTH = 'lineOrheaderOrBoth';
export const GET_DIAGNOSIS_DATA = 'getDiagnosisData';
export const POST_DIAGNOSIS_DATA = 'postDiagnosis';
export const EDIT_DIAGNOSIS_DATA = 'editDiagnosis';
export const GET_ACTION_DATA = 'actiondata';
export const POST_HEADER_PDX = 'postheaderpdx';

export const GET_BILL_TYPE_DATA = 'getbilltypedata';
export const POLICY_BILL_TYPE_DATA = 'policybilltypedata';
export const GET_SOURCE_BILL_TYPE_LKP_DATA = 'getsourcebilltypelkpdata';
export const POST_BILL_TYPE_DATA = 'postBillTypeData';
export const GET_POLICY_BILL_TYPE_ACTION_LKP = 'getPolicyBillTypeActionLkp';

export const GET_MODIFIER_PAY_PERCENTAGE_LKP = 'getModifierPayPercentageLkp';
export const MODIFIER_PAY_PERCENTAGE_DATA = 'modifierPayPercentageData';

export const GET_CONDITION_TYPE_DATA = 'getConditionTypeData';
export const POLICY_CONDITION_TYPE_DATA = 'policyConditiontypedata';
export const POST_CONDITION_TYPE_DATA = 'postConditionTypeData';

export const LINE_OR_HEADER_OR_PRINC = 'lineOrheaderOrPrinc';

export const PATHS = 'paths';

export const MEDICAL_CHECK = 'medicalCheck';
export const MEDICAL_DESC = 'medicalDesc';
export const DIALOG_MEDICAL_RESET_STATE = 'dialogMedicalResetState';
export const TEMP_MEDICAL_CHECK = 'tempMedicalCheck';
export const TEMP_MEDICAL_DESC = 'tempMedicalDesc';
export const TEMP_MEDICAL_POLICY_CODE = 'tempMedicalPolicyCode';

export const SUBPOLICY_CHECK = 'subpolicyCheck';
export const DIALOG_SUB_POLICY_RESET_STATE = 'dialogSubPolicyResetState';
export const SUBPOLICY_DESC = 'subpolicyDesc';
export const TEMP_SUBPOLICY_CHECK = 'tempSubPolicyCheck';
export const TEMP_SUBPOLICY_DESC = 'tempSubPolicyDesc';
export const TEMP_SUBPOLICY_CODE = 'tempSubPolicyCode';

export const ENFO_CAT_CHECK = 'enfoCatCheck';
export const ENFO_CAT_DESC = 'enfoCatDesc';
export const TEMP_ENFO_CAT_CHECK = 'temporaryEnfoCatCheck';
export const TEMP_ENFO_CAT_DESC = 'temporaryEnfoCatDesc';
export const DIALOG_ENFO_CAT_RESET_STATE = 'dialogEnfoCatResetState';
export const TEMP_ENFORCE_BEFORE_CAT_CODE = 'tempEnforceBeforeCatCode';

export const MIN_AGE_ID_CHECK = 'minAgeIdCheck';
export const MIN_AGE_DESC = 'minageDesc';
export const TEMP_MIN_AGE_ID_CHECK = 'temporaryMinAgeIdCheck';
export const TEMP_MIN_AGE_DESC = 'tempoaryMinAgeDesc';
export const DIALOG_MIN_AGE_RESET_STATE = 'dialogMinAgeResetState';
export const TEMP_PROCEDURE_MIN_AGE_CODE = 'tempProcedureMinAgeCode';

export const MAX_AGE_ID_CHECK = 'maxAgeIdCheck';
export const MAX__AGE_DESC = 'maxageDesc';
export const TEMP_MAX__AGE_ID_CHECK = 'temporaryMaxAgeIdCheck';
export const TEMP_MAX_AGE_DESC = 'tempoaryMaxAgeDesc';
export const DIALOG_MAX_AGE_RESET_STATE = 'dialogMaxAgeResetState';
export const TEMP_PROCEDURE_MAX_AGE_CODE = 'tempProcedureMaxAgeCode';
export const NEW_POLICY_TABS = 'newPolicyTabs';
export const USER_DETAILS = 'userDetails1';
export const CLAIM_TYPE_LINK = 'claimTypeLink';

export const FREQUENCY = 'frequency';
export const RANKING = 'ranking';
export const PAYMENT = 'payment';
export const UNITS = 'units';
export const DURATION = 'duration';
export const DURATION_DROPDOWN = 'durationDropdown';
export const FREQUENCY_RESET = 'frequencyReset';
